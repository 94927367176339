import React, { useEffect, useState, useRef } from 'react';
import {
  Toolbar,
  SaveButton,
  List,
  Button,
  Create,
  Edit,
  BooleanInput,
  Confirm,
  TextInput,
  TextField,
  EditButton,
  NumberInput,
  Filter,
  ReferenceField,
  Loading,
  FormDataConsumer,
  SelectInput,
  TabbedForm,
  FormTab,
  RadioButtonGroupInput,
  ReferenceInput,
  ArrayInput,
  SimpleFormIterator,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  useListContext,
  DateField,
  useRecordContext,
  useShowController,
  useDataProvider,
  useRedirect,
  Pagination,
  ShowButton,
  TopToolbar,
  useTranslate,
  Show,
} from 'react-admin';
import { gapi } from 'gapi-script';
import { Editor } from '@tinymce/tinymce-react';
import Draggable from 'react-draggable';
import { CirclePicker } from 'react-color';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import IconCancel from '@material-ui/icons/Cancel';
import ShareIcon from '@material-ui/icons/Share';
import VisibilityIcon from '@material-ui/icons/Visibility';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { syncedStore, getYjsValue, SyncedXml } from '@syncedstore/core';
import {
  IconButton, Dialog, DialogActions, DialogContent,
  Card, CardActions, CardContent,
  Typography, InputAdornment, Box, makeStyles,
  TextField as MUITextField, Button as MuiButton,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Badge from '@material-ui/core/Badge';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Parse from 'parse';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import UsuarioParcelas from './cursos/UsuarioParcelas';
import { globalStore, store } from './componentes/store';
import SelectCrud from './componentes/SelectCrud';
import Borrador from './static/img/borrador.png';
import Tiza from './static/img/tiza.png';
import VoltarIcon from './static/img/Voltar.png';
import CreateTipoEvalButton from './componentes/CreateTipoEvalButton';
import AddIcon from './static/img/Add.png';
import CreateProfButton from './componentes/CreateProfButton';
import EventCheck from './componentes/EventCheck';
import CreateModuloButton from './componentes/CreateModuloButton';
import {
  BoardAntigua,
  Board,
} from './componentes/Boards';
// import SelectCrud from './componentes/SelectCrud';
import { DateInput, TimeInput } from './DateInput';
import Color from './postit/images/color.png';
import MiniUser from './static/img/miniuser.png';
import PdfIcon from './static/img/pdf.png';
import ZipIcon from './static/img/zip.png';
import ExcelIcon from './static/img/excel.png';
import PPtIcon from './static/img/powerpoint.png';
import WordIcon from './static/img/nopath---copia--2--1@1x.png';
import YoutubeIcon from './static/img/youtube.png';
import ImageIcon from './static/img/picture.png';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CancelIcon from '@mui/icons-material/Cancel';
// import { MisIconos } from './material';
import { ButtonDefault } from './componentes/Buttons';

const createReactClass = require('create-react-class');
// api key
// AIzaSyDndqfBz9I67JZjW3h36XYl_S8jj4ahDSk
// Id cliente
// 147265468486-gjn3tgfd5s132kf1b71rr8fpbbg2l2o7.apps.googleusercontent.com

const API_KEY = 'AIzaSyDndqfBz9I67JZjW3h36XYl_S8jj4ahDSk';
const CLIENT_ID = '147265468486-gjn3tgfd5s132kf1b71rr8fpbbg2l2o7.apps.googleusercontent.com';
// Array of API discovery doc URLs for APIs
const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES = 'https://www.googleapis.com/auth/drive.metadata.readonly';

const docY = getYjsValue(store);
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  sticky: {
    position: 'sticky',
    left: 0,
    backgroundColor: 'white',
    boxShadow: '5px 2px 5px #EAECEE',
    borderRight: '2px solid #EAECEE',
  },
});

function ProfFilter(props) {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <TextInput variant="outlined" label={translate('ra.label.buscar_nome')} source="nombre" alwaysOn />
      <ReferenceInput
        perPage={100000}
        emptyText="Todos"
        label={translate('ra.label.buscar_profe')}
        alwaysOn
        sort={{ field: 'nombre', order: 'ASC' }}
        variant="outlined"
        style={{ minWidth: '50%' }}
        source="profId"
        reference="profesores"
        cursor="pointer"
      >
        <SelectInput options={{ autoWidth: true }} optionText="nombre" />
      </ReferenceInput>
    </Filter>
  );
}

function ListActions() {
  const translate = useTranslate();
  return (
    <TopToolbar>
      {localStorage.getItem('perfil')
              && (localStorage.getItem('perfil') === 'profesor' || localStorage.getItem('perfil') === 'alumno')
        ? null : (
          <Button href="#/cursos/create" label="ra.label.criar_curso">
            <img src={AddIcon} style={{ width: 35 }} alt="Agregar" />
          </Button>
        )}
    </TopToolbar>
  );
}

export function CursoList(props) {
  return (
    <List
      {...props}
      exporter={false}
      perPage={100}
      sort={{ field: 'nombre', order: 'ASC' }}
      actions={<ListActions />}
      filters={localStorage.getItem('perfil')
              && (localStorage.getItem('perfil') === 'profesor' || localStorage.getItem('perfil') === 'alumno')
        ? null : <ProfFilter />}
      filter={
        localStorage.getItem('profe')
        && localStorage.getItem('perfil')
        && localStorage.getItem('perfil') === 'profesor'
          ? { profId: JSON.parse(localStorage.getItem('profe')).objectId }
          : localStorage.getItem('alumno')
        && localStorage.getItem('perfil')
        && localStorage.getItem('perfil') === 'alumno'
            ? { alumnos: JSON.parse(localStorage.getItem('alumno')).objectId }
            : null
}
      pagination={<Pagination rowsPerPageOptions={[100, 150, 200]} {...props} />}
    >
      <CursoGrid />
    </List>
  );
}

const cardStyle = {
  width: 288,
  height: 230,
  margin: '0.5em',
  display: 'inline-block',
  verticalAlign: 'top',
};

const titleStyle = {
  color: '#004AAD',
  
  fontSize: 18,
  fontWeight: 'bold',
  textTransform: 'capitalize',
};
const title2Style = {
  color: '#004AAD',
  
  fontSize: 16,
};

function AlumnoField(props) {
  const { source } = props;
  const record = useRecordContext(props);
  return <span>{record[source] ? record[source].length : 0}</span>;
}
function CursoGrid() {
  const translate = useTranslate();
  const { ids, data, basePath } = useListContext();
  return (
    <div style={{ margin: '1em' }}>
      {ids.map((id) => (
        <Card variant="outlined" key={id} style={cardStyle} hoverable="true" className="card-cursos">
          <CardContent>
            <TextField label="ra.label.nome" style={titleStyle} record={data[id]} source="nombre" />
          </CardContent>
          <CardContent style={title2Style}>
            {translate('ra.label.alunos')}
            {' '}
            <AlumnoField style={title2Style} record={data[id]} source="alumnos" />
            <br />
            {translate('ra.label.professor')}
            {' '}
            <ReferenceField style={title2Style} source="profId" record={data[id]} reference="profesores">
              <TextField style={title2Style} source="nombre" />
            </ReferenceField>
            <br />
            {translate('ra.label.modulo')}
            {' '}
            <ReferenceField style={title2Style} source="moduloId" record={data[id]} reference="modulos">
              <TextField style={title2Style} source="name" />
            </ReferenceField>
            <br />
            Inicio:
            {' '}
            <DateField
              style={title2Style}
              record={data[id]}
              label="ra.label.data_inicio"
              source="fecha_inicio"
            />
            <br />
            {/* Termino: <TextField style={title2Style} record={data[id]} source="telefono" /> */}
          </CardContent>
          { (!localStorage.getItem('perfil')
                || localStorage.getItem('perfil') !== 'alumno') && (
                <CardActions style={{ textAlign: 'right' }}>
                  <EditButton resource="cursos" basePath={basePath} record={data[id]} label="ra.label.editar" />
                  <ShowButton resource="cursos" basePath={basePath} record={data[id]} label="ra.label.sala_de_aula" />
                </CardActions>
          )}
          {localStorage.getItem('perfil') && localStorage.getItem('perfil') === 'alumno'
                && (
                <CardActions style={{ textAlign: 'right' }}>
                  <ShowButton resource="cursos" basePath={basePath} record={data[id]} label="ra.label.sala_de_aula" />
                </CardActions>
                )}
        </Card>
      ))}
    </div>
  );
}

const Subscription = createReactClass({
  async fetchDatas() {
    // console.log('Entro a la segunda subscripción', this.props);
    this.props.loadMaterial();
    // console.log('Entro a update la segunda subscripción', this.props);
    // this.props.updateDocumentos(object);
  },

  async livequery() {
    // const self = this;
    const query = new Parse.Query('alumno_documentos');
    query.equalTo('objectId', this.props.doc.objectId);
    const subscription = await query.subscribe();
    // subscription.on('open', () => console.log('Entro a open de la segunda subscripción'));
    subscription.on('create', this.fetchDatas);
    // subscription.on('update', async (object) => {
    //   console.log('Entro a update de la segunda subscripción');
    //   console.log(self.props);
    //   self.props.updateDocumentos(object);
    // });
    subscription.on('delete', this.fetchDatas);
  },
  componentDidMount() {
    this.livequery();
  },
  render() {
    return <span />;
  },
});

export function CursoCreate(props) {
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const onSuccess = async ({ data }) => {
    const created = data;
    setLoading(true);
    const { data: record } = await dataProvider.getOne('cursos', { id: props.id });

    const { data: cursoEventos } = await dataProvider.getList(
      'eventos',
      {
        filter: { cursoId: props.id },
        sort: { field: 'createdAt', order: 'DESC' },
        pagination: { page: 1, perPage: 50000 },
      },
    );

    // if (cursoEventos.length) {
    //   for (const evnt of cursoEventos) {
    //     dataProvider.delete('eventos', { id: evnt.objectId });
    //   }
    // }

    if (record.fecha_inicio && record.carga_horaria) {
      const newDate = new Date(record.fecha_inicio);
      let cargaTemporal = 0;

      while (cargaTemporal <= record.carga_horaria) {
        let tengoClase = null;
        switch (newDate.getDay()) {
          case 1:
            tengoClase = record.carga_por_dias.find((cxdias) => cxdias.dia === 'Lunes');
            break;
          case 2:
            tengoClase = record.carga_por_dias.find((cxdias) => cxdias.dia === 'Martes');
            break;
          case 3:
            tengoClase = record.carga_por_dias.find((cxdias) => cxdias.dia === 'Miercoles');
            break;
          case 4:
            tengoClase = record.carga_por_dias.find((cxdias) => cxdias.dia === 'Jueves');
            break;
          case 5:
            tengoClase = record.carga_por_dias.find((cxdias) => cxdias.dia === 'Viernes');
            break;
          case 6:
            tengoClase = record.carga_por_dias.find((cxdias) => cxdias.dia === 'Sabado');
            break;
          case 0:
            tengoClase = record.carga_por_dias.find((cxdias) => cxdias.dia === 'Domingo');
            break;
          default:
            break;
        }
        // 2022-07-28T00:30:00.000Z
        if (tengoClase && tengoClase.hora_inicial) {
          const horaClase = new Date(tengoClase.hora_inicial);
          newDate.setHours(horaClase.getHours());
          newDate.setMinutes(horaClase.getMinutes());
          const fechaInicio = newDate.toISOString();
          const endDate = newDate;

          if (tengoClase.horas && tengoClase.horas !== '0') endDate.setHours(newDate.getHours() + parseInt(tengoClase.horas));

          if (tengoClase.minutos && tengoClase.minutos !== '0') endDate.setMinutes(newDate.getMinutes() + parseInt(tengoClase.minutos));

          cargaTemporal += parseInt(tengoClase.horas);
          await dataProvider.create('eventos', {
            data: {
              profId: record.profId,
              cursoId: record.objectId,
              fecha_inicio: fechaInicio,
              fecha_final: endDate.toISOString(),
              nombre: `${record.nombre}`,
            },
          });
        }

        newDate.setDate(newDate.getDate() + 1);
      }
    }

    // Redirect
    alert('Las clases fueron cargadas en la agenda');
    setLoading(false);
    // useRedirect('/cursos')
    window.location.href = `#/cursos/${created.objectId}`;
  };

  if (loading) return <Loading loadingPrimary="Espere" loadingSecondary="Cargando datos en la agenda. No recargue, se podría perder información." />;

  return (
    <Create {...props} onSuccess={onSuccess}>
      <TabbedForm redirect="list" toolbar={<PostCreateToolbar />}>
        <FormTab label="ra.label.curso">
          <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
            <img src={VoltarIcon} alt="Voltar" />
          </Button>
          <TextInput variant="outlined" fullWidth label="ra.label.nome_curso" source="nombre" />
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <SelectCrud
                resource="modulos"
                label="name"
                title={translate('ra.label.sel_modulo')}
                open={open}
                onClose={(value) => {
                  setOpen(false);
                }}
              />
              <ReferenceInput
                InputProps={{
                  readOnly: true,
                }}
                perPage={100000}
                sort={{ field: 'name', order: 'ASC' }}
                variant="outlined"
                style={{ minWidth: '50%' }}
                source="moduloId"
                label="ra.label.modulo"
                reference="modulos"
              >
                <SelectInput optionText="name" onClick={() => setOpen(true)} />
              </ReferenceInput>
              <CreateModuloButton />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <ReferenceInput
                label="ra.label.professor"
                perPage={100000}
                sort={{ field: 'nombre', order: 'ASC' }}
                variant="outlined"
                style={{ minWidth: '50%' }}
                source="profId"
                reference="profesores"
              >
                <SelectInput optionText="nombre" />
              </ReferenceInput>
              <CreateProfButton />
            </Box>
          </Box>
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <TextInput
                label="ra.label.carga_horaria"
                variant="outlined"
                source="carga_horaria"
              />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
              mr={{ xs: 0, sm: '0.5em' }}
            />
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              {/* <CheckboxGroupInput source="dias_de_clase" choices={[
                  { id: 'Lunes', name: 'Lunes' },
                  { id: 'Martes', name: 'Martes' },
                  { id: 'Miercoles', name: 'Miercoles' },
                  { id: 'Jueves', name: 'Jueves' },
                  { id: 'Viernes', name: 'Viernes' },
                  { id: 'Sabado', name: 'Sabado' },
                  { id: 'Domingo', name: 'Domingo' },
              ]} /> */}
              <DateInput
                label="ra.label.data_inicio"
                variant="outlined"
                source="fecha_inicio"
              />
            </Box>
          </Box>
          <ArrayInput label="ra.label.carga_dia" source="carga_por_dias">
            <SimpleFormIterator
              disableReordering
              className="RaSimpleFormIterator"
              addButton={<Button><AddCircleIcon /></Button>}
              removeButton={<Button><RemoveCircleIcon /></Button>}
            >
              <SelectInput
                className="smallInput"
                label="ra.label.dias_semana"
                variant="outlined"
                source="dia"
                choices={[
                  { id: 'Lunes', name: 'ra.label.segunda' },
                  { id: 'Martes', name: 'ra.label.terca' },
                  { id: 'Miercoles', name: 'ra.label.quarta' },
                  { id: 'Jueves', name: 'ra.label.quinta' },
                  { id: 'Viernes', name: 'ra.label.sexta' },
                  { id: 'Sabado', name: 'ra.label.sabado' },
                  { id: 'Domingo', name: 'ra.label.domingo' },
                ]}
              />

              <SelectInput
                className="smallInput"
                variant="outlined"
                label="ra.label.horas"
                source="horas"
                choices={[
                  { id: '0', name: '0' },
                  { id: '1', name: '1' },
                  { id: '2', name: '2' },
                  { id: '3', name: '3' },
                  { id: '4', name: '4' },
                  { id: '5', name: '5' },
                  { id: '6', name: '6' },
                  { id: '7', name: '7' },
                  { id: '8', name: '8' },
                ]}
              />
              <SelectInput
                className="smallInput"
                variant="outlined"
                label="ra.label.minutos"
                source="minutos"
                choices={[
                  { id: '0', name: '00' },
                  { id: '10', name: '10' },
                  { id: '15', name: '15' },
                  { id: '20', name: '20' },
                  { id: '25', name: '25' },
                  { id: '30', name: '30' },
                  { id: '35', name: '35' },
                  { id: '40', name: '40' },
                  { id: '45', name: '45' },
                  { id: '50', name: '50' },
                  { id: '55', name: '55' },
                ]}
              />
              <TimeInput variant="outlined" label="ra.label.hora_inicio" fullWidth source="hora_inicial" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="ra.label.avaliacao">
          <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
            <img src={VoltarIcon} alt="Voltar" />
          </Button>
          <TextInput variant="outlined" fullWidth label="ra.label.nome_curso" source="nombre" />
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <NumberInput
                label="ra.label.aproveitamento"
                variant="outlined"
                source="aprovechamiento"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      %
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <NumberInput
                label="ra.label.nota_min"
                defaultValue={0}
                variant="outlined"
                source="nota_min"
              />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <NumberInput
                label="ra.label.nota_max"
                variant="outlined"
                source="nota_max"
              />
            </Box>
          </Box>
          <Typography style={{ marginBottom: 10 }} variant="h4">
            {translate('ra.label.adicionar_avaliacao')}
          </Typography>
          <ArrayInput label="" source="evaluaciones">
            <SimpleFormIterator
              className="RaSimpleFormIterator"
              addButton={<Button><AddCircleIcon /></Button>}
              removeButton={<Button><RemoveCircleIcon /></Button>}
            >
              <DateInput
                className="smallInput"
                label="ra.label.data"
                variant="outlined"
                source="fecha"
              />
              <ReferenceInput
                perPage={100000}
                className="smallInput"
                sort={{ field: 'name', order: 'ASC' }}
                label="ra.label.tipo_avaliacao"
                variant="outlined"
                style={{ minWidth: '50%' }}
                source="tipoevaluacionId"
                reference="tipo_evaluacion"
              >
                <SelectInput className="smallInput" optionText="name" />
              </ReferenceInput>
              <CreateTipoEvalButton />
              <TextInput multiline minRows={3} variant="outlined" label="ra.label.observacoes" source="Observaciones" />
              <TextInput
                className="smallInput"
                label="ra.label.peso_avaliacao"
                variant="outlined"
                source="peso_evaluacion"
              />
              <SelectInput
                className="tinyInput"
                variant="outlined"
                label=""
                source="tipo_peso"
                choices={[
                  { id: '%', name: '%' },
                  { id: 'pts', name: 'pts' },
                ]}
              />
            </SimpleFormIterator>
          </ArrayInput>
          <FormDataConsumer>
            {({ formData }) => {
              let total = 0;
              if (formData.evaluaciones && formData.evaluaciones.length) {
                for (const itemEv of formData.evaluaciones) {
                  if (itemEv && itemEv.peso_evaluacion) {
                    let sumando = parseFloat(itemEv.peso_evaluacion.replace(',', '.'));
                    if (itemEv.tipo_peso
                        && itemEv.tipo_peso === '%') {
                      sumando = ((parseFloat(parseFloat(formData.nota_max.replace(',', '.'))) * (parseFloat(itemEv.peso_evaluacion.replace(',', '.')))) / (100));
                    }

                    total += sumando;
                  }
                }
              }
              return (
                <Typography style={{
                  textAlign: 'right',
                  color: total > formData.nota_max ? 'red' : null,
                }}
                >
                  Total:
                  {' '}
                  {total}
                </Typography>
              );
            }}
          </FormDataConsumer>
        </FormTab>
        <FormTab label="ra.label.alunos">
          <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
            <img src={VoltarIcon} alt="Voltar" />
          </Button>
          <ReferenceArrayInput
            sort={{ field: 'nombre', order: 'ASC' }}
            label="ra.label.alunos"
            reference="alumnos"
            source="alumnos"
            // enableGetChoices={({ q }) => (q ? q.length >= 2 : false)}
          >
            <AutocompleteArrayInput fullWidth variant="outlined" optionText="nombre" />
          </ReferenceArrayInput>
        </FormTab>
        {(!localStorage.getItem('perfil') || (localStorage.getItem('perfil') && localStorage.getItem('perfil') === 'admin')) && (
          <FormTab label="ra.label.valores">
            <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
              <img src={VoltarIcon} alt="Voltar" />
            </Button>
            <TextInput variant="outlined" fullWidth label="ra.label.nome_curso" source="nombre" />
            <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
              <Box
                flex={1}
                mr={{ xs: 0, sm: '0.5em' }}
              >
                {/* incluir seletor de moedas <SelectCrud/> */}
                <SelectInput
                  variant="outlined"
                  label="ra.label.moeda_cobranca"
                  source="moedas"
                  choices={[
                    // moedas Google
                  ]}
                />
              </Box>
              <Box
                flex={1}
                mr={{ xs: 0, sm: '0.5em' }}
              >
                <NumberInput
                  label="ra.label.valor_curso"
                  variant="outlined"
                  fullWidth
                  source="valor_curso"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box
                flex={1}
                mr={{ xs: 0, sm: '0.5em' }}
              >
                <NumberInput
                  label="ra.label.valor_hora"
                  variant="outlined"
                  fullWidth
                  source="valor_hora"
                />
              </Box>
              <Box
                flex={1}
                ml={{ xs: 0, sm: '0.5em' }}
              >
                <FormDataConsumer>
                  {({ formData }) => formData.valor_curso && formData.carga_horaria
                  && (
                  <NumberInput
                    readonly
                    variant="outlined"
                    defaultValue={parseInt(formData.valor_curso) / parseInt(formData.carga_horaria)}
                    fullWidth
                    source="valor_por_hora"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      ),

                    }}
                  />
                  )}
                </FormDataConsumer>
              </Box>
            </Box>
            {/* Segundo box - CONVERSOS MOEDA LOCAL $valor do curso na moeda local + $/hora na moeda local */}
            <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
              <Box
                flex={1}
                mr={{ xs: 0, sm: '0.5em' }}
                style={{ color: '#004AAD', fontWeight: 'bold', paddingTop: '15px' }}
              >
                {translate('ra.label.val_moeda_local')}
              </Box>
              <Box
                flex={1}
                mr={{ xs: 0, sm: '0.5em' }}
              >
                <NumberInput
                  label="ra.label.valor_curso"
                  variant="outlined"
                  fullWidth
                  source="valor_curso"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box
                flex={1}
                mr={{ xs: 0, sm: '0.5em' }}
              >
                <NumberInput
                  label="ra.label.valor_hora"
                  variant="outlined"
                  fullWidth
                  source="valor_hora"
                />
              </Box>
              <Box
                flex={1}
                ml={{ xs: 0, sm: '0.5em' }}
              >
                <FormDataConsumer>
                  {({ formData }) => formData.valor_curso && formData.carga_horaria
                  && (
                  <NumberInput
                    readonly
                    variant="outlined"
                    defaultValue={parseInt(formData.valor_curso) / parseInt(formData.carga_horaria)}
                    fullWidth
                    source="valor_por_hora"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      ),

                    }}
                  />
                  )}
                </FormDataConsumer>
              </Box>
            </Box>
            {/* criar nova tela com forma de pamentos */}
            <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
              <Box
                flex={1}
                mr={{ xs: 0, sm: '0.5em' }}
                style={{ color: '#518f18', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '20px', fontSize: '24px' }}
              >
                {translate('ra.label.formas_pgto')}
              </Box>
            </Box>
            <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
              <BooleanInput Label="ra.label.desconto_pontualidade" source="desconto_pontualidade" />
              <FormDataConsumer>
                {({ formData }) => formData.desconto_pontualidade && (
                  <NumberInput
                    label="ra.label.desconto"
                    variant="outlined"
                    fullWidth
                    source="desconto"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          %
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </FormDataConsumer>
            </Box>
            <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
              <Box
                flex={0.1}
                mr={{ xs: 0, sm: '0.5em' }}
                ml={{ xs: 0, sm: '0.5em' }}
              >
                <NumberInput
                  label="ra.label.parcelas"
                  variant="outlined"
                  fullWidth
                  source="parcelas"
                />
              </Box>
              <Box
                flex={0.2}
                mr={{ xs: 0, sm: '0.5em' }}
                ml={{ xs: 0, sm: '0.5em' }}
              >
                <DateInput label="ra.label.data" variant="outlined" fullWidth source="fecha_inicio" />
              </Box>
              <Box
                flex={0.1}
                mr={{ xs: 0, sm: '0.5em' }}
                ml={{ xs: 0, sm: '0.5em' }}
              >
                <NumberInput
                  label="ra.label.desconto"
                  variant="outlined"
                  fullWidth
                  source="desconto"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        %
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box
                flex={0.2}
                mr={{ xs: 0, sm: '0.5em' }}
                ml={{ xs: 0, sm: '0.5em' }}
              >
                <NumberInput
                  label="ra.label.valor_parcela"
                  variant="outlined"
                  fullWidth
                  source="valor_parcela"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </FormTab>
        )}
      </TabbedForm>
    </Create>
  );
}

function MyDeleteItem(props) {
  const [openConfirm3, setOpenConfirm3] = useState(false);
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const { record, resource, basePath } = props;
  return (
    <>
      <ButtonDefault label="ra.label.excluir" onClick={() => setOpenConfirm3(true)} buttonClass="branco-azul" />
      <Confirm
        isOpen={openConfirm3}
        title="Borrar"
        content={`Estás seguro de borrar ${record.nombre}?`}
        onConfirm={async () => {
          await dataProvider.delete(resource, { id: record.objectId });
          const { data: cursoEventos } = await dataProvider.getList(
            'eventos',
            {
              filter: { cursoId: record.objectId },
              sort: { field: 'createdAt', order: 'DESC' },
              pagination: { page: 1, perPage: 50000 },
            },
          );
          if (cursoEventos && cursoEventos.length) {
            for (const evnt of cursoEventos) {
              await dataProvider.delete('eventos', { id: evnt.objectId });
            }
          }

          setOpenConfirm3(false);
          redirect(basePath);
        }}
        onClose={() => setOpenConfirm3(false)}
      />
    </>
  );
}

function PostCreateToolbar(props) {
  return (
    <Toolbar {...props} style={{ justifyContent: 'space-between' }}>
      <SaveButton label="ra.label.salvar" />
      <MyDeleteItem />
    </Toolbar>
  );
}

export function CursoEdit(props) {
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const syncMaterial = async () => {
    const { data: record } = await dataProvider.getOne('cursos', { id: props.id });
    const ratings = await Parse.Cloud.run('uploadMaterial', { moduloId: record.moduloId, cursoId: props.id, folderId: '1jAEqdm_59Nm--aSOaSDNCIgsy3X-MkLx' });
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < ratings.length; index++) {
      const element = ratings[index];
      await dataProvider.create('drive_documents', {
        data: {
          cursoId: props.id,
          moduloId: record.moduloId,
          driveId: element.data.id,
          file: element.file,
          doc_profe: true,
        },
      });
    }
  };

  const syncMaterialEstudiante = async () => {
    const { data: record } = await dataProvider.getOne('cursos', { id: props.id });
    const { data: modulo } = await dataProvider.getOne('modulos', { id: record.moduloId });
    // eslint-disable-next-line no-plusplus
    for (let index0 = 0; index0 < record.alumnos.length; index0++) {
      const alumnoId = record.alumnos[index0];
      const ratings = await Parse.Cloud.run('uploadMaterialEstudiante', { moduloId: record.moduloId, cursoId: props.id, folderId: modulo.folderId });
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < ratings.length; index++) {
        const element = ratings[index];
        await dataProvider.create('drive_documents', {
          data: {
            cursoId: props.id,
            moduloId: record.moduloId,
            driveId: element.data.id,
            file: element.file,
            alumnoId,
          },
        });
      }
    }
  };

  const onSuccess = async () => {
    setLoading(true);
    const { data: record } = await dataProvider.getOne('cursos', { id: props.id });
    if (!record.fecha_inicio) {
      alert('Debe escoger una fecha de inicio obligatoriamente');
      window.location.href = `#/cursos/${record.objectId}/show`;
    }
    const { data: cursoEventos } = await dataProvider.getList(
      'eventos',
      {
        filter: { cursoId: props.id },
        sort: { field: 'createdAt', order: 'DESC' },
        pagination: { page: 1, perPage: 50000 },
      },
    );

    if (cursoEventos.length) {
      alert('Borrando cursos anteriores');
      for (const evnt of cursoEventos) {
        await dataProvider.delete('eventos', { id: evnt.objectId });
      }
    }

    // if(!cursoEventos.length){
    const newDate = new Date(record.fecha_inicio);
    let cargaTemporal = 0;

    while (cargaTemporal <= record.carga_horaria) {
      let tengoClase = null;
      switch (newDate.getDay()) {
        case 1:
          tengoClase = record.carga_por_dias.find((cxdias) => cxdias.dia === 'Lunes');
          break;
        case 2:
          tengoClase = record.carga_por_dias.find((cxdias) => cxdias.dia === 'Martes');
          break;
        case 3:
          tengoClase = record.carga_por_dias.find((cxdias) => cxdias.dia === 'Miercoles');
          break;
        case 4:
          tengoClase = record.carga_por_dias.find((cxdias) => cxdias.dia === 'Jueves');
          break;
        case 5:
          tengoClase = record.carga_por_dias.find((cxdias) => cxdias.dia === 'Viernes');
          break;
        case 6:
          tengoClase = record.carga_por_dias.find((cxdias) => cxdias.dia === 'Sabado');
          break;
        default:
          break;
      }
      // 2022-07-28T00:30:00.000Z
      if (tengoClase && tengoClase.hora_inicial) {
        const horaClase = new Date(tengoClase.hora_inicial);
        newDate.setHours(horaClase.getHours());
        newDate.setMinutes(horaClase.getMinutes());
        const fechaInicio = newDate.toISOString();
        const endDate = newDate;

        if (tengoClase.horas && tengoClase.horas !== '0') endDate.setHours(newDate.getHours() + parseInt(tengoClase.horas));

        if (tengoClase.minutos && tengoClase.minutos !== '0') endDate.setMinutes(newDate.getMinutes() + parseInt(tengoClase.minutos));

        cargaTemporal += parseInt(tengoClase.horas);
        await dataProvider.create('eventos', {
          data: {
            profId: record.profId,
            cursoId: record.objectId,
            fecha_inicio: fechaInicio,
            fecha_final: endDate.toISOString(),
            nombre: `${record.nombre}`,
          },
        });
      }

      newDate.setDate(newDate.getDate() + 1);
    }
    // }

    // Redirect
    alert('Las clases fueron cargadas en la agenda');
    setLoading(false);
    // useRedirect('/cursos')
    window.location.href = `#/cursos/${record.objectId}`;
  };

  if (loading) return <Loading loadingPrimary="Espere" loadingSecondary="Cargando datos en la agenda. No recargue, se podría perder información." />;

  return (
    <Edit title="ra.label.cursos" undoable={false} {...props} onSuccess={onSuccess}>
      <TabbedForm redirect="list" toolbar={<PostCreateToolbar />}>
        <FormTab label="ra.label.curso">
          <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
            <img src={VoltarIcon} alt="Voltar" />
          </Button>
          <TextInput variant="outlined" fullWidth label="ra.label.nome_curso" source="nombre" />
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <SelectCrud
                resource="modulos"
                label="name"
                title="Seleccione el módulo"
                open={open}
                onClose={(value) => {
                  setOpen(false);
                }}
              />
              <ReferenceInput
                InputProps={{
                  readOnly: true,
                }}
                perPage={100000}
                sort={{ field: 'name', order: 'ASC' }}
                variant="outlined"
                style={{ minWidth: '50%' }}
                source="moduloId"
                label="ra.label.modulo"
                reference="modulos"
              >
                <SelectInput optionText="name" onClick={() => setOpen(true)} />
              </ReferenceInput>
              <CreateModuloButton />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <ReferenceInput
                perPage={100000}
                label="ra.label.professor"
                sort={{ field: 'nombre', order: 'ASC' }}
                variant="outlined"
                style={{ minWidth: '50%' }}
                source="profId"
                reference="profesores"
              >
                <SelectInput optionText="nombre" />
              </ReferenceInput>
              <CreateProfButton />
              <Button onClick={syncMaterial} label="Sincronizar material del profesor" />
            </Box>
          </Box>
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <TextInput label="ra.label.carga_horaria" variant="outlined" fullWidth source="carga_horaria" />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
              mr={{ xs: 0, sm: '0.5em' }}
            />
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <DateInput label="ra.label.data_inicio" variant="outlined" fullWidth source="fecha_inicio" />
            </Box>
          </Box>
          <ArrayInput label="ra.label.carga_dia" source="carga_por_dias">
            <SimpleFormIterator
              className="RaSimpleFormIterator"
              addButton={<Button><AddCircleIcon /></Button>}
              removeButton={<Button><RemoveCircleIcon /></Button>}
            >
              <SelectInput
                className="smallInput"
                label="ra.label.dias_semana"
                variant="outlined"
                source="dia"
                choices={[
                  { id: 'Lunes', name: 'ra.label.segunda' },
                  { id: 'Martes', name: 'ra.label.terca' },
                  { id: 'Miercoles', name: 'ra.label.quarta' },
                  { id: 'Jueves', name: 'ra.label.quinta' },
                  { id: 'Viernes', name: 'ra.label.sexta' },
                  { id: 'Sabado', name: 'ra.label.sabado' },
                  { id: 'Domingo', name: 'ra.label.domingo' },
                ]}
              />

              <SelectInput
                className="smallInput"
                variant="outlined"
                label="ra.label.horas"
                source="horas"
                choices={[
                  { id: '0', name: '0' },
                  { id: '1', name: '1' },
                  { id: '2', name: '2' },
                  { id: '3', name: '3' },
                  { id: '4', name: '4' },
                  { id: '5', name: '5' },
                  { id: '6', name: '6' },
                  { id: '7', name: '7' },
                  { id: '8', name: '8' },
                ]}
              />
              <SelectInput
                className="smallInput"
                variant="outlined"
                label="ra.label.minutos"
                source="minutos"
                choices={[
                  { id: '0', name: '00' },
                  { id: '10', name: '10' },
                  { id: '15', name: '15' },
                  { id: '20', name: '20' },
                  { id: '25', name: '25' },
                  { id: '30', name: '30' },
                  { id: '35', name: '35' },
                  { id: '40', name: '40' },
                  { id: '45', name: '45' },
                  { id: '50', name: '50' },
                  { id: '55', name: '55' },
                ]}
              />
              <TimeInput variant="outlined" label="ra.label.hora_inicio" fullWidth source="hora_inicial" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        {(1 !== 2 || !localStorage.getItem('perfil') || (localStorage.getItem('perfil') && localStorage.getItem('perfil') === 'admin')) && (
          <FormTab label="ra.label.valores">
            <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
              <img src={VoltarIcon} alt="Voltar" />
            </Button>
            <TextInput variant="outlined" fullWidth label="ra.label.nome_curso" source="nombre" />
            <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
              <Box
                flex={1}
                mr={{ xs: 0, sm: '0.5em' }}
              >
                <NumberInput
                  label="ra.label.valor_curso"
                  variant="outlined"
                  fullWidth
                  source="valor_curso"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box
                flex={1}
                ml={{ xs: 0, sm: '0.5em' }}
              >
                <FormDataConsumer>
                  {({ formData }) => formData.valor_curso && formData.carga_horaria
                  && (
                  <NumberInput
                    variant="outlined"
                    defaultValue={parseInt(formData.valor_curso) / parseInt(formData.carga_horaria)}
                    fullWidth
                    source="valor_por_hora"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      ),

                    }}
                  />
                  )}
                </FormDataConsumer>
              </Box>
            </Box>
            <Typography style={{ marginBottom: 10, width: '100%' }} variant="h4">
              Formas de pagamento
            </Typography>
            <UsuarioParcelas {...props} />
          </FormTab>
        )}
        <FormTab label="ra.label.avaliacao">
          <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
            <img src={VoltarIcon} alt="Voltar" />
          </Button>
          <TextInput variant="outlined" fullWidth label="ra.label.nome_curso" source="nombre" />
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <NumberInput
                label="ra.label.aproveitamento"
                variant="outlined"
                source="aprovechamiento"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      %
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <NumberInput
                defaultValue={0}
                label="ra.label.nota_min"
                variant="outlined"
                source="nota_min"
              />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <NumberInput
                label="ra.label.nota_max"
                variant="outlined"
                source="nota_max"
              />
            </Box>
          </Box>
          <Typography style={{ marginBottom: 10 }} variant="h4">
            {translate('ra.label.adicionar_avaliacao')}
          </Typography>
          <ArrayInput label="" source="evaluaciones">
            <SimpleFormIterator
              className="RaSimpleFormIterator"
              addButton={<Button><AddCircleIcon /></Button>}
              removeButton={<Button><RemoveCircleIcon /></Button>}
            >
              <DateInput
                className="smallInput"
                label="ra.label.data"
                variant="outlined"
                source="fecha"
              />
              <ReferenceInput
                perPage={100000}
                className="smallInput"
                sort={{ field: 'name', order: 'ASC' }}
                label="ra.label.tipo_avaliacao"
                variant="outlined"
                style={{ minWidth: '50%' }}
                source="tipoevaluacionId"
                reference="tipo_evaluacion"
              >
                <SelectInput className="smallInput" optionText="name" />
              </ReferenceInput>
              <CreateTipoEvalButton />
              <TextInput multiline minRows={3} variant="outlined" label="ra.label.observacoes" source="observaciones" />
              <TextInput
                className="smallInput"
                label="ra.label.peso_avaliacao"
                variant="outlined"
                source="peso_evaluacion"
              />
              <SelectInput
                className="tinyInput"
                variant="outlined"
                label=""
                source="tipo_peso"
                choices={[
                  { id: '%', name: '%' },
                  { id: 'pts', name: 'pts' },
                ]}
              />
            </SimpleFormIterator>
          </ArrayInput>
          <FormDataConsumer>
            {({ formData }) => {
              let total = 0;
              if (formData.evaluaciones && formData.evaluaciones.length && formData.nota_max) {
                for (const itemEv of formData.evaluaciones) {
                  if (itemEv && itemEv.peso_evaluacion) {
                    let sumando = parseFloat(itemEv.peso_evaluacion.replace(',', '.'));
                    if (itemEv.tipo_peso && itemEv.tipo_peso === '%') {
                      sumando = (parseFloat(parseFloat(formData.nota_max.toString().replace(',', '.')) * parseFloat(itemEv.peso_evaluacion.toString().replace(',', '.'))) / (100));
                    }

                    total += sumando;
                  }
                }
              }
              return (
                <Typography style={{
                  textAlign: 'right',
                  color: total > formData.nota_max ? 'red' : null,
                }}
                >
                  Total:
                  {' '}
                  {total}
                </Typography>
              );
            }}
          </FormDataConsumer>
        </FormTab>
        <FormTab label="ra.label.alunos">
          <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
            <img src={VoltarIcon} alt="Voltar" />
          </Button>
          <Button onClick={syncMaterialEstudiante} label="Sincronizar material del alumno" />
          <ReferenceArrayInput
            sort={{ field: 'nombre', order: 'ASC' }}
            label="ra.label.alunos"
            reference="alumnos"
            source="alumnos"
            // enableGetChoices={({ q }) => (q ? q.length >= 2 : false)}
          >
            <AutocompleteArrayInput fullWidth variant="outlined" optionText="nombre" />
          </ReferenceArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

// function GoogleConnector() {
//   const [listDocumentsVisible, setListDocumentsVisibility] = useState(false);
//   const [documents, setDocuments] = useState([]);
//   const [isLoadingGoogleDriveApi, setIsLoadingGoogleDriveApi] = useState(false);
//   const [isFetchingGoogleDriveFiles, setIsFetchingGoogleDriveFiles] = useState(false);
//   const [signedInUser, setSignedInUser] = useState();
//   /**
//    *  Sign in the user upon button click.
//    */
//   const handleAuthClick = (event) => {
//     gapi.auth2.getAuthInstance().signIn();
//   };

//   const updateSigninStatus = (isSignedIn) => {
//     if (isSignedIn) {
//       // Set the signed in user
//       setSignedInUser(gapi.auth2.getAuthInstance().currentUser.je.Qt);
//       setIsLoadingGoogleDriveApi(false);
//       // list files if user is authenticated
//       // listFiles();
//     } else {
//       // prompt user to sign in
//       handleAuthClick();
//     }
//   };

//   const initClient = () => {
//     setIsLoadingGoogleDriveApi(true);
//     gapi.client
//       .init({
//         apiKey: API_KEY,
//         clientId: CLIENT_ID,
//         discoveryDocs: DISCOVERY_DOCS,
//         scope: SCOPES,
//       })
//       .then(() => {
//         // Listen for sign-in state changes.
//         gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

//         // Handle the initial sign-in state.
//         updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
//       }, (error) => console.log(error));
//   };

//   return (
//     <Button
//       onClick={() => gapi.load('client:auth2', initClient)}
//       label="Ingresa en google drive"
//     />
//   );
// }

class MaterialAlumno extends React.PureComponent {
  constructor() {
    super();
    this.state = { data: [] };
  }

  async componentDidMount() {
    this.props.dataProvider.getList(
      'alumno_documentos',
      { filter: {
        cursoId: this.props.cursoId,
        enAula: true, // compartido
      } },
    )
      .then(({ data }) => this.setState({ documentos: data }));
  }

  render() {
    return (
      <TableContainer style={{ maxWidth: 'calc(100vw - 300px)' }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{
                color: '#004AAD',
                fontWeight: 'bold',
                minWidth: 50,
              }}
              >
                {this.props.translate('ra.label.documento')}
              </TableCell>
              <TableCell style={{
                color: '#004AAD',
                fontWeight: 'bold',
                minWidth: 50,
              }}
              >
                {this.props.translate('ra.label.compartilhado')}
              </TableCell>
              <TableCell style={{
                color: '#004AAD',
                fontWeight: 'bold',
                minWidth: 50,
              }}
              >
                {this.props.translate('ra.label.abrir')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.materialCurso.map((itemM) => itemM.pictures && itemM.pictures.sort((a, b) => (
              a.title
                .replace(/UNIDADE 1 /g, 'UNIDADE 01 ')
                .replace(/UNIDADE 2 /g, 'UNIDADE 02 ')
                .replace(/UNIDADE 3 /g, 'UNIDADE 03 ')
                .replace(/UNIDADE 4 /g, 'UNIDADE 04 ')
                .replace(/UNIDADE 5 /g, 'UNIDADE 05 ')
                .replace(/UNIDADE 6 /g, 'UNIDADE 06 ')
                .replace(/UNIDADE 7 /g, 'UNIDADE 07 ')
                .replace(/UNIDADE 8 /g, 'UNIDADE 08 ') > b.title
                .replace(/UNIDADE 1 /g, 'UNIDADE 01 ')
                .replace(/UNIDADE 2 /g, 'UNIDADE 02 ')
                .replace(/UNIDADE 3 /g, 'UNIDADE 03 ')
                .replace(/UNIDADE 4 /g, 'UNIDADE 04 ')
                .replace(/UNIDADE 5 /g, 'UNIDADE 05 ')
                .replace(/UNIDADE 6 /g, 'UNIDADE 06 ')
                .replace(/UNIDADE 7 /g, 'UNIDADE 07 ')
                .replace(/UNIDADE 8 /g, 'UNIDADE 08 ') ? 1 : -1
            )).filter((item) => !item.title.includes('Instrutivo') && !item.title.includes('INSTRUTIVO') && !item.title.includes('instrutivo')).map((item, index) => (
              <MyDocRowAlumno
                compartidos={this.state.documentos}
                key={`${item.src}-${index}`}
                // louza={<Board alumnos={this.props.alumnos} profesor={this.props.profesor} mini alumnoId={null} cursoId={this.props.cursoId} dataProvider={this.props.dataProvider} />}
                doc={item}
                index={index}
                alumno={this.props.alumno}
                cursoId={this.props.cursoId}
              />
            )))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

function CursoTitle({ record }) {
  return (
    <span>
      {record ? `${record.nombre}` : ''}
    </span>
  );
}

export function CursoShow(props) {
  const translate = useTranslate();
  const {
    loading, // boolean that is true on mount, and false once the record was fetched
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props);
  const redirect = useRedirect();
  const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  const classes = useStyles();
  const { format } = require('date-fns');
  const dataProvider = useDataProvider();
  const [eventos, setEventos] = useState([]);
  const [alumnos, setAlumnos] = useState([]);
  const [profesor, setProfesor] = useState(null);
  const [record2, setRecord] = useState(null);
  const [losaready, setLosaReady] = useState(false);
  const [materialCurso, setMaterialCurso] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [documentos, setDocumentos] = useState([]);
  const [losasAll, setLosasAll] = useState([]);
  const [losaDialog, setLosaDialog] = useState(false);
  const [eventDialog, seteventDialog] = useState(false);
  const [eventToChange, setEventToChange] = useState(null);
  const [content, setContent] = useState(null);
  const [dia, setDia] = useState('hoy');
  const alumno = localStorage.getItem('alumno') || null;
  const [tipoEvaluaciones, setTipoEvaluaciones] = React.useState([]);
  const editorRef = useRef(null);

  async function loadMisPresencias() {
    // const { data: cursoEventos } = await dataProvider.getList(
    //   'eventos',
    //   {
    //     filter: { cursoId: props.id, alumnoId: alumno.objectId },
    //     sort: { field: 'fecha', order: 'ASC' },
    //     pagination: { page: 1, perPage: 50000 },
    //   },
    // );
    // console.log('estoy aqui', cursoEventos);
    // const perMes = cursoEventos.reduce((acc, curr) => {
    //   const inicio = new Date(curr.fecha_inicio);
    //   acc[inicio.getMonth()] = acc[inicio.getMonth()] || [];
    //   acc[inicio.getMonth()].push(curr);
    //   return acc;
    // }, Object.create(null));
    // console.log(perMes);
    // const { data: cursosQry } = await dataProvider.getList('tipo_evaluacion', { filter: {} });

    // setTipoEvaluaciones(cursosQry);
    // setEventos(cursoEventos);
  }

  async function loadAgenda(isSubscribed) {
    const { data: cursoEventos } = await dataProvider.getList(
      'eventos',
      {
        filter: { cursoId: props.id },
        sort: { field: 'fecha_inicio', order: 'ASC' },
        pagination: { page: 1, perPage: 50000 },
      },
    );
    // alert(JSON.stringify(cursoEventos))
    if (isSubscribed) setEventos(cursoEventos);
  }

  async function loadLozasAnteriores() {
    const { data: docAll } = await dataProvider.getList('posts', { filter: { cursoId: record2.objectId } });
    // Filtrar los alumnos del curso
    const fechas = docAll.map((item) => item.createdAt.split('T')[0]);

    setLosasAll(fechas.filter((value, index, self) => self.indexOf(value) === index));
  }

  async function loadDocumentos() {
    const { data: docAll } = await dataProvider.getList('alumno_documentos', { filter: { moduloId: record2.moduloId } });
    // Filtrar los alumnos del curso
    setDocumentos(docAll);
  }

  async function loadMaterial(isSubscribed) {
    let recordT = record;
    if (!record) {
      const { data: recordQry } = await dataProvider.getOne('cursos', { id: props.id });
      recordT = recordQry;
    }
    if (isSubscribed) setRecord(recordT);

    const { data: materialRslt } = await dataProvider.getList('material', { filter: { moduloId: recordT.moduloId } });
    if (isSubscribed) setMaterialCurso(materialRslt);

    const { data: recordBK } = await dataProvider.getOne('cursos', { id: props.id });

    const { data: alumnos } = await dataProvider.getList(
      'alumnos',
      {
        filter: { objectId: { $in: recordBK.alumnos } },
        sort: { field: 'createdAt', order: 'DESC' },
        pagination: { page: 1, perPage: 50000 },
      },
    );

    if (isSubscribed) setAlumnos(alumnos);

    const { data: profesorT } = await dataProvider.getOne(
      'profesores',
      {
        id: recordBK.profId,
      },
    );
    if (isSubscribed) setProfesor(profesorT);
    // Esta rutina la hace el estudiante en su pantalla curso
  }

  const disparoTexto = (event) => {
    const delayDebounceFn = setTimeout(() => {
      if (losaready) {
        dataProvider.update('posts', { id: losaready, data: { note: event } });
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  };

  // losaready
  const loadLousa = async (isSubscribed) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const { data: posts } = await dataProvider.getList(
      'posts',
      {
        filter: { cursoId: props.id, diaClase: today.toISOString() },
        sort: {},
        pagination: { page: 1, perPage: 50000 },
      },
    );
    if (!posts || !posts.length) {
      const losaCreation = await dataProvider.create('posts', { data: { cursoId: props.id, diaClase: today } });
      if (losaCreation && losaCreation.data && losaCreation.data.id) setLosaReady(losaCreation.data.id);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (isSubscribed) {
        setLosaReady(posts[0].objectId);
        setContent(posts[0].note);
      }
    }
  };

  useEffect(() => {
    let isSubscribed = true;
    if (!localStorage.getItem('perfil') || localStorage.getItem('perfil') !== 'alumno') loadAgenda(isSubscribed);
    // else if (localStorage.getItem('perfil') === 'alumno') loadMisPresencias();
    loadLousa(isSubscribed);
    loadMaterial(isSubscribed);
    // eslint-disable-next-line no-return-assign
    return () => isSubscribed = false;
  }, []);

  useEffect(() => {
    if (record2) {
      loadDocumentos();
      loadLozasAnteriores();
    }
  }, [record2]);

  const hoy = new Date();
  const options = {
    weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
  };

  if (localStorage.getItem('perfil') === 'alumno' && (record2 || record)) {
    return (
      <Show actions={null} title={<CursoTitle />} {...props}>
        <Paper style={{ padding: 30 }}>
          <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
            <img src={VoltarIcon} alt="Voltar" />
          </Button>
          <Typography style={{ marginBottom: 10 }} variant="h4">
            {translate('ra.label.lousa')}
            {' '}
            {hoy.toLocaleString(translate('ra.label.dia_semana'), options)}
          </Typography>
          <Board
            alumnos={alumnos}
            profesor={profesor}
            alumnoId={alumno.objectId}
            cursoId={props.id}
            dataProvider={dataProvider}
          />
          <Typography style={{ marginBottom: 30, marginTop: 30 }} variant="h4">{translate('ra.label.lousa_dia')}</Typography>
          <Dialog
            fullWidth
            maxWidth="lg"
            open={losaDialog}
            onClose={() => setLosaDialog(false)}
          >
            <DialogContent>
              <BoardAntigua
                dia={dia}
                alumnos={alumnos}
                profesor={profesor}
                alumnoId={null}
                profeId={record2 ? record2.profId : record ? record.profId : null}
                cursoId={props.id}
                dataProvider={dataProvider}
              />
            </DialogContent>
            <DialogActions>
              <Button
                label="ra.label.fechar"
                onClick={() => setLosaDialog(false)}
                disabled={loading}
              >
                <IconCancel />
              </Button>
            </DialogActions>
          </Dialog>
          <div style={{
            display: 'flex', flexDirection: 'row', alignItems: 'stretch', flexWrap: 'wrap', minHeight: 100,
          }}
          >
            {losasAll.map((item) => (
              <LousaIcon
                dataProvider={dataProvider}
                cursoId={props.id}
                item={item}
                onClick={() => {
                  setDia(item);
                  setLosaDialog(true);
                }}
              />
            ))}
          </div>
          {/* <Typography style={{ marginBottom: 30, marginTop: 30 }} variant="h4">
            Material do curso
          </Typography>
          {materialCurso && materialCurso.map((material) => <MisIconos cursoId={props.id} id={material.objectId} louza={<Board alumnos={alumnos} profesor={profesor} mini alumnoId={alumno.objectId} cursoId={props.id} dataProvider={dataProvider} />} source="pictures" deleteItem={() => console.log('sin permisos')} renameItem={() => console.log('sin permisos')} />)} */}
          <Typography style={{ marginBottom: 30, marginTop: 30 }} variant="h4">
            {translate('ra.label.material_curso')}
          </Typography>
          <MaterialAlumno
            translate={translate}
            alumnos={alumnos}
            alumno={alumno}
            profesor={profesor}
            cursoId={props.id}
            dataProvider={dataProvider}
            materialCurso={materialCurso}
          />
          <Typography style={{ marginBottom: 30, marginTop: 30 }} variant="h4">{translate('ra.label.desempenho')}</Typography>
          <Typography style={{ marginBottom: 20 }}>{translate('ra.label.avaliacao')}</Typography>
          <TableContainer style={{ maxWidth: 'calc(100vw - 300px)' }}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      color: '#004AAD',
                      fontWeight: 'bold',
                      minWidth: 50,
                    }}
                    className={classes.sticky}
                  >
                    {translate('ra.label.data')}
                  </TableCell>
                  <TableCell
                    style={{
                      color: '#004AAD',
                      fontWeight: 'bold',
                      minWidth: 50,
                    }}
                    className={classes.sticky}
                  >
                    {translate('ra.label.tipo_avaliacao')}
                  </TableCell>
                  <TableCell
                    style={{
                      color: '#004AAD',
                      fontWeight: 'bold',
                      minWidth: 50,
                    }}
                    className={classes.sticky}
                  >
                    {translate('ra.label.peso_avaliacao')}
                  </TableCell>
                  <TableCell
                    style={{
                      color: '#004AAD',
                      fontWeight: 'bold',
                      minWidth: 250,
                    }}
                    className={classes.sticky}
                  >
                    {translate('ra.label.observacoes')}
                  </TableCell>
                  <TableCell
                    style={{
                      color: '#004AAD',
                      fontWeight: 'bold',
                      minWidth: 50,
                    }}
                    className={classes.sticky}
                  >
                    Nota
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {record
                && record.evaluaciones
                && record.evaluaciones.map((actividad, index, allEvaluaciones) => (
                  <MyRow
                    tipoEvaluaciones={tipoEvaluaciones}
                    actividad={actividad}
                    allEvaluaciones={allEvaluaciones}
                    index={index}
                    record={alumno}
                    curso={record}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <Typography style={{ marginBottom: 20, marginTop: 30 }} variant="h4">{translate('ra.label.presenca')}</Typography>
          <TableContainer style={{ maxWidth: 'calc(100vw - 300px)' }}>
            <Table className={classes.table} aria-label="simple table">
              <TableRow>
                <TableCell
                  style={{
                    color: '#004AAD',
                    fontWeight: 'bold',
                    minWidth: 250,
                    backgroundColor: 'white',
                  }}
                  className={classes.sticky}
                >
                  {translate('ra.label.presenca')}
                </TableCell>
                {eventos && eventos.length && eventos.map((evnt) => (
                  <TableCell style={{
                    color: '#004AAD',
                    minWidth: 130,
                  }}
                  >
                    {format(new Date(evnt.fecha_inicio), 'dd-MM-yyyy')}
                  </TableCell>
                ))}
              </TableRow>
              <TableBody>
                <TableRow key={alumno ? alumno.objectId : 'jkjkj'}>
                  <TableCell
                    style={{
                      color: '#004AAD',
                      backgroundColor: 'white',
                      zIndex: 2,
                    }}
                    component="th"
                    scope="row"
                    className={classes.sticky}
                  >
                    <img src={MiniUser} alt="user" />
                    {JSON.parse(alumno).nombre || ''}
                  </TableCell>
                  {eventos && eventos.length && eventos.map((evnt) => (
                    <TableCell style={{
                      color: '#004AAD',
                      minWidth: 130,
                      zIndex: 2,
                    }}
                    >
                      <EventCheck disabled evento={evnt} alumno={alumno} curso={record} />
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer> */}
          {/*
              <TableHead>
                <TableRow>
                  {eventos.map((evnt) => (
                    <TableCell style={{
                      color: '#004AAD',
                      minWidth: 130,
                    }}
                    >
                      {format(new Date(evnt.fecha_inicio), 'dd-MM-yyyy')}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer> */}
        </Paper>
      </Show>
    );
  }

  if (!record2 || !record2.profId) {
    return (
      <Loading loadingPrimary="Espere" loadingSecondary="Cargando datos de precencias." />
    );
  }

  return (
    <Show actions={null} title={<CursoTitle />} {...props}>
      <Paper style={{ padding: 30 }}>
        <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
          <img src={VoltarIcon} alt="Volver" />
        </Button>
        <Typography style={{ marginBottom: 10 }} variant="h4">
          {translate('ra.label.lousa')}
          {' '}
          {hoy.toLocaleString(translate('ra.label.dia_semana'), options)}
        </Typography>
        {losaready ? (
          <Editor
            apiKey="uy3q5l7cf7dleypasrmsoj07udywg77xp86c6l6zenisb1vm"
            onInit={(evt, editor) => editorRef.current = editor}
            // value={values}
            initialValue={content}
            onEditorChange={(e) => disparoTexto(e)}
            init={{
              height: 300,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace textcolor visualblocks code fullscreen',
                'insertdatetime image media table paste code help wordcount',
              ],
              toolbar: 'bold italic forecolor backcolor underline strikethrough | alignleft aligncenter ',
              content_style: 'body { font-family:Helvetica,Arial, sans-serif; font-size:14px }',
            }}
          />
        ) : <p>Cargando...</p>}
        <Typography style={{ marginBottom: 30, marginTop: 30 }} variant="h4">{translate('ra.label.lousa_dia')}</Typography>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={losaDialog}
          onClose={() => setLosaDialog(false)}
        >
          <DialogContent>
            <BoardAntigua
              dia={dia}
              alumnos={alumnos}
              profesor={profesor}
              alumnoId={null}
              profeId={record2.profId}
              cursoId={props.id}
              dataProvider={dataProvider}
            />
          </DialogContent>
          <DialogActions>
            <Button
              label="ra.label.fechar"
              onClick={() => setLosaDialog(false)}
              disabled={loading}
            >
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
        <div style={{
          display: 'flex', flexDirection: 'row', alignItems: 'stretch', flexWrap: 'wrap', minHeight: 100,
        }}
        >
          {losasAll.map((item) => (
            <LousaIcon
              dataProvider={dataProvider}
              cursoId={props.id}
              item={item}
              onClick={() => {
                setDia(item);
                setLosaDialog(true);
              }}
            />
          ))}
        </div>
        <Typography style={{ marginBottom: 20, marginTop: 30 }} variant="h4">{translate('ra.label.presenca')}</Typography>
        <TableContainer style={{ maxWidth: 'calc(100vw - 300px)' }}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    color: '#004AAD',
                    fontWeight: 'bold',
                    minWidth: 250,
                    backgroundColor: 'white',
                  }}
                  className={classes.sticky}
                >
                  {translate('ra.label.presenca')}
                </TableCell>
                {eventos.map((evnt) => (
                  <TableCell style={{
                    color: '#004AAD',
                    minWidth: 130,
                  }}
                  >
                    {format(new Date(evnt.fecha_inicio), 'dd-MM-yyyy')}
                    <IconButton color="primary" aria-label="Mudar data" component="label" onClick={() => seteventDialog(true)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton color="primary" aria-label="Cancelar aula" component="label">
                      <CancelIcon />
                    </IconButton>
                    {/*<Button size="small" label="Mudar data" onClick={() => seteventDialog(true)} />
                                        <Button size="small"label="Cancelar aula" onClick={() => console.log(true)} />*/}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {alumnos.map((row) => (
                <TableRow key={row.objectId}>
                  <TableCell
                    style={{
                      color: '#004AAD',
                      backgroundColor: 'white',
                      zIndex: 2,
                    }}
                    component="th"
                    scope="row"
                    className={classes.sticky}
                  >
                    <img src={MiniUser} alt="user" />
                    <span style={{cursor: 'pointer'}} onClick={() => {
                      redirect(`/alumnos/${row.objectId}/show`)
                    }}>{row.nombre}</span>
                  </TableCell>
                  {eventos.map((evnt) => (
                    <TableCell style={{
                      color: '#004AAD',
                      minWidth: 130,
                      zIndex: 2,
                    }}
                    >
                      <EventCheck evento={evnt} alumno={row} curso={record} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={eventDialog}
          onClose={() => seteventDialog(false)}
        >
          <DialogContent>
            <MUITextField
              autoFocus
              margin="dense"
              id="date"
              label="Fecha"
              type="date"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button
              label="ra.label.fechar"
              onClick={() => seteventDialog(false)}
              disabled={loading}
            >
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
        <Typography style={{ marginBottom: 30, marginTop: 30 }} variant="h4">{translate('ra.label.material_curso')}</Typography>
        <TableContainer style={{ maxWidth: 'calc(100vw - 300px)' }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{
                  color: '#004AAD',
                  fontWeight: 'bold',
                }}
                >
                  {translate('ra.label.documento')}
                </TableCell>
                <TableCell style={{
                  color: '#004AAD',
                  fontWeight: 'bold',
                }}
                >
                  {translate('ra.label.compartilhado')}
                </TableCell>
                {alumnos && alumnos.length > 1 && (
                  <TableCell style={{
                    color: '#004AAD',
                    fontWeight: 'bold',
                  }}
                  >
                    {translate('ra.label.privado')}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {materialCurso.map((itemM) => itemM.pictures && itemM.pictures.sort((a, b) => (
                a.title
                  .replace(/UNIDADE 1 /g, 'UNIDADE 01 ')
                  .replace(/UNIDADE 2 /g, 'UNIDADE 02 ')
                  .replace(/UNIDADE 3 /g, 'UNIDADE 03 ')
                  .replace(/UNIDADE 4 /g, 'UNIDADE 04 ')
                  .replace(/UNIDADE 5 /g, 'UNIDADE 05 ')
                  .replace(/UNIDADE 6 /g, 'UNIDADE 06 ')
                  .replace(/UNIDADE 7 /g, 'UNIDADE 07 ')
                  .replace(/UNIDADE 8 /g, 'UNIDADE 08 ')
                  .replace(/UNIDADE 9 /g, 'UNIDADE 09 ') > b.title
                  .replace(/UNIDADE 1 /g, 'UNIDADE 01 ')
                  .replace(/UNIDADE 2 /g, 'UNIDADE 02 ')
                  .replace(/UNIDADE 3 /g, 'UNIDADE 03 ')
                  .replace(/UNIDADE 4 /g, 'UNIDADE 04 ')
                  .replace(/UNIDADE 5 /g, 'UNIDADE 05 ')
                  .replace(/UNIDADE 6 /g, 'UNIDADE 06 ')
                  .replace(/UNIDADE 7 /g, 'UNIDADE 07 ')
                  .replace(/UNIDADE 8 /g, 'UNIDADE 08 ')
                  .replace(/UNIDADE 9 /g, 'UNIDADE 09 ')
                  ? 1 : -1
              )).map((item, index) => (
                <MyMaterialProfesor
                  cursoId={props.id}
                  key={`${item.objectId}-${index}`}
                  // eslint-disable-next-line max-len
                  // louza={<Board alumnos={alumnos} profesor={profesor} mini alumnoId={null} profeId={record.profId} cursoId={props.id} dataProvider={dataProvider} />}
                  doc={item}
                  index={index}
                  alumnos={alumnos}
                />
              )))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Show>
  );
}

function MyRow(props) {
  const dataProvider = useDataProvider();
  const {
    actividad, record, tipoEvaluaciones,
  } = props;
  const tipo = actividad.tipoevaluacionId
    ? tipoEvaluaciones.find((item) => item.objectId === actividad.tipoevaluacionId) : null;
  const [selected, setSelected] = React.useState(null);

  // console.log(actividad)
  // console.log(record)
  React.useEffect(() => {
    if (actividad && actividad.calificaciones && actividad.calificaciones.length) {
      dataProvider.getList('calificaciones', {
        filter: {
          objectId: { $in: actividad.calificaciones },
          alumnoId: record.objectId,
        },
      }).then(({ data }) => {
        if (data && data.length) {
          setSelected(data[0]);
        } else setSelected(null);
      });
    }
  }, []);

  return (
    <TableRow key={actividad[`${record.objectId}`]
      ? actividad[`${record.objectId}`].nota
      : record.objectId}
    >
      <TableCell className="desempeno">{actividad.fecha}</TableCell>
      <TableCell className="desempeno">{tipo ? tipo.name : ''}</TableCell>
      <TableCell className="desempeno">{actividad.peso_evaluacion}</TableCell>
      <TableCell className="desempeno">{actividad.observaciones}</TableCell>
      <TableCell className="desempeno">
        {selected ? selected.calificacion : ''}
      </TableCell>
    </TableRow>
  );
}

function MyDocRowAlumno(props) {
  const translate = useTranslate();
  const {
    doc, cursoId, compartidos,
  } = props;
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [showDialog, setShowDialog] = React.useState(false);
  const [showIframe, setshowIframe] = React.useState(false);
  const [iframeSrc, setiframeSrc] = React.useState(null);
  const [loading] = React.useState(false);
  const alumno = JSON.parse(localStorage.getItem('alumno'));

  const handleClick = async (alumnoBool) => {
    // Traigo el documento o lo creo si no existe
    if ((doc.src && doc.src.split('.').pop() === 'doc') || (doc.src && doc.src.split('.').pop() === 'docx')) {
      const query = alumnoBool ? { cursoId, alumnoId: alumno.objectId } : { cursoId, doc_profe: true };
      const { data: driveDoc } = await dataProvider.getList(
        'drive_documents',
        {
          filter: query,
        },
      );
      const filter = driveDoc.filter((item) => item.file.title === doc.title);
      if (filter && filter.length) {
        redirect(`/drive_documents/${filter[0].id}`);
        // setiframeSrc(`https://docs.google.com/document/d/${filter[0].driveId}`);
        // setshowIframe(true);
        // window.open(`https://docs.google.com/document/d/${filter[0].driveId}`, '_blank');
      }
    } else if ((doc.src && doc.src.split('.').pop() === 'ppt') || (doc.src && doc.src.split('.').pop() === 'pptx')) {
      // PPT abro powerpoint
      setiframeSrc(`https://view.officeapps.live.com/op/embed.aspx?src=${doc.src}`);
      setshowIframe(true);
      // window.open(`https://view.officeapps.live.com/op/embed.aspx?src=${doc.src}`, '_blank');
    } else {
      setiframeSrc(doc.src);
      setshowIframe(true);
      // window.open(doc.src, '_blank');
    }
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  useEffect(() => {
  }, []);

  return (
    <TableRow key={`documento${doc.objectId}`}>
      <TableCell className="desempeno">
        <img
          alt="Icono"
          id={`img-${doc.objectId}`}
          style={{ width: 30 }}
          src={doc.src && doc.src.split('.').pop() === 'pdf'
            ? PdfIcon
            : doc.src && doc.src.includes('youtube') ? YoutubeIcon
              : (doc.src && doc.src.split('.').pop() === 'doc') || ((doc.src && doc.src.split('.').pop() === 'docx') || (doc.src && doc.src.split('.').pop() === 'html'))
                ? WordIcon
                : (doc.src && doc.src.split('.').pop() === 'png') || ((doc.src && doc.src.split('.').pop() === 'jpg') || (doc.src && doc.src.split('.').pop() === 'jpeg'))
                  ? ImageIcon
                  : (doc.src && doc.src.split('.').pop() === 'ppt') || (doc.src && doc.src.split('.').pop() === 'pptx')
                    ? PPtIcon
                    : doc.src && doc.src.split('.').pop() === 'zip'
                      ? ZipIcon
                      : (doc.src && doc.src.split('.').pop() === 'xls') || (doc.src.split('.').pop() === 'xlsx')
                        ? ExcelIcon
                        : WordIcon}
        />
        {' '}
        {doc.title}
        {/* <br /> */}
        {/* {`Fecha de última actualización ${JSON.stringify(doc)}`} */}
      </TableCell>
      <TableCell className="desempeno">
        <MuiButton onClick={() => handleClick()} color="primary" startIcon={<VisibilityIcon style={{ fontSize: 20 }} />}>DOCUMENTO PROFESOR</MuiButton>
      </TableCell>
      <TableCell className="desempeno">
        <MuiButton onClick={() => handleClick(true)} color="primary" startIcon={<VisibilityIcon style={{ fontSize: 20 }} />}>MI DOCUMENTO</MuiButton>
      </TableCell>
      <Dialog
        fullWidth
        fullScreen
        open={showIframe}
        onClose={() => setshowIframe(false)}
      >
        <DialogContent>
          <div style={{ display: 'flex', height: '100%' }}>
            {/* <div style={{
              marginRight: 20, flex: 1, paddingBottom: 50, height: '100%',
            }}
            >
              <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => setshowIframe(false)} label="ra.label.voltar">
                <img src={VoltarIcon} alt="Voltar" />
              </Button>
              <Typography style={{ marginBottom: 10 }} variant="h4">{translate('ra.label.lousa')}</Typography>
              {louza}
            </div> */}
            <div style={{ marginRight: 20, flex: 1 }}>
              <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => setshowIframe(false)} label="ra.label.voltar">
                <img src={VoltarIcon} alt="Voltar" />
              </Button>
              <iframe
                title={doc.title}
                src={iframeSrc}
                width="100%"
                height="calc(100vh - 60px)"
                style={{ height: 'calc(100vh - 60px)' }}
                frameBorder="0"
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            label="ra.label.fechar"
            onClick={() => setshowIframe(false)}
            disabled={loading}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </TableRow>
  );
}
// function MyDocRowCompartido(props) {
//   const translate = useTranslate();
//   const {
//     doc, louza,
//   } = props;
//   const dataProvider = useDataProvider();
//   const [showDialog, setShowDialog] = React.useState(false);
//   const [loading] = React.useState(false);
//   const [webrtcProvider, setwebrtcProvider] = React.useState(null);
//   const handleClick = () => {
//     /**
//      * Si existe el doc tenemos q crear el diccionario
//      */
//     console.log(doc);
//     localStorage.setItem('docId', doc.objectId);
//     if (doc.src && (doc.src.split('.').pop() === 'doc' || doc.src.split('.').pop() === 'docx' || doc.src.split('.').pop() === 'html')) {
//       const webrtcProviderT = new WebrtcProvider(doc.objectId, docY);
//       setwebrtcProvider(webrtcProviderT);
//       setShowDialog(true);
//     } else if ((doc.src && doc.src.split('.').pop() === 'ppt') || (doc.src && doc.src.split('.').pop() === 'pptx')) {
//       // PPT abro powerpoint
//       window.open(`https://view.officeapps.live.com/op/embed.aspx?src=${doc.src}`, '_blank');
//     } else {
//       window.open(doc.src, '_blank');
//     }
//   };

//   const handleCloseClick = () => {
//     setShowDialog(false);
//   };

//   useEffect(() => {
//   }, []);

//   return (
//     <TableRow key={`documento${doc.objectId}`}>
//       <TableCell className="desempeno">
//         <img
//           alt="Icono"
//           id={`img-${doc.objectId}`}
//           style={{ width: 30 }}
//           src={doc.src && doc.src.split('.').pop() === 'pdf'
//             ? PdfIcon
//             : doc.src && doc.src.includes('youtube') ? YoutubeIcon
//               : (doc.src && doc.src.split('.').pop() === 'doc') || ((doc.src && doc.src.split('.').pop() === 'docx') || (doc.src && doc.src.split('.').pop() === 'html'))
//                 ? WordIcon
//                 : (doc.src && doc.src.split('.').pop() === 'png') || ((doc.src && doc.src.split('.').pop() === 'jpg') || (doc.src && doc.src.split('.').pop() === 'jpeg'))
//                   ? ImageIcon
//                   : (doc.src && doc.src.split('.').pop() === 'ppt') || (doc.src && doc.src.split('.').pop() === 'pptx')
//                     ? PPtIcon
//                     : doc.src && doc.src.split('.').pop() === 'zip'
//                       ? ZipIcon
//                       : (doc.src && doc.src.split('.').pop() === 'xls') || (doc.src.split('.').pop() === 'xlsx')
//                         ? ExcelIcon
//                         : WordIcon}
//         />
//         {' '}
//         <Badge badgeContent="Compartido" color="secondary">
//           {doc.title}
//         </Badge>
//       </TableCell>
//       {/* <TableCell className="desempeno">
//         {!doc.enAula ? 'NO' : 'SI' }
//       </TableCell> */}
//       <TableCell className="desempeno">
//         <IconButton onClick={handleClick} color="primary"><VisibilityIcon style={{ fontSize: 20 }} /></IconButton>
//       </TableCell>
//       <Dialog
//         fullWidth
//         fullScreen
//         open={showDialog && webrtcProvider}
//         onClose={handleCloseClick}
//       >
//         {/* <Subscription
//           doc={doc}
//           // updateDocumentos={(object) => {
//           //   setDoc(object.toJSON());
//           // }}
//           loadMaterial={() => console.log('load')}
//         /> */}
//         {/* <DialogTitle></DialogTitle> */}
//         {/* <Typography>Puedes agregar observaciones relacionadas a la asistencia</Typography> */}
//         <DialogContent>
//           <div style={{ display: 'flex', height: '100%' }}>
//             <div style={{
//               marginRight: 20, flex: 1, paddingBottom: 50, height: '100%',
//             }}
//             >
//               <Typography style={{ marginBottom: 10 }} variant="h4">{translate('ra.label.lousa')}</Typography>
//               {louza}
//             </div>
//             <div style={{ marginRight: 20, flex: 2 }}>
//               <MyEditor
//                 doc={docY}
//                 webrtcProvider2={webrtcProvider}
//                 handleCloseClick={handleCloseClick}
//                 user={
//                 localStorage.getItem('profe')
//                   ? JSON.parse(localStorage.getItem('profe'))
//                   : localStorage.getItem('alumno')
//                     ? JSON.parse(localStorage.getItem('alumno'))
//                     : JSON.parse(localStorage.getItem('user'))
//                 }
//                 dataProvider={dataProvider}
//                 docHtml={doc}
//               />
//             </div>
//           </div>
//         </DialogContent>
//         <DialogActions>
//           <Button
//             label="ra.label.fechar"
//             onClick={handleCloseClick}
//             disabled={loading}
//           >
//             <IconCancel />
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </TableRow>
//   );
// }

// eslint-disable-next-line no-unused-vars

function MyMaterialProfesor(props) {
  const {
    doc, louza, cursoId, alumnos,
  } = props;
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const redirect = useRedirect();
  const [showDialog, setShowDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  // const { format } = require('date-fns');
  const [documentoActual, setDoc] = React.useState(null);
  const [values, setValues] = React.useState(null);
  const [webrtcProvider, setwebrtcProvider] = React.useState(null);
  const [compartidos, setCompartidos] = React.useState([]);
  const [showIframe, setshowIframe] = React.useState(false);
  const [iframeSrc, setiframeSrc] = React.useState(null);

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  function abrodocumento(docTemp) {
    redirect(`/alumno_documentos/${docTemp.objectId}/show`);
    // localStorage.setItem('docId', docTemp.objectId);
    // const webrtcProviderT = new WebrtcProvider(docTemp.objectId, docY);
    // setwebrtcProvider(webrtcProviderT);
    // setDoc(docTemp);
    // setShowDialog(true);
  }

  const handleClick = async (doc, enAula, alumno) => {
    if ((doc.src && doc.src.split('.').pop() === 'doc') || (doc.src && doc.src.split('.').pop() === 'docx')) {
      const query = alumno ? { cursoId, alumnoId: alumno.objectId } : { cursoId, doc_profe: true };
      const { data: driveDoc } = await dataProvider.getList(
        'drive_documents',
        {
          filter: query,
        },
      );
      const filter = driveDoc.filter((item) => item.file.title === doc.title);
      if (filter && filter.length) {
        redirect(`/drive_documents/${filter[0].id}`);
        // setiframeSrc(`https://docs.google.com/document/d/${filter[0].driveId}`);
        // setshowIframe(true);
        // window.open(`https://docs.google.com/document/d/${filter[0].driveId}`, '_blank');
      }
    } else if ((doc.src && doc.src.split('.').pop() === 'ppt') || (doc.src && doc.src.split('.').pop() === 'pptx')) {
      // PPT abro powerpoint
      setiframeSrc(`https://view.officeapps.live.com/op/embed.aspx?src=${doc.src}`);
      setshowIframe(true);
      // window.open(`https://view.officeapps.live.com/op/embed.aspx?src=${doc.src}`, '_blank');
    } else {
      setiframeSrc(doc.src);
      setshowIframe(true);
      // window.open(doc.src, '_blank');
    }
  };

  return (
    <TableRow key={`documento${doc.objectId}`}>
      <TableCell className="desempeno">
        <img
          alt="Icono"
          id={`img-${doc.objectId}`}
          style={{ width: 30 }}
          src={doc.src && doc.src.split('.').pop() === 'pdf'
            ? PdfIcon
            : doc.src && doc.src.includes('youtube') ? YoutubeIcon
              : (doc.src && doc.src.split('.').pop() === 'doc') || ((doc.src && doc.src.split('.').pop() === 'docx') || (doc.src && doc.src.split('.').pop() === 'html'))
                ? WordIcon
                : (doc.src && doc.src.split('.').pop() === 'png') || ((doc.src && doc.src.split('.').pop() === 'jpg') || (doc.src && doc.src.split('.').pop() === 'jpeg'))
                  ? ImageIcon
                  : (doc.src && doc.src.split('.').pop() === 'ppt') || (doc.src && doc.src.split('.').pop() === 'pptx')
                    ? PPtIcon
                    : doc.src && doc.src.split('.').pop() === 'zip'
                      ? ZipIcon
                      : (doc.src && doc.src.split('.').pop() === 'xls') || (doc.src.split('.').pop() === 'xlsx')
                        ? ExcelIcon
                        : WordIcon}
        />
        {' '}
        {doc.title}
      </TableCell>
      <TableCell className="desempeno">
        <Button label="ra.label.abrir" onClick={() => handleClick(doc, true)} color="primary"><VisibilityIcon style={{ fontSize: 20 }} /></Button>
      </TableCell>
      {alumnos.length && !doc.title.includes('Instrutivo') && !doc.title.includes('INSTRUTIVO') && !doc.title.includes('instrutivo') ? (
        <TableCell className="desempeno">
          {alumnos.map((alumno, index) => (
            <>
              <Button onClick={() => handleClick(doc, false, alumno)} label={alumno.nombre} key={`${doc.objectId}-${alumno.objectId}-${index}`}>
                <VisibilityIcon style={{ fontSize: 20 }} />
              </Button>
              <br />
            </>
          ))}
          {/* compartidos.length > 0 ? (
            <>
              <Table>
                {compartidos.map((item, index) => (
                  <TableRow key={`${doc.objectId}-${item.objectId}-${index}`}>
                    <TableCell>
                      <span style={{ fontSize: 14 }}>
                        {alumnos.find((alumno) => alumno.objectId === item.alumnoId) ? alumnos.find((alumno) => alumno.objectId === item.alumnoId).nombre : item.alumnoId}
                      </span>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleClick(item, false)} color="primary">
                        <VisibilityIcon style={{ fontSize: 20 }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </>
          ) : (
            <div>
              { doc.src && (doc.src.split('.').pop() === 'doc' || doc.src.split('.').pop() === 'docx' || doc.src.split('.').pop() === 'html') ? (
                <Button
                  disabled={loading}
                  label="ra.label.privado"
                  onClick={async () => {
                    const response = await fetch(doc.src);
                    const html = await response.text();
                    for (const alumno of alumnos) {
                      setLoading(true);
                      await dataProvider.create(
                        'alumno_documentos',
                        {
                          data: {
                            src: doc.src,
                            title: doc.title,
                            cursoId: cursoId || '',
                            enAula: false,
                            alumnoId: alumno.objectId,
                            body: html.split('src="').join('src="https://www.portuguesplus.co/wp-content/uploads/2022/'),
                          },
                        },
                      );
                      fetchCompartidos();
                      setLoading(false);
                    }
                  }}
                  color="default"
                >
                  {loading ? <CircularProgress size={24} /> : <CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
                </Button>
              ) : null }
            </div>
          ) */}
        </TableCell>
      ) : <TableCell className="desempeno" />}
      <Dialog
        fullWidth
        fullScreen
        open={showIframe}
        onClose={() => setshowIframe(false)}
      >
        {/* <Subscription
          doc={doc}
          // updateDocumentos={(object) => {
          //   setDoc(object.toJSON());
          // }}
          loadMaterial={() => console.log('load')}
        /> */}
        {/* <DialogTitle></DialogTitle> */}
        {/* <Typography>Puedes agregar observaciones relacionadas a la asistencia</Typography> */}
        <DialogContent>
          <div style={{ display: 'flex', height: '100%' }}>
            {/* <div style={{
              marginRight: 20, flex: 1, paddingBottom: 50, height: '100%',
            }}
            >
              <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => setshowIframe(false)} label="ra.label.voltar">
                <img src={VoltarIcon} alt="Voltar" />
              </Button>
              <Typography style={{ marginBottom: 10 }} variant="h4">{translate('ra.label.lousa')}</Typography>
              {louza}
            </div> */}
            <div style={{ marginRight: 20, flex: 1 }}>
              {iframeSrc && (iframeSrc.split('.').pop() === 'png' || iframeSrc.split('.').pop() === 'jpg' || iframeSrc.split('.').pop() === 'jpeg') ? (
                <img style={{ maxWidth: '100%' }} src={iframeSrc} alt={doc.title} />
              ) : (
                <iframe
                  title={doc.title}
                  src={iframeSrc}
                  width="100%"
                  height="calc(100vh - 60px)"
                  style={{
                    height: 'calc(100vh - 60px)',
                  }}
                  frameBorder="0"
                />
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            label="ra.label.fechar"
            onClick={() => setshowIframe(false)}
            disabled={loading}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </TableRow>
  );
}

function LousaIcon(props) {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(props.item);

  return (
    <div
      style={{
        alignSelf: 'flex-end',
        width: 150,
        marginLeft: 30,
        marginRight: 30,
        marginBottom: 30,
        justifyContent: 'center',
        textAlign: 'center',
        cursor: 'pointer',
      }}
    >
      <img
        onClick={props.onClick}
        style={{ width: 60 }}
        src="https://portuguesplus.co/interativa/image-2@1x.png"
        alt={props.item}
      />
      {!editing
        ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography noWrap className="docTitle">{value}</Typography>
            <Button
              label=""
              onClick={() => setEditing(true)}
            >
              <EditIcon />
            </Button>
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <MUITextField onChange={(e) => setValue(e.target.value)} variant="outlined" label="nombre" value={value} />
            <Button
              label="ra.label.salvar"
              onClick={async () => {
                const { data: docAll } = await props.dataProvider.getList('posts', { filter: { cursoId: props.cursoId } });
                const lousadodia = docAll.filter((item) => item.createdAt.split('T')[0] === props.item);
                for (const iterator of lousadodia) {
                  /**
                   * Actualizo el nombre
                   */
                  await props.dataProvider.update('posts', { id: iterator.objectId, data: { nombre: value } });
                }
                setEditing(false);
              }}
            >
              <SaveIcon />
            </Button>
          </div>
        )}
    </div>
  );
}
