import React, { useEffect, useState } from 'react';
import {
  Loading, Error, useDataProvider, useTranslate,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles, IconButton } from '@material-ui/core';
import addpostick from '../static/img/addpostick.png';
import Postit from '../postit';
import parseConfig from '../parseConfig';

const useStyles = makeStyles(() => ({
  main: {
    // display: 'flex',
    display: 'block',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#ffffff',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    marginTop: '60px',
    marginBottom: '-25px',
    marginRight: '-25px',
  },
  resumen: {
    height: 226,
    padding: 20,
  },
  miniagenda: {
    maxWidth: 'calc(100vw - 300px)',
    height: 166,
    border: '1px solid #518f18 !important',
    borderRadius: 10,
    overflow: 'auto',
    paddingLeft: 50,
  },
  horizontal: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    display: 'flex',
  },
  agendaitem: {
    border: '1px solid #518f18 !important',
    borderRadius: 10,
    padding: 10,
    marginRight: 10,
  },
  imagen: {
    width: 300,
    height: 'auto',
    marginTop: 60,
  },
  paperroot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    height: 250,
    margin: 20,
  },
  paperitem: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '20%',
    minWidth: 220,
    // height: 250,
    margin: 20,
    borderRadius: 30,
  },
  logo: { width: 260 },
  encuestaTitle: {
    textAlign: 'center',
  },
  titulo: {
    fontSize: '2em',
    fontWeight: 'bold',
  },
  icono: {
    width: '30%',
  },
}));
// import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
const horas = [
  '5h',
  '6h',
  '7h',
  '8h',
  '9h',
  '10h',
  '11h',
  '12h',
  '13h',
  '14h',
  '15h',
  '16h',
  '17h',
  '18h',
  '19h',
  '20h',
  '21h',
  '22h',
  '23h',
  '00h',
  '01h',
  '02h',
  '03h',
  '04h',
];
export default function () {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [eventos, setEventos] = useState([]);
  const [profes, setProfes] = useState([]);
  const [its, setIts] = useState([]);
  const [show] = useState(true);
  const [opacityOnHover] = useState(false);
  let miscursosT = [];

  function saveIts(its) {
    dataProvider.update('users', { id: localStorage.getItem('userId'), data: { mypostick: its } });
  }
  const fetchDatas = async () => {
    if (localStorage.getItem('userId')) {
      const perfil = localStorage.getItem('perfil') || null;
      const profe = localStorage.getItem('profe') ? JSON.parse(localStorage.getItem('profe')) : null;
      const alumno = localStorage.getItem('alumno') ? JSON.parse(localStorage.getItem('alumno')) : null;
      const hoy = new Date();
      // traigo cumpleaños
      const { data: cumples } = await dataProvider.getList('alumnos', { filter: { cumpleanos: `${hoy.getDate()}/${(hoy.getMonth() + 1)}` } });

      if (perfil === 'alumno' && alumno) {
        dataProvider.getList('cursos', { filter: { alumnos: alumno.objectId } })
          .then(({ data }) => console.log(data));

        fetch(
          `${parseConfig.URL}/classes/cursos?where={"alumnos":${JSON.stringify(alumno.objectId)}}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Parse-Application-Id': 'PPlusCr0b51',
              'X-Parse-REST-API-Key': 'PPlusr3stk3y',
            },
          },
        )
          .then((response) => response.json())
          .then(({ results }) => {
            console.log(results);
            miscursosT = results.map((item) => item.objectId);
            // setMiscursos(results);
            setLoading(false);
          });
      }

      dataProvider.getOne('users', { id: localStorage.getItem('userId') })
        .then(({ data }) => {
          // if(data && data.error)
          //     redirect('/login')
          // Completo los postick con los cumpleaños
          let myPostit = data.mypostick ? data.mypostick : [];
          for (const cumple of cumples) {
            myPostit = [...myPostit, {
              id: cumple.objectId,
              value: `<p>${cumple.cumpleanos} es el cumpleaños de ${cumple.nombre}</p><br/><p>FELICÍTALA 🎂 🎉</p>`,
              backgroundColor: '#518f18',
              teste: '',
              position: { x: 260, y: 35 },
              size: { width: 206, heigth: 203 },
              radius: true,
              shadow: true,
            }];
          }
          setIts(myPostit);
          setLoading(false);
          localStorage.setItem('userData', data);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });

      dataProvider.getList('profesores', { filter: {} })
        .then(({ data }) => {
          setProfes(data);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });

      // Si soy profe, solo mis eventos
      // Si soy alumno, solo los eventos de mi curso
      console.log(miscursosT);
      const filtro = perfil && perfil === 'profesor' && profe ? { profId: profe.objectId }
        : perfil && perfil === 'alumno' && alumno ? { cursoId: { $in: miscursosT } } : {};
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      let tomorrow = new Date();
      tomorrow.setHours(0, 0, 0, 0);
      tomorrow = tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow = new Date(tomorrow).toISOString();
      filtro.fecha_inicio = { $gte: today, $lte: tomorrow };
      console.log(filtro);
      dataProvider.getList('eventos', { filter: filtro })
        .then(({ data }) => {
          console.log(data);
          setEventos(data);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
      // console.log(myLojas)
      // setMyLojas(myLojas.data)
    } else {
      // redirect('/login')
    }
  };

  const add = (() => {
    // var randColors = ['#d27478','#6d4f80','#f0ec78','#fcdbf6'];
    // var backgroundColor = "#fcdbf6";
    const today = new Date();
    const id = today.getTime();
    // Standard object for it to work
    const obj = {
      id,
      value: '<p>Mensagem</p>',
      backgroundColor: '#518f18',
      teste: '',
      position: { x: 260, y: 35 },
      size: { width: 206, heigth: 203 },
      radius: true,
      shadow: true,
    };
    saveIts([...its, obj]);
    setIts([...its, obj]);
  });

  if (its.length === 0 && 1 === 2) {
    // console.log('Setou inicial');
    setIts([
      {
        id: 'xa1',
        value: '<p>My 1</p>',
        backgroundColor: '#f0ec78',
        teste: '',
        position: { x: 260, y: 35 },
        size: { width: 206, heigth: 203 },
        radius: true,
        shadow: true,
      },
      {
        id: 'xa2',
        value: '<p>My 2</p>',
        backgroundColor: '#607d8b',
        teste: '',
        position: { x: 500, y: 35 },
        size: { width: 206, heigth: 203 },
        radius: true,
        shadow: true,
      },
      {
        id: 'xa3',
        value: '<p>My 3</p>',
        backgroundColor: '#fcdbf6',
        teste: '',
        position: { x: 750, y: 35 },
        size: { width: 206, heigth: 203 },
        radius: true,
        shadow: true,
      },
    ]);
  }

  if (loading) return <Loading />;
  if (error) return <Error />;
  const translate = useTranslate();
  const hoy = new Date();
  const options = {
    weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
  };

  useEffect(() => {
    // localStorage.removeItem('loja')
    fetchDatas();

    /**
     * Script de Carlos para arreglar documentos
     */

    // fetch(
    //   `${parseConfig.URL}/classes/alumno_documentos?limit=1000`,
    //   {
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'X-Parse-Application-Id': parseConfig.APP_ID,
    //       'X-Parse-REST-API-Key': 'PPlusr3stk3y',
    //       'X-Parse-Master-Key': parseConfig.MASTER_KEY,
    //     },
    //   },
    // )
    //   .then((response) => response.json())
    //   .then((json) => {
    //     for (let index = 0; index < json.results.length; index++) {
    //       const data = json.results[index];
    //       const body = {
    //         alumnoDocumentoId: data.objectId,
    //       };
    //       if (data.doc || data.body) {
    //         body.html = data.doc ? data.doc : data.body ? data.body : '';
    //       }
    //       fetch(`${parseConfig.URL}/classes/html_alumno_documento`, {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json',
    //           'X-Parse-Application-Id': parseConfig.APP_ID,
    //           'X-Parse-REST-API-Key': 'PPlusr3stk3y',
    //           'X-Parse-Master-Key': parseConfig.MASTER_KEY,
    //         },
    //         body: JSON.stringify(body),
    //       }).then((response) => response.json())
    //         .then((json) => console.log(json))
    //         .catch((error) => {
    //           console.log(error);
    //           console.log(data.objectId);
    //           fetch(`${parseConfig.URL}/classes/html_alumno_documento`, {
    //             method: 'POST',
    //             headers: {
    //               'Content-Type': 'application/json',
    //               'X-Parse-Application-Id': parseConfig.APP_ID,
    //               'X-Parse-REST-API-Key': 'PPlusr3stk3y',
    //               'X-Parse-Master-Key': parseConfig.MASTER_KEY,
    //             },
    //             body: JSON.stringify({
    //               alumnoDocumentoId: data.objectId,
    //             }),
    //           }).then((response) => response.json())
    //             .then((json) => console.log(json))
    //             .catch((error) => {
    //               console.log(error);
    //               console.log(data);
    //             });
    //         });
    //     }
    //   });
  }, []);

  return (
    <div className={classes.main}>
      <div className={classes.resumen}>
        <Typography>
          {translate('ra.label.atividades')}
          {': '}
          {hoy.toLocaleString(translate('ra.label.dia_semana'), options)}
        </Typography>
        <div className={classes.miniagenda}>
          <ul id="first-list">
            {horas.map((hora) => {
              const { format } = require('date-fns');
              // let eventosdahora = []
              // for (const item of eventos) {
              //   const fechainicio = format(new Date(item.fecha_inicio), 'HH:mm')
              //   console.log(parseInt(hora), parseInt(fechainicio))
              //   if(hora === JSON.stringify(fechainicio).split(':')[0])
              //     eventosdahora.push(item)
              // }

              const eventosdahora = eventos.filter((item) => {
                const fechainicio = format(new Date(item.fecha_inicio), 'HH:mm');
                return parseInt(hora) === parseInt(fechainicio);
              });

              return (
                <li>
                  <span />
                  <div className={classes.horizontal}>
                    {eventosdahora.map((evento) => {
                      const profe = profes.find((item) => item.objectId === evento.profId);
                      return (
                        <div className={classes.agendaitem}>
                          <div className="title">{evento.nombre}</div>
                          <div className="info">
                            Profesor:
                            {profe ? profe.nombre : ''}
                          </div>
                          <div className="name">
                            Término:
                            {format(new Date(evento.fecha_final), 'HH:mm')}
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <div className="time">
                    <span>{hora}</span>
                    <span />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="app_potit_example">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography className="cuadro-anotaciones">{translate('ra.label.quadro_avisos')}</Typography>
          <IconButton onClick={() => add()}>
            <img alt="Agregar" src={addpostick} />
          </IconButton>
        </div>
        <Postit
          onEvent={(event) => {
            console.log(event);
            if (event.type !== 'delete') {
              const string = JSON.stringify(its);
              const parse = JSON.parse(string);
              parse[event.index][event.prop] = event.value;
              setIts(parse);
              saveIts(parse);
            }

            if (event.type === 'delete') {
              const string = JSON.stringify(its);
              const parse = JSON.parse(string);
              parse.splice(event.index, 1);
              setIts(parse);
              saveIts(parse);
            }
          }}
          data={its}
          show={show}
          opacityNotHover={(opacityOnHover === true ? 0.5 : 1)}
        />

      </div>
    </div>
  );
}
