/**
 * 
 * Afazeres por precensia
 * cambiar fecha no esta traducido
 * Arreglar el color del tabler
 * Arreglar el nombre del material
 * Abrir el margen del material
 * Asistencias
 * Tablero en otro color
 * 
 * */
import React from 'react';
import { Admin, Resource } from 'react-admin';
import './App.css';
import polyglotI18nProvider from 'ra-i18n-polyglot';
// import { Route } from 'react-router-dom';
import AuthClient from './authClient';
import RestClient from './restClient';
import addUploadFeature from './addUploadFeature';
import {
  PostList, AlumnoDocList, PostCreate, PostEdit,
} from './posts';
import {
  FinancieroList,
  FinancieroCreate,
  FinancieroEdit,
  DespesasList,
  ReceitasList,
} from './financiero/financiero';
import { UserList, UserEdit, UserCreate } from './users';
import {
  AlunoList, AlunoCreate, AlunoEdit, AlunoShow,
} from './alunos';
import { ConfigList } from './config';
import {
  CursoList, CursoCreate, CursoEdit, CursoShow,
} from './cursos';
import { ProfeList, ProfeCreate, ProfeEdit } from './profesores';
import {
  MaterialList, MaterialCreate, MaterialEdit, MaterialShow,
} from './material';
import { EventList, EventCreate, EventEdit } from './eventos';
import { Dashboard } from './dashboard';
import { Login, Layout } from './layout';
// import MyEditor from './componentes/MyEditor';
import { DriveEdit } from './alumnoDoc';
// parse-dashboard --config ~/Documents/parse-dashboard-config.json
// export const i18nProvider = polyglotI18nProvider(() => spanishMessages, 'es');
import {
  englishLang, portuguesLang, espanolLang, italianoLang,
} from './layout/Translate';
// import frenchMessages from 'ra-language-french';
import parseConfig from './parseConfig';

const messages = {
  es: espanolLang,
  en: englishLang,
  it: italianoLang,
  pt: portuguesLang,
};

const i18nProvider = polyglotI18nProvider((locale) => {
  const locale2 = localStorage.getItem('lang');
  // alert(`locale: ${locale} - ${locale2}`);
  if (locale2 && locale2 !== locale) { return (locale2 ? messages[locale2] : messages.pt); }
  return (messages.pt);
});

const authProvider = AuthClient(parseConfig);
const dataProvider = RestClient(parseConfig);
const uploadCapableDataProvider = addUploadFeature(dataProvider);

// const customRoutes = [
//   <Route exact path="/myEditor/:id" component={MyEditor} noLayout />,
// ];

function App() {
  return (
    <Admin
      dashboard={Dashboard}
      authProvider={authProvider}
      // customRoutes={customRoutes}
    // theme={theme}
      i18nProvider={i18nProvider}
      loginPage={Login}
      appLayout={Layout}
      dataProvider={uploadCapableDataProvider}
    >
      <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} />
      <Resource name="alumnos" create={AlunoCreate} list={AlunoList} edit={AlunoEdit} show={AlunoShow} />
      <Resource name="profesores" create={ProfeCreate} list={ProfeList} edit={ProfeEdit} />
      <Resource name="cursos" show={CursoShow} create={CursoCreate} list={CursoList} edit={CursoEdit} />
      <Resource name="material" create={MaterialCreate} list={MaterialList} edit={MaterialEdit} show={MaterialShow} />
      <Resource name="modulos" />
      <Resource name="parcelas" />
      <Resource name="receitas" list={ReceitasList} />
      <Resource name="despesas" list={DespesasList} />
      <Resource name="pagos" />
      <Resource name="contratos" />
      <Resource name="natureza_entrada" />
      <Resource name="alumno_documentos" list={AlumnoDocList} edit={PostEdit} /* show={AlumnoDocumentoShow} */ />
      <Resource name="drive_documents" edit={DriveEdit} />
      <Resource name="natureza_saida" />
      <Resource name="financiero" create={FinancieroCreate} list={FinancieroList} edit={FinancieroEdit} />
      <Resource name="entradas" create={FinancieroCreate} list={FinancieroList} edit={FinancieroEdit} />
      <Resource name="salidas" create={FinancieroCreate} list={FinancieroList} edit={FinancieroEdit} />
      <Resource name="tipo_evaluacion" />
      <Resource name="configuracion" list={ConfigList} />
      <Resource name="eventos" create={EventCreate} list={EventList} edit={EventEdit} />
      <Resource name="posts" create={PostCreate} list={PostList} edit={PostEdit} />
    </Admin>
  );
}

export default App;
