import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  FileField,
  FileInput,
  useTranslate,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FileIcon from '../static/img/filesicon.png';

function QuickCreateContrato({ onChange, clienteId }) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('contratos');
  const notify = useNotify();
  const form = useForm();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    // eslint-disable-next-line no-param-reassign
    values.alumnoId = clienteId;
    // eslint-disable-next-line no-param-reassign
    values.clienteId = clienteId;

    // eslint-disable-next-line no-unreachable
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          form.change('contratoId', data.id);
          onChange();
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        },
      },
    );
  };

  const translate = useTranslate();

  return (
    <>
      <Button onClick={handleClick} label="ra.label.add">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create post"
      >
        <DialogTitle>{translate('ra.label.contrato')}</DialogTitle>

        <FormWithRedirect
          resource="posts"
          save={handleSubmit}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving,
          }) => (
            <>
              <DialogContent>
                <TextInput
                  source="titulo"
                  validate={required()}
                  fullWidth
                  variant="outlined"
                />
                <ReferenceInput
                  perPage={100000}
                  emptyText="Ninguno"
                  fullWidth
                  sort={{ field: 'nombre', order: 'ASC' }}
                  variant="outlined"
                  style={{ minWidth: '50%' }}
                  label="ra.label.cliente"
                  source="alumnoId"
                  reference="alumnos"
                  defaultValue={clienteId}
                >
                  <SelectInput defaultValue={clienteId} emptyText="Ninguno" optionText="nombre" />
                </ReferenceInput>
                <FileInput
                  source="pictures"
                  multiple
                  label="ra.label.contrato"
                  placeholder={(
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'transparent',
                      borderColor: '#518F18',
                      borderStyle: 'dashed',
                      borderWidth: 1,
                      borderRadius: 16,
                      width: 260,
                      height: 160,
                    }}
                    >
                      <p>
                        <img src={FileIcon} alt="Archivo" />
                      </p>
                      <p>{translate('ra.label.arraste_solte')}</p>
                    </div>
                                      )}
                  accept="image/*, application/*, text/*"
                >
                  <FileField source="src" title="title" />
                </FileInput>
              </DialogContent>
              <DialogActions>
                <Button
                  className="cancelar-btn"
                  label="ra.label.cancelar"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                                  handleSubmitWithRedirect
                              }
                  label="ra.label.salvar"
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default QuickCreateContrato;
