import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { Resizable } from 're-resizable';
import ReactQuill from 'react-quill';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ShareIcon from '@material-ui/icons/Share';
import { useDataProvider } from 'react-admin';
import {
  ListItemIcon,
} from '@material-ui/core/';
import SimpleDialog from '../../componentes/SelectUser';
import Color from '../images/color.png';
import ChangeColor from './ChangeColor.jsx';
import DeletePostit from './DeletePostit.jsx';

function Postit(props) {
  const dataProvider = useDataProvider();
  const { value } = props;
  const [openDelete, setOpendelete] = useState(false);
  const [openChangeColor, setOpenChangeColor] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [state] = React.useState({
    mouseX: null,
    mouseY: null,
  });

  const handleClose = (id, obj) => {
    setOpen(false);
    // setSelectedValue(value);
    /** Share postick */
    if (id && obj && obj.usuarioId) {
      console.log(obj);
      dataProvider.getOne('users', { id: obj.usuarioId })
        .then(({ data }) => {
          const temporalPoisticks = data.mypostick ? data.mypostick : [];
          dataProvider.update('users', { id: obj.usuarioId, data: { mypostick: [...temporalPoisticks, props.value] } })
            .then(() => (data && data.updatedAt ? alert(`Compartido con ${obj.nombre}`) : console.log(data)));
        });
    }
  };

  const deletePostit = (() => {
    props.sendEvent({ type: 'delete', index: props.index, id: value.id });
  });

  let radius = false;
  let shadow = false;

  if (value.radius === true) { radius = true; }
  if (value.shadow === true) { shadow = true; }

  function handleRightClick(event) {
    console.log(event.target);
    event.preventDefault();

    // if (!event.target.id) {
    //     handleClose()
    // }else{
    //     if(event.target.id)
    //     setState({
    //         mouseX: event.clientX - 2,
    //         mouseY: event.clientY - 4,
    //       });
    // }
  }

  return (
    <>
      {/* <Draggable
                      handle=".handle"
                      defaultPosition={{x: 0, y: 0}}
                      position={null}
                      grid={[1, 1]}
                      scale={1}
                      onStart={(e, data)=>console.log(e, data)}
                      onDrag={(e, data)=>console.log(e, data)}
                      onStop={(e, data)=>console.log(e, data)}>
                      <div style={{
                        cursor: 'move'
                      }}>
                        <div style={{color:'red'}} className="handle">Drag from here</div>
                        <div style={{color:'red'}}>This readme is really dragging on...</div>
                      </div>
                    </Draggable> */}
      <Draggable
        axis="both"
        handle=".handle_drag_postit"
                    // position={{x:value.position.x,y:value.position.y}}
        defaultPosition={{ x: value.position.x - 250, y: value.position.y }}
        grid={[1, 1]}
        scale={1}
        onStop={(e) => {
          props.sendEvent({
            type: 'changePosition', index: props.index, value: { x: (e.x - e.offsetX), y: (e.y - e.offsetY) }, prop: 'position', id: value.id,
          });
        }}
        onDrag={(e) => console.log(e)}
        defaultClassName="resize-postit"
      >
        <Resizable
          id={`cntx${props.index}`}
          onContextMenu={handleRightClick}
          onResizeStop={(e, direction, ref, d) => {
            const val = { width: value.size.width + d.width, heigth: value.size.heigth + d.height };
            props.sendEvent({
              type: 'changeSize', index: props.index, value: val, prop: 'size', id: value.id,
            });
          }}
          size={{ width: value.size.width, height: value.size.heigth }}
        >
          <Menu
            keepMounted
            open={
                       state.mouseY !== null
                    }
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
                    state.mouseY !== null && state.mouseX !== null
                      ? { top: state.mouseY, left: state.mouseX }
                      : undefined
                    }
          >
            <MenuItem
              onClick={() => {
                // window.open(doc.src, '_blank');
                handleClose();
              }}
            >
              <ListItemIcon>
                {/* <OpenWithIcon fontSize="small" /> */}
              </ListItemIcon>
              Abrir
              {' '}
            </MenuItem>
            <MenuItem
              onClick={() => {
                // var a = document.createElement("a");
                // a.href = doc.src;
                // a.setAttribute("download", doc.title);
                // a.click();
                handleClose();
              }}
            >
              <ListItemIcon>
                {/* <CloudDownloadIcon fontSize="small" /> */}
              </ListItemIcon>
              Descargar
            </MenuItem>
            {/* <MenuItem onClick={handleClose}>
                        <ListItemIcon><VisibilityIcon fontSize="small"/></ListItemIcon>
                        Ver Permisos</MenuItem> */}
            <MenuItem
              onClick={() => {
                // setShowDialog(true)
                handleClose();
              }}
            >
              <ListItemIcon>
                {/* <EditIcon fontSize="small"/> */}
              </ListItemIcon>
              Renombrar
            </MenuItem>
            <MenuItem
              onClick={() => {
                // deleteItem(index)
                handleClose();
              }}
            >
              <ListItemIcon>
                {/* <DeleteOutlineIcon fontSize="small"/> */}
              </ListItemIcon>
              Borrar
            </MenuItem>

          </Menu>
          <div className={`postit_drag ${radius === true ? 'postit_drag_border' : ''} ${shadow === true ? 'postit_drag_shadown' : ''}`} style={{ backgroundColor: value.backgroundColor }}>
            <div className="handle_drag_postit" />
            <ReactQuill
              id={`quill${props.index}`}
              onChange={(valueQuill) => props.sendEvent({
                type: 'changeContent', index: props.index, value: valueQuill, prop: 'value', id: value.id,
              })}
              theme="bubble"
              value={value.value}
            />
          </div>
          <div className="div_bottom_postit">
            <DeleteOutlineIcon fontSize="small" onClick={() => setOpendelete(true)} className="trashPostit" />
            <ShareIcon fontSize="small" onClick={() => setOpen(true)} className="trashPostit" />
            <SimpleDialog resource="alumnos" resource2="profesores" title="Seleccione a quien desea compartir" open={open} onClose={handleClose} />
            <img alt="Borrar" onClick={() => { setOpenChangeColor(!openChangeColor); }} className="trashPostit" src={Color} />
          </div>
          {

                        (openDelete === true)
                        && (
                        <DeletePostit
                          value={value}
                          delete={() => deletePostit()}
                          close={() => setOpendelete(false)}
                        />
                        )

                    }
          {

                        (openChangeColor === true)
                        && (
                        <ChangeColor
                          change={(e) => {
                            console.log(e);
                            props.sendEvent({
                              type: 'changeBackgroundColor', index: props.index, value: e, prop: 'backgroundColor', id: value.id,
                            });
                          }}
                          value={value}
                          close={() => setOpendelete(false)}
                        />
                        )

                    }
        </Resizable>
      </Draggable>
    </>
  );
}

export default Postit;
