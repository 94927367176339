import React, { useState } from 'react';
import {
  Button,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  ReferenceInput,
  SelectInput,
  useTranslate,
} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import { DateTimeInput, TimeInput } from '../DateInput';
import CreateProfButton from './CreateProfButton';
import { ButtonDefault } from './Buttons';

function CreateEventoButton() {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('eventos');
  const notify = useNotify();
  // const form = useForm();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: () => {
          setShowDialog(false);
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          //   form.change('moduloId', data.id);
          // onChange();
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        },
      },
    );
  };

  const translate = useTranslate();
  return (
    <>
      <Button id="CrearEventoBtn" onClick={handleClick} label="ra.label.criar_evento">
        <AddCircleIcon />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>{translate('ra.label.criar_evento')}</DialogTitle>
        <FormWithRedirect
          resource="eventos"
          save={handleSubmit}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving,
          }) => (
            <>
              <DialogContent>
                <TextInput variant="outlined" label="ra.label.nome_evento" fullWidth source="nombre" />
                <TextInput label="ra.label.descricao" variant="outlined" minRows={4} fullWidth multiline source="descripcion" />
                <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
                  <Box
                    flex={1}
                    mr={{ xs: 0, sm: '0.5em' }}
                  >
                    <DateTimeInput label="ra.label.data" variant="outlined" source="fecha_inicio" />
                    <TimeInput variant="outlined" label="ra.label.hora_inicio" fullWidth source="hora_inicial" />
                  </Box>
                </Box>
                <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
                  <Box
                    flex={1}
                    mr={{ xs: 0, sm: '0.5em' }}
                  >
                    <div style={{ display: 'flex' }}>
                      <ReferenceInput
                        perPage={100000}
                        sort={{ field: 'nombre', order: 'ASC' }}
                        variant="outlined"
                        style={{ minWidth: '50%' }}
                        source="profId"
                        label="ra.label.professor"
                        reference="profesores"
                      >
                        <SelectInput optionText="nombre" />
                      </ReferenceInput>
                      <CreateProfButton />
                    </div>
                  </Box>
                  <Box
                    flex={1}
                    ml={{ xs: 0, sm: '0.5em' }}
                  >
                    <ReferenceInput
                      perPage={100000}
                      sort={{ field: 'nombre', order: 'ASC' }}
                      variant="outlined"
                      style={{ minWidth: '50%' }}
                      source="cursoId"
                      label="ra.label.curso"
                      reference="cursos"
                    >
                      <SelectInput optionText="nombre" />
                    </ReferenceInput>
                  </Box>
                </Box>
                <SelectInput
                  label="ra.label.tipo_evento"
                  variant="outlined"
                  source="tipo_evento"
                  choices={[
                    { id: 'Reunión', name: 'ra.label.aula' },
                    { id: 'Formación', name: 'ra.label.formacao' },
                    { id: 'CReunión', name: 'ra.label.reuniao' },
                    { id: 'Privado', name: 'ra.label.privado' },
                    { id: 'Otro', name: 'ra.label.outro' },
                  ]}
                />
              </DialogContent>
              <DialogActions>
                <ButtonDefault
                  buttonClass="branco-azul"
                  label="ra.label.cancelar"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </ButtonDefault>
                <SaveButton
                  label="ra.label.salvar"
                  className="destaque-verde"
                  handleSubmitWithRedirect={
                                  handleSubmitWithRedirect
                              }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default CreateEventoButton;
