import React, { useState } from 'react';
import {
  Button,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  ReferenceInput,
  SelectInput,
  useDataProvider,
  // FileField,
  // FileInput,
  useTranslate,
  // useRecordContext,
  // useRedirect,
} from 'react-admin';
// import { List as MUIList, makeStyles } from '@material-ui/core';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
// import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
// import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CreateModuloButton from './CreateModuloButton';
import AddDirIcon from '../static/img/addpasta.png';
// import FileIcon from '../static/img/filesicon.png';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: '100%',
//   },
//   heading: {
//     fontSize: theme.typography.pxToRem(15),
//     fontWeight: theme.typography.fontWeightRegular,
//   },
// }));

// function MisDocumentos() {
//   const record = useRecordContext();
//   const classes = useStyles();
//   const dataProvider = useDataProvider();
//   const redirect = useRedirect();
//   const [loading, setLoading] = useState(false);
//   return (
//     <MUIList className={classes.root}>
//       {record.pictures && record.pictures.map((doc, index) => (
//         <>
//           <ListItem alignItems="flex-start">
//             <ListItemText
//               primary={(
//                 <>
//                   {index + 1}
//                   .
//                   {doc.title}
//                 </>
// )}
//             />
//             <ListItemSecondaryAction>
//               <Typography
//                 component="span"
//                 variant="body2"
//                 className={classes.inline}
//                 color="textPrimary"
//               >
//                 {doc.date}
//               </Typography>
//               <IconButton
//                 color="primary"
//                 aria-label="add to shopping cart"
//                 onClick={async () => {
//                   setLoading(index + 1);
//                   record.pictures.splice(index, 1);
//                   console.log(record.pictures);
//                   const updateMio = await dataProvider.update('material',
//                      { id: record.id, data: { pictures: record.pictures } });
//                   // .then(()=>redirect('/material/'+record.id))
//                   console.log(updateMio);
//                   redirect(`/material/${record.id}`);
//                 }}
//               >
//                 {loading !== index + 1
//                   ? <DeleteOutlineIcon style={{ color: '#004aad' }} />
//                   : <CircularProgress size={20} />}
//               </IconButton>
//             </ListItemSecondaryAction>
//           </ListItem>
//           <Divider style={{ backgroundColor: '#004AAD' }} component="li" />
//         </>
//       ))}
//     </MUIList>
//   );
// }

function CreateCarpetaMaterialButton(props) {
  const dataProvider = useDataProvider();
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('material');
  const notify = useNotify();
  // const form = useForm();
  const translate = useTranslate();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    const body = {};
    body.padreId = props.record.objectId;
    body.moduloId = props.record.moduloId;
    body.nombre = values.nombrehijo;
    body.descripcion = values.descripcionhijo;

    create(
      { payload: { data: body } },
      {
        onSuccess: ({ data }) => {
          const permisosArr = [];
          /** Creo los permisos por default para los alumnos y el profe */
          /**
                    *1. Traigo a los alumnos del módulo
                    *1.1. Traigo los cursos con ese módulo
                    *1.2. Los alumnos de cada curso obtenido son los q me sirven
                    */
          console.log(data);
          const nuevoId = data.id;
          dataProvider.getList('cursos', { filter: { moduloId: values.moduloId } })
            .then(({ data: cursos }) => {
              /**
                        *2. Creo el array de permisos para los alumnos con los valores default
                        */
              console.log(cursos);

              if (cursos && cursos.length) {
                for (let index = 0; index < cursos.length; index++) {
                  const curso = cursos[index];
                  console.log(curso);
                  if (curso && curso.alumnos) {
                    for (const alumno of curso.alumnos) {
                      permisosArr.push({
                        alumno,
                        acceso: [
                          'Leer',
                          'Editar',
                          'Descargar',
                        ],
                        status: 'Activo',
                      });
                    }
                  }
                }
              }
              /**
                        *3. Save the permisos array
                        */
              dataProvider.update('material', {
                id: nuevoId,
                data: {
                  permisos: permisosArr,
                },
              }).then((result) => console.log(result));
            });

          setShowDialog(false);
          props.getChilds();
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          //   form.change('moduloId', data.id);
          // onChange();
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        },
      },
    );
  };

  return (
    <>
      <Button onClick={handleClick} label="" style={{ position: 'absolute', right: 40 }}>
        <img
          alt="Agregar"
          src={AddDirIcon}
          style={{
            width: 60,
            height: 55,
          }}
        />
      </Button>
      <Dialog
        fullWidth
        fullScreen
        open={showDialog}
        onClose={handleCloseClick}

      >
        <DialogTitle>
          <div style={{ display: 'flex' }}>
            <Button
              label="ra.label.voltar"
              onClick={handleCloseClick}
              disabled={loading}
            >
              <ArrowBackIosIcon />
            </Button>
            <Typography fullWidth style={{ textAlign: 'center', flex: 1 }}>
              {translate('ra.label.criar_subpasta')}
            </Typography>
          </div>
        </DialogTitle>
        <FormWithRedirect
          resource="moviles"
          save={handleSubmit}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving,
          }) => (
            <>
              <DialogContent>
                <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
                  <Box
                    flex={1}
                    mr={{ xs: 0, sm: '0.5em' }}
                  >
                    <TextInput variant="outlined" fullWidth label="ra.label.nome_pasta" source="nombrehijo" />
                  </Box>
                  <Box
                    flex={1}
                    ml={{ xs: 0, sm: '0.5em' }}
                  >
                    <ReferenceInput
                      label="ra.label.modulo"
                      perPage={100000}
                      defaultValue={props.record.moduloId}
                      sort={{ field: 'name', order: 'ASC' }}
                      variant="outlined"
                      style={{ minWidth: '50%' }}
                      source="moduloId"
                      reference="modulos"
                    >
                      <SelectInput defaultValue={props.record.moduloId} optionText="name" />
                    </ReferenceInput>
                    <CreateModuloButton />
                  </Box>
                </Box>
                <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
                  <Box
                    flex={1}
                    mr={{ xs: 0, sm: '0.5em' }}
                  >
                    <TextInput minRows={6} multiline variant="outlined" fullWidth label="ra.label.descricao" source="descripcionhijo" />
                  </Box>
                  <Box flex={1} />
                </Box>
                {/* <Typography variant="h4">
                    {translate('ra.label.adicionar_arquivo')}
                  </Typography> */}
                {/* <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
                  <Box
                    flex={0.5}
                    mr={{ xs: 0, sm: '0.5em' }}
                  >
                    <FileInput
                      source="pictures"
                      multiple
                      label=""
                      placeholder={(
                        <div style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: 'transparent',
                          borderColor: '#518F18',
                          borderStyle: 'dashed',
                          borderWidth: 1,
                          borderRadius: 16,
                          width: 260,
                          height: 160,
                        }}
                        >
                          <p>
                            <img src={FileIcon} alt="Archivo" />
                          </p>
                          <p>{translate('ra.label.arraste_solte')}</p>
                        </div>
                                  )}
                      accept="image/*, application/*, text/*"
                    >
                      <FileField source="src" title="title" />
                    </FileInput>

                  </Box> */}
                {/* <Box
                    flex={1}
                    ml={{ xs: 0, sm: '0.5em' }}
                  >
                    <MisDocumentos source="pictures" />
                  </Box> */}
                {/* </Box> */}
                {/* JSON.stringify(props.record) */}
              </DialogContent>
              <DialogActions>
                <Button
                  className="cancelar-btn"
                  label="ra.label.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                                  handleSubmitWithRedirect
                              }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default CreateCarpetaMaterialButton;
