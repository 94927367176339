// URL: 'https://interactiva.freiesland.com/interactiva', // Pruebas
// URL: 'https://interactiva.portuguesplus.com/pplus', // Production
// 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

const parseConfig = {
  URL: 'https://interactiva.portuguesplus.com/pplus', // Produccion
  DOMAiN: 'https://interactiva.portuguesplus.com', // Produccion
  // URL: 'https://interactiva.freiesland.com/interactiva', // Pruebas
  // DOMAiN: 'https://interactiva.freiesland.com', // Pruebas
  'APP-ID': 'PPlusCr0b51',
  'REST-API-KEY': 'PPlusr3stk3y',
  'REST-MASTER-KEY': 'PPlusm4st3rk3y',
  JAVASCRIPT_KEY: null,
  APP_ID: 'PPlusCr0b51',
  MASTER_KEY: 'PPlusm4st3rk3y',
  headers: {
    'Content-Type': 'application/json',
    'X-Parse-Application-Id': 'PPlusCr0b51',
    'X-Parse-REST-API-Key': 'PPlusr3stk3y',
    'X-Parse-Master-Key': 'PPlusm4st3rk3y',
  },
};

export default parseConfig;
