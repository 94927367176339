
import Parse from 'parse';
import parseConfig from './parseConfig';
// const parseConfig = {
//   URL: 'https://interactiva.portuguesplus.com/pplus',
//   'APP-ID': 'PPlusCr0b51',
//   'REST-API-KEY': 'PPlusr3stk3y',
//   'REST-MASTER-KEY': 'PPlusm4st3rk3y',
//   JAVASCRIPT_KEY: null,
//   APP_ID: 'PPlusCr0b51',
//   MASTER_KEY: 'PPlusm4st3rk3y',
// };

Parse.initialize(parseConfig.APP_ID, parseConfig.JAVASCRIPT_KEY, parseConfig.MASTER_KEY);
Parse.serverURL = parseConfig.URL;

export const convertFileToBase64 = (file) => new Promise((resolve, reject) => Parse.User.logIn('crobinson', 'crobinson')
  .then(() => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);
    reader.onload = (event) => {
      const base64 = event.target.result;
      const parseFile = new Parse.File(file.rawFile.name, { base64 });
      return parseFile.save().then(() => {
        console.log(parseFile);
        return resolve(parseFile.url().replace('http://localhost:1337', parseConfig.DOMAiN));
      }, (error) => {
        console.log(error);
        return resolve(error);
      });
    };
    reader.onerror = reject;
  }));

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadFeature = (requestHandler) => (type, resource, params) => {
  if (type === 'UPDATE' || type === 'CREATE' /* && resource === 'posts' */) {
    // notice that following condition can be true only when `<ImageInput source="pictures" />`
    // component has parameter `multiple={true}`
    // if parameter `multiple` is false, then data.pictures is not an array,
    // but single object

    if (params.data.pictures && params.data.pictures.length) {
      // only freshly dropped pictures are instance of File
      const formerPictures = params.data.pictures.filter((p) => !(p.rawFile instanceof File));
      const newPictures = params.data.pictures.filter((p) => p.rawFile instanceof File);

      return Promise.all(newPictures.map(convertFileToBase64))
        .then((base64Pictures) => base64Pictures.map((picture64, index) => ({
          src: picture64.replace('http://localhost:1337', 'https://miembros.vescolombia.com.co'),
          title: `${newPictures[index].title}`,
        })))
        .then((transformedNewPictures) => requestHandler(type, resource, {
          ...params,
          data: {
            ...params.data,
            pictures: [...transformedNewPictures, ...formerPictures],
          },
        }));
    }
  }
  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params);
};

export default addUploadFeature;
