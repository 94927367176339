import React from 'react';
import Parse from 'parse';
import Draggable from 'react-draggable';
import {
  Button,
} from 'react-admin';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { CirclePicker } from 'react-color';
import DeleteIcon from '@material-ui/icons/Delete';
import Color from '../postit/images/color.png';
import Tiza from '../static/img/tiza.png';

const createReactClass = require('create-react-class');

const Note = createReactClass({
  // The notes are intially not being edited
  getInitialState() {
    return { editing: false };
  },
  // Random number genreator for note placement componentWillMount function
  randomBetween(min, max) {
    return (min + Math.ceil(Math.random() * max));
  },
  // Determines the location and skew of each sticky note randomly
  componentWillMount() {
    if (!this.props.style) {
      this.style = {
        right: `${this.randomBetween(60, document.getElementById('tablero').clientWidth - 150)}px`,
        top: `${this.randomBetween(200, document.getElementById('tablero').clientHeight - 150)}px`,
      // transform: 'rotate(' + this.randomBetween(-15, 15) + 'deg)'
      };
    } else this.style = this.props.style;
  },
  // jQuery UI methd allowing notes to be dragged around board once they are created
  componentDidMount() {
    // $(this.getDOMNode()).draggable();
  },
  // Changes note state to being edited
  edit() {
    // this.setState({ editing: true });
  },
  // Save the text prop of the note being edited to contain entered text on save
  save() {
    this.setState({ editing: false });
    this.props.onChange(
      this.state.value, // this.refs.newText.getDOMNode().value,
      this.props.index,
      this.style,
    );
    this.setState({ editing: false });
  },
  // Deletes sticky note
  remove() {
    this.props.onRemove(this.props.index);
  },
  //
  renderDisplay() {
    return (
      <Draggable>
        <div className="note">
          {this.props.children && (
            <div
              className="tiza"
              style={{
                color: this.state.color ? `${this.state.color} !important` : 'white !important',
              }}
              dangerouslySetInnerHTML={{ __html: this.props.children.replace(/\r\n|\r|\n/g, '<br />') }}
            />
          )}
          {/* {}
          </p> */}
          <small style={{
            color: 'white',
            fontWeight: 'bold',
            fontSize: 8,
          }}
          >
            {this.props.author}
          </small>
          <span>
            <Button
              className="btn-blanco"
              style={{ color: 'white' }}
              label=""
              onClick={this.edit}
            >
              <EditIcon />
            </Button>
            <Button
              className="btn-blanco"
              style={{ color: 'white' }}
              label=""
              onClick={this.remove}
            >
              <DeleteIcon />
            </Button>
            <Button label="">
              <img alt="Borrar" style={{ width: 15, height: 15 }} onClick={() => { this.setState({ showColor: !this.state.showColor }); }} className="trashPostit" src={Color} />
            </Button>
            {this.state.showColor
                      && (
                      <div className="changeColorPostit">
                        <CirclePicker color={this.state.color ? this.state.color : 'white'} onChangeComplete={(e) => this.setState({ color: e.hex })} />
                      </div>
                      )}
          </span>
        </div>
      </Draggable>
    );
  },
  renderForm() {
    return (
      <div className="note" style={this.style}>
        <textarea
          style={{ minWidth: '70%' }}
          ref="newText"
          autoFocus
          spellCheck="false"
          autoCorrect="off"
          autoCapitalize="off"
          onChange={(e) => this.setState({ value: e.target.value })}
          defaultValue={this.props.children}
          className="form-control"
        />
        <Button
          className="btn-blanco"
          style={{ color: 'white' }}
          label="ra.label.salvar"
          onClick={this.save}
        >
          <SaveIcon />
        </Button>
      </div>
    );
  },
  render() {
    if (this.state.editing || this.props.edit) {
      return this.renderForm();
    }

    return this.renderDisplay();
  },
});

export const Board = createReactClass({
  propTypes: {
    count(props, propName) {
      if (typeof props[propName] !== 'number') {
        return new Error('The count property must be a number');
      }
      if (props[propName] > 100) {
        return new Error(`Creating ${props[propName]} notes is ridiculous`);
      }
    },
  },
  getInitialState() {
    return {
      notes: [],
      editing: false,
    };
  },
  nextId() {
    this.uniqueId = this.uniqueId || 0;
    // eslint-disable-next-line no-plusplus
    return this.uniqueId++;
  },
  componentDidMount() {
    this.livequery();
  },
  async livequery() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    let tomorrow = new Date();
    tomorrow.setHours(0, 0, 0, 0);
    tomorrow = tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow = new Date(tomorrow).toISOString();

    const query = new Parse.Query('posts');
    // query.equalTo('name', 'Mengyan');
    // query.greaterThan("createdAt", today);
    // query.lessThan("createdAt", tomorrow);
    const subscription = await query.subscribe();
    subscription.on('open', this.fetchDatas);
    subscription.on('create', this.fetchDatas);
    subscription.on('update', this.fetchDatas);
    subscription.on('delete', this.fetchDatas);
  },
  loadData(data) {
    const myData = data.map((item) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (item.createdAt > today.toISOString()) {
        return {
          id: item.objectId,
          note: item.note,
          style: item.style,
          author: item.author,
        };
      }

      return null;
    });
    // var arr = this.state.notes;
    //   arr.push({
    //       id: this.nextId(),
    //       note: text
    //   });
    /** Aqui no guaardo porq aún no se modificó el placeholder */

    this.setState({ notes: myData });
  },
  componentWillMount() {
    if (this.props.cursoId) {
      this.fetchDatas();
      // $.getJSON("http://baconipsum.com/api/?type=all-meat&sentences=" +
      //     this.props.count + "&start-with-lorem=1&callback=?", function(results){
      //         results[0].split('. ').forEach(function(sentence){
      //             self.add(sentence.substring(0,40));
      //         });
      //     });
    }
  },
  fetchDatas() {
    console.log('entro a subscripcion');

    let today = new Date();
    today.setHours(0, 0, 0, 0);

    let tomorrow = new Date();
    tomorrow.setHours(0, 0, 0, 0);
    tomorrow = tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow = new Date(tomorrow).toISOString();

    today = today.toISOString();

    console.log({ cursoId: this.props.cursoId, createdAt: { $gte: today.split('T')[0], $lte: tomorrow.split('T')[0] } });
    this.props.dataProvider.getList('posts', {
      filter: {
        cursoId: this.props.cursoId,
        //  createdAt: {$gte: today.split('T')[0], $lte:tomorrow.split('T')[0]}
      },
      sort: { field: 'createdAt', order: 'ASC' },
    })
      .then(({ data }) => {
        if (this.state.editing !== true) { this.loadData(data); }
      });
  },
  handleOpen() {
    console.log('subscription opened');
  },
  handleCreate(object) {
    console.log(`${object} created.`);
  },
  deleteAll() {
    const arr = this.state.notes;
    this.setState({ notes: [] });
    arr.forEach((element) => {
      this.props.dataProvider.delete('posts', { id: element.id });
    });
  },
  add(text) {
    this.setState({ editing: true });
    const arr = this.state.notes;
    arr.push({
      id: this.nextId(),
      note: text,
      edit: true,
    });
    /** Aqui no guaardo porq aún no se modificó el placeholder */

    this.setState({ notes: arr });
  },
  update(newText, i, style) {
    this.setState({ editing: false });
    const arr = this.state.notes;
    arr[i].note = newText;
    if (newText && newText.length) {
      this.props.dataProvider.create('posts', {
        data: {
          notaId: arr.id,
          note: newText,
          cursoId: this.props.cursoId,
          alumnoId: this.props.alumnoId ? this.props.alumnoId : '',
          profeId: this.props.profeId ? this.props.profeId : '',
          author: localStorage.getItem('userId'),
          style,
          edit: false,
        },
      })
        .then((result) => {
          arr[i].id = result.objectId;
          arr[i].edit = false;
          this.setState({ notes: arr, editing: false });
        })
        .catch((error) => {
          console.log(error);
          arr[i].edit = false;
          this.setState({ editing: false });
        });
    }
  },
  remove(i) {
    this.setState({ editing: true });
    const arr = this.state.notes;
    this.props.dataProvider.delete('posts', { id: arr[i].id });
    arr.splice(i, 1);
    this.setState({ notes: arr, editing: false });
  },
  eachNote(note, i) {
    if (!note) return null;

    let author = '';
    for (const item of this.props.alumnos) {
      if (item.usuarioId === note.author) author = item.nombre;
    }
    // author = this.props.alumnos.find(item=> item.usuarioId===note.author)
    if (this.props.profesor && note.author === this.props.profesor.objectId) {
      author = this.props.profesor.nombre;
    }

    if (note) {
      return (
        <Note
          key={note.id}
          index={i}
          edit={note.edit}
          style={note.style}
          onChange={this.update}
          onRemove={this.remove}
          author={author}
        >
          {note.note}
        </Note>
      );
    }

    return null;
  },
  render() {
    return (
      <div id="tablero" className={this.props.mini ? 'board-mini' : 'board'}>
        {/* <Button
          className="btn-blanco"
          style={{ color: 'white' }}
          label="ra.label.escrever"
          onClick={this.add.bind(null, '')}
        >
          <img src={Tiza} alt="Tiza" />
        </Button> */}
        {/* <Button
          label="ra.label.apagar_tudo"
          className="btn-blanco"
          style={{ color: 'white' }}
          onClick={this.deleteAll.bind()}
        >
          <img src={Borrador} alt="Borrador" />
        </Button> */}
        <div className="notes-wrapper">
          {this.state.notes.map(this.eachNote)}
        </div>
      </div>

    );
  },
});

export const BoardAntigua = createReactClass({
  propTypes: {
    count(props, propName) {
      if (typeof props[propName] !== 'number') {
        return new Error('The count property must be a number');
      }
      if (props[propName] > 100) {
        return new Error(`Creating ${props[propName]} notes is ridiculous`);
      }
    },
  },
  getInitialState() {
    return {
      notes: [],
    };
  },
  nextId() {
    this.uniqueId = this.uniqueId || 0;
    // eslint-disable-next-line no-plusplus
    return this.uniqueId++;
  },

  loadData(data) {
    console.log(this.props);

    const myData = data.map((item) => {
      if (this.props.dia) {
        const today = new Date(this.props.dia);
        today.setHours(0, 0, 0, 0);
        const tomorrow = new Date();
        tomorrow.setHours(0, 0, 0, 0);
        tomorrow.setDate(tomorrow.getDate() + 1);
        // tomorrow = new Date(tomorrow).toISOString()
        if (item.createdAt > today.toISOString() && item.createdAt < tomorrow.toISOString()) {
          return {
            id: item.objectId,
            note: item.note,
            style: item.style,
            author: item.author,
          };
        }
      }

      return null;
    });
    // var arr = this.state.notes;
    //   arr.push({
    //       id: this.nextId(),
    //       note: text
    //   });
    /** Aqui no guaardo porq aún no se modificó el placeholder */

    this.setState({ notes: myData });
  },
  componentWillMount() {
    if (this.props.cursoId) {
      this.fetchDatas();
      // $.getJSON("http://baconipsum.com/api/?type=all-meat&sentences=" +
      //     this.props.count + "&start-with-lorem=1&callback=?", function(results){
      //         results[0].split('. ').forEach(function(sentence){
      //             self.add(sentence.substring(0,40));
      //         });
      //     });
    }
  },
  fetchDatas() {
    let today = new Date(this.props.dia);
    today.setHours(0, 0, 0, 0);

    let tomorrow = new Date();
    tomorrow.setHours(0, 0, 0, 0);
    tomorrow = tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow = new Date(tomorrow).toISOString();

    today = today.toISOString();

    console.log({ cursoId: this.props.cursoId, createdAt: { $gte: today.split('T')[0], $lte: tomorrow.split('T')[0] } });
    this.props.dataProvider.getList('posts', {
      filter: {
        cursoId: this.props.cursoId,
        //  createdAt: {$gte: today.split('T')[0], $lte:tomorrow.split('T')[0]}
      },
      sort: { field: 'createdAt', order: 'ASC' },
    })
      .then(({ data }) => this.loadData(data));
  },

  add(text) {
    const arr = this.state.notes;
    arr.push({
      id: this.nextId(),
      note: text,
      edit: true,
    });
    /** Aqui no guaardo porq aún no se modificó el placeholder */

    this.setState({ notes: arr });
  },
  update(newText, i, style) {
    const arr = this.state.notes;
    arr[i].note = newText;
    this.props.dataProvider.create('posts', {
      data: {
        notaId: arr.id,
        note: newText,
        cursoId: this.props.cursoId,
        alumnoId: this.props.alumnoId ? this.props.alumnoId : '',
        profeId: this.props.profeId ? this.props.profeId : '',
        author: localStorage.getItem('userId'),
        style,
        edit: false,
      },
    })
      .then((result) => {
        arr[i].id = result.objectId;
        arr[i].edit = false;
        this.setState({ notes: arr });
      })
      .catch((error) => {
        arr[i].edit = false;
        console.log(error);
      });
  },
  remove(i) {
    const arr = this.state.notes;
    this.props.dataProvider.delete('posts', { id: arr[i].id });
    arr.splice(i, 1);
    this.setState({ notes: arr });
  },
  eachNote(note, i) {
    console.log(note);
    if (!note) return null;

    let author = '';
    for (const item of this.props.alumnos) {
      if (item.usuarioId === note.author) author = item.nombre;
    }
    // author = this.props.alumnos.find(item=> item.usuarioId===note.author)
    if (this.props.profesor && note.author === this.props.profesor.objectId) {
      author = this.props.profesor.nombre;
    }

    if (author) console.log(author);
    if (note) {
      return (
        <Note
          key={note.id}
          index={i}
          edit={note.edit}
          style={note.style}
          onChange={this.update}
          onRemove={this.remove}
          author={author}
        >
          {note.note}
        </Note>
      );
    }

    return null;
  },
  render() {
    return (
      <div id="tablero" className="board">
        <div className="notes-wrapper">
          {this.state.notes.map(this.eachNote)}
        </div>
      </div>

    );
  },
});
