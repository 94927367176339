import React from 'react';
import { useTranslate } from 'react-admin';

export function ButtonDefault(props) {
  const translate = useTranslate();
  return (
    <button type="button" className={props.buttonClass} onClick={props.onClick}>
      {translate(props.label)}
    </button>
  );
}

export function ButtonSubmit(props) {
  const translate = useTranslate();
  return (
    <button type="submit" className={props.buttonClass} onClick={props.onClick}>
      {translate(props.label)}
    </button>
  );
}
