import React from 'react';
import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  Datagrid,
  // TextField,
  // EditButton,
  NumberInput,
  SelectInput,
  ReferenceInput,
  FileInput,
  FileField,
  FormDataConsumer,
  // ReferenceField,
  ReferenceManyField,
  DateField,
  Button,
  TopToolbar,
  useTranslate,
  useRedirect,
  useListContext,
} from 'react-admin';
import {
  InputAdornment, Box, makeStyles, Grid, Typography,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
// import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
// import Table from '@material-ui/core/Table';
// import Chip from '@material-ui/core/Chip';
// import TableBody from '@material-ui/core/TableBody';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import { EntradasFilter, SaidasFilter } from './componentes/FinancieroFilter';
// import DollarIcon from '@material-ui/icons/AttachMoney';
// import DoneIcon from '@material-ui/icons/Done';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import Divider from '@material-ui/core/Divider';
import MUIDataTable from 'mui-datatables';
import parseConfig from '../parseConfig';
import { CreateEntradaButton, CreateSalidaButton } from './CreateFinanciero';
import CreatePagoButton from './CreatePago';
import FileIcon from '../static/img/filesicon.png';
import { DateInput } from '../DateInput';
import ClientesInadiplentes from './clientesInadiplentes';
import {
  Receitas,
  Despesas,
} from './receitasDespesas';
import VoltarIcon from '../static/img/Voltar.png';

const HEADERS = { headers: parseConfig.headers };

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));

function PriceField({ record = {}, source }) {
  return (
    <span style={{
      fontWeight: 'bolder', textAlign: 'right', display: 'block', fontSize: 12,
    }}
    >
      $
      {record[source] ? record[source].toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.0', '') : 0}
    </span>
  );
}

function ListActionsEntradas(props) {
  return (
    <Box width="100%">
      <TopToolbar>
        <CreateEntradaButton {...props} tipo="Entrada" />
      </TopToolbar>
    </Box>
  );
}

function ListActionsSalidas(props) {
  return (
    <Box width="100%">
      <TopToolbar>
        <CreateSalidaButton {...props} tipo="Salida" />
      </TopToolbar>
    </Box>
  );
}

export function FinancieroList() {
  const classes = useStyles();
  const navigate = useRedirect();
  // const [value, setValue] = React.useState(0);
  // const translate = useTranslate();
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  return (
    <Paper
      style={{
        flexGrow: 1, margin: 'auto', width: '100%', padding: 20,
      }}
    >
      <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
        <Box
          flex={1}
          mr={{ xs: 0, sm: '0.5em' }}
        >
          <Paper variant="outlined" elevation={0} className={classes.paper}>
            <Typography style={{ marginBottom: 30, marginTop: 30 }} variant="h6">Clientes inadimplentes</Typography>
            <ClientesInadiplentes />
          </Paper>
        </Box>
        <Box
          flex={2}
          ml={{ xs: 0, sm: '0.5em' }}
        >
          <Paper variant="outlined" elevation={0} className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs>
                <Typography onClick={() => navigate('/receitas')} style={{ marginBottom: 30, marginTop: 30, cursor: 'pointer' }} variant="h6">Receitas</Typography>
                <Receitas />
              </Grid>
              <Grid item xs>
                <Typography onClick={() => navigate('/despesas')} style={{ marginBottom: 30, marginTop: 30, cursor: 'pointer' }} variant="h6">Despesas</Typography>
                <Despesas />
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Box>
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
        <Box
          flex={1}
          mr={{ xs: 0, sm: '0.5em' }}
        >
          <Paper variant="outlined" elevation={0} className={classes.paper}>
            <MiniDashboardReceitas />
          </Paper>
        </Box>
        <Box
          flex={1}
          ml={{ xs: 0, sm: '0.5em' }}
        >
          <Paper variant="outlined" elevation={0} className={classes.paper}>
            <MiniDashboardDespesas />
          </Paper>
        </Box>
      </Box>
    </Paper>
  );

  // return (
  //   <Paper style={{ flexGrow: 1, margin: 'auto', width: '100%' }}>
  //     <AppBar position="static" color="default">
  //       <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
  //         <Tab label={translate('ra.label.entradas')} {...a11yProps(0)} />
  //         <Tab label={translate('ra.label.saidas')} {...a11yProps(1)} />
  //       </Tabs>
  //     </AppBar>
  //     <TabPanel value={value} index={0}>
  //       <List
  //         {...props}
  //         actions={<ListActionsEntradas />}
  //         bulkActionButtons={false}
  //         filter={{ tipo: 'Entrada' }}
  //         exporter={false}
  //       >
  //         <Datagrid>
  //           <ReferenceField label="ra.label.natureza" source="naturezaId" reference="natureza_entrada">
  //             <TextField source="nombre" />
  //           </ReferenceField>
  //           <ReferenceField link={false}
  //             label="ra.label.cliente"
  //             source="alumnoId"
  //             reference="alumnos"
  //           >
  //             <TextField source="nombre" />
  //           </ReferenceField>
  //           <DateField
  //             label={translate('ra.label.data_vencimento')}
  //             source="fecha_vencimiento"
  //           />
  //           <PriceField
  //             label={translate('ra.label.valor_receber')}
  //             source="valor"
  //           />
  //           <DateField
  //             label={translate('ra.label.data_pgto')}
  //             source="fecha_pgto"
  //           />
  //           <PriceField
  //             label={translate('ra.label.valor_pago')}
  //             source="valor"
  //           />
  //           <TextField label="ra.label.observacoes" source="observaciones" />
  //           <EditButton label="ra.label.editar" />
  //         </Datagrid>
  //       </List>
  //     </TabPanel>
  //     <TabPanel value={value} index={1}>
  //       <List
  //         {...props}
  //         actions={<ListActionsSalidas />}
  //         bulkActionButtons={false}
  //         filter={{ tipo: 'Salida' }}
  //         exporter={false}
  //       >
  //         <Datagrid>
  //           <ReferenceField label="ra.label.natureza_saida" source="naturezaId" reference="natureza_entrada">
  //             <TextField source="nombre" />
  //           </ReferenceField>
  //           <ReferenceField label="ra.label.tipo_saida" source="naturezaId" reference="natureza_entrada">
  //             <TextField source="nombre" />
  //           </ReferenceField>
  //           <DateField label="ra.label.data_vencimento" source="fecha_vencimiento" />
  //           {/* <TextField source="estado" /> */}
  //           <PriceField
  //             label="ra.label.valor_vencimento"
  //             source="valor"
  //           />
  //           <DateField label="##data do pgto" source="fecha_vencimiento" />
  //           {/* <TextField source="estado" /> */}
  //           <PriceField
  //             label="ra.label.valor_pago"
  //             source="valor"
  //           />
  //           <TextField
  //             label="ra.label.observacoes"
  //             source="observaciones"
  //           />
  //           <EditButton label="ra.label.editar" />
  //         </Datagrid>
  //       </List>
  //     </TabPanel>
  //   </Paper>
  // );
}

function Empty(props) {
  return (
    <Box width="100%">
      <TopToolbar>
        <CreateEntradaButton {...props} tipo="Entrada" />
      </TopToolbar>
      <Box textAlign="center" m={1}>
        <Typography variant="h4" paragraph>
          Não tem receitas ainda
        </Typography>
        <CreateEntradaButton {...props} tipo="Entrada" />
      </Box>
    </Box>
  );
}

function Empty2(props) {
  return (
    <Box width="100%">
      <TopToolbar>
        <CreateSalidaButton {...props} tipo="Salida" />
      </TopToolbar>
      <Box textAlign="center" m={1}>
        <Typography variant="h4" paragraph>
          Não tem despesas ainda
        </Typography>
        <CreateSalidaButton {...props} tipo="Salida" />
      </Box>
    </Box>
  );
}

export function ReceitasList(props) {
  // const translate = useTranslate();
  return (
    <List
      {...props}
      actions={<ListActionsEntradas />}
      bulkActionButtons={false}
      // filter={{ tipo: 'Entrada' }}
      pagination={false}
      exporter={false}
      empty={<Empty />}
    >
      <ReceitasGrid />
    </List>
  );
}

function MiniDashboardReceitas() {
  const [chartData, setChartData] = React.useState(null);

  React.useEffect(() => {
    const receitasT = {
      pagadas: {},
      pendientes: {},
    };
    fetch(
      `${parseConfig.URL}/classes/receitas?limit=1000`,
      {
        headers: parseConfig.headers,
      },
    )
      .then((responseReceitas) => responseReceitas.json())
      .then((json) => {
        for (const result of json.results) {
          if (result && result.parcelas) {
            const jsonParcelas = result.parcelas;
            for (let index = 0; index < jsonParcelas.length; index++) {
              const parcela = jsonParcelas[index];
              const mesVencimento = parcela.data_vencimento ? `${parcela.data_vencimento.split('-')[0]}/${parcela.data_vencimento.split('-')[1]}` : null;
              if (mesVencimento) {
                if (parcela.estado === 'pagada') {
                  receitasT.pagadas[mesVencimento] = receitasT.pagadas[mesVencimento] && typeof (receitasT.pagadas[mesVencimento]) === 'object'
                    ? [...receitasT.pagadas[mesVencimento], {
                      ...parcela,
                    }] : [{
                      ...parcela,
                    }];
                } else {
                  receitasT.pendientes[mesVencimento] = receitasT.pendientes[mesVencimento] && typeof (receitasT.pendientes[mesVencimento]) === 'object'
                    ? [...receitasT.pendientes[mesVencimento], {
                      ...parcela,
                    }] : [{
                      ...parcela,
                    }];
                }
              }
            }
          }
        }

        console.log(Object.keys(receitasT.pendientes).sort((a, b) => a.split('/').join('') - b.split('/').join('')));
        const mydata = {
          labels: Object.keys(receitasT.pendientes).sort((a, b) => a.split('/').join('') - b.split('/').join('')), // ['January', 'February', 'March', 'April', 'May', 'June', 'July'], // Object.keys(receitasT.pendientes),
          datasets: [
            {
              label: 'Por receber',
              data: Object.keys(receitasT.pendientes).map((mes) => receitasT.pendientes[mes].reduce((acc, curr) => acc + parseInt(curr.valor), 0)), // [65, 59, 80, 81, 56, 55, 40],
              // fill: false,
              borderColor: 'rgb(75, 192, 192)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              // tension: 0.1,
            },
            {
              label: 'Recebidas',
              data: Object.keys(receitasT.pagadas).map((mes) => receitasT.pagadas[mes].reduce((acc, curr) => acc + parseInt(curr.valor), 0)), // [65, 59, 80, 81, 56, 55, 40],
              // fill: false,
              borderColor: 'rgb(75, 192, 192)',
              // tension: 0.1,
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
          ],
        };
        setChartData(mydata);
      });
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Receitas x mes',
      },
    },
  };

  if (chartData) {
    return (
      <>
        <h1>Receitas</h1>
        {chartData ? <Line options={options} data={chartData} /> : null}
      </>
    );
  }

  return null;
}

function MiniDashboardDespesas() {
  const [chartData, setChartData] = React.useState(null);

  React.useEffect(() => {
    const receitasT = {
      pagadas: {},
      pendientes: {},
    };
    fetch(
      `${parseConfig.URL}/classes/despesas?limit=1000`,
      {
        headers: parseConfig.headers,
      },
    )
      .then((responseReceitas) => responseReceitas.json())
      .then((json) => {
        for (const result of json.results) {
          if (result && result.parcelas) {
            const jsonParcelas = result.parcelas;
            for (let index = 0; index < jsonParcelas.length; index++) {
              const parcela = jsonParcelas[index];
              const mesVencimento = parcela.data_vencimento ? `${parcela.data_vencimento.split('-')[0]}/${parcela.data_vencimento.split('-')[1]}` : null;
              if (mesVencimento) {
                if (parcela.estado === 'pagada') {
                  receitasT.pagadas[mesVencimento] = receitasT.pagadas[mesVencimento] && typeof (receitasT.pagadas[mesVencimento]) === 'object'
                    ? [...receitasT.pagadas[mesVencimento], {
                      ...parcela,
                    }] : [{
                      ...parcela,
                    }];
                } else {
                  receitasT.pendientes[mesVencimento] = receitasT.pendientes[mesVencimento] && typeof (receitasT.pendientes[mesVencimento]) === 'object'
                    ? [...receitasT.pendientes[mesVencimento], {
                      ...parcela,
                    }] : [{
                      ...parcela,
                    }];
                }
              }
            }
          }
        }
        console.log(receitasT);
        const mydata = {
          labels: Object.keys(receitasT.pendientes).sort((a, b) => a.split('/').join('') - b.split('/').join('')), // ['January', 'February', 'March', 'April', 'May', 'June', 'July'], // Object.keys(receitasT.pendientes),
          datasets: [
            {
              label: 'Por pagar',
              data: Object.keys(receitasT.pendientes).map((mes) => receitasT.pendientes[mes].reduce((acc, curr) => acc + parseInt(curr.valor), 0)), // [65, 59, 80, 81, 56, 55, 40],
              // fill: false,
              borderColor: 'rgb(75, 192, 192)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              // tension: 0.1,
            },
            {
              label: 'Pagadas',
              data: Object.keys(receitasT.pagadas).map((mes) => receitasT.pagadas[mes].reduce((acc, curr) => acc + parseInt(curr.valor), 0)), // [65, 59, 80, 81, 56, 55, 40],
              // fill: false,
              borderColor: 'rgb(75, 192, 192)',
              // tension: 0.1,
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
          ],
        };
        setChartData(mydata);
      });
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Despesas x mes',
      },
    },
  };

  if (chartData) {
    return (
      <>
        <h1>Despesas</h1>
        {chartData ? <Line options={options} data={chartData} /> : null}
      </>
    );
  }

  return null;
}

function ReceitasGrid() {
  // eslint-disable-next-line no-unused-vars
  const { ids, data, basePath } = useListContext();
  const [dataList, setDataList] = React.useState([]);
  const [receitas, setReceitas] = React.useState([]);
  // const [qriedReceitas, setQriedReceitas] = React.useState([]);
  const [filtro, setFiltro] = React.useState('A receber');
  // const [allClientes, setClientes] = React.useState([]);

  React.useEffect(() => {
    let receitasT = [];
    for (const id of ids) {
      if (data[id] && data[id].parcelas) {
        const jsonParcelas = data[id].parcelas;
        for (let index = 0; index < jsonParcelas.length; index++) {
          const parcela = jsonParcelas[index];
          receitasT.push({
            ...parcela,
            alumnoId: data[id].alumnoId,
            naturezaId: data[id].naturezaId,
            id,
            index,
            natureza: '...',
            cliente: '...',
          });
        }
      }
    }
    console.log(receitasT);
    setDataList(receitasT);
    setReceitas(receitasT);

    fetch(
      `${parseConfig.URL}/classes/natureza_entrada`,
      HEADERS,
    )
      .then((response) => response.json())
      .then(({ results }) => {
        const allNaturezas = results;
        fetch(
          `${parseConfig.URL}/classes/alumnos`,
          HEADERS,
        )
          .then((response) => response.json())
          .then(({ results }) => {
            const allClientes = results;
            receitasT = receitasT.map((item) => {
              let natureza = allNaturezas.find(nat => nat.objectId === item.naturezaId);
              let cliente = allClientes.find(cli => cli.objectId === item.alumnoId);
              natureza = natureza ? natureza.nombre : '';
              cliente = cliente ? cliente.nombre : '';
              return ({
                ...item,
                natureza,
                cliente,
                valor_receber: !item.estado || item.estado !== 'pagada' ? item.valor : null,
                valor_pagado: item.estado && item.estado === 'pagada' ? item.valorPagado : null,
              });
            });
            // Incio con a receber
            setFiltro('A receber');
            setDataList(receitasT.filter(parcela => !parcela.estado || parcela.estado !== 'pagada'));
            // Seteo el arraycompleto
            console.log(receitasT);
            setReceitas(receitasT);
          });
      });
  }, [ids]);

  React.useEffect(() => {
    if (filtro === 'A receber') {
      setDataList(receitas.filter(parcela => parcela.estado !== 'pagada'));
    } else if (filtro === 'Recebidas') {
      setDataList(receitas.filter(parcela => parcela.estado === 'pagada'));
    } else { setDataList(receitas); }
  }, [filtro]);

  const columns = [
    {
      name: 'natureza',
      label: 'Natureza',
      options: {
        filter: true,
        sort: true,
        filterType: 'multiselect',
        setCellProps: () => ({ className: 'financiero-cell' }),
      },
    },
    {
      name: 'cliente',
      label: 'Cliente',
      options: {
        filter: true,
        sort: false,
        filterType: 'multiselect',
        setCellProps: () => ({ className: 'financiero-cell' }),
      },
    },
    {
      name: 'data_vencimento',
      label: 'Vencimiento',
      options: {
        filter: true,
        sort: false,
        filterType: 'textField',
        setCellProps: () => ({ className: 'financiero-cell' }),
        customBodyRender: (value) => value.split('T')[0],
      },
    },
    {
      name: 'valor_receber',
      label: 'Valor a receber',
      options: {
        filter: true,
        sort: false,
        filterType: 'textField',
        setCellProps: () => ({ className: 'financiero-cell' }),
        customBodyRender: (value) => (
          value ? `$${value.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.0', '')}` : null
        ),
      },
    },
    {
      name: 'fecha_pago',
      label: 'Data pagamento',
      options: {
        filter: true,
        sort: false,
        filterType: 'textField',
        setCellProps: () => ({ className: 'financiero-cell' }),
        customBodyRender: (value) => (value ? value.split('T')[0] : null),
      },
    },
    {
      name: 'valor_pagado',
      label: 'Valor pagado',
      options: {
        filter: true,
        sort: false,
        filterType: 'textField',
        setCellProps: () => ({ className: 'financiero-cell' }),
        customBodyRender: (value) => (
          value ? `$${value.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.0', '')}` : null
        ),
      },
    },
    {
      name: 'observaciones',
      label: 'Observaciones',
      options: {
        filter: true,
        sort: false,
        filterType: 'textField',
        setCellProps: () => ({ className: 'financiero-cell' }),
      },
    },
    {
      name: '',
      label: '',
      options: {
        filter: true,
        sort: false,
        filterType: 'textField',
        customBodyRender: (value, tableMeta) => {
          const parcela = dataList[tableMeta.rowIndex];
          return (
            <CreatePagoButton data={data[parcela.id]} parcela={parcela} table="receitas" />
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: 'none',
    setTableProps: () => ({
      size: 'small',
    }),
    onRowClick: (data, meta) => {
      console.log(data);
      console.log(meta);
    },
  };

  return (
    <div style={{ margin: '1em' }}>
      <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
        <img src={VoltarIcon} alt="Voltar" />
      </Button>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          margin: 10,
          alignItems: 'center',
        }}
      >
        <Typography style={{ marginRight: 10 }}>Entradas: </Typography>
        <Select
          variant="outlined"
          value={filtro}
          size="small"
          onChange={(e) => {
            setFiltro(e.target.value);
          }}
        >
          <MenuItem value="">
            <em>Todas</em>
          </MenuItem>
          <MenuItem value="A receber">A receber</MenuItem>
          <MenuItem value="Recebidas">Recebidas</MenuItem>
        </Select>
      </div>
      <TableContainer component={Paper}>
        <MUIDataTable
          title="Entradas a receber"
          data={dataList}
          columns={columns}
          options={options}
        />
        {/* <Table aria-label="simple table">
          <TableBody>
            {qriedReceitas && qriedReceitas.map((parcela, index) => {
              if (filtro && filtro.length) {
                if ((filtro === 'Recebidas' && parcela.estado === 'pagada') || (filtro === 'A receber' && parcela.estado !== 'pagada')) {
                  return (
                    <TableRow key={`${index}-parcela`}>
                      <TableCell>
                        <ReferenceField link={false} record={data[parcela.id]} label="ra.label.natureza" source="naturezaId" reference="natureza_entrada">
                          <TextField source="nombre" />
                        </ReferenceField>
                      </TableCell>
                      <TableCell>
                        <ReferenceField
                          link={false}
                          record={data[parcela.id]}
                          label="ra.label.cliente"
                          source="alumnoId"
                          reference="alumnos"
                        >
                          <TextField source="nombre" />
                        </ReferenceField>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2">{parcela.data_vencimento.split('T')[0]}</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2">
                          $
                          {parcela.valor.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.0', '')}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2">{parcela.fecha_pago ? parcela.fecha_pago.split('T')[0] : null}</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2">
                          $
                          {parcela.valorPagado ? parcela.valorPagado.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.0', '') : 0}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2">{parcela.observaciones ? parcela.observaciones : null}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2">{parcela.saldo === 0 || parcela.estado === 'pagada' ? <Chip icon={<DoneIcon />} label="Pagada" color="primary" /> : <CreatePagoButton data={data[parcela.id]} parcela={parcela} table="receitas" />}</Typography>
                      </TableCell>
                    </TableRow>
                  );
                }
                return null;
              }

              return (
                <TableRow key={`${index}-parcela`}>
                  <TableCell component="th" scope="row">
                    <ReferenceField link={false} record={data[parcela.id]} label="ra.label.natureza" source="naturezaId" reference="natureza_entrada">
                      <TextField source="nombre" />
                    </ReferenceField>
                  </TableCell>
                  <TableCell>
                    <ReferenceField
                      link={false}
                      record={data[parcela.id]}
                      label="ra.label.cliente"
                      source="alumnoId"
                      reference="alumnos"
                    >
                      <TextField source="nombre" />
                    </ReferenceField>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body2">{parcela.data_vencimento.split('T')[0]}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body2">
                      $
                      {parcela.valorPagado ? parcela.valorPagado.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.0', '') : 0}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body2">{parcela.fecha_pago ? parcela.fecha_pago.split('T')[0] : null}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body2">
                      $
                      {parcela.saldo || parcela.saldo === 0 ? parcela.saldo.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.0', '') : parcela.valor.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.0', '')}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">{parcela.observaciones ? parcela.observaciones : null}</TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">
                      {parcela.saldo === 0 || parcela.estado === 'pagada' ? <Chip icon={<DoneIcon />} label="Pagada" color="primary" /> : <CreatePagoButton data={data[parcela.id]} parcela={parcela} table="receitas" />}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table> */}
      </TableContainer>
    </div>
  );
}

export function DespesasList(props) {
  // const translate = useTranslate();
  return (
    <List
      {...props}
      actions={<ListActionsSalidas />}
      bulkActionButtons={false}
      // filter={{ tipo: 'Entrada' }}
      exporter={false}
      empty={<Empty2 />}
    >
      <DespesasGrid />
    </List>
  );
}

function DespesasGrid() {
  // eslint-disable-next-line no-unused-vars
  const { ids, data, basePath } = useListContext();
  const [dataList, setDataList] = React.useState([]);
  const [receitas, setReceitas] = React.useState([]);
  // const [qriedReceitas, setQriedReceitas] = React.useState([]);
  const [filtro, setFiltro] = React.useState('A receber');
  // const [allClientes, setClientes] = React.useState([]);

  React.useEffect(() => {
    let receitasT = [];
    for (const id of ids) {
      if (data[id] && data[id].parcelas) {
        const jsonParcelas = data[id].parcelas;
        for (let index = 0; index < jsonParcelas.length; index++) {
          const parcela = jsonParcelas[index];
          receitasT.push({
            ...parcela,
            alumnoId: data[id].alumnoId,
            naturezaId: data[id].naturezaId,
            id,
            index,
            natureza: '...',
            cliente: '...',
          });
        }
      }
    }

    setDataList(receitasT);
    setReceitas(receitasT);

    fetch(
      `${parseConfig.URL}/classes/natureza_saida`,
      HEADERS,
    )
      .then((response) => response.json())
      .then(({ results }) => {
        console.log(results);
        const allNaturezas = results;
        receitasT = receitasT.map((item) => {
          let natureza = allNaturezas.find(nat => nat.objectId === item.naturezaId);
          natureza = natureza ? natureza.nombre : '';
          return ({
            ...item,
            natureza,
            valor_receber: !item.estado || item.estado !== 'pagada' ? item.valor : null,
            valor_pagado: item.estado && item.estado === 'pagada' ? item.valorPagado : null,
          });
        });
        // Incio con a receber
        setFiltro('Por pagar');
        setDataList(receitasT.filter(parcela => parcela.estado !== 'pagada'));
        // Seteo el arraycompleto
        setReceitas(receitasT);
      });
  }, [ids]);

  React.useEffect(() => {
    if (filtro === 'Por pagar') {
      setDataList(receitas.filter(parcela => parcela.estado !== 'pagada'));
    } else if (filtro === 'Pagadas') {
      setDataList(receitas.filter(parcela => parcela.estado === 'pagada'));
    } else { setDataList(receitas); }
  }, [filtro]);

  const columns = [
    {
      name: 'natureza',
      label: 'Natureza',
      options: {
        filter: true,
        sort: true,
        filterType: 'multiselect',
        setCellProps: () => ({ className: 'financiero-cell' }),
      },
    },
    {
      name: 'cliente',
      label: 'Cliente',
      options: {
        filter: true,
        sort: false,
        filterType: 'multiselect',
        setCellProps: () => ({ className: 'financiero-cell' }),
      },
    },
    {
      name: 'data_vencimento',
      label: 'Vencimiento',
      options: {
        filter: true,
        sort: false,
        filterType: 'textField',
        setCellProps: () => ({ className: 'financiero-cell' }),
        customBodyRender: (value) => value.split('T')[0],
      },
    },
    {
      name: 'valor_receber',
      label: 'Valor a receber',
      options: {
        filter: true,
        sort: false,
        filterType: 'textField',
        setCellProps: () => ({ className: 'financiero-cell' }),
        customBodyRender: (value) => (
          value ? `$${value.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.0', '')}` : null
        ),
      },
    },
    {
      name: 'fecha_pago',
      label: 'Data pagamento',
      options: {
        filter: true,
        sort: false,
        filterType: 'textField',
        setCellProps: () => ({ className: 'financiero-cell' }),
        customBodyRender: (value) => (value ? value.split('T')[0] : null),
      },
    },
    {
      name: 'valor_pagado',
      label: 'Valor pagado',
      options: {
        filter: true,
        sort: false,
        filterType: 'textField',
        setCellProps: () => ({ className: 'financiero-cell' }),
        customBodyRender: (value) => (
          value ? `$${value.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.0', '')}` : null
        ),
      },
    },
    {
      name: 'observaciones',
      label: 'Observaciones',
      options: {
        filter: true,
        sort: false,
        filterType: 'textField',
        setCellProps: () => ({ className: 'financiero-cell' }),
      },
    },
    {
      name: '',
      label: '',
      options: {
        filter: true,
        sort: false,
        filterType: 'textField',
        customBodyRender: (value, tableMeta) => {
          const parcela = dataList[tableMeta.rowIndex];
          return (
            <CreatePagoButton data={data[parcela.id]} parcela={parcela} table="receitas" />
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: 'none',
    setTableProps: () => ({
      size: 'small',
    }),
    onRowClick: (data, meta) => {
      console.log(data);
      console.log(meta);
    },
  };

  return (
    <div style={{ margin: '1em' }}>
      <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
        <img src={VoltarIcon} alt="Voltar" />
      </Button>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          margin: 10,
          alignItems: 'center',
        }}
      >
        <Typography style={{ marginRight: 10 }}>Salidas: </Typography>
        <Select
          variant="outlined"
          value={filtro}
          size="small"
          onChange={(e) => {
            setFiltro(e.target.value);
          }}
        >
          <MenuItem value="">
            <em>Todas</em>
          </MenuItem>
          <MenuItem value="Por pagar">Por pagar</MenuItem>
          <MenuItem value="Pagadas">Pagadas</MenuItem>
        </Select>
      </div>
      <TableContainer component={Paper}>
        <MUIDataTable
          title="Salidas por pagar"
          data={dataList}
          columns={columns}
          options={options}
        />
      </TableContainer>
    </div>
  );
}

export function FinancieroCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm
        redirect="edit"
      >
        <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
          <img src={VoltarIcon} alt="Voltar" />
        </Button>
        <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
          <Box
            flex={1}
            mr={{ xs: 0, sm: '0.5em' }}
          >
            <SelectInput
              source="tipo"
              choices={[
                { id: 'Entrada', name: 'ra.label.entrada' },
                { id: 'Salida', name: 'ra.label.saida' },
              ]}
            />
          </Box>
          <Box
            flex={1}
            ml={{ xs: 0, sm: '0.5em' }}
          >
            <SelectInput
              source="estado"
              choices={[
                { id: 'Por pagar', name: 'Por pagar' },
                { id: 'Pagada', name: 'Pagada' },
              ]}
            />
          </Box>
        </Box>
        <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
          <Box
            flex={1}
            mr={{ xs: 0, sm: '0.5em' }}
          >
            <FormDataConsumer>
              {({ formData }) => (
                <ReferenceInput
                  perPage={100000}
                  fullWidth
                  filter={{ tipo_natureza: formData.tipo === 'Salida' ? 'saida' : 'entrada' }}
                  sort={{ field: 'nombre', order: 'ASC' }}
                  variant="outlined"
                  style={{ minWidth: '50%' }}
                  label={`Natureza da ${formData.tipo}`}
                  source="naturezaId"
                  reference="natureza_entrada"
                >
                  <SelectInput optionText="nombre" />
                </ReferenceInput>
              )}
            </FormDataConsumer>
          </Box>
          <Box
            flex={1}
            ml={{ xs: 0, sm: '0.5em' }}
          >
            <ReferenceInput
              perPage={100000}
              emptyText="Ninguno"
              fullWidth
              sort={{ field: 'nombre', order: 'ASC' }}
              variant="outlined"
              style={{ minWidth: '50%' }}
              label="ra.label.cliente"
              source="alumnoId"
              reference="alumnos"
            >
              <SelectInput emptyText="Ninguno" optionText="nombre" />
            </ReferenceInput>
          </Box>
        </Box>
        <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
          <Box
            flex={1}
            mr={{ xs: 0, sm: '0.5em' }}
          >
            <NumberInput
              variant="outlined"
              source="valor"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            flex={1}
            ml={{ xs: 0, sm: '0.5em' }}
          >
            {/* <CreatePagoButton /> */}
          </Box>
        </Box>
        <DateInput
                // className='smallInput'
          label="ra.label.data_vencimento"
          variant="outlined"
          source="fecha_vencimiento"
        />
        <TextInput
          label="ra.label.observacoes"
          multiline
          minRows={3}
          variant="outlined"
          fullWidth
          source="observaciones"
        />
        <FileInput
          source="pictures"
          multiple
          label="ra.label_aexar_arqui"
          placeholder={(
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'transparent',
              borderColor: '#518F18',
              borderStyle: 'dashed',
              borderWidth: 1,
              borderRadius: 16,
              width: 260,
              height: 160,
            }}
            >
              <p>
                <img src={FileIcon} alt="Archivo" />
              </p>
              <p>arraste_solte</p>
            </div>
                          )}
          accept="image/*, application/*, text/*"
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
}

export function FinancieroEdit(props) {
  const translate = useTranslate();
  return (
    <Edit undoable={false} {...props}>
      <SimpleForm
        redirect="edit"
      >
        <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
          <img src={VoltarIcon} alt="Voltar" />
        </Button>
        <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
          <Box
            flex={1}
            mr={{ xs: 0, sm: '0.5em' }}
          >
            <FormDataConsumer>
              {({ formData }) => (
                <ReferenceInput
                  perPage={100000}
                  fullWidth
                  filter={{ tipo_natureza: formData.tipo === 'Salida' ? 'saida' : 'entrada' }}
                  sort={{ field: 'nombre', order: 'ASC' }}
                  variant="outlined"
                  style={{ minWidth: '50%' }}
                  label="ra.label.tipo_saida"
                  source="naturezaId"
                  reference="natureza_entrada"
                >
                  <SelectInput optionText="nombre" />
                </ReferenceInput>
              )}
            </FormDataConsumer>
          </Box>
          <Box
            flex={1}
            ml={{ xs: 0, sm: '0.5em' }}
          >
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <NumberInput
                label="ra.label.valor"
                variant="outlined"
                source="valor"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      $
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
          <Box
            flex={1}
            mr={{ xs: 0, sm: '0.5em' }}
          >
            <ReferenceInput
              perPage={100000}
              emptyText="Ninguno"
              fullWidth
              sort={{ field: 'nombre', order: 'ASC' }}
              variant="outlined"
              style={{ minWidth: '50%' }}
              label="ra.label.cliente"
              source="alumnoId"
              reference="alumnos"
            >
              <SelectInput emptyText="Ninguno" optionText="nombre" />
            </ReferenceInput>
          </Box>
          <Box
            flex={1}
            ml={{ xs: 0, sm: '0.5em' }}
          >
            <ReferenceInput
              perPage={100000}
              emptyText="Ninguno"
              fullWidth
              sort={{ field: 'nombre', order: 'ASC' }}
              variant="outlined"
              style={{ minWidth: '50%' }}
              label="ra.label.professor"
              source="profeId"
              reference="profesores"
            >
              <SelectInput emptyText="Ninguno" optionText="nombre" />
            </ReferenceInput>
          </Box>
        </Box>
        <CreatePagoButton {...props} />
        <ReferenceManyField reference="pagos" target="financieroId" label="">
          <Datagrid>
            <DateField label="Data de pago" source="fecha" />
            <PriceField label="Valor pago" source="valor" />
            {/* <Congelar data={ props }/> */}
          </Datagrid>
        </ReferenceManyField>
        <DateInput
                // className='smallInput'
          label="ra.label.data_vencimento"
          variant="outlined"
          source="fecha_vencimiento"
        />
        <TextInput
          label="ra.label.observacoes"
          multiline
          minRows={3}
          variant="outlined"
          fullWidth
          source="observaciones"
        />
        <FileInput
          source="pictures"
          multiple
          label="ra.label.anexar_arquivos"
          placeholder={(
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'transparent',
              borderColor: '#518F18',
              borderStyle: 'dashed',
              borderWidth: 1,
              borderRadius: 16,
              width: 260,
              height: 160,
            }}
            >
              <p>
                <img src={FileIcon} alt="Archivo" />
              </p>
              <p>{translate('ra.label.arraste_solte')}</p>
            </div>
                          )}
          accept="image/*, application/*, text/*"
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
}

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
      <Box p={3}>
        <Typography>{children}</Typography>
      </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }
