import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import PersonIcon from '@material-ui/icons/Person';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import parseConfig from '../parseConfig';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: '#40434e',
    color: '#ffffff',
  },
});

function ClientesInadiplentes() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [alumnos, setAlumnos] = React.useState([]);

  async function fetchDatas() {
    let tomorrow = new Date();
    tomorrow.setHours(0, 0, 0, 0);
    tomorrow = tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow = new Date(tomorrow).toISOString();

    // const filtro1 = {
    //   estado: 'pendiente',
    //   data_prevista: { $lte: tomorrow },
    // };
    const response = await fetch(
      `${parseConfig.URL}/classes/receitas?limit=1000&where={"estado":"pendiente"}`,
      {
        headers: parseConfig.headers,
      },
    );
    const json = await response.json();
    // console.log(json);
    if (json && json.results) {
      const parcelasPendientes = [];
      for (let indexJson = 0; indexJson < json.results.length; indexJson++) {
        const venta = json.results[indexJson];
        /**
         * Busco las parcelas vencidas
         */
        if (venta.parcelas && venta.parcelas.length) {
          const jsonParcelas = venta.parcelas;
          for (let index = 0; index < jsonParcelas.length; index++) {
            const parcela = jsonParcelas[index];
            if (parcela.data_vencimento <= tomorrow && (!parcela.estado || parcela.estado === 'pendiente')) {
              parcelasPendientes.push({
                ...parcela,
                alumnoId: venta.alumnoId,
              });
            }
          }
        } else { parcelasPendientes.push(json.results); }
      }
      // console.log(parcelasPendientes);
      setData(parcelasPendientes);
      // if (json.results.parcelas && json.results.parcelas.length) {
      // } else { setData(json.results); }

      const responseClientes = await fetch(
        `${parseConfig.URL}/classes/alumnos?limit=1000&where={"objectId":{"$in":${JSON.stringify(json.results.map((item) => (item.alumnoId)))}}}`,
        {
          headers: parseConfig.headers,
        },
      );
      const jsonClientes = await responseClientes.json();
      if (jsonClientes && jsonClientes.results) { setAlumnos(jsonClientes.results); }
    }

    setLoading(false);
  }

  // React.useEffect(() => {
  //   console.log(data);
  // }, [data]);

  React.useEffect(() => {
    setLoading(true);
    fetchDatas();
  }, []);

  if (loading) { return <CircularProgress />; }

  return (
    <List>
      {data.map((item) => {
        const cliente = alumnos.find((alumno) => alumno.objectId === item.alumnoId);
        if (cliente) {
          return (
            <ListItem>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  {/* <PersonIcon /> */}
                  {cliente.avatar ? <img alt="agenda" style={{ width: 30 }} src={cliente.avatar} /> : <PersonIcon />}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={cliente.nombre} secondary={`${cliente.tipo_alumno ? cliente.tipo_alumno : null} ${cliente.nombre_empresa ? cliente.nombre_empresa : null}`} />
            </ListItem>
          );
        }
        return null;
      })}
    </List>
  );
}

export default ClientesInadiplentes;
