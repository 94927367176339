import React from 'react';
import { connect } from 'react-redux';
import { Layout, Sidebar } from 'react-admin';
// import spanishMessages from 'ra-language-pt-br';
import AppBar from './AppBar';
import Menu from './Menu';
import { darkTheme } from './themes';

// const messages = {
//   es: spanishMessages,
// };

function CustomSidebar(props) {
  return <Sidebar {...props} size={100} />;
}
function CustomLayout(props) {
  return (
    <Layout
      locale="es"
      // messages={messages}
      {...props}
      appBar={AppBar}
      sidebar={CustomSidebar}
      menu={Menu}
    />
  );
}

export default connect(
  (state) => ({
    theme: state.theme === 'dark' ? darkTheme : 'dark',
  }),
  {},
)(CustomLayout);
