import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useInput, FieldTitle } from 'ra-core';
import {
  DatePicker,
  KeyboardTimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from '@date-io/moment';
const Picker = ({ PickerComponent, ...fieldProps }) => {
  const {
    options,
    label,
    source,
    resource,
    className,
    isRequired,
    providerOptions,
    defaultValue,
    disabled,
    format,
    views,
    openTo,
  } = fieldProps;
  const { input, meta } = useInput({ source });
  const { touched, error } = meta;
  const handleChange = useCallback((value) => {
    Date.parse(value) ? input.onChange(value) : input.onChange(null);
  }, []);
  return (
    <div className="picker">
      <MuiPickersUtilsProvider {...providerOptions}>
        <PickerComponent
          {...options}
          label={(
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
          )}
          openTo={openTo}
          variant="outlined"
          inputVariant="outlined"
          disabled={disabled}
          margin="dense"
          format={format}
          fullWidth
          views={views}
          error={!!(touched && error)}
          helperText={touched && error}
          className={className}
          value={input.value && input.value.seconds
            ? new Date(input.value.seconds * 1000)
            : input.value ? new Date(input.value) : defaultValue
            ? new Date(defaultValue) : null}
          onChange={(date) => handleChange(date)}
          onBlur={() => input.onBlur(input.value ? new Date(input.value) : null)}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

Picker.propTypes = {
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  labelTime: PropTypes.string,
  className: PropTypes.string,
  providerOptions: PropTypes.shape({
    utils: PropTypes.func,
    locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
};

Picker.defaultProps = {
  input: {},
  isRequired: false,
  meta: { touched: false, error: false },
  options: {},
  defaultValue: null,
  resource: '',
  source: '',
  labelTime: '',
  className: '',
  providerOptions: {
    utils: DateFnsUtils,
    locale: undefined,
  },
};

export const DateInput = (props) => <Picker margin="dense" openTo={props.openTo} views={props.views} format="dd/MM/yyyy" PickerComponent={DatePicker} {...props} />;
export const TimeInput = (props) => <Picker margin="dense" format="HH:mm" PickerComponent={KeyboardTimePicker} {...props} />;
export const DateTimeInput = (props) => <Picker format="dd/MM/yyyy HH:mm" margin="dense" PickerComponent={DateTimePicker} {...props} />;
