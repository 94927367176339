import React, { useEffect, useState, useRef } from 'react';
import {
  useShowController, Loading, useDataProvider, useRedirect, LoadingPage,
  Button, useNotify,
} from 'react-admin';
import { getYjsValue } from "@syncedstore/core";
import { WebsocketProvider } from "y-websocket";
import { useParams } from 'react-router-dom';
import { useQuill } from "react-quilljs";
import 'quill/dist/quill.snow.css'; // Add css for snow theme
import { QuillBinding } from 'y-quill';
import Typography from '@material-ui/core/Typography';
import * as Y from 'yjs';
import SaveIcon from '@material-ui/icons/Save';
import { Editor } from '@tinymce/tinymce-react';
import Parse from 'parse';
import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { store } from './componentes/store';
import {
  Board,
} from './componentes/Boards';
import VoltarIcon from './static/img/Voltar.png';

const docY = getYjsValue(store);
const ydoc1 = new Y.Doc();
// const ydoc2 = new Y.Doc();
const demoText = "Here is the new text";

export function DriveEdit(props) {
  const {
    loading, // boolean that is true on mount, and false once the record was fetched
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props);
  const dataProvider = useDataProvider();
  const routerParams = useParams();
  const navigate = useRedirect();
  const [profesor, setProfesor] = React.useState(null);
  const [losaready, setLosaReady] = useState(false);
  const [content, setContent] = useState(null);
  const [alumno, setAlumno] = React.useState(null);
  const [record1, setRecord] = React.useState(props.record);
  const [alumnos, setAlumnos] = React.useState([]);
  const [showBoard, setShowBoard] = React.useState(true);
  const editorRef = useRef(null);
  const user = localStorage.getItem('profe')
    ? JSON.parse(localStorage.getItem('profe'))
    : localStorage.getItem('alumno')
      ? JSON.parse(localStorage.getItem('alumno'))
      : JSON.parse(localStorage.getItem('user'));

  const loadLousa = async (isSubscribed, record0) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const { data: posts } = await dataProvider.getList(
      'posts',
      {
        filter: { cursoId: record0.cursoId, diaClase: today.toISOString() },
        sort: {},
        pagination: { page: 1, perPage: 50000 },
      },
    );
    if (!posts || !posts.length) {
      const losaCreation = await dataProvider.create('posts', { data: { cursoId: record0.cursoId, diaClase: today } });
      if (losaCreation && losaCreation.data && losaCreation.data.id) setLosaReady(losaCreation.data.id);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (isSubscribed) {
        setLosaReady(posts[0].objectId);
        setContent(posts[0].note);
      }
    }
  };

  async function getBoard(isSubscribed) {
    const { data: recordT } = record1 ? { data: record1 } : await dataProvider.getOne('drive_documents', { id: routerParams.id });
    const record0 = record1 || recordT;
    if (localStorage.getItem('perfil') !== 'alumno') loadLousa(isSubscribed, record0);
    if (isSubscribed) setRecord(record0);
    const { data: recordBK } = await dataProvider.getOne('cursos', { id: record0.cursoId });
    const { data: alumnosCurso } = await dataProvider.getList(
      'alumnos',
      {
        filter: { objectId: { $in: recordBK.alumnos } },
        sort: { field: 'createdAt', order: 'DESC' },
        pagination: { page: 1, perPage: 50000 },
      },
    );

    if (isSubscribed) setAlumnos(alumnosCurso);

    const { data: profesorT } = await dataProvider.getOne(
      'profesores',
      {
        id: recordBK.profId,
      },
    );
    if (isSubscribed) setProfesor(profesorT);
    if (record && record.alumnoId && record.alumnoId.length) {
      const { data: alumnoT } = await dataProvider.getOne(
        'alumnos',
        {
          id: record.alumnoId,
        },
      );
      // alert(JSON.stringify(alumnoT));
      if (isSubscribed) setAlumno(alumnoT);
    } else {
      setAlumno(' ');
    }
  }

  const disparoTexto = (event) => {
    const delayDebounceFn = setTimeout(() => {
      if (losaready) {
        dataProvider.update('posts', { id: losaready, data: { note: event } });
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  };

  React.useEffect(() => {
    let isSubscribed = true;
    getBoard(isSubscribed);
    // eslint-disable-next-line no-return-assign
    return () => isSubscribed = false;
  }, []);

  if (record1 && user && alumno) {
    return (
      <>
        <div
          style={{
            display: 'flex',
          }}
        >

          <Button
            style={{ width: 89, margin: '10px 20px 5px' }}
            onClick={() => window.history.back()}
            label="ra.label.voltar"
          >
            <img src={VoltarIcon} alt="Voltar" />
          </Button>
          {/* <Button onClick={() => setShowBoard(!showBoard)} label={showBoard ? "Fechar Lousa" : "Abrir Lousa"}>
            <NoteAltIcon />
          </Button> */}
          <Button
            style={{ width: 289, margin: '10px 20px 5px' }}
            onClick={() => window.open(`https://docs.google.com/document/d/${record.driveId}`, '_blank')}
            label="Abrir en Google Drive"
          >
            <AddToDriveIcon />
          </Button>
        </div>
        <div style={{ display: 'flex', height: '100%' }}>
          {localStorage.getItem('perfil') !== 'alumno' && losaready ? (
            <div style={{
              marginRight: 20, flex: 1, paddingBottom: 50, height: '100%', maxWidth: 300,
            }}
            >
              {losaready ? (
                <Editor
                  apiKey="uy3q5l7cf7dleypasrmsoj07udywg77xp86c6l6zenisb1vm"
                  onInit={(evt, editor) => editorRef.current = editor}
                  // value={values}
                  initialValue={content}
                  onEditorChange={(e) => disparoTexto(e)}
                  init={{
                    height: "100%",
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace textcolor visualblocks code fullscreen',
                      'insertdatetime image media table paste code help wordcount',
                      'emoticons',
                    ],
                    toolbar: 'bold italic forecolor backcolor underline strikethrough | alignleft aligncenter emoticons ',
                    content_style: 'body { font-family:Helvetica,Arial, sans-serif; font-size:14px }',
                  }}
                />
              ) : <p>Cargando...</p>}
            </div>
          ) : null}
          {showBoard && localStorage.getItem('perfil') === 'alumno' ? (
            <div style={{
              marginRight: 20, flex: 1, paddingBottom: 50, height: '100%', maxWidth: 300,
            }}
            >
              <Typography style={{ marginBottom: 5, flex: 1, marginTop: 10 }} variant="h5">{record1.title}</Typography>
              <Board
                alumnos={alumnos}
                profesor={profesor}
                mini
                alumnoId={null}
                cursoId={record1.cursoId}
                dataProvider={dataProvider}
              />
            </div>
          ) : null}
          <div style={{ marginRight: 20, flex: 2 }}>
            <iframe
              title={record1 && record1.file ? record1.file.title : ''}
              src={`https://docs.google.com/document/d/${record.driveId}`}
              width="100%"
              height="calc(100vh - 90px)"
              style={{ height: 'calc(100vh - 60px)' }}
              frameBorder="0"
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <LoadingPage />
  );
}

function ClickToEdit(props) {
  const { quill, quillRef } = useQuill();
  const [status, setStatus] = React.useState('...');
  const [provider, setProvider] = useState(null);

  useEffect(() => {
    if (quill) {
      setProvider(new WebsocketProvider('wss://yws.freiesland.com', `quill${props.room}`, props.ydoc));
    }
  }, [quill]);

  useEffect(() => {
    if (provider !== null) {
      const yQuillTextYtype = props.ydoc.getText('quill');
      const binding = new QuillBinding(yQuillTextYtype, quill, provider.awareness);
      const handleObserve = (e) => {
        if (quill && e.delta[0]) {
          const text = e.delta[0].insert;
          quill.clipboard.dangerouslyPasteHTML(props.text);
          yQuillTextYtype.unobserve(handleObserve);
        }
      };
      yQuillTextYtype.observe(handleObserve);
      provider.on('status', (event) => setStatus(event.status));
    }
  }, [provider]);

  const saveToDBClose = () => {
    // console.log(quill.getText());
    // console.log(quill.getContents());
    // console.log(quill.root.innerHTML); // Get innerHTML using quill
    // console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
    props.dataProvider.update(
      'alumno_documentos',
      {
        id: props.docHtml.objectId,
        data: { doc: quill.root.innerHTML }, // { body: docRTC }, // { doc: docRTC, mystore },
      },
    )
      .then((result) => {
        // setGuardando(false);
      });
  };

  const goBack = () => {
    provider.disconnect();
    provider.destroy();
    props.handleCloseClick();
  };

  return (
    <>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
      >
        <Typography style={{ marginBottom: 5, flex: 1, marginTop: 10 }} variant="h5">{props.docHtml.title}</Typography>
        <Button
          style={{ width: 89, marginTop: 10, marginBottom: 5 }}
          onClick={() => window.history.back()}
          label="ra.label.voltar"
        >
          <img src={VoltarIcon} alt="Voltar" />
        </Button>
        <Button
          style={{ width: 89, marginTop: 10, marginBottom: 5 }}
          onClick={saveToDBClose}
          label="ra.label.salvar"
        >
          <SaveIcon />
        </Button>
      </div>
      {props.alumno && props.alumno.nombre && <Typography style={{ flex: 1 }} variant="caption">{`Alumno: ${props.alumno.nombre}.`}</Typography>}
      <Typography style={{ flex: 1, marginBottom: 5 }} variant="caption">{` Status do material: ${status}`}</Typography>
      <div style={{ width: 500, height: 300 }}>
        <div ref={quillRef} />
      </div>
    </>
  );
}
