/* Seletor de linguagem

/*requisito:
1- Criarm em configurações a tela de seleção de linguagem
2- Armazenar por usuário a linguagem setada
3- Criar as variáveis por linguagem
4- Substituir variáveis
*/

/*
Pop-up seleção idioma
*/
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import FlagIcon from '@material-ui/icons/Flag';
import { blue } from '@material-ui/core/colors';
import { Card, CardActions, CardContent } from '@material-ui/core';
import {
  useSetLocale,
  useTranslate,
} from 'react-admin';
import Flag from '../static/img/flag.png';

const language = ['English', 'Español', 'Português'];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  total: { fontWeight: 'bold' },
  root: {
    maxWidth: 160,
    margin: 10,
    cursor: 'pointer',
  },
  bigicon: {
    fontSize: 50,
    alignSelf: 'center',
  },
});

export default function LanguageDialog() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectedValue, setSelectedValue] = React.useState(language[1]);
  const setLocale = useSetLocale();

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const handleListItemClick = (value) => {
    setOpen(false);
    const mylocale = value === 'English' ? 'en' : value === 'Español' ? 'es' : value === 'Italiano' ? 'it' : value === 'Português' ? 'pt' : 'pt';
    localStorage.setItem('lang', mylocale);
    setLocale(mylocale);
  };
  const translate = useTranslate();

  return (
    <>
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">{translate('ra.label.idioma')}</DialogTitle>
        <List>
          {language.map((lang) => (
            <ListItem button onClick={() => handleListItemClick(lang)} key={lang}>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <FlagIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={lang} />
            </ListItem>
          ))}
        </List>
      </Dialog>
      <Card variant="contained" onClick={() => setOpen(true)} className={classes.root}>
        <CardContent
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            width: 150,
            height: 150,
          }}
        >
          <img src={Flag} style={{ width: 80 }} alt="Flag" />
        </CardContent>
        <CardActions>
          <Button size="small">{translate('ra.label.idioma')}</Button>
        </CardActions>
      </Card>
    </>
  );
}
