export const englishLang = {
  ra: {
    action: {
      add_filter: 'Add filter',
      add: 'Add',
      back: 'Go Back',
      bulk_actions: '1 item selected |||| %{smart_count} items selected',
      cancel: 'Cancel',
      clear_input_value: 'Clear value',
      clone: 'Clone',
      confirm: 'Confirm',
      create: 'Create',
      create_item: 'Create %{item}',
      delete: 'Delete',
      edit: 'Edit',
      export: 'Export',
      list: 'List',
      refresh: 'Refresh',
      remove_filter: 'Remove this filter',
      remove: 'Remove',
      save: 'Save',
      search: 'Search',
      select_all: 'Select all',
      select_row: 'Select this row',
      show: 'Show',
      sort: 'Sort',
      undo: 'Undo',
      unselect: 'Unselect',
      expand: 'Expand',
      close: 'Close',
      open_menu: 'Open menu',
      close_menu: 'Close menu',
      update: 'Update',
      move_up: 'Move up',
      move_down: 'Move down',
      open: 'Open',
      toggle_theme: 'Toggle Theme',
    },
    boolean: {
      true: 'Yes',
      false: 'No',
      null: ' ',
    },
    label: {
      a_receber: 'A reber',
      a_vencer: 'A vencer',
      abrir: 'Open',
      abrir_menu: 'Open menu',
      add: 'Add',
      add_entrada: 'Add income',
      add_saida: 'Add expense',
      add_filter: 'Add filter',
      adicionar_arquivo: 'Add file',
      adicionar_avaliacao: 'Add valuation',
      adicionar_prof: 'Add teacher',
      agenda: 'Schedule',
      alunos: 'Students',
      anexar_arquivos: 'Attach files',
      apagar_tudo: 'Erase all',
      aproveitamento: 'Assiduity',
      arraste_solte: 'Drag\'N drop the file here',
      arquivos_uploading: 'Uploading files',
      atividades: 'To do',
      ativo: 'Active',
      atualizar: 'Refresh',
      avaliacao: 'Valuation',
      back: 'Go Back',
      baixar: 'Download',
      bloqueado: 'Blocked',
      bulk_actions: '1 item selected |||| %{smart_count} items selected',
      buscar_nome: 'Search by name',
      buscar_profe: 'Search by teacher',
      cancelar: 'Cancel',
      carga_dia: 'Workload per day',
      carga_horaria: 'Workload',
      cargos: 'Job title',
      carregando: 'Loading',
      carregar: 'Update',
      celular: 'Cell number',
      cidade: 'City',
      clear_input_value: 'Clear value',
      cliente: 'Client',
      clonar: 'Clone',
      close_menu: 'Close menu',
      compartilhado: 'Shared',
      compartir: 'Share with',
      configuracoes: 'Settings',
      confirmar: 'Confirm',
      contrato: 'Contrat',
      coordenador: 'Coordinator',
      cpf: 'CPF - BR',
      criar: 'Create',
      criar_: 'Create %{name}',
      criar_curso: 'Add class',
      criar_evento: 'Add event',
      criar_item: 'Create %{item}',
      criar_modulo: 'Add module',
      criar_subpasta: 'create subdirectory',
      curso: 'Course',
      cursos: 'Courses',
      dados_pgto: 'Payment info.',
      dashboard: 'Dashboard',
      data: 'Date',
      data_inicio: 'Start date',
      data_nascimento: 'birth date',
      data_pgto: 'Payment date',
      data_termino: 'End date',
      data_vencimento: 'Due date',
      desconto: 'Discount',
      dsconto_pontualidade: 'Desconto por pontualidade',
      descricao: 'Description',
      desempenho: 'Performance',
      dia_semana: 'en-US',
      dias_semana: 'Week day',
      doc_ident: 'Id document',
      documento: 'Document',
      documentos: 'Documents',
      documentos_pareceres: 'Documents & References',
      domingo: 'Sunday',
      edit: '%{name} #%{id}',
      editar: 'Edit',
      email: 'e-mail',
      empregados: 'Workers',
      empresa: 'Company',
      empresarial: 'Incompany',
      empty: 'No %{name} yet.',
      endereco: 'Address',
      entradas: 'Income',
      entrar: 'Get in',
      error: 'Something went wrong',
      escrever: 'Write',
      estado: 'State',
      excluir: 'Delete',
      exibir: 'Show',
      expandir: 'Expand',
      exportar: 'Export',
      fechar: 'Close',
      feminino: 'Feminine',
      financeiro: 'Financial',
      formas_pgto: 'Payment methods',
      genero: 'Genre',
      hora_inicio: 'Start time',
      horas: 'Hours',
      identificacao: 'SSN',
      idioma: 'Set language',
      inativo: 'Inactive',
      inicio: 'Start',
      invite: 'Do you want to add one?',
      lancar_pgto: 'Lançar pagamento',
      leer: 'Read',
      list: '%{name}',
      listar: 'List',
      lousa: 'Blackboard:',
      lousa_dia: 'Blackboad of day',
      masculino: 'Masculine',
      material: 'Class Material',
      material_curso: 'Classes',
      minutos: 'Minuts',
      modulo: 'Module',
      moeda_cobranca: 'Billing currency',
      moeda_local: 'Local currency',
      mover_baixo: 'Move down',
      mover_cima: 'Move up',
      n_identificacao: 'N° identification',
      nao_encontrado: 'Not Found',
      natureza: 'Kind of income',
      natureza_saida: 'Kind of spent',
      nome: 'Name',
      nome_aluno: 'Student\'s name',
      nome_curso: 'Course name',
      nome_empresa: 'Company name',
      nome_evento: 'Event\'s name',
      nome_pasta: 'Directory\'s name',
      nota: 'Grade',
      notas: 'Grades',
      nota_max: 'max. grade',
      nota_min: 'min. grade',
      observacoes: 'Coments',
      ordenar: 'Sort',
      outro: 'Other',
      pagamentos: 'Billing',
      pagas: 'Pagas',
      pais: 'Country',
      parcelas: 'Installments',
      passaporte: 'Passaport',
      perfil: 'Profile',
      permissoes: 'Permissions',
      peso_avaliacao: 'Valuation weight',
      pesquisar: 'Search',
      presenca: 'Assiduity',
      privado: 'Private',
      profe: 'Teacher',
      professor: 'Teacher',
      profissao: 'Job',
      quadro_avisos: 'Billboard',
      quarta: 'Wednesday',
      quinta: 'Thursday',
      recebidas: 'Recebidas',
      remove_filter: 'Remove this filter',
      remover: 'Remove',
      renomear: 'Rename',
      reparcelar: 'Repay',
      rg: 'RG - BR',
      rge: 'RG Estrangeiro - BR',
      sabado: 'Saturday',
      saidas: 'Payments',
      sair: 'Exit',
      sala_de_aula: 'Classroom',
      salvar: 'Save',
      segunda: 'Monday',
      sel_linha: 'Select this row',
      sel_modulo: 'Set the module',
      sel_tudo: 'Select all',
      sexta: 'Friday',
      show: '%{name} #%{id}',
      status: 'Status',
      ssn: 'Social Security Number',
      telefone: 'Phone number',
      terca: 'Tuesday',
      tipo_aluno: 'Type of student',
      tipo_avaliacao: 'Valuation type',
      tipo_doc: 'Type of document',
      tipo_evento: 'Event type',
      tipo_saida: 'Payment type',
      titulo_evento: 'Event title',
      todas: 'All',
      transgenero: 'Transgender',
      trocar_nome: 'Change name',
      ultima_atualizacao: 'Last update',
      undo: 'Undo',
      unselect: 'Unselect',
      usuario: 'User',
      val_moeda_local: 'Value at local currency',
      valor: 'Value',
      valor_curso: 'Course value',
      valor_hora: 'Value per hour',
      valores: 'Values',
      valor_receber: 'Value to recip',
      valor_pago: 'Value paied',
      valor_vencimento: 'Due value',
      vencidas: 'Vencidas',
      voltar: 'Back',
    },
    input: {
      file: {
        upload_several:
                'Drop some files to upload, or click to select one.',
        upload_single: 'Drop a file to upload, or click to select it.',
      },
      image: {
        upload_several:
                'Drop some pictures to upload, or click to select one.',
        upload_single:
                'Drop a picture to upload, or click to select it.',
      },
      references: {
        all_missing: 'Unable to find references data.',
        many_missing:
                'At least one of the associated references no longer appears to be available.',
        single_missing:
                'Associated reference no longer appears to be available.',
      },
      password: {
        toggle_visible: 'Hide password',
        toggle_hidden: 'Show password',
      },
    },
    message: {
      about: 'About',
      are_you_sure: 'Are you sure?',
      bulk_delete_content:
            'Are you sure you want to delete this %{name}? |||| Are you sure you want to delete these %{smart_count} items?',
      bulk_delete_title:
            'Delete %{name} |||| Delete %{smart_count} %{name}',
      bulk_update_content:
            'Are you sure you want to update this %{name}? |||| Are you sure you want to update these %{smart_count} items?',
      bulk_update_title:
            'Update %{name} |||| Update %{smart_count} %{name}',
      delete_content: 'Are you sure you want to delete this item?',
      delete_title: 'Delete %{name} #%{id}',
      details: 'Details',
      error:
            "A client error occurred and your request couldn't be completed.",
      invalid_form: 'The form is not valid. Please check for errors',
      loading: 'The page is loading, just a moment please',
      no: 'No',
      not_found:
            'Either you typed a wrong URL, or you followed a bad link.',
      yes: 'Yes',
      unsaved_changes:
            "Some of your changes weren't saved. Are you sure you want to ignore them?",
    },
    navigation: {
      no_results: 'No results found',
      no_more_results:
            'The page number %{page} is out of boundaries. Try the previous page.',
      page_out_of_boundaries: 'Page number %{page} out of boundaries',
      page_out_from_end: 'Cannot go after last page',
      page_out_from_begin: 'Cannot go before page 1',
      page_range_info: '%{offsetBegin}-%{offsetEnd} of %{total}',
      page_rows_per_page: 'Rows per page:',
      next: 'Next',
      prev: 'Prev',
      skip_nav: 'Skip to content',
    },
    sort: {
      sort_by: 'Sort by %{field} %{order}',
      ASC: 'ascending',
      DESC: 'descending',
    },
    auth: {
      auth_check_error: 'Please login to continue',
      user_menu: 'Profile',
      username: 'Username',
      password: 'Password',
      sign_in: 'Sign in',
      sign_in_error: 'Authentication failed, please retry',
      logout: 'Logout',
    },
    notification: {
      updated: 'Element updated |||| %{smart_count} elements updated',
      created: 'Element created',
      deleted: 'Element deleted |||| %{smart_count} elements deleted',
      bad_item: 'Incorrect element',
      item_doesnt_exist: 'Element does not exist',
      http_error: 'Server communication error',
      data_provider_error:
            'dataProvider error. Check the console for details.',
      i18n_error:
            'Cannot load the translations for the specified language',
      canceled: 'Action cancelled',
      logged_out: 'Your session has ended, please reconnect.',
      not_authorized: "You're not authorized to access this resource.",
    },
    validation: {
      required: 'Required',
      minLength: 'Must be %{min} characters at least',
      maxLength: 'Must be %{max} characters or less',
      minValue: 'Must be at least %{min}',
      maxValue: 'Must be %{max} or less',
      number: 'Must be a number',
      email: 'Must be a valid email',
      oneOf: 'Must be one of: %{options}',
      regex: 'Must match a specific format (regexp): %{pattern}',
    },
    page: {
      profe: 'Teacher',
      adicionar_avaliacao: 'Agregar evaluaciones',
      alunos: 'Students',
      inicio: 'Start',
      buscar_nome: 'Search by name',
      create: 'Aggiungi %{name}',
      dashboard: 'Cruscotto',
      edit: '%{name} %{id}',
      error: 'Qualcosa non ha funzionato',
      list: '%{name}',
      loading: 'Caricamento in corso',
      not_found: 'Non trovato',
      show: '%{name} %{id}',
      empty: 'Nessun %{name} ancora.',
      invite: 'Vuoi aggiungerne uno?',
      avaliacao: 'Avaliação',
      material: 'Material',
    },
  },
};

export const portuguesLang = {
  ra: {
    action: {
      add_filter: 'Adicionar Filtro',
      add: 'Adicionar',
      back: 'Voltar',
      bulk_actions: '1 item selecionado |||| %{smart_count} itens selecionados',
      cancel: 'Cancelar',
      clear_input_value: 'Limpar campo',
      clone: 'Duplicar',
      confirm: 'Confirmar',
      contrato: 'Contrato',
      create: 'Novo',
      create_item: 'Novo %{item}',
      delete: 'Deletar',
      edit: 'Editar',
      export: 'Exportar',
      list: 'Listar',
      refresh: 'Atualizar',
      remove_filter: 'Cancelar filtro',
      remove: 'Excluir',
      save: 'Salvar',
      search: 'Buscar',
      select_all: 'Selecionar tudo',
      select_row: 'Selecionar essa linha',
      show: 'Exibir',
      sort: 'Ordenar',
      undo: 'Desfazer',
      unselect: 'Desmarcar',
      expand: 'Expandir',
      close: 'Fechar',
      open_menu: 'Abrir menu',
      close_menu: 'Fechar menu',
      update: 'Atualizar',
      move_up: 'Mover para cima',
      move_down: 'Mover para baixo',
      open: 'Abrir',
      toggle_theme: 'Alternar tema',
    },
    boolean: {
      true: 'Sim',
      false: 'Não',
      null: '',
    },
    label: {
      a_receber: 'A reber',
      a_vencer: 'A vencer',
      abrir: 'Abrir',
      abrir_menu: 'Abrir menu',
      add: 'Adicionar',
      add_saida: 'Adicionar saida',
      add_entrada: 'Adicionar entrada',
      add_filter: 'Add filtro',
      adicionar_arquivo: 'Add arquivo',
      adicionar_avaliacao: 'Add avaliação',
      adicionar_prof: 'Adicionar professor',
      agenda: 'Agenda',
      alunos: 'Alunos',
      anexar_arquivos: 'Anexar arquivos',
      apagar_tudo: 'Apagar tudo',
      aproveitamento: 'Assiduidade',
      arraste_solte: 'Arraste e solte o arquivo aqui',
      arquivos_uploading: 'Archivos subiendo',
      atividades: 'Afazeres',
      ativo: 'Ativo',
      atualizar: 'Atualizar',
      avaliacao: 'Avaliação',
      back: 'Voltar',
      baixar: 'Descarregar',
      bloqueado: 'Bloqueado',
      bulk_actions: '1 item selected |||| %{smart_count} items selected',
      buscar_nome: 'Buscar por nome',
      buscar_profe: 'Buscar por professor',
      cancelar: 'Cancelar',
      carga_dia: 'Carga horária diária',
      carga_horaria: 'Carga horária',
      cargos: 'Cargos',
      carregando: 'Carregando',
      carregar: 'Carregar',
      celular: 'Celular',
      cidade: 'Cidade',
      clear_input_value: 'Limpar valor',
      cliente: 'Cliente',
      clonar: 'Clonar',
      close_menu: 'Fechar menu',
      compartilhado: 'Compartilhado',
      compartir: 'Compartilhar',
      configuracoes: 'Configurações',
      confirmar: 'Confirmar',
      contrato: 'Contrato',
      coordenador: 'Coordenador',
      cpf: 'CPF',
      criar: 'Criar',
      criar_: 'Create %{name}',
      criar_curso: 'Add curso',
      criar_evento: 'Criar evento',
      criar_item: 'Create %{item}',
      criar_modulo: 'Criar módulo',
      criar_subpasta: 'criar subpasta',
      curso: 'Curso',
      cursos: 'Cursos',
      dados_pgto: 'Dados de pgto',
      dashboard: 'Dashboard',
      data: 'Data',
      data_inicio: 'Data de início',
      data_nascimento: 'data de nascimento',
      data_pgto: 'Data do pagamento',
      data_termino: 'Data de término',
      data_vencimento: 'Data de vencimento',
      desconto: 'Desconto',
      dsconto_pontualidade: 'Desconto por pontualidade',
      descricao: 'Descrição',
      desempenho: 'Desempenho',
      dia_semana: 'pt-BR',
      dias_semana: 'Dia da semana',
      doc_ident: 'Documento de identificação',
      documento: 'Documento',
      documentos: 'Documentos',
      documentos_pareceres: 'Documentos e pareceres',
      domingo: 'Domingo',
      edit: '%{name} #%{id}',
      editar: 'Editar',
      email: 'e-mail',
      empregados: 'Empregados',
      empresa: 'Empresa',
      empresarial: 'Empresarial',
      empty: 'No %{name} yet.',
      endereco: 'Endereço',
      entradas: 'Entradas',
      entrar: 'Entrar',
      error: 'Something went wrong',
      escrever: 'Escrever',
      estado: 'Estado',
      excluir: 'Excluir',
      exibir: 'Exibir',
      expandir: 'Expandir',
      exportar: 'Exportar',
      fechar: 'Fechar',
      feminino: 'Feminino',
      financeiro: 'Financeiro',
      formas_pgto: 'Formas de pagamento',
      genero: 'Gênero',
      hora_inicio: 'Hora de início',
      horas: 'Horas',
      identificacao: 'Indentificação',
      idioma: 'Idioma',
      inativo: 'Inativo',
      inicio: 'Início',
      invite: 'Quer adicionar uma pessoa?',
      lancar_pgto: 'Lançar pagamento',
      leer: 'Leer',
      list: '%{name}',
      listar: 'Listar',
      lousa: 'Lousa:',
      lousa_dia: 'Lousa do dia',
      masculino: 'Masculino',
      material: 'Material',
      material_curso: 'Material do curso',
      minutos: 'Minutos',
      modulo: 'Módulo',
      moeda_cobranca: 'Moeda de cobrança',
      moeda_local: 'Moeda local',
      mover_baixo: 'Mover p/ baixo',
      mover_cima: 'Mover p/ cima',
      n_identificacao: 'N° do documento',
      natureza: 'Natureza da entrada',
      natureza_saida: 'Natureza da saída',
      nao_encontrado: 'Not Found',
      nome: 'Nome',
      nome_aluno: 'Nome do aluno',
      nome_curso: 'Nome do curso',
      nome_empresa: 'Nome da empresa',
      nome_evento: 'Nome do evento',
      nome_pasta: 'Nome da pasta',
      nota: 'Nota',
      notas: 'Notas',
      nota_max: 'nota max.',
      nota_min: 'nota min.',
      observacoes: 'Observações',
      ordenar: 'Ordenar',
      outro: 'Outro',
      pagamentos: 'Pagamentos',
      pagas: 'Pagas',
      pais: 'País',
      parcelas: 'Parcelas',
      passaporte: 'Passaporte',
      perfil: 'Perfil',
      permissoes: 'Permissões',
      peso_avaliacao: 'Peso da avaliação',
      pesquisar: 'Pesquisar',
      presenca: 'Presença',
      privado: 'Privado',
      professor: 'Professor',
      profissao: 'Profissão',
      quadro_avisos: 'Quadro de avisos',
      quarta: 'Quarta-feira',
      quinta: 'Quinta-feira',
      recebidas: 'Recebidas',
      remove_filter: 'Remover filtro',
      remover: 'Remover',
      renomear: 'Renomear',
      reparcelar: 'Reparcelar',
      rg: 'RG',
      rge: 'RG Extranjeiro',
      sabado: 'Sábado',
      saidas: 'Saídas',
      sair: 'Sair',
      sala_de_aula: 'Sala de aula',
      salvar: 'Salvar',
      segunda: 'Segunda-feira',
      sel_linha: 'Selecionar linha',
      sel_modulo: 'Selecionar môdulo',
      sel_tudo: 'Selecionar tudo',
      sexta: 'Sexta-feira',
      show: '%{name} #%{id}',
      status: 'Status',
      ssn: 'Social Security Number - EUA',
      telefone: 'Telefone',
      terca: 'Terça-feira',
      tipo_aluno: 'Tipo de aluno',
      tipo_avaliacao: 'Tipo de avaliação',
      tipo_doc: 'Tipo de documento',
      tipo_evento: 'Tipo de evento',
      titulo_evento: 'Título do evento',
      todas: 'Todas',
      transgenero: 'Transgênero',
      trocar_nome: 'Trocar nome',
      ultima_atualizacao: 'Ultima atualização',
      undo: 'Undo',
      unselect: 'Unselect',
      usuario: 'Usuário',
      val_moeda_local: 'Valor na moeda local',
      valor: 'Valor',
      valor_curso: 'Valor do curso',
      valor_hora: 'Valor por hora',
      valores: 'Valores',
      valor_pago: 'Valor pago',
      valor_receber: 'Valor a receber',
      valor_vencimento: 'Valor no vencimento',
      vencidas: 'Vencidas',
      voltar: 'Voltar',
    },
    input: {
      file: {
        upload_several:
          'Solte arquivos para upload, ou clique para selecionar um.',
        upload_single:
          'Solte um arquivo para upload, ou clique para selecioná-lo.',
      },
      image: {
        upload_several:
          'Solte imagens para upload, ou clique para selecionar uma.',
        upload_single:
          'Solte uma imagem para upload, ou clique para selecioná-la.',
      },
      references: {
        all_missing:
          'Não foi possível encontrar os dados de referência.',
        many_missing:
          'Pelo menos uma das referências associadas aparenta não estar mais disponível.',
        single_missing:
          'A referência associada aparenta não estar mais disponível.',
      },
      password: {
        toggle_visible: 'Ocultar senha',
        toggle_hidden: 'Mostrar senha',
      },
    },
    message: {
      about: 'Sobre',
      are_you_sure: 'Tem certeza?',
      bulk_delete_content:
        'Você tem certeza que quer remover isso %{name}? |||| Você tem certeza que quer remover estes %{smart_count} items?',
      bulk_delete_title:
        'Remover %{name} |||| Remover %{smart_count} %{name}',
      delete_content: 'Você tem certeza que quer remover este item?',
      delete_title: 'Remover %{name} #%{id}',
      details: 'Detalhes',
      error:
        'Ocorreu um erro no navegador e seu requisição não foi completada.',
      invalid_form:
        'O formulário não é válido. Por favor, verifique os erros',
      loading: 'A página está carregando, aguarde um momento',
      no: 'Não',
      not_found: 'Você digitou uma URL errada, ou seguiu um link errado.',
      yes: 'Sim',
      unsaved_changes:
        'Algumas de suas alteracões não foram salvas. Você tem certeza que quer ignorá-las?',
    },
    navigation: {
      no_results: 'Nenhum resultado encontrado',
      no_more_results:
        'A página número %{page} fora dos limites. Tente a página anterior.',
      page_out_of_boundaries: 'A página %{page} está fora dos limites',
      page_out_from_end: 'Fim da paginação',
      page_out_from_begin: 'A página não pode ser menor que 1',
      page_range_info: '%{offsetBegin}-%{offsetEnd} de %{total}',
      page_rows_per_page: 'Linhas por página:',
      next: 'Próxima',
      prev: 'Anterior',
      skip_nav: 'Ir para o conteúdo',
    },
    sort: {
      sort_by: 'Ordernar por %{field} %{order}',
      ASC: 'ascendente',
      DESC: 'descendente',
    },
    auth: {
      auth_check_error: 'Faça o login para continuar',
      user_menu: 'Perfil',
      username: 'Usuário',
      password: 'Senha',
      sign_in: 'Entrar',
      sign_in_error: 'Erro na autenticação, por favor tente novamente',
      logout: 'Sair',
    },
    notification: {
      updated:
        'Elemento atualizado |||| %{smart_count} elementos atualizados',
      created: 'Elemento criado',
      deleted:
        'Elemento removido |||| %{smart_count} elementos removidos',
      bad_item: 'Elemento incorreto',
      item_doesnt_exist: 'O elemento não existe',
      http_error: 'Erro de comunicação com o servidor',
      data_provider_error:
        'Erro com o dataProvider. Verifique o console para mais detalhes.',
      i18n_error:
        'Não foi possível carregar as traduções para a linguagem especificada',
      canceled: 'Ação cancelada',
      logged_out: 'Sua sessão foi finalizada, por favor reconecte.',
    },
    validation: {
      required: 'Obrigatório',
      minLength: 'Deve ter pelo menos %{min} caracteres',
      maxLength: 'Deve ter no máximo %{max} caracteres',
      minValue: 'Deve ser no mínimo %{min}',
      maxValue: 'Deve ser no máximo %{max}',
      number: 'Deve ser um número',
      email: 'Deve ser um e-mail válido',
      oneOf: 'Deve ser um destes: %{options}',
      regex: 'Deve respeitar um formato específico (regexp): %{pattern}',
    },
    page: {
      profe: 'Teacher',
      adicionar_avaliacao: 'Agregar evaluaciones',
      alunos: 'Students',
      inicio: 'Start',
      buscar_nome: 'Search by name',
      create: 'Aggiungi %{name}',
      dashboard: 'Cruscotto',
      edit: '%{name} %{id}',
      error: 'Qualcosa non ha funzionato',
      list: '%{name}',
      loading: 'Caricamento in corso',
      not_found: 'Non trovato',
      show: '%{name} %{id}',
      empty: 'Nessun %{name} ancora.',
      invite: 'Vuoi aggiungerne uno?',
      avaliacao: 'Avaliação',
      material: 'Material',
    },
  },
};

export const espanolLang = {
  ra: {
    action: {
      add: 'Añadir',
      add_filter: 'Añadir filtro',
      back: 'Ir atrás',
      bulk_actions: '1 item seleccionado |||| %{smart_count} items seleccionados',
      cancel: 'Cancelar',
      clear_input_value: 'Limpiar valor',
      clone: 'Clonar',
      close: 'Cerrar',
      close_menu: 'Cerrar menú',
      confirm: 'Confirmar',
      contrato: 'Contrato',
      create: 'Crear',
      delete: 'Eliminar',
      edit: 'Editar',
      expand: 'Expandir',
      export: 'Exportar',
      list: 'Listar',
      open_menu: 'Abrir menú',
      refresh: 'Refrescar',
      remove: 'Borrar',
      remove_filter: 'Borrar filtro',
      save: 'Guardar',
      search: 'Buscar',
      show: 'Mostrar',
      sort: 'Ordenar',
      undo: 'Deshacer',
      unselect: 'Deseleccionar',
    },
    boolean: {
      true: 'Sí',
      false: 'No',
      null: ' ',
    },
    label: {
      a_receber: 'Por recibir',
      a_vencer: 'Por vencer',
      abrir: 'Abrir',
      abrir_menu: 'Abrir menu',
      add: 'Añadir',
      add_entrada: 'Añanir entrada',
      add_saida: 'Añanir salida',
      add_filter: 'Añadir filtro',
      adicionar_arquivo: 'Agregar archivos',
      adicionar_avaliacao: 'Añandir evaluación',
      adicionar_prof: 'Añandir profesor',
      agenda: 'Calendario',
      alunos: 'Alumnos',
      anexar_arquivos: 'Añandir arquivos',
      apagar_tudo: 'Borrar todo',
      aproveitamento: 'Aprovechamiento',
      arraste_solte: 'Arraste e solte o arquivo aqui',
      arquivos_uploading: 'Arquivos subindo',
      atividades: 'Actividades',
      ativo: 'Activo',
      atualizar: 'Refresh',
      avaliacao: 'Evaluación',
      back: 'Ir atrás',
      baixar: 'Descargar',
      bloqueado: 'Bloqueado',
      bulk_actions: '1 item selected |||| %{smart_count} items selected',
      buscar_nome: 'Buscar por nombre',
      buscar_profe: 'Buscar por profe',
      cancelar: 'Cancelar',
      carga_dia: 'Carga por día',
      carga_horaria: 'Carga horaria',
      cargos: 'Cargos',
      carregando: 'Loading',
      carregar: 'Update',
      celular: 'Celular',
      cidade: 'Ciudad',
      clear_input_value: 'Limpiar valor',
      cliente: 'Cliente',
      clonar: 'Clonar',
      close_menu: 'Cerrar menú',
      compartilhado: 'Compartido',
      compartir: 'Compartir con',
      configuracoes: 'Configuraciones',
      confirmar: 'Confirmar',
      contrato: 'Contrato',
      coordenador: 'Coordinador',
      cpf: 'CPF - BR',
      criar: 'Crear',
      criar_: 'Create %{name}',
      criar_curso: 'Agregar curso',
      criar_evento: 'Crear Evento',
      criar_item: 'Create %{item}',
      criar_modulo: 'Crear modulo',
      criar_subpasta: 'Crear subcarpeta',
      curso: 'Curso',
      cursos: 'Cursos',
      dados_pgto: 'Datos de pago',
      dashboard: 'Tablero',
      data: 'Fecha',
      data_inicio: 'Fecha de inicio',
      data_nascimento: 'Fecha de nascimiento',
      data_pgto: 'Data del pago',
      data_termino: 'Fecha de término',
      data_vencimento: 'Fecha de vencimiento',
      desconto: 'Descuento',
      dsconto_pontualidade: 'Descuento por pontualidad',
      descricao: 'Descripción',
      desempenho: 'Desempeño',
      dia_semana: 'es-CO',
      dias_semana: 'Día de la semana',
      doc_ident: 'Documento de identificación',
      documento: 'Documento',
      documentos: 'Documentos',
      documentos_pareceres: 'Documentos y pareceres',
      domingo: 'Domingo',
      edit: '%{name} #%{id}',
      editar: 'Editar',
      email: 'correo electronico',
      empregados: 'Empleados',
      empresa: 'Empresa',
      empresarial: 'Empresarial',
      empty: 'Sin %{name} todavía.',
      endereco: 'Dirección',
      entradas: 'Entradas',
      entrar: 'Ingresar',
      error: 'Algo salió mal',
      escrever: 'Escribir',
      estado: 'Departamento',
      excluir: 'Delete',
      exibir: 'Show',
      expandir: 'Expandir',
      exportar: 'Exportar',
      fechar: 'Close',
      feminino: 'Feminine',
      financeiro: 'Financiero',
      formas_pgto: 'Formas de pago',
      genero: 'Género',
      hora_inicio: 'Hora de inicio',
      horas: 'Horas',
      identificacao: 'Horas',
      idioma: 'Elegir idioma',
      inativo: 'Inactivo',
      inicio: 'Inicio',
      invite: '¿Quiere agregar una?',
      lancar_pgto: 'Lanzar pago',
      leer: 'Leer',
      list: '%{name}',
      listar: 'Listar',
      lousa: 'Tablero:',
      lousa_dia: 'Tablero del día',
      masculino: 'Masculino',
      material: 'Material',
      material_curso: 'Material del curso',
      minutos: 'Minutos',
      modulo: 'Modulo',
      moeda_cobranca: 'Moneda de cobro',
      moeda_local: 'Moneda local',
      mover_baixo: 'Move down',
      mover_cima: 'Move up',
      n_identificacao: 'N° identificación',
      natureza: 'Naturaleza de la entrada',
      natureza_saida: 'Naturaleza del pago',
      nao_encontrado: 'Not Found',
      nome: 'Nombre',
      nome_aluno: 'Nombre del alumno',
      nome_curso: 'Nombre del curso',
      nome_empresa: 'Nombre de la empresa',
      nome_evento: 'Nombre del evento',
      nome_pasta: 'Nombre de la carpeta',
      nota: 'Nota',
      notas: 'Notas',
      nota_max: 'nota max.',
      nota_min: 'nota min.',
      observacoes: 'Observaciónes',
      ordenar: 'Sort',
      outro: 'Otro',
      pagamentos: 'Facturación',
      pagas: 'Pagas',
      pais: 'País',
      parcelas: 'Cuotas',
      passaporte: 'Pasaporte',
      perfil: 'Perfil',
      permissoes: 'Permisos',
      peso_avaliacao: 'Peso de la evaluación',
      pesquisar: 'Buscar',
      presenca: 'Presenças',
      privado: 'Privado',
      professor: 'Professor',
      profissao: 'Profesión',
      quadro_avisos: 'Tablero de avisos',
      quarta: 'Miércoles',
      quinta: 'Jueves',
      recebidas: 'Recibidas',
      remove_filter: 'Borrar filtro',
      remover: 'Borrar',
      renomear: 'Renombrar',
      reparcelar: 'Refinanciar',
      rg: 'Cédula Ciudadania',
      rge: 'Cédula Extranjeria',
      sabado: 'Sabado',
      sair: 'Salir',
      saidas: 'Salidas',
      sala_de_aula: 'Sala de clase',
      salvar: 'Guardar',
      segunda: 'Lunes',
      sel_linha: 'Select this row',
      sel_modulo: 'Selecione el modulo',
      sel_tudo: 'Select all',
      sexta: 'Viernes',
      show: '%{name} #%{id}',
      status: 'Status',
      ssn: 'Social Security Number - EUA',
      telefone: 'Teléfono',
      terca: 'Martes',
      tipo_aluno: 'Tipo de alumno',
      tipo_avaliacao: 'Tipo de evaluación',
      tipo_doc: 'Tipo do documento',
      tipo_evento: 'Tipo de evento',
      titulo_evento: 'Titulo del evento',
      todas: 'Todas',
      transgenero: 'Transgénero',
      trocar_nome: 'Cambiar nombre',
      ultima_atualizacao: 'Última atualización',
      undo: 'Deshacer',
      unselect: 'Deseleccionar',
      usuario: 'Usuario',
      val_moeda_local: 'Valor en la moneda local',
      valor: 'Valor',
      valor_curso: 'Valor del curso',
      valor_hora: 'Valor por hora',
      valores: 'Valores',
      valor_receber: 'Valor a recibir',
      valor_pago: 'Valor pagado',
      valor_vencimento: 'Valor en el vencimiento',
      vencidas: 'Vencidas',
      voltar: 'Volver',
    },
    auth: {
      auth_check_error: 'Por favor inicie sesión para continuar',
      logout: 'Cerrar Sesión',
      password: 'Contraseña',
      sign_in: 'Acceder',
      sign_in_error: 'La autenticación falló, por favor, vuelva a intentarlo',
      user_menu: 'Perfil',
      username: 'Usuario',
    },
    input: {
      file: {
        upload_several:
          'Arrastre algunos archivos para subir o haga clic para seleccionarlos.',
        upload_single:
          'Arrastre un archivo para subir o haga clic para seleccionarlo.',
      },
      image: {
        upload_several:
          'Arrastre algunas imagénes para subir o haga clic para seleccionarlas.',
        upload_single:
          'Arrastre alguna imagen para subir o haga clic para seleccionarla.',
      },
      references: {
        all_missing: 'No se pueden encontrar datos de referencias.',
        many_missing:
          'Al menos una de las referencias asociadas parece no estar disponible.',
        single_missing: 'La referencia asociada no parece estar disponible.',
      },
      password: {
        toggle_visible: 'Ocultar contraseña',
        toggle_hidden: 'Mostrar contraseña',
      },
    },
    message: {
      about: 'Acerca de',
      are_you_sure: '¿Está seguro?',
      bulk_delete_content:
        '¿Seguro que quiere eliminar este %{name}? |||| ¿Seguro que quiere eliminar estos %{smart_count} items?',
      bulk_delete_title:
        'Eliminar %{name} |||| Eliminar %{smart_count} %{name} items',
      delete_content: '¿Seguro que quiere eliminar este item?',
      delete_title: 'Eliminar %{name} #%{id}',
      details: 'Detalles',
      error:
        'Se produjo un error en el cliente y su solicitud no se pudo completar',
      invalid_form:
        'El formulario no es válido. Por favor verifique si hay errores',
      loading: 'La página se está cargando, espere un momento por favor',
      no: 'No',
      not_found:
        'O bien escribió una URL incorrecta o siguió un enlace incorrecto.',
      yes: 'Sí',
      unsaved_changes:
        'Algunos de sus cambios no se guardaron. ¿Está seguro que quiere ignorarlos?',
    },
    navigation: {
      next: 'Siguiente',
      no_more_results:
        'El número de página %{page} está fuera de los límites. Pruebe la página anterior.',
      no_results: 'No se han encontrado resultados',
      page_out_from_begin: 'No puede ir antes de la página 1',
      page_out_from_end: 'No puede ir después de la última página',
      page_out_of_boundaries: 'Número de página %{page} fuera de los límites',
      page_range_info: '%{offsetBegin} - %{offsetEnd} de %{total}',
      page_rows_per_page: 'Filas por página:',
      prev: 'Anterior',
      skip_nav: 'Saltar al contenido',
    },
    sort: {
      sort_by: 'Ordenar por %{field} %{order}',
      ASC: 'ascendente',
      DESC: 'descendente',
    },
    notification: {
      bad_item: 'Elemento incorrecto',
      canceled: 'Acción cancelada',
      created: 'Elemento creado',
      data_provider_error:
        'Error del proveedor de datos. Consulte la consola para más detalles.',
      deleted: 'Elemento borrado |||| %{smart_count} elementos borrados.',
      http_error: 'Error de comunicación con el servidor',
      item_doesnt_exist: 'El elemento no existe',
      logged_out: 'Su sesión ha finalizado, vuelva a conectarse.',
      updated:
        'Elemento actualizado |||| %{smart_count} elementos actualizados',
      i18n_error:
        'No se pudieron cargar las traducciones para el idioma especificado',
    },
    validation: {
      email: 'Debe ser un correo electrónico válido',
      maxLength: 'Debe contener %{max} caracteres o menos',
      maxValue: 'Debe ser %{max} o menos',
      minLength: 'Debe contener %{min} caracteres al menos',
      minValue: 'Debe ser al menos %{min}',
      number: 'Debe ser un número',
      oneOf: 'Debe ser uno de: %{options}',
      regex: 'Debe coincidir con un formato específico (regexp): %{pattern}',
      required: 'Requerido',
    },
    page: {
      create: 'Crear %{name}',
      dashboard: 'Tablero',
      edit: '%{name} #%{id}',
      empty: 'Sin %{name} todavía.',
      error: 'Algo salió mal',
      invite: '¿Quiere agregar una?',
      list: 'Lista de %{name}',
      loading: 'Cargando',
      not_found: 'No encontrado',
      show: '%{name} #%{id}',
    },
  },
};

export const italianoLang = {
  ra: {
    action: {
      abrir: 'Open',
      criar_curso: 'Creare corso',
      add_filter: 'Aggiungi un filtro',
      add: 'Aggiungi',
      back: 'Indietro',
      bulk_actions: '%{smart_count} selezionati',
      cancel: 'Annulla',
      clear_input_value: 'Svuota il modulo',
      clone: 'Duplica',
      confirm: 'Conferma',
      contrato: 'Contrat',
      create: 'Crea',
      delete: 'Cancella',
      edit: 'Modifica',
      export: 'Esporta',
      list: 'Elenco',
      refresh: 'Aggiorna',
      remove_filter: 'Rimuovi questo filtro',
      remove: 'Rimuovi',
      save: 'Salva',
      search: 'Ricerca',
      show: 'Mostra',
      sort: 'Ordina',
      undo: 'Annulla',
      unselect: 'Annulla selezione',
      expand: 'Espandi',
      close: 'Chiudi',
      open_menu: 'Apri il menu',
      close_menu: 'Chiudi il menu',
    },
    label: {
      nome: 'Nombre',
      compartir: 'Compartir con',
      compartido: 'Compartido',
      dia_semana: 'it-IT',
      permissoes: 'permi',
      usuario: 'user',
      contrato: 'Contrato',
    },
    boolean: {
      true: 'Si',
      false: 'No',
      null: ' ',
    },
    page: {
      profe: 'Teacher',
      adicionar_avaliacao: 'Agregar evaluaciones',
      alunos: 'Students',
      inicio: 'Start',
      buscar_nome: 'Search by name',
      create: 'Aggiungi %{name}',
      dashboard: 'Cruscotto',
      edit: '%{name} %{id}',
      error: 'Qualcosa non ha funzionato',
      list: '%{name}',
      loading: 'Caricamento in corso',
      not_found: 'Non trovato',
      show: '%{name} %{id}',
      empty: 'Nessun %{name} ancora.',
      invite: 'Vuoi aggiungerne uno?',
      avaliacao: 'Avaliação',
      material: 'Material',
    },
    input: {
      file: {
        upload_several:
          'Trascina i files da caricare, oppure clicca per selezionare.',
        upload_single: 'Trascina il file da caricare, oppure clicca per selezionarlo.',
      },
      image: {
        upload_several:
          'Trascina le immagini da caricare, oppure clicca per selezionarle.',
        upload_single:
          'Trascina l\'immagine da caricare, oppure clicca per selezionarla.',
      },
      references: {
        all_missing: 'Impossibile trovare i riferimenti associati.',
        many_missing:
          'Almeno uno dei riferimenti associati non sembra più disponibile.',
        single_missing:
          'Il riferimento associato non sembra più disponibile.',
      },
    },
    password: {
      toggle_visible: 'Nascondi la password',
      toggle_hidden: 'Mostra la password',
    },
    message: {
      about: 'Informazioni',
      are_you_sure: 'Sei sicuro ?',
      bulk_delete_content:
        'Sei sicuro di voler cancellare questo %{name}? |||| Sei sicuro di voler eliminare questi %{smart_count}?',
      bulk_delete_title:
        'Delete %{name} |||| Delete %{smart_count} %{name} items',
      delete_content: 'Are you sure you want to delete this item?',
      delete_title: 'Cancella %{name} #%{id}',
      details: 'Dettagli',
      error:
        'Un errore locale è occorso e la tua richiesta non è stata completata.',
      invalid_form: 'Il modulo non è valido. Si prega di verificare la presenza di errori.',
      loading: 'La pagina si sta caricando, solo un momento per favore',
      no: 'No',
      not_found:
        'Hai inserito un URL errato, oppure hai cliccato un link errato',
      yes: 'Si',
      unsaved_changes:
        'Alcune modifiche non sono state salvate. Sei sicuro di volerle ignorare?',
    },
    navigation: {
      no_results: 'Nessun risultato trovato',
      no_more_results:
        'La pagina numero %{page} è fuori dell\'intervallo. Prova la pagina precedente.',
      page_out_of_boundaries: 'Il numero di pagina %{page} è fuori dei limiti',
      page_out_from_end: 'Fine della paginazione',
      page_out_from_begin: 'Il numero di pagina deve essere maggiore di 1',
      page_range_info: '%{offsetBegin}-%{offsetEnd} di %{total}',
      page_rows_per_page: 'Righe per pagina',
      next: 'Successivo',
      prev: 'Precedente',
      skip_nav: 'Vai al contenuto',
    },
    sort: {
      sort_by: 'Ordina per %{field} %{order}',
      ASC: 'cresente',
      DESC: 'decrescente',
    },
    auth: {
      auth_check_error: 'È necessario accedere per continuare',
      user_menu: 'Profilo',
      username: 'Nome utente',
      password: 'Password',
      sign_in: 'Login',
      sign_in_error: 'Autenticazione fallita, riprovare.',
      logout: 'Disconnessione',
    },
    notification: {
      updated: 'Record aggiornato |||| %{smart_count} records aggiornati',
      created: 'Record creato',
      deleted: 'Record eliminato |||| %{smart_count} records eliminati',
      bad_item: 'Record errato',
      item_doesnt_exist: 'Record inesistente',
      http_error: 'Errore di comunicazione con il server dati',
      data_provider_error:
        'Errore del data provider. Controlla la console per i dettagli.',
      i18n_error:
        'Traduzioni non trovate per il linguaggio specificato',
      canceled: 'Azione annullata',
      logged_out: 'La sessione è stata terminata, si prega di ripetere l\'autenticazione.',
    },
    validation: {
      required: 'Campo obbligatorio',
      minLength: 'Deve essere lungo %{min} caratteri almeno',
      maxLength: 'Deve essere lungo %{max} caratteri al massimo',
      minValue: 'Deve essere almeno %{min}',
      maxValue: 'Deve essere al massimo %{max}',
      number: 'Deve essere un numero',
      email: 'Deve essere un valido indirizzo email',
      oneOf: 'Deve essere uno di: %{options}',
      regex: 'Deve rispettare il formato (espressione regolare): %{pattern}',
    },
  },
};

// const langSelected = localStorage.get('lang');
// export const i18nProvider = polyglotI18nProvider(
//   (locale) => (langSelected === 'en' ? englishLang : langSelected === 'it' ? italianoLang : langSelected === 'esp' ? espanolLang : langSelected === 'esp' ? espanolLang : portuguesLang),
//   'en', // Default locale
// );
