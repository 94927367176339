import { getYjsValue, syncedStore } from '@syncedstore/core';
import { WebrtcProvider } from 'y-webrtc';

export const globalStore = syncedStore({ todos: [] });
export const store = syncedStore({ todos: [], fragment: 'xml' });

// new WebrtcProvider('idjuemadres', getYjsValue(globalStore)); // sync via webrtc
// Create a document that syncs automatically using Y-WebRTC
// const doc = getYjsValue(store);
// export const webrtcProvider = new WebrtcProvider('idjuemadresX2cr11', doc);

// export const disconnect = () => webrtcProvider.disconnect();
// export const connect = () => webrtcProvider.connect();
