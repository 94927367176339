import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import TextField from '@material-ui/core/TextField';
import { useDataProvider } from 'react-admin';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: '#40434e',
    color: '#ffffff',
  },
});

export default function SimpleDialog(props) {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const { onClose, selectedValue, open } = props;
  const [emails, setEmails] = useState([]);
  const [value, setValue] = useState(null);
  const handleClose = () => {
    setValue(null);
    onClose(selectedValue);
  };

  const handleListItemClick = (value, obj) => {
    onClose(value, obj);
  };

  useEffect(() => {
    if (props.resource !== 'users') {
      dataProvider.getList(props.resource, {
        filter: { },
        sort: { field: 'nombre', order: 'ASC' },
        pagination: { page: 1, perPage: 50000 },
      })
        .then(({ data }) => {
          if (props.resource2 && props.resource2 !== '') {
            const temporalData = data;
            dataProvider.getList(props.resource2, {
              filter: { },
              sort: { field: 'nombre', order: 'ASC' },
              pagination: { page: 1, perPage: 50000 },
            })
              .then(({ data }) => setEmails(
                [...temporalData, ...data].sort((a, b) => a.nombre > b.nombre),
              ));
          } else {
            setEmails(data);
          }
        });
    }
  }, []);

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{props.title}</DialogTitle>
      <TextField
        onChange={(e) => setValue(e.target.value)}
        variant="outlined"
        label="Buscar por nome"
        fullWidth
        style={{
          width: '80%',
          margin: 20,
        }}
      />
      <List>
        {emails && emails.filter(
          (item) => (value && item.nombre
            ? item.nombre.toLowerCase().includes(value.toLowerCase()) : item),
        ).map((email) => (props.exclude !== email.objectId ? (
          <ListItem
            button
            onClick={() => handleListItemClick(email.objectId, email)}
            key={email.objectId}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                {email.avatar ? <img alt="agenda" style={{ width: 30 }} src={email.avatar} /> : <PersonIcon />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={email.nombre} secondary={email.correo} />
          </ListItem>
        ) : null))}

        {/* <ListItem autoFocus button onClick={() => handleListItemClick('addAccount')}>
          <ListItemAvatar>
            <Avatar>
              <AddIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Add account" />
        </ListItem> */}
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
