import React, { useState } from 'react';
import {
  required,
  Button,
  SaveButton,
  useCreate,
  useNotify,
  FormWithRedirect,
  FileInput,
  FileField,
  TextInput,
} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import RichTextInput from 'ra-input-rich-text';
import FileIcon from '../static/img/filesicon.png';

function CreatePagoButton(props) {
  // const { values : temporalValues } = useFormState();
  // console.log(temporalValues);
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('notas');
  const notify = useNotify();
  // const form = useForm();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    values.alumnoId = props.alumnoId;
    create(
      { payload: { data: values } },
      {
        onSuccess: () => {
          setShowDialog(false);
          alert('La nota fué guardada');
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          //   form.change('moduloId', data.id);
          // onChange();
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        },
      },
    );
  };

  return (
    <>
      <Button onClick={handleClick} label="Agregar nota">
        <AddCircleIcon />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>Notas</DialogTitle>
        <FormWithRedirect
          resource="notas"
          save={handleSubmit}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving,
          }) => (
            <>
              <DialogContent>
                <TextInput source="Data" type="date" />
                <RichTextInput validate={required()} label="Escriba su comentario" source="notas" />
                <FileInput
                  source="pictures"
                  multiple
                  label="Archivo"
                  placeholder={(
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'transparent',
                      borderColor: '#518F18',
                      borderStyle: 'dashed',
                      borderWidth: 1,
                      borderRadius: 16,
                      width: 260,
                      height: 160,
                    }}
                    >
                      <p>
                        <img src={FileIcon} alt="Archivo" />
                      </p>
                      <p>Arrastre y suelte los archivos Aquí</p>
                    </div>
                              )}
                  accept="image/*, application/*, text/*"
                >
                  <FileField source="src" title="title" />
                </FileInput>
              </DialogContent>
              <DialogActions>
                <Button
                  className="cancelar-btn"
                  label="ra.label.cancelar"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                                  handleSubmitWithRedirect
                              }
                  label="ra.label.salvar"
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default CreatePagoButton;
