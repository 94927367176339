import React, { useEffect } from 'react';
import {
  List,
  TopToolbar,
  Create,
  Edit,
  BooleanInput,
  TextInput,
  TextField,
  EditButton,
  Filter,
  DateField,
  Loading,
  FormDataConsumer,
  SelectInput,
  TabbedForm,
  FormTab,
  RadioButtonGroupInput,
  useListContext,
  Button,
  useDataProvider,
  Pagination,
  ShowButton,
  useShowController,
  useRefresh,
  required,
  FormWithRedirect,
  SaveButton,
  Toolbar,
  DeleteButton,
  RichTextField,
  useTranslate,
  useRedirect,
  useNotify,
} from 'react-admin';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import {
  IconButton, Box, makeStyles,
  Card, CardActions, CardContent, CardHeader,
  Typography,
} from '@material-ui/core';
import Avatar from 'react-avatar-edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
// import readXlsxFile from 'read-excel-file';
// import IconUpload from '@material-ui/icons/CloudUpload';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Country, State, City } from 'country-state-city';
import { useFormState } from 'react-final-form';
import Parse from 'parse';
import LanguageDialog from './componentes/Lang';
import { DateInput } from './DateInput';
import AddImg from './static/img/miniuser@3x.png';
import Telefono from './static/img/telefono.png';
import Whatsapp from './static/img/whatsapp.png';
import VoltarIcon from './static/img/Voltar.png';
import AddIcon from './static/img/adduser.png';
import MiniUser from './static/img/miniuser.png';
import EventCheck from './componentes/EventCheck';
import CreateNota from './componentes/CreateNota';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  table: {
    minWidth: 650,
  },
  sticky: {
    position: 'sticky',
    left: 0,
    backgroundColor: 'white',
    boxShadow: '5px 2px 5px #EAECEE',
    borderRight: '2px solid #EAECEE',
  },
  tabroot: {
    marginTop: 35,
    '& .MuiTabs-scrollButtons': {
      // display: 'none'
      color: '#004AAD',
    },
  },
  tabindctr: { display: 'none' },
  tabitemroot: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 10,
    minWidth: 110,
    maxWidth: 115,
    height: 43,
    fontSize: 14,
    textTransform: 'capitalize',
    marginLeft: 12,
    marginRight: 12,
    borderColor: '#004aad',
    color: '#004aad',
    lineHeight: '14px',
    paddingLeft: 20,
    paddingRight: 20,
  },
  tabitemselected: {
    backgroundColor: '#518F18',
    color: '#FFDF00 !important',
    fontSize: '14px !important',
    '& :hover': {

    },
  },
});

function PostFilter(props) {
  return (
    <Filter {...props}>
      <TextInput variant="outlined" label="ra.label.nome_aluno" source="nombre" alwaysOn />
    </Filter>
  );
}

// function Archivo() {
//   const dataProvider = useDataProvider();
//   const handleNvEnter = async () => {
//     const input = document.getElementById('input');
//     readXlsxFile(input.files[0]).then(async (rows) => {
//       console.log(rows.length);

//       // eslint-disable-next-line no-plusplus
//       for (let i = 1; i < rows.length; i++) {
//         const row = rows[i];
//         console.log(row);
//         const mybody = {};
//         mybody.tipo_alumno = row[1]
//          && row[1].toString().includes('Grupal') ? 'Grupo' : 'Privado';
//         mybody.empresarial = !!(row[1] && row[1].toString().includes('Empresarial'));
//         if (row[2]) mybody.nombre = row[2] && row[2].toString() ? row[2].toString() : '';
//         if (row[3]) mybody.profesion = row[3] && row[3].toString() ? row[3].toString() : '';
//         if (row[4]) mybody.genero = row[4] && row[4].toString() ? row[4].toString() : '';
//         if (row[5]) mybody.correo = row[5] && row[5].toString() ? row[5].toString() : '';
//         if (row[6]) mybody.fecha_nacimiento = row[6]
//          && row[6].toString() ? row[5].toString() : '';
//         if (row[8]) mybody.no_identificacion = row[8]
//          && row[8].toString() ? row[8].toString() : '';
//         if (row[9]) mybody.rut = row[9] && row[9].toString() ? row[9].toString() : '';
//         if (row[11]) mybody.nombre_empresa = row[11]
//          && row[11].toString() ? row[11].toString() : '';

//         await dataProvider.create('alumnos', { data: mybody });
//       }

//       window.location.reload();
//     });
//   };
//   return (
//     <label className="custom-file-upload">
//       <input onChange={handleNvEnter} id="input" type="file" />
//       <Typography style={{ color: '#fff' }} align="center">
//         <IconUpload />
//         {' '}
//         Subir lista
//       </Typography>
//     </label>
//   );
// }

function ListActions() {
  return (
    <TopToolbar>
      {/* Add your custom actions */}
      <Button href="#/alumnos/create" label="" style={{ position: 'absolute', right: 0, top: 0 }}>
        <img
          alt="Agregar"
          src={AddIcon}
          style={{
            width: 50,
          }}
        />
      </Button>
      {/* <Archivo /> */}
    </TopToolbar>
  );
}

export function AlunoList(props) {
  return (
    <List
      {...props}
      sort={{ field: 'nombre', order: 'ASC' }}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[100, 150, 200]} {...props} />}
      filters={<PostFilter />}
      exporter={false}
      actions={<ListActions />}
      bulkActionButtons={false}
    >
      <AlumnoGrid />
    </List>
  );
}

const cardPagoStyle = {
  // width: 288,
  minHeight: 97,
  margin: '0.5em',
  display: 'block',
  verticalAlign: 'top',
};

const cardStyle = {
  width: 288,
  minHeight: 280,
  margin: '0.5em',
  display: 'inline-block',
  verticalAlign: 'top',
};
const cardStyleShow = {
  minWidth: 288,
  margin: '0.5em',
  display: 'inline-block',
  verticalAlign: 'top',
};

const titleStyle = {
  color: '#004AAD',
  
  fontSize: 18,
  fontWeight: 'bold',
  textTransform: 'capitalize',
};
const title2Style = {
  color: '#004AAD',
  
  fontSize: 16,
};
function AlumnoGrid() {
  const { ids, data, basePath } = useListContext();

  return (
    <div style={{ margin: '1em' }}>
      {ids.map((id, index) => {
        /**
         * Reviso el cambio de letra
         */
        const idAnterior = ids[index - 1];
        if (!data[idAnterior]
          || (
            data[idAnterior]
            && data[id].nombre.charAt(0) !== data[idAnterior].nombre.charAt(0)
          )
        ) {
          return (
            <>
              <div id={`title${id}`} style={{ display: 'block', padding: 10 }}>
                <Typography variant="h4">{data[id].nombre.charAt(0)}</Typography>
              </div>
              <Card variant="outlined" key={id} style={cardStyle} className={data[id] && data[id].avatar ? 'card-alunos-a' : 'card-alunos-b'}>
                <CardContent>
                  <TextField style={titleStyle} record={data[id]} source="nombre" />
                </CardContent>
                <CardHeader
                  style={{ paddingTop: 25, paddingLeft: 45 }}
                  title={
                        data[id].nombre_empresa
                          ? <TextField style={title2Style} record={data[id]} source="nombre_empresa" />
                          : <TextField style={title2Style} record={data[id]} source="tipo_alumno" />
}
                  subheader={<DateField record={data[id]} source="created_at" />}
                  avatar={<img style={{ width: 72 }} alt="" src={data[id] && data[id].avatar} />}
                />
                <CardContent>
                  {data[id].celular && (
                  <>
                    <img alt="whatsapp" src={Whatsapp} />
                    <a target="_blank" href={`https://wa.me/${data[id].celular ? data[id].celular.split('+').join('') : null}`} rel="noreferrer">
                      <TextField style={titleStyle} record={data[id]} source="celular" />
                    </a>
                    <br />
                  </>
                  )}
                  {data[id].telefono && (
                  <>
                    <img alt="telefono" src={Telefono} />
                    <a href={`tel:${data[id].celular}`}>
                      <TextField style={titleStyle} record={data[id]} source="telefono" />
                    </a>
                  </>
                  )}
                </CardContent>
                <CardActions style={{ textAlign: 'right' }}>
                  <EditButton label="ra.label.editar" resource="posts" basePath={basePath} record={data[id]} />
                  <ShowButton resource="cursos" basePath={basePath} record={data[id]} label="ra.label.desempenho" />
                </CardActions>
              </Card>
            </>
          );
        }

        return (
          <Card variant="outlined" key={id} style={cardStyle} className={data[id] && data[id].avatar ? 'card-alunos-a' : 'card-alunos-b'}>
            <CardContent>
              <TextField style={titleStyle} record={data[id]} source="nombre" />
            </CardContent>
            <CardHeader
              style={{ paddingTop: 25, paddingLeft: 45 }}
              title={
                    data[id].nombre_empresa
                      ? <TextField style={title2Style} record={data[id]} source="nombre_empresa" />
                      : <TextField style={title2Style} record={data[id]} source="tipo_alumno" />
}
              subheader={<DateField record={data[id]} source="created_at" />}
              avatar={<img style={{ width: 72 }} alt="" src={data[id] && data[id].avatar} />}
            />
            <CardContent>
              {data[id].celular && (
                <>
                  <img alt="whatsapp" src={Whatsapp} />
                  <a target="_blank" href={`https://wa.me/${data[id].celular ? data[id].celular.split('+').join('') : null}`} rel="noreferrer">
                    <TextField style={titleStyle} record={data[id]} source="celular" />
                  </a>
                  <br />
                </>
              )}
              {data[id].telefono && (
                <>
                  <img alt="telefono" src={Telefono} />
                  <a href={`tel:${data[id].celular}`}>
                    <TextField style={titleStyle} record={data[id]} source="telefono" />
                  </a>
                </>
              )}
            </CardContent>
            <CardActions style={{ textAlign: 'right' }}>
              <EditButton label="ra.label.editar" resource="posts" basePath={basePath} record={data[id]} />
              <ShowButton resource="cursos" basePath={basePath} record={data[id]} label="ra.label.desempenho" />
            </CardActions>
          </Card>
        );
      })}
      <ul style={{
        textAlign: 'center',
        position: 'fixed',
        right: 80,
        display: 'block',
        width: 'auto',
        height: 'auto',
        top: '50%',
        transform: 'translateY(-50%)',
        textRendering: 'optimizeLegibility',
      }}
      >
        {ids.map((id, index) => {
          /**
           * Reviso el cambio de letra
           */
          const idAnterior = ids[index - 1];
          if (!data[idAnterior]
            || (
              data[idAnterior]
                && data[id].nombre.charAt(0) !== data[idAnterior].nombre.charAt(0)
            )
          ) {
            return (
              <li style={{ marginBottom: 5, textTransform: 'uppercase' }}>
                {data[id].nombre.charAt(0)}
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
}

export function AlunoCreate(props) {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const notify = useNotify();
  const onSuccess = ({ data }) => {
    console.log(data);
    console.log(props);
    dataProvider.getOne('alumnos', { id: data.id })
      .then(({ data: record }) => {
        console.log(record);
        const hoy = new Date(record.fecha_nacimiento);
        dataProvider.update('alumnos', { id: data.id, data: { cumpleanos: `${hoy.getDate()}/${(hoy.getMonth() + 1)}` } })
          .then(() => {
            notify('ra.notification.created', { messageArgs: { smart_count: 1 } });
            redirect(`/alumnos/${data.id}`);
          });
      });
  };
  return (
    <Create {...props} onSuccess={onSuccess}>
      <TabbedForm toolbar={<CustomToolbar />} redirect="list">
        <FormTab label="ra.label.perfil">
          <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
            <img src={VoltarIcon} alt="Voltar" />
          </Button>
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={0.3}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <img alt="Agregar" src={AddImg} />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <TextInput label="ra.label.nome" variant="outlined" fullWidth source="nombre" />
              <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
                <Box
                  flex={1}
                  mr={{ xs: 0, sm: '0.5em' }}
                >
                  <TextInput label="ra.label.celular" variant="outlined" source="celular" />
                </Box>
                <Box
                  flex={1}
                  ml={{ xs: 0, sm: '0.5em' }}
                >
                  <TextInput label="ra.label.telefone" variant="outlined" source="telefono" />
                </Box>
              </Box>
            </Box>
          </Box>
          <TextInput label="ra.label.email" variant="outlined" fullWidth source="correo" />
          <TextInput label="ra.label.profissao" variant="outlined" source="profesion" />
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={0.5}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <SelectInput
                fullWidth
                variant="outlined"
                source="doc_type"
                label="ra.label.doc_ident"
                choices={[
                  { id: 'CPF', name: 'ra.label.cpf' },
                  { id: 'Passaporte', name: 'ra.label.passaporte' },
                  { id: 'Ced_RG', name: 'ra.label.rg' },
                  { id: 'Ced_RGE', name: 'ra.label.rge' },
                  { id: 'SSN', name: 'ra.label.ssn' },
                ]}
              />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <TextInput fullWidth variant="outlined" source="no_identificacion" label="ra.label.n_identificacao" />
            </Box>
          </Box>
          <DateInput variant="outlined" source="fecha_nacimiento" label="ra.label.data_nascimento" openTo="year" views={['year', 'month', 'date']} />
          <SelectInput
            variant="outlined"
            source="genero"
            label="ra.label.genero"
            choices={[
              { id: 'Femenino', name: 'ra.label.feminino' },
              { id: 'Masculino', name: 'ra.label.masculino' },
              { id: 'Transgénero', name: 'ra.label.transgenero' },
              { id: 'Otro', name: 'ra.label.outro' },
            ]}
          />
          <RadioButtonGroupInput
            label="ra.label.tipo_aluno"
            source="tipo_alumno"
            choices={[
              { id: 'Privado', name: 'ra.label.privado' },
              { id: 'Grupo', name: 'ra.label.empresa' },
            ]}
          />
          <BooleanInput Label="ra.label.empresarial" source="empresarial" />
          <FormDataConsumer>
            {({ formData }) => formData.empresarial
                && <TextInput variant="outlined" source="nombre_empresa" label="ra.label.nome_empresa" />}
          </FormDataConsumer>
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              {/* <TextInput fullWidth label='País' variant='outlined' source="pais" /> */}
              <SelectInput
                fullWidth
                variant="outlined"
                label="ra.label.pais"
                optionText={(choice) => `${choice.flag} ${choice.name}`}
                optionValue="isoCode"
                source="pais"
                choices={Country.getAllCountries()}
              />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <SelectInput
                    fullWidth
                    label="ra.label.estado"
                    source="distrito"
                    variant="outlined"
                    optionValue="isoCode"
                    choices={
                          formData.pais
                            ? State.getStatesOfCountry(formData.pais)
                            : []
                      }
                    {...rest}
                  />
                )}
              </FormDataConsumer>
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <SelectInput
                    fullWidth
                    variant="outlined"
                    label="ra.label.cidade"
                    source="ciudad"
                    optionValue="name"
                    choices={
                          formData.distrito
                            ? City.getCitiesOfState(formData.pais, formData.distrito)
                            : []
                      }
                    {...rest}
                  />
                )}
              </FormDataConsumer>
            </Box>
          </Box>
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <TextInput fullWidth variant="outlined" label="ra.label.endereco" source="direccion" />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            />
          </Box>
        </FormTab>
        <FormTab label="ra.label.pagamentos">
          <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
            <img src={VoltarIcon} alt="Volver" />
          </Button>
        </FormTab>
      </TabbedForm>
    </Create>
  );
}

function PostTitle({ record }) {
  return (
    <span style={{ fontSize: 28 }}>
      Alumno:
      {record.nombre}
    </span>
  );
}

function PostEditActions({ data }) {
  return (
    <TopToolbar>
      {/* Add your custom actions */}
      {data && data.objectId && <Button color="primary" href={`#/alumnos/${data.objectId}/show`} label="Ver " />}
    </TopToolbar>
  );
}
function CustomToolbar(props) {
  return (
    <Toolbar {...props}>
      <SaveButton label="ra.label.salvar" />
      {localStorage.getItem('perfil')
      && localStorage.getItem('perfil') !== 'alumno'
      && localStorage.getItem('perfil') !== 'profesor' && <DeleteButton label="ra.label.remover" undoable={false} />}
    </Toolbar>
  );
}
export function AlunoEdit(props) {
  const [open, setOpen] = React.useState(false);
  const dataProvider = useDataProvider();
  const [imagen, setImagen] = React.useState(null);
  const [preview, setPreview] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const inputFile = React.useRef(null);
  const soyAlumno = localStorage.getItem('alumno') ? JSON.parse(localStorage.getItem('alumno')) : null;
  const redirect = useRedirect();
  const notify = useNotify();

  function onChangeFile(event) {
    setLoading(true);
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    console.log(file);
    /**
     * Subo la imagen y después si la edito
     */
    Parse.User.logIn('crobinson', 'crobinson')
      .then(() => {
      //  console.log(user)
        const reader = new FileReader();
        //  console.log(file)
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const base64 = event.target.result;
          const parseFile = new Parse.File(file.name, { base64 });
          parseFile.save().then(() => {
            console.log(parseFile.url());
            setImagen(parseFile.url());
            setLoading(false);
            //  resolve(parseFile.url().replace("http://localhost:1337", "https://interactiva.portuguesplus.com"));
          }, (error) => {
            console.log(error);
            setLoading(false);
            //  return resolve(error);
          });
        };

        reader.onerror = (error) => console.log(error);
      });
  }

  useEffect(() => {
    dataProvider.getOne(props.resource, { id: props.id })
      .then(({ data: record }) => {
        if (record && record.avatar) {
          setPreview(record.avatar);
        }
      });
  }, []);

  useEffect(() => {
    // console.log('entro');
    if (imagen) {
      setOpen(true);
      setLoading(false);
    }
  }, [imagen]);

  function onClose() {
    setPreview(null);
  }

  function onCrop(preview) {
    setPreview(preview);
  }

  function onSavePreview() {
    console.log(preview);
    console.log(props);
    dataProvider.update(props.resource, { id: props.id, data: { avatar: preview } })
      .then(() => setOpen(false));
  }

  function onBeforeFileLoad(elem) {
    if (elem.target.files[0].size > 71680) {
      alert('Archivo demasiado grande!');
      // eslint-disable-next-line no-param-reassign
      elem.target.value = '';
    }
  }

  if (loading) return <Loading loadingPrimary="Espere" loadingSecondary="Preparando el editor de fotos..." />;

  const onSuccess = () => {
    dataProvider.getList('alumnos', { filter: { objectId: props.id } })
      .then(({ data }) => {
        const record = data[0];
        const hoy = new Date(record.fecha_nacimiento);
        dataProvider.update('alumnos', { id: props.id, data: { cumpleanos: `${hoy.getDate()}/${(hoy.getMonth() + 1)}` } })
          .then(() => {
            notify('ra.notification.created', { messageArgs: { smart_count: 1 } });
            redirect(`/alumnos/${props.id}`);
          });
      });
  };

  return (
    <Edit onSuccess={onSuccess} title={<PostTitle />} actions={<PostEditActions />} undoable={false} {...props}>
      <TabbedForm toolbar={<CustomToolbar />} redirect="edit">
        <FormTab label="ra.label.perfil">
          <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
            <img src={VoltarIcon} alt="Volver" />
          </Button>
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={0.2}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              {!preview
                ? (
                  <img
                    alt="Agregar"
                    src={AddImg}
                    onClick={() => {
                      if (soyAlumno && soyAlumno.objectId === props.id) {
                        inputFile.current.click();
                      }
                    }}
                    style={{ cursor: 'pointer', width: 100 }}
                  />
                ) : (
                  <img src={preview} onClick={() => inputFile.current.click()} style={{ cursor: 'pointer', width: 100 }} alt="Preview" />
                )}
              <input
                onChange={(e) => onChangeFile(e)}
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: 'none' }}
              />
              <Dialog onClose={() => setOpen(false)} aria-labelledby="simple-dialog-title" open={open}>
                <Avatar
                  width={390}
                  height={295}
                  onCrop={onCrop}
                  onClose={onClose}
                  onBeforeFileLoad={onBeforeFileLoad}
                  src={imagen}
                />
                <DialogActions>
                  <IconButton onClick={onSavePreview} color="primary">
                    <SaveIcon style={{ fontSize: 20 }} />
                  </IconButton>
                </DialogActions>
              </Dialog>
            </Box>
            {soyAlumno && soyAlumno.objectId === props.id ? (
              <Box
                flex={0.3}
                mr={{ xs: 0, sm: '0.5em' }}
              >
                <LanguageDialog />
              </Box>
            ) : null}
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <TextInput label="ra.label.nome" variant="outlined" fullWidth source="nombre" />
              <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
                <Box
                  flex={1}
                  mr={{ xs: 0, sm: '0.5em' }}
                >
                  <TextInput label="ra.label.celular" variant="outlined" source="celular" />
                </Box>
                <Box
                  flex={1}
                  ml={{ xs: 0, sm: '0.5em' }}
                >
                  <TextInput label="ra.label.telefone" variant="outlined" source="telefono" />
                </Box>
              </Box>
            </Box>
          </Box>
          <TextInput label="ra.label.email" variant="outlined" fullWidth source="correo" />
          <TextInput variant="outlined" source="profesion" label="ra.label.profissao" />
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={0.5}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <SelectInput
                fullWidth
                variant="outlined"
                source="doc_type"
                label="ra.label.doc_ident"
                choices={[
                  { id: 'CPF', name: 'ra.label.cpf' },
                  { id: 'Passaporte', name: 'ra.label.passaporte' },
                  { id: 'Ced_RG', name: 'ra.label.rg' },
                  { id: 'Ced_RGE', name: 'ra.label.rge' },
                  { id: 'SSN', name: 'ra.label.ssn' },
                ]}
              />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <TextInput fullWidth variant="outlined" source="no_identificacion" label="ra.label.n_identificacao" />
            </Box>
          </Box>
          <DateInput variant="outlined" source="fecha_nacimiento" label="ra.label.data_nascimento" openTo="year" views={['year', 'month', 'date']} />
          <SelectInput
            variant="outlined"
            source="genero"
            label="ra.label.genero"
            choices={[
              { id: 'Femenino', name: 'ra.label.feminino' },
              { id: 'Masculino', name: 'ra.label.masculino' },
              { id: 'Transgénero', name: 'ra.label.transgenero' },
              { id: 'Otro', name: 'ra.label.outro' },
            ]}
          />
          {!soyAlumno || (soyAlumno && soyAlumno.objectId !== props.id) ? (
            <>
              <RadioButtonGroupInput
                label="ra.label.tipo_aluno"
                source="tipo_alumno"
                choices={[
                  { id: 'Privado', name: 'Privado' },
                  { id: 'Grupo', name: 'Grupo' },
                ]}
              />
              <BooleanInput source="empresarial" />
            </>
          ) : null}
          <FormDataConsumer>
            {({ formData }) => formData.empresarial
                && <TextInput variant="outlined" source="nombre_empresa" label="ra.label.nome_empresa" />}
          </FormDataConsumer>
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              {/* <TextInput fullWidth label='País' variant='outlined' source="pais" /> */}
              <SelectInput
                fullWidth
                variant="outlined"
                label="ra.label.pais"
                optionText={(choice) => `${choice.flag} ${choice.name}`}
                optionValue="isoCode"
                source="pais"
                choices={Country.getAllCountries()}
              />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <SelectInput
                    fullWidth
                    label="ra.label.estado"
                    source="distrito"
                    variant="outlined"
                    optionValue="isoCode"
                    choices={
                          formData.pais
                            ? State.getStatesOfCountry(formData.pais)
                            : []
                      }
                    {...rest}
                  />
                )}
              </FormDataConsumer>
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <SelectInput
                    fullWidth
                    variant="outlined"
                    label="ra.label.cidade"
                    source="ciudad"
                    optionValue="name"
                    choices={
                          formData.distrito
                            ? City.getCitiesOfState(formData.pais, formData.distrito)
                            : []
                      }
                    {...rest}
                  />
                )}
              </FormDataConsumer>
            </Box>
          </Box>
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <TextInput fullWidth variant="outlined" label="ra.label.endereco" source="direccion" />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            />
          </Box>
        </FormTab>
        {/* <FormTab label="ra.label.pagamentos">
          <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
            <img src={VoltarIcon} alt="Voltar" />
          </Button>
          <MisDatosCobro {...props} />
        </FormTab> */}
      </TabbedForm>
    </Edit>
  );
}

// eslint-disable-next-line no-unused-vars
function MisDatosCobro() {
  const dataProvider = useDataProvider();
  const { values: temporalValues } = useFormState();
  const [value, setValue] = React.useState('todas');
  const [myItems, setMyItems] = React.useState([]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  async function fetchFinanciero() {
    const { data: financieroAll } = await dataProvider.getList('financiero', { filter: { alumnoId: temporalValues.objectId } });
    let filtrados = [];
    const misPagos = financieroAll.map((item) => item.objectId);
    console.log(misPagos);
    const { data: pagos } = await dataProvider.getList('pagos', { filter: { financieroId: { $in: misPagos } } });
    const output = pagos.reduce((acc, curr) => {
      acc[curr.financieroId] = acc[curr.financieroId] || 0;
      acc[curr.financieroId] += curr.valor;
      return acc;
    }, Object.create(null));
    console.log(output); // El output contiene los totales

    if (value === 'por_pagar') {
      /** Veo si cada output tiene su total? */
      for (const k of output) {
        const financieroOne = financieroAll.find((item) => item.objectId === k);
        console.log(financieroOne, output[k]);
        if (financieroOne && financieroOne.valor > output[k]) { filtrados.push(financieroOne); }
      }

      let tomorrow = new Date();
      tomorrow.setHours(0, 0, 0, 0);
      tomorrow = tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow = new Date(tomorrow).toISOString();

      filtrados = filtrados.filter((item) => item.fecha_vencimiento < tomorrow.split('T')[0]);
    }

    if (value === 'pagadas') {
      /** Veo si cada output tiene su total? */
      for (const k of output) {
        const financieroOne = financieroAll.find((item) => item.objectId === k);
        console.log(financieroOne, output[k]);
        if (financieroOne && financieroOne.valor <= output[k]) { filtrados.push(financieroOne); }
      }
    }

    if (value === 'todas') { filtrados = financieroAll; }

    setMyItems(filtrados);
  }

  useEffect(() => {
    fetchFinanciero();
  }, []);

  useEffect(() => {
    fetchFinanciero();
  }, [value]);

  return (
    <>
      <FormControl component="fieldset">
        <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
          <FormControlLabel value="todas" control={<Radio />} label="ra.label.todas" />
          <FormControlLabel value="por_vencer" control={<Radio />} label="ra.label.a_vencer" />
          <FormControlLabel value="vencidas" control={<Radio />} label="ra.label.vencidas" />
          <FormControlLabel value="pagadas" control={<Radio />} label="ra.label.pagas" />
        </RadioGroup>
      </FormControl>
      {myItems.map((item, index) => <MiPago item={item} index={index} />)}
    </>
  );
}

function Estado(item) {
  const dataProvider = useDataProvider();
  const [estado, setEstado] = React.useState('');

  async function getPago() {
    const { data: pagos } = await dataProvider.getList('pagos', { filter: { financieroId: item.objectId } });

    if (!pagos || pagos.length === 0) { setEstado('Por pagar'); }

    if (pagos || pagos.length > 0) {
      const output = pagos.reduce((acc, curr) => {
        acc[curr.financieroId] = acc[curr.financieroId] || 0;
        acc[curr.financieroId] += curr.valor;
        return acc;
      }, Object.create(null));

      if (item && item.valor >= output[item.objectId]) { setEstado('Por pagar'); } else { setEstado('Pagada'); }

      let tomorrow = new Date();
      tomorrow.setHours(0, 0, 0, 0);
      tomorrow = tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow = new Date(tomorrow).toISOString();

      if (item.fecha_vencimiento < tomorrow.split('T')[0]) {
        setEstado('Vencida');
      }
    }
  }

  useEffect(() => getPago(), []);

  return (
    <span style={{
      border: '1px solid #518F18', padding: 5, borderRadius: 10, display: 'block', width: '-webkit-max-content',
    }}
    >
      {estado}
    </span>
  );
}

function MiPago({ item }) {
  return (
    <Card key={item.objectId} style={cardPagoStyle}>
      <CardContent style={{ flexDirection: 'column', display: 'flex' }}>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <TextField className="list-title-blue" record={item} source="observaciones" />
        </div>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <div style={{ flex: 1 }} />
          <div>
            <PriceField className="list-title-blue" record={item} source="valor" />
          </div>
        </div>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <Estado item={item} />
          </div>
          <div>
            <Typography>
              Vence em:
              <DateField className="list-title-blue" record={item} source="fecha_vencimiento" />
            </Typography>
          </div>
        </div>
        {/* <span>{JSON.stringify(item)}</span> */}
      </CardContent>
    </Card>

  );
}

export function AlunoShow(props) {
  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
    basePath,
  } = useShowController(props);
  const [loading, setLoading] = React.useState(true);
  const dataProvider = useDataProvider();
  const [cursos, setCursos] = React.useState([]);
  const [modulos, setModulos] = React.useState([]);
  const perfil = localStorage.getItem('perfil') || null;

  async function loadDesempeno() {
    /**
     * Busco los cursos del usuario
     */
    const { data: cursosQry } = await dataProvider.getList(
      'cursos',
      {
        filter: { alumnos: props.id },
        sort: { field: 'nombre', order: 'DESC' },
      },
    );
    console.log(cursosQry);
    setCursos(cursosQry);

    const { data: modulosQry } = await dataProvider.getList(
      'modulos',
      {
        filter: {},
        sort: { field: 'nombre', order: 'DESC' },
      },
    );
    console.log(modulosQry);
    setModulos(modulosQry);

    setLoading(false);
  }
  useEffect(() => {
    loadDesempeno();
  }, []);

  if (loading) { return <Loading loadingPrimary="Espere" loadingSecondary="Carregando dados de desempenho." />; }

  return (
    <Paper style={{ padding: 30, display: 'flex', flexDirection: 'column' }}>
      <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
        <img src={VoltarIcon} alt="Voltar" />
      </Button>
      <Card variant="outlined" key={record.objectId} style={cardStyleShow}>
        <CardContent>
          <TextField style={titleStyle} record={record} source="nombre" />
        </CardContent>
        <CardHeader
          title={
                  record.nombre_empresa
                    ? <TextField style={title2Style} record={record} source="nombre_empresa" />
                    : <TextField style={title2Style} record={record} source="tipo_alumno" />
}
          subheader={<DateField record={record} source="created_at" />}
          avatar={<img alt="Usuario" src={MiniUser} />}
        />
        <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
          <Box
            flex={10}
            mr={{ xs: 0, sm: '0.5em' }}
          >
            {record.celular && record.telefono && (
              <CardContent>
                {record.celular && (
                <>
                  <img alt="whatsapp" src={Whatsapp} />
                  <a target="_blank" href={`https://wa.me/${record.celular ? record.celular.split('+').join('') : null}`} rel="noreferrer">
                    <TextField style={titleStyle} record={record} source="celular" />
                  </a>
                  <br />
                </>
                )}
                {record.telefono && (
                <>
                  <img alt="Telefono" src={Telefono} />
                  <a href={`tel:${record.celular}`}>
                    <TextField style={titleStyle} record={record} source="telefono" />
                  </a>
                </>
                )}
              </CardContent>
            )}
          </Box>
          <Box
            flex={1}
            ml={{ xs: 0, sm: '0.5em' }}
          >
            {perfil && perfil !== 'alumno' && (
              <CardContent style={{ textAlign: 'right' }}>
                <EditButton variant="contained" color="primary" label="ra.label.editar" resource="posts" basePath={basePath} record={record} />
              </CardContent>
            )}
          </Box>
        </Box>
      </Card>
      {cursos.length && modulos.length && <ScrollableTabsButtonAuto key={`key-${cursos.length}`} cursos={cursos} modulos={modulos} record={record} />}
    </Paper>
  );
}

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function ScrollableTabsButtonAuto(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" elevation={0} style={{ backgroundColor: 'white', border: 'none' }}>
        <Tabs
          className="alunoPills"
          classes={{
            root: classes.tabroot, // class name, e.g. `classes-nesting-root-x`
            indicator: classes.tabindctr,
          }}
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {props.cursos && props.cursos.map((curso, index) => {
            const modulo = props.modulos.find((item) => item.objectId === curso.moduloId);
            return <Tab className="tabPill" classes={{ root: classes.tabitemroot, selected: classes.tabitemselected }} key={`cat_${modulo.objectId}-${curso.objectId}`} label={`${modulo.name}-${curso.nombre}`} {...a11yProps(index)} />;
          })}
        </Tabs>
      </AppBar>
      {props.cursos
        && props.cursos.map((curso, index) => (
          <MyPanel record={props.record} curso={curso} value={value} index={index} />
        ))}
    </div>
  );
}

const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

function MyPanel(props) {
  const { format } = require('date-fns');
  const classes = useStyles();
  const {
    index, value, curso, record,
  } = props;
  const dataProvider = useDataProvider();
  const [tipoEvaluaciones, setTipoEvaluaciones] = React.useState([]);
  const [docs, setDocs] = React.useState([]);
  const [notas, setNotas] = React.useState([]);
  const [eventos, setEventos] = React.useState([]);
  // const [asistencias, setAsistencias] = React.useState([]);
  // const [loading, setLoading] = React.useState(true);

  async function loadEval() {
    /**
     * Busco los cursos del usuario
     */
    const { data: cursosQry } = await dataProvider.getList('tipo_evaluacion', { filter: {} });
    console.log(cursosQry);
    setTipoEvaluaciones(cursosQry);
    if (props.record.objectId) {
      const { data: notasQry } = await dataProvider.getList('notas', { filter: { alumnoId: props.record.id } });
      setNotas(notasQry);
    }
  }

  async function loadDocs() {
    /**
     * Busco los docs del usuario
     */
    // setLoading(true);
    if (props.record.usuarioId) {
      const { data: cursosQry } = await dataProvider.getList('alumno_documentos', { filter: { alumnoId: props.record.usuarioId } });
      setDocs(cursosQry);
    } else {
      setDocs([]);
    }

    console.log(props);
    // setAsistencias(asistenciasQry);
    const { data: cursoEventos } = await dataProvider.getList(
      'eventos',
      {
        filter: { cursoId: props.curso.objectId },
        sort: { field: 'fecha_inicio', order: 'ASC' },
        pagination: { page: 1, perPage: 50000 },
      },
    );

    const perMes = cursoEventos.reduce((acc, curr) => {
      const inicio = new Date(curr.fecha_inicio);
      acc[inicio.getMonth()] = acc[inicio.getMonth()] || [];
      acc[inicio.getMonth()].push(curr);
      return acc;
    }, Object.create(null));
    console.log(perMes);
    // alert(JSON.stringify(cursoEventos))
    setEventos(perMes);
  }
  useEffect(() => {
    loadEval();
    loadDocs();
  }, []);

  const translate = useTranslate();
  return (
    <TabPanel value={value} index={index}>
      <Typography style={{ marginBottom: 30, marginTop: 20 }}>{translate('ra.label.avaliacao')}</Typography>
      <TableContainer style={{ maxWidth: 'calc(100vw - 300px)' }}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{
                color: '#004AAD',
                fontWeight: 'bold',
                minWidth: 50,
              }}
              >
                {translate('ra.label.data')}
              </TableCell>
              <TableCell style={{
                color: '#004AAD',
                fontWeight: 'bold',
                minWidth: 50,
              }}
              >
                {translate('ra.label.tipo_avaliacao')}
              </TableCell>
              <TableCell style={{
                color: '#004AAD',
                fontWeight: 'bold',
                minWidth: 50,
              }}
              >
                {translate('ra.label.peso_avaliacao')}
              </TableCell>
              <TableCell style={{
                color: '#004AAD',
                fontWeight: 'bold',
                minWidth: 250,
              }}
              >
                {translate('ra.label.observacoes')}
              </TableCell>
              <TableCell style={{
                color: '#004AAD',
                fontWeight: 'bold',
                minWidth: 50,
              }}
              >
                {translate('ra.label.nota')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {curso
            && curso.evaluaciones
            && curso.evaluaciones.map((actividad, index, allEvaluaciones) => (
              <MyRow
                tipoEvaluaciones={tipoEvaluaciones}
                actividad={actividad}
                allEvaluaciones={allEvaluaciones}
                index={index}
                record={record}
                curso={curso}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography style={{ marginBottom: 30, marginTop: 20 }}>{translate('ra.label.documentos')}</Typography>
      <TableContainer style={{ maxWidth: 'calc(100vw - 300px)' }}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{
                color: '#004AAD',
                fontWeight: 'bold',
                minWidth: 50,
              }}
              >
                {translate('ra.label.ultima_atualizacao')}
              </TableCell>
              <TableCell style={{
                color: '#004AAD',
                fontWeight: 'bold',
                minWidth: 50,
              }}
              >
                {translate('ra.label.documento')}
              </TableCell>
              <TableCell style={{
                color: '#004AAD',
                fontWeight: 'bold',
                minWidth: 50,
              }}
              >
                {translate('ra.label.abrir')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {docs.map((item, index) => <MyDocRow doc={item} index={index} />)}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography style={{ marginBottom: 30, marginTop: 20 }}>{translate('ra.label.presenca')}</Typography>
      <TableContainer style={{ maxWidth: 'calc(100vw - 300px)' }}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {Object.keys(eventos).map((mes) => (
                <TableCell style={{
                  color: '#004AAD',
                  verticalAlign: 'top',
                }}
                >
                  {meses[mes]}
                  <>
                    {eventos[mes].map((item) => (
                      <p>
                        <div style={{ display: 'flex' }}>
                          <Typography variant="caption">{format(new Date(item.fecha_inicio), 'EEEE do')}</Typography>
                          <EventCheck
                            disabled={localStorage.getItem('perfil') === 'alumno'}
                            evento={item}
                            alumno={props.record}
                            curso={curso}
                          />
                        </div>
                      </p>
                    ))}
                  </>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <div style={{
        display: 'flex', justifyContent: 'space-between', marginBottom: 30, marginTop: 20,
      }}
      >
        <Typography>{translate('ra.label.documentos_pareceres')}</Typography>
        <CreateNota alumnoId={props.record.objectId} />
      </div>
      <TableContainer style={{ maxWidth: 'calc(100vw - 300px)' }}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{
                color: '#004AAD',
                fontWeight: 'bold',
                minWidth: 50,
              }}
              >
                {translate('ra.label.data')}
              </TableCell>
              <TableCell style={{
                color: '#004AAD',
                fontWeight: 'bold',
                minWidth: 50,
              }}
              >
                {translate('ra.label.notas')}
              </TableCell>
              {/* <TableCell style={{
                color: '#004AAD',
                fontWeight: 'bold',
                minWidth: 50,
              }}
              >
                {translate('ra.label.documentos')}
              </TableCell>
              <TableCell style={{
                color: '#004AAD',
                fontWeight: 'bold',
                minWidth: 50,
              }}
              >
                {translate('ra.label.abrir')}
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {notas.map((item) => (
              <TableRow>
                <TableCell>
                  {item.createdAt}
                </TableCell>
                <TableCell>
                  <div dangerouslySetInnerHTML={{ __html: item.notas }} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TabPanel>
  );
}

function MyDocRow(props) {
  const { doc } = props;
  const [showDialog, setShowDialog] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = React.useState(false);
  const { format } = require('date-fns');

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  return (
    <TableRow key={`documento${doc.objectId}`}>
      <TableCell className="desempeno">{format(new Date(doc.updatedAt), 'dd-MM-yyyy HH:mm')}</TableCell>
      <TableCell className="desempeno">{doc.title}</TableCell>
      <TableCell className="desempeno">
        <IconButton onClick={handleClick} color="primary"><VisibilityIcon style={{ fontSize: 20 }} /></IconButton>
      </TableCell>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle />
        {/* <Typography>Puedes agregar observaciones relacionadas a la asistencia</Typography> */}
        <DialogContent>
          <RichTextField record={doc} source="body" />
          {/* <div dangerouslySetInnerHTML={{ __html: doc.body }} /> */}
        </DialogContent>
        <DialogActions>
          <Button
            label="ra.label.data"
            onClick={handleCloseClick}
            disabled={loading}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </TableRow>
  );
}

function MyRow(props) {
  const dataProvider = useDataProvider();
  const {
    actividad, record, tipoEvaluaciones, index, allEvaluaciones, curso,
  } = props;
  const tipo = actividad.tipoevaluacionId
    ? tipoEvaluaciones.find((item) => item.objectId === actividad.tipoevaluacionId) : null;
  const [showDialog, setShowDialog] = React.useState(false);
  const [selected, setSelected] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const refresh = useRefresh();
  // console.log(actividad)
  // console.log(record)
  React.useEffect(() => {
    if (actividad && actividad.calificaciones && actividad.calificaciones.length) {
      dataProvider.getList('calificaciones', {
        filter: {
          objectId: { $in: actividad.calificaciones },
          alumnoId: record.objectId,
        },
      }).then(({ data }) => {
        if (data && data.length) {
          console.log(data);
          setSelected(data[0]);
        } else setSelected(null);
      });
    }
  }, []);

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleDelete = async () => {
    await dataProvider.delete('calificaciones', { id: selected.objectId });
    refresh();
    handleCloseClick();
  };

  const handleSubmit = async (values) => {
    console.log(values, props);
    setLoading(true);
    if (selected) {
      await dataProvider.update('calificaciones', { id: selected.objectId, data: values });
    } else {
      const { data: nuevaCalificacion } = await dataProvider.create('calificaciones', {
        data: {
          ...values,
          alumnoId: record.objectId,
          cursoId: curso.objectId,
        },
      });

      let calificaciones = [nuevaCalificacion.id];
      if (allEvaluaciones[index] && allEvaluaciones[index].calificaciones) {
        calificaciones = [
          ...allEvaluaciones[index].calificaciones,
          nuevaCalificacion.id,
        ];
      }

      allEvaluaciones[index].calificaciones = calificaciones;

      await dataProvider.update('cursos', {
        id: curso.objectId,
        data: {
          evaluaciones: allEvaluaciones,
        },
      });
    }
    setLoading(false);
    refresh();
    handleCloseClick();
  };
  const translate = useTranslate();
  return (
    <TableRow key={actividad[`${record.objectId}`]
      ? actividad[`${record.objectId}`].nota
      : record.objectId}
    >
      <TableCell className="desempeno">{actividad.fecha}</TableCell>
      <TableCell className="desempeno">{tipo ? tipo.name : ''}</TableCell>
      <TableCell className="desempeno">{actividad.peso_evaluacion}</TableCell>
      <TableCell className="desempeno">{actividad.observaciones}</TableCell>
      <TableCell className="desempeno">
        {selected ? selected.calificacion : ''}
        {localStorage.getItem('perfil') !== 'alumno' && <IconButton onClick={handleClick} color="primary"><EditIcon style={{ fontSize: 20 }} /></IconButton>}
        {selected && <IconButton onClick={handleDelete} color="primary"><DeleteForeverIcon style={{ fontSize: 20 }} /></IconButton>}
      </TableCell>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>
          {selected ? 'Editar' : 'Agregar'}
          {' '}
          {translate('ra.label.nota')}
        </DialogTitle>
        {/* <Typography>Puedes agregar observaciones relacionadas a la asistencia</Typography> */}
        <FormWithRedirect
          resource="cursos"
          save={handleSubmit}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving,
          }) => (
            <>
              <DialogContent>
                <TextInput
                  defaultValue={selected && selected.calificacion ? selected.calificacion : null}
                  label="Nota"
                  variant="outlined"
                  fullWidth
                  validate={required()}
                  source="calificacion"
                />
                <TextInput
                  defaultValue={selected && selected.observaciones ? selected.observaciones : null}
                  label="Observações"
                  multiline
                  minRows={3}
                  variant="outlined"
                  fullWidth
                  validate={required()}
                  source="observaciones"
                />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.label.cancelar"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  label={selected ? translate('ra.label.editar') : translate('ra.label.salvar')}
                  handleSubmitWithRedirect={
                                handleSubmitWithRedirect
                            }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </TableRow>
  );
}
function PriceField({ record = {}, source }) {
  return (
    <Typography style={{ textAlign: 'right', display: 'block', fontSize: 16 }}>
      Valor: $
      {record[source] ? record[source].toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '{body},').replace('.0', '') : 0}
    </Typography>
  );
}
