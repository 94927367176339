import React from 'react';
import {
  List,
  ImageInput,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  EditButton,
  NumberInput,
  ImageField,
  DeleteButton,
  Filter,
} from 'react-admin';

function PostFilter(props) {
  return (
    <Filter {...props}>
      <TextInput label="Busca por Nombre" source="nombre" alwaysOn />
      <NumberInput label="Busca por Código de ICG" source="codarticulo" alwaysOn />
      <TextInput source="objectId" />
      <TextInput source="alumnoId" />
      <TextInput source="cursoId" />
    </Filter>
  );
}

export function AlumnoDocList(props) {
  return (
    <List {...props} exporter={false} filters={<PostFilter />}>
      <Datagrid>
        <TextField source="objectId" />
        <TextField source="alumnoId" />
        <TextField source="cursoId" />
        <TextField source="title" />
        <TextField source="updatedAt" />
        <EditButton label="ra.label.editar" />
        <DeleteButton label="Delete" />
      </Datagrid>
    </List>
  );
}

export function PostList(props) {
  return (
    <List {...props} exporter={false} filters={<PostFilter />}>
      <Datagrid>
        <TextField source="nombre" />
        <EditButton label="ra.label.editar" />
      </Datagrid>
    </List>
  );
}

export function PostCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm
        redirect="edit"
      >
        <TextInput fullWidth source="nombre" />
        <ImageInput multiple label="Foto del Plato" source="pictures" placeholder={<p>Haz clic o arrastra</p>}>
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
}

export function PostEdit(props) {
  return (
    <Edit undoable={false} {...props}>
      <SimpleForm redirect="edit">
        <TextInput source="alumnoId" />
        <TextInput source="cursoId" />
        <TextInput fullWidth source="body" multiline />
        {/* <BooleanInput source="publicado" /> */}
      </SimpleForm>
    </Edit>
  );
}
