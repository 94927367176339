import React, { useState } from 'react';
import {
  Button,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  NumberInput,
  ReferenceInput,
  SelectInput,
  FileInput,
  FileField,
  useRefresh,
  useTranslate,
  FormDataConsumer,
  // useDataProvider,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';
import { InputAdornment, Box } from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import IconCancel from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import QuickCreateNatureza from './QuickCreateNatureza';
import QuickCreateContrato from './QuickCreateContrato';
import { DateInput } from '../DateInput';
import FileIcon from '../static/img/filesicon.png';
// import parseConfig from '../parseConfig';

export function CreateEntradaButton(props) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('receitas');
  const notify = useNotify();
  const refresh = useRefresh();
  const [version, setVersion] = useState(0);
  const handleChange = React.useCallback(() => setVersion(version + 1), [version]);

  // const dataProvider = useDataProvider();
  // const form = useForm();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    // values.tipo = props.tipo;
    console.log(props);
    if (values.parcelaId) {
      /**
       * La receita ahora tiene sus parcelas
       */

      // const responseParcela = await fetch(
      //   `${parseConfig.URL}/classes/parcelas/${values.parcelaId}`,
      //   {
      //     headers: parseConfig.headers,
      //   },
      // );
      // const jsonParcela = await responseParcela.json();
      // const valorParsela = parseInt(jsonParcela.valor);
      // console.log(valorParsela);
      // const valorPago = parseInt(values.valor);
      // console.log(valorPago);
      // const saldo = valorPago - valorParsela;
      // console.log(saldo);
      // await dataProvider.update('parcelas', { id: values.parcelaId, data: { saldo, estado: saldo < 0 ? 'pendiente' : 'completada' } });
      create(
        { payload: { data: values } },
        {
          onSuccess: () => {
            refresh();
            setShowDialog(false);
          },
          onFailure: ({ error }) => {
            notify(error.message, 'error');
          },
        },
      );
    } else {
      create(
        { payload: { data: values } },
        {
          onSuccess: () => {
            refresh();
            setShowDialog(false);
            // Update the comment form to target the newly created post
            // Updating the ReferenceInput value will force it to reload the available posts
            //   form.change('moduloId', data.id);
            // onChange();
          },
          onFailure: ({ error }) => {
            notify(error.message, 'error');
          },
        },
      );
    }
  };

  function MyNumberInput(props) {
    return (
      <CurrencyTextField
        name={props.name}
        label={props.lael}
        variant="outlined"
        // value={value}
        currencySymbol="$"
        // minimumValue="0"
        outputFormat="string"
        decimalCharacter="."
        digitGroupSeparator=","
        // onChange={(event, value)=> setValue(value)}
      />
    );
  }

  const translate = useTranslate();
  return (
    <>
      <Button onClick={handleClick} label="ra.label.add_entrada">
        <AddCircleIcon />
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>
          {translate('ra.label.add_entrada')}
        </DialogTitle>
        <FormWithRedirect
          resource="pagos"
          save={handleSubmit}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving,
          }) => (
            <>
              <DialogContent>
                <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
                  <Box
                    flex={1}
                    mr={{ xs: 0, sm: '0.5em' }}
                  >
                    <ReferenceInput
                      key={version}
                      perPage={100000}
                      filter={{ tipo_natureza: 'entrada' }}
                      sort={{ field: 'nombre', order: 'ASC' }}
                      variant="outlined"
                      style={{ minWidth: '50%' }}
                      label="ra.label.natureza"
                      source="naturezaId"
                      reference="natureza_entrada"
                    >
                      <SelectInput optionText="nombre" />
                    </ReferenceInput>
                    <QuickCreateNatureza onChange={handleChange} tipo="entrada" />
                  </Box>
                  <Box
                    flex={1}
                    ml={{ xs: 0, sm: '0.5em' }}
                  >
                    <MyNumberInput
                      name="valor"
                      label="ra.label.valor"
                      variant="outlined"
                      source="valor"
                      // format={(v) => {
                      //   const dollarUSLocale = Intl.NumberFormat('es-CO');
                      //   console.log(dollarUSLocale.format(v));
                      //   return dollarUSLocale.format(v);
                      // }}
                      // parse={(v) => {
                      //   const dollarUSLocale = Intl.NumberFormat('de-DE');
                      //   console.log(dollarUSLocale.format(v));
                      //   return dollarUSLocale.format(v);
                      // }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            COP$
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>
                <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
                  <Box
                    flex={1}
                    mr={{ xs: 0, sm: '0.5em' }}
                  >
                    <ReferenceInput
                      perPage={100000}
                      emptyText="Ninguno"
                      fullWidth
                      sort={{ field: 'nombre', order: 'ASC' }}
                      variant="outlined"
                      style={{ minWidth: '50%' }}
                      label="ra.label.cliente"
                      source="alumnoId"
                      reference="alumnos"
                    >
                      <SelectInput emptyText="Ninguno" optionText="nombre" />
                    </ReferenceInput>
                  </Box>
                  <Box
                    flex={1}
                    ml={{ xs: 0, sm: '0.5em' }}
                  >
                    <FormDataConsumer>
                      {({ formData }) => (
                        <>
                          <ReferenceInput
                            filter={{ alumnoId: formData.alumnoId }}
                            perPage={100000}
                            emptyText="Ninguno"
                            sort={{ field: 'titulo', order: 'ASC' }}
                            variant="outlined"
                            style={{ minWidth: '50%' }}
                            label="ra.label.contrato"
                            source="contratoId"
                            reference="contratos"
                          >
                            <SelectInput emptyText="Ninguno" optionText="titulo" />
                          </ReferenceInput>
                          <QuickCreateContrato onChange={handleChange} clienteId={formData.alumnoId ? formData.alumnoId : null} />
                        </>
                      )}
                    </FormDataConsumer>
                  </Box>
                </Box>
                <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
                  <Box
                    flex={1}
                    mr={{ xs: 0, sm: '0.5em' }}
                  >
                    <DateInput
                      defaultValue={new Date()}
                      // className="smallInput"
                      label={`${translate('ra.label.data_vencimento')} #1`}
                      variant="outlined"
                      source="fecha_vencimiento"
                    />
                  </Box>
                  <Box
                    mr={{ xs: 0, sm: '0.5em' }}
                    flex={1}
                  >
                    <NumberInput variant="outlined" source="no_parcelas" />
                  </Box>
                </Box>
                <FormDataConsumer>
                  {({ formData }) => {
                    if (formData.fecha_vencimiento) {
                      console.log('entro: ', formData.fecha_vencimiento.getMonth());
                    }

                    const parcelasT = [];
                    const hoy = new Date();
                    const tomorrow = formData.fecha_vencimiento ? new Date(formData.fecha_vencimiento.getFullYear(), formData.fecha_vencimiento.getMonth(), formData.fecha_vencimiento.getDate()) : new Date(hoy.getFullYear(), hoy.getMonth() + 1, 0);
                    tomorrow.setHours(0, 0, 0, 0);
                    console.log(tomorrow);
                    if (formData.no_parcelas) {
                      for (let index = 0; index < formData.no_parcelas; index++) {
                        const proxMes = new Date(tomorrow.getFullYear(), tomorrow.getMonth() + index, tomorrow.getDate()).toISOString();
                        parcelasT.push({
                          data_vencimento: proxMes,
                          valor: parseFloat(formData.valor) / parseInt(formData.no_parcelas),
                        });
                      }
                    }

                    formData.parcelas = parcelasT;
                    if (formData.parcelas.length && formData.no_parcelas) {
                      return (
                        <ArrayInput label="ra.label.parcelas" source="parcelas">
                          <SimpleFormIterator
                            disableReordering
                            className="RaSimpleFormIterator"
                            addButton={<Button><AddCircleIcon /></Button>}
                            removeButton={<Button><RemoveCircleIcon /></Button>}
                          >
                            <DateInput
                              label="Data vencimento"
                              variant="outlined"
                              source="data_vencimento"
                              size="small"
                            />
                            <NumberInput
                              size="small"
                              label="ra.label.valor"
                              variant="outlined"
                              source="valor"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </SimpleFormIterator>
                        </ArrayInput>
                      );
                    }
                    return null;
                  }}
                </FormDataConsumer>
                {/* <DateInput
                            // className='smallInput'
                  label="ra.label.data_pgto"
                  variant="outlined"
                  source="fecha_pgto"
                /> */}
                {/* <NumberInput
                  size="small"
                  label="ra.label.valor"
                  variant="outlined"
                  source="valor"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                  }}
                /> */}
                <TextInput
                  label="ra.label.observacoes"
                  multiline
                  minRows={3}
                  variant="outlined"
                  fullWidth
                  source="observaciones"
                />
                <FileInput
                  source="pictures"
                  multiple
                  label="ra.label.anexar_arquivos"
                  placeholder={(
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'transparent',
                      borderColor: '#518F18',
                      borderStyle: 'dashed',
                      borderWidth: 1,
                      borderRadius: 16,
                      width: 260,
                      height: 160,
                    }}
                    >
                      <p>
                        <img src={FileIcon} alt="Archivo" />
                      </p>
                      <p>{translate('ra.label.arraste_solte')}</p>
                    </div>
                                      )}
                  accept="image/*, application/*, text/*"
                >
                  <FileField source="src" title="title" />
                </FileInput>
              </DialogContent>
              <DialogActions>
                <Button
                  className="cancelar-btn"
                  label="ra.label.cancelar"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                                  handleSubmitWithRedirect
                              }
                  label="ra.label.salvar"
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export function CreateSalidaButton() {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('despesas');
  const notify = useNotify();
  const refresh = useRefresh();
  const [version, setVersion] = useState(0);
  const handleChange = React.useCallback(() => setVersion(version + 1), [version]);
  // const form = useForm();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    // values.tipo = props.tipo;
    create(
      { payload: { data: values } },
      {
        onSuccess: () => {
          refresh();
          setShowDialog(false);
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          //   form.change('moduloId', data.id);
          // onChange();
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        },
      },
    );
  };

  const translate = useTranslate();
  return (
    <>
      <Button onClick={handleClick} label="ra.label.add_saida">
        <AddCircleIcon />
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>
          {translate('ra.label.add_saida')}
        </DialogTitle>
        <FormWithRedirect
          resource="pagos"
          save={handleSubmit}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving,
          }) => (
            <>
              <DialogContent>
                <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
                  <Box
                    flex={1}
                    mr={{ xs: 0, sm: '0.5em' }}
                  >
                    <ReferenceInput
                      key={version}
                      perPage={100000}
                      filter={{ tipo_natureza: 'saida' }}
                      sort={{ field: 'nombre', order: 'ASC' }}
                      variant="outlined"
                      style={{ minWidth: '50%' }}
                      label="ra.label.natureza_saida"
                      source="naturezaId"
                      reference="natureza_entrada"
                    >
                      <SelectInput optionText="nombre" />
                    </ReferenceInput>
                    <QuickCreateNatureza onChange={handleChange} tipo="saida" />
                  </Box>
                  <Box
                    flex={1}
                    ml={{ xs: 0, sm: '0.5em' }}
                  >
                    <NumberInput
                      label="ra.label.valor"
                      variant="outlined"
                      source="valor"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>
                <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
                  <Box
                    flex={1}
                    mr={{ xs: 0, sm: '0.5em' }}
                  >
                    <FormDataConsumer>
                      {({ formData }) => (
                        <ReferenceInput
                          perPage={100000}
                          fullWidth
                          filter={formData.naturezaId ? { padre: formData.naturezaId } : {}}
                          sort={{ field: 'nombre', order: 'ASC' }}
                          variant="outlined"
                          style={{ minWidth: '50%' }}
                          label="Nome da saida"
                          source="subnaturezaId"
                          reference="natureza_saida"
                        >
                          <SelectInput optionText="nombre" />
                        </ReferenceInput>
                      )}
                    </FormDataConsumer>
                  </Box>
                  <Box
                    flex={1}
                    ml={{ xs: 0, sm: '0.5em' }}
                  >
                    <ReferenceInput
                      perPage={100000}
                      emptyText="Ninguno"
                      fullWidth
                      sort={{ field: 'nombre', order: 'ASC' }}
                      variant="outlined"
                      style={{ minWidth: '50%' }}
                      label="Funcionarios"
                      source="profeId"
                      reference="profesores"
                    >
                      <SelectInput emptyText="Ninguno" optionText="nombre" />
                    </ReferenceInput>
                  </Box>
                </Box>
                <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
                  <Box
                    flex={1}
                    mr={{ xs: 0, sm: '0.5em' }}
                  >
                    <DateInput
                      defaultValue={new Date()}
                      // className="smallInput"
                      label={`${translate('ra.label.data_vencimento')} #1`}
                      variant="outlined"
                      source="fecha_vencimiento"
                    />
                  </Box>
                  <Box
                    mr={{ xs: 0, sm: '0.5em' }}
                    flex={1}
                  >
                    <NumberInput variant="outlined" source="no_parcelas" />
                  </Box>
                </Box>
                <FormDataConsumer>
                  {({ formData }) => {
                    if (formData.fecha_vencimiento) {
                      console.log('entro: ', formData.fecha_vencimiento.getMonth());
                    }

                    const parcelasT = [];
                    const hoy = new Date();
                    const tomorrow = formData.fecha_vencimiento ? new Date(formData.fecha_vencimiento.getFullYear(), formData.fecha_vencimiento.getMonth(), formData.fecha_vencimiento.getDate()) : new Date(hoy.getFullYear(), hoy.getMonth() + 1, 0);
                    tomorrow.setHours(0, 0, 0, 0);
                    console.log(tomorrow);
                    if (formData.no_parcelas) {
                      for (let index = 0; index < formData.no_parcelas; index++) {
                        const proxMes = new Date(tomorrow.getFullYear(), tomorrow.getMonth() + index, tomorrow.getDate()).toISOString();
                        parcelasT.push({
                          data_vencimento: proxMes,
                          valor: parseFloat(formData.valor) / parseInt(formData.no_parcelas),
                        });
                      }
                    }

                    formData.parcelas = parcelasT;
                    if (formData.parcelas.length && formData.no_parcelas) {
                      return (
                        <ArrayInput label="ra.label.parcelas" source="parcelas">
                          <SimpleFormIterator
                            disableReordering
                            className="RaSimpleFormIterator"
                            addButton={<Button><AddCircleIcon /></Button>}
                            removeButton={<Button><RemoveCircleIcon /></Button>}
                          >
                            <DateInput
                              label="Data vencimento"
                              variant="outlined"
                              source="data_vencimento"
                              size="small"
                            />
                            <NumberInput
                              size="small"
                              label="ra.label.valor"
                              variant="outlined"
                              source="valor"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </SimpleFormIterator>
                        </ArrayInput>
                      );
                    }
                    return null;
                  }}
                </FormDataConsumer>
                <TextInput
                  label="ra.label.observacoes"
                  multiline
                  minRows={3}
                  variant="outlined"
                  fullWidth
                  source="observaciones"
                />
                <FileInput
                  source="pictures"
                  multiple
                  label="ra.label.anexar_arquivos"
                  placeholder={(
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'transparent',
                      borderColor: '#518F18',
                      borderStyle: 'dashed',
                      borderWidth: 1,
                      borderRadius: 16,
                      width: 260,
                      height: 160,
                    }}
                    >
                      <p>
                        <img src={FileIcon} alt="Archivo" />
                      </p>
                      <p>{translate('ra.label.arraste_solte')}</p>
                    </div>
                                      )}
                  accept="image/*, application/*, text/*"
                >
                  <FileField source="src" title="title" />
                </FileInput>
              </DialogContent>
              <DialogActions>
                <Button
                  className="cancelar-btn"
                  label="ra.label.cancelar"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                                  handleSubmitWithRedirect
                              }
                  label="ra.label.salvar"
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}
