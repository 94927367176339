import React, { useEffect, useState, useRef } from 'react';
import {
  List,
  Create,
  Edit,
  TextInput,
  Datagrid,
  TextField,
  ShowButton,
  Filter,
  TabbedForm,
  FormTab,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  Button,
  ArrayInput,
  SimpleFormIterator,
  CheckboxGroupInput,
  useListContext,
  ReferenceField,
  useRecordContext,
  useDataProvider,
  useRedirect,
  useRefresh,
  ArrayField,
  TopToolbar,
  SaveButton,
  Pagination,
  CreateButton,
  // FormWithRedirect,
  Toolbar,
  DeleteButton,
  useTranslate,
  Loading,
} from 'react-admin';
import Dropzone from 'react-dropzone';
import FolderIcon from '@mui/icons-material/Folder';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Editor } from '@tinymce/tinymce-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Accordion from '@material-ui/core/Accordion';
import {
  TextField as MUITextField,
  Box,
  makeStyles,
  Typography,
  ListItemIcon,
  List as MUIList,
  ListItem,
  ListItemText,
  LinearProgress,
  Paper,
} from '@material-ui/core';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconCancel from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import OpenWithIcon from '@material-ui/icons/OpenWith';
// import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Parse from 'parse';
import SelectCrud from './componentes/SelectCrud';
import FileIcon from './static/img/filesicon.png';
import ImageIcon from './static/img/picture.png';
import CreateModuloButton from './componentes/CreateModuloButton';
// eslint-disable-next-line no-unused-vars
// import MyEditor from './componentes/MyEditor';
import PdfIcon from './static/img/pdf.png';
import ZipIcon from './static/img/zip.png';
import ExcelIcon from './static/img/excel.png';
import PPtIcon from './static/img/powerpoint.png';
import WordIcon from './static/img/nopath---copia--2--1@1x.png';
import VoltarIcon from './static/img/Voltar.png';
import CarpetaIcon from './static/img/pasta.png';
import CreateLinkButton from './componentes/CreateLinkButton';
import YoutubeIcon from './static/img/youtube.png';
import CreateCarpetaMaterialButton from './componentes/CreateCarpetaMaterialButton';
import AddDirIcon from './static/img/addpasta.png';
import parseConfig from './parseConfig';

Parse.initialize(parseConfig.APP_ID, parseConfig.JAVASCRIPT_KEY, parseConfig.MASTER_KEY);
Parse.serverURL = parseConfig.URL;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const titleStyle = {
  color: '#004AAD',
  
  fontSize: 18,
  fontWeight: 'bold',
  textTransform: 'capitalize',
  marginBottom: 20,
};

function AccesosField({ record, source }) {
  return <span>{record[source].join(', ')}</span>;
}

function MyRichTextField({ record, source }) {
  return record && record[source] ? (
    <div style={{
      height: 94,
      display: 'block',
      overflow: 'auto',
    }}
    >
      {record[source].split('\n').map((item) => (
        <span>
          {item}
          <br />
        </span>
      ))}
    </div>
  ) : '';
}

function MaterialGrid(props) {
  const redirect = useRedirect();
  const { ids, data, basePath } = useListContext();
  const classes = useStyles();
  const soyprofe = localStorage.getItem('profe');
  const miperfil = localStorage.getItem('perfil');
  const translate = useTranslate();
  if (miperfil === 'profesor' && soyprofe && JSON.parse(soyprofe)) {
    const mismodulos = props.miscursos.map((item) => item.moduloId);
    console.log(mismodulos);
    return (
      <div className={classes.root}>
        {ids.map((id) => (
          <>
            {mismodulos.includes(data[id].moduloId) ? (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div onClick={() => redirect(`/material/${data[id].objectId}/show`)} style={{ display: 'flex', flexDirection: 'column' }}>
                    <TextField style={titleStyle} record={data[id]} source="nombre" />
                    <spam>
                      <MyRichTextField record={data[id]} source="descripcion" />
                    </spam>
                  </div>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography>
                      {translate('ra.label.permissoes')}
                    </Typography>
                    <ShowButton label="ra.label.entrar" resource="posts" basePath={basePath} record={data[id]} />
                  </div>
                  <ArrayField record={data[id]} source="permisos">
                    <Datagrid>
                      <ReferenceField source="alumno" reference="alumnos">
                        <TextField source="nombre" />
                      </ReferenceField>
                      <AccesosField source="acceso" />
                      <TextField source="status" />
                    </Datagrid>
                  </ArrayField>
                </AccordionDetails>
              </Accordion>
            )
              : null}
          </>
        ))}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {ids.map((id) => (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div onClick={() => redirect(`/material/${data[id].objectId}/show`)} style={{ display: 'flex', flexDirection: 'column' }}>
              <TextField style={titleStyle} record={data[id]} source="nombre" />
              <spam>
                <MyRichTextField record={data[id]} source="descripcion" />
              </spam>
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography>
                Permisos
              </Typography>
              <ShowButton label="ra.label.entrar" resource="posts" basePath={basePath} record={data[id]} />
            </div>
            <ArrayField record={data[id]} source="permisos">
              <Datagrid>
                <ReferenceField source="alumno" reference="alumnos">
                  <TextField source="nombre" />
                </ReferenceField>
                <AccesosField source="acceso" />
                <TextField source="status" />
              </Datagrid>
            </ArrayField>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

function PostFilter(props) {
  return (
    <Filter {...props}>
      <TextInput variant="outlined" label="ra.label.nome_pasta" source="nombre" alwaysOn />
    </Filter>
  );
}

function ListActions() {
  return (
    <TopToolbar>
      {/* Add your custom actions */}
      {localStorage.getItem('perfil') !== 'alumno' && (
      <Button href="#/material/create" label="" style={{ position: 'absolute', right: 0, top: 0 }}>
        <img
          alt="Agregar"
          src={AddDirIcon}
          style={{
            width: 60,
            height: 55,
          }}
        />
      </Button>
      )}
    </TopToolbar>
  );
}

function Empty() {
  const perfil = localStorage.getItem('perfil') || null;
  const { basePath } = useListContext();
  return (
    <Box textAlign="center" m={1}>
      <Typography variant="h4" paragraph>
        Não há material disponível
      </Typography>
      {perfil !== 'alumno' && (
      <>
        <Typography variant="body1">
          Se é administrador ou professor, podes criar materiais
        </Typography>
        <CreateButton basePath={basePath} />
      </>
      )}
    </Box>
  );
}

export function MaterialList(props) {
  const dataProvider = useDataProvider();
  const soyprofe = localStorage.getItem('profe') ? JSON.parse(localStorage.getItem('profe')) : null;
  const soyalumno = localStorage.getItem('alumno') ? JSON.parse(localStorage.getItem('alumno')) : null;
  const [miscursos, setMiscursos] = useState([]);
  const perfil = localStorage.getItem('perfil') || null;
  console.log(localStorage.getItem('miscursos'));

  async function getCursos() {
    if (soyprofe && perfil === 'profesor') {
      console.log(soyprofe.objectId);
      dataProvider.getList('cursos', { filter: { profId: soyprofe.objectId } })
        .then(({ data }) => setMiscursos(data));
    }

    if (localStorage.getItem('alumno') && localStorage.getItem('perfil') === 'alumno') {
      dataProvider.getList(
        'cursos',
        { filter: { alumnos: soyalumno.objectId } },
      )
        .then(({ data }) => {
          setMiscursos(data);
        });
    }
  }

  useEffect(() => {
    getCursos();
  }, []);

  return (
    <List
      {...props}
      empty={<Empty />}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[100, 150, 200]} {...props} />}
      actions={<ListActions />}
      sort={{ field: 'nombre', order: 'ASC' }}
    // filter={{"padreId":{"$exists":false}}}
      filter={
        localStorage.getItem('profe')
        && localStorage.getItem('perfil')
        && localStorage.getItem('perfil') === 'profesor'
          ? {
            moduloId: { $in: miscursos.map((item) => item.moduloId) },
            padreId: { $exists: false },
          }
          : localStorage.getItem('alumno')
        && localStorage.getItem('perfil')
        && localStorage.getItem('perfil') === 'alumno'
            ? {
              moduloId: { $in: miscursos.map((item) => item.moduloId) },
              padreId: { $exists: false },
            }
            : { padreId: { $exists: false } }
}
      filters={<PostFilter />}
      exporter={false}
      bulkActionButtons={false}
    >
      <MaterialGrid miscursos={miscursos} />
    </List>
  );
}

export function MaterialCreate(props) {
  const refresh = useRefresh();
  const translate = useTranslate();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);
  const onSuccess = async ({ data }) => {
    const permisosArr = [];
    /** Creo los permisos por default para los alumnos y el profe */
    /**
         *1. Traigo a los alumnos del módulo
         *1.1. Traigo los cursos con ese módulo
         *1.2. Los alumnos de cada curso obtenido son los q me sirven
         */
    console.log(data);
    const { data: cursos } = await dataProvider.getList('cursos', { filter: { moduloId: data.moduloId } });
    /**
         *2. Creo el array de permisos para los alumnos con los valores default
        */
    console.log(cursos);

    if (cursos && cursos.length) {
      for (let index = 0; index < cursos.length; index++) {
        const curso = cursos[index];
        console.log(curso);
        if (curso && curso.alumnos) {
          for (const alumno of curso.alumnos) {
            permisosArr.push({
              alumno,
              acceso: [
                'Leer',
                'Editar',
                'Descargar',
              ],
              status: 'Activo',
            });
          }
        }
      }
    }
    /**
         *3. Save the permisos array
        */
    const result = await dataProvider.update('material', {
      id: data.id,
      data: {
        permisos: permisosArr,
      },
    });
    console.log(result);

    // notify(`Carpeta creada`);
    redirect(`/material/${data.id}/show`);
    refresh();
  };
  return (
    <Create {...props} onSuccess={onSuccess}>
      <TabbedForm redirect="edit">
        <FormTab label="Material">
          <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
            <img src={VoltarIcon} alt="Voltar" />
          </Button>
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <TextInput variant="outlined" fullWidth label="ra.label.nome_pasta" source="nombre" />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <SelectCrud
                resource="modulos"
                label="nome"
                title={translate('ra.label.sel_modulo')}
                open={open}
                onClose={(value) => {
                  console.log(value);
                  setOpen(false);
                }}
              />
              <ReferenceInput
                InputProps={{
                  readOnly: true,
                }}
                perPage={100000}
                sort={{ field: 'name', order: 'ASC' }}
                variant="outlined"
                style={{ minWidth: '50%' }}
                source="moduloId"
                label="ra.label.modulo"
                reference="modulos"
              >
                <SelectInput optionText="name" onClick={() => setOpen(true)} />
              </ReferenceInput>
              <CreateModuloButton />
            </Box>
          </Box>
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <TextInput minRows={6} multiline variant="outlined" fullWidth label="ra.label.descricao" source="descripcion" />
            </Box>
            <Box flex={1} />
          </Box>
          {/* <Box  fullWidth={true} display={{ xs: 'block', sm: 'flex' }}>
                <Box
                flex={.5}
                mr={{ xs: 0, sm: '0.5em' }}
                >
                    <FileInput
                        source="pictures"
                        multiple={true}
                        label="Archivo"
                        placeholder={
                            <div style={{
                                display:'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems:'center',
                                backgroundColor:'transparent',
                                borderColor: '#518F18',
                                borderStyle: 'dashed',
                                borderWidth: 1,
                                borderRadius: 16,
                                width: 260,
                                height:160
                            }}>
                                <p>
                                    <img src={FileIcon} alt="Archivo" />
                                </p>
                                <p>Arraste e solte o arquivo aqui</p>
                            </div>
                        }
                        accept="image/*, application/*, text/*">
                        <FileField source="src" title="title" />
                    </FileInput>
                </Box>
                <Box
                flex={1}
                ml={{ xs: 0, sm: '0.5em' }}
                >
                    <MisDocumentos source="pictures" />
                </Box>
            </Box> */}
        </FormTab>
        <FormTab label="ra.label.permissoes">
          <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
            <img src={VoltarIcon} alt="Voltar" />
          </Button>
          <ArrayInput
            label="ra.label.permissoes"
            source="permisos"
          >
            <SimpleFormIterator
              className="RaSimpleFormIterator"
              addButton={<Button><AddCircleIcon /></Button>}
              removeButton={<Button><RemoveCircleIcon /></Button>}
            >
              <ReferenceInput
                perPage={100000}
                variant="outlined"
                sort={{ field: 'nombre', order: 'ASC' }}
                label="ra.label.alunos"
                source="alumno"
                reference="alumnos"
              >
                <AutocompleteInput
                  fullWidth
                  variant="outlined"
                  optionText="nombre"
                />
              </ReferenceInput>
              <CheckboxGroupInput
                label="ra.label.permissoes"
                source="acceso"
                choices={[
                  { id: 'Leer', name: 'ra.label.leer' },
                  { id: 'Editar', name: 'ra.label.editar' },
                  { id: 'Descargar', name: 'ra.label.baixar' },
                ]}
              />
              <SelectInput
                fullWidth
                variant="outlined"
                source="status"
                label="ra.label.status"
                choices={[
                  { id: 'Activo', name: 'ra.label.ativo' },
                  { id: 'Inactivo', name: 'ra.label.inativo' },
                  { id: 'Bloqueado', name: 'ra.label.bloqueado' },
                ]}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
}

const initialState = {
  mouseX: null,
  mouseY: null,
};

function MyDir({ carpeta, index, deleteItem }) {
  const [state, setState] = React.useState(initialState);
  const perfil = localStorage.getItem('perfil') || null;

  function handleRightClick(event) {
    console.log(event.target);
    event.preventDefault();

    if (!event.target.id) {
      handleClose();
    } else if (event.target.id) {
      setState({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
    }
  }

  function handleClose() {
    setState(initialState);
  }

  const translate = useTranslate();
  return (
    <div style={{ position: 'relative' }}>
      {/* <div key={`${carpeta.objectId}`}
            style={{ cursor: 'pointer' }}
                // onContextMenu={handleRightClick}
                href={`#/material/${carpeta.objectId}/show`}
                style={{
                    alignSelf:'flex-end',
                    width:150,
                    marginLeft: 30,
                    marginRight: 30,
                    marginBottom: 30,
                    justifyContent: 'center',
                    textAlign: 'center'
                }}>
                <img
                    style={{width: 60}}
                    src={ CarpetaIcon } />
                <Typography noWrap className={`docTitle`}>{carpeta.nombre}</Typography>
            </div> */}
      <div
        key={`${carpeta.objectId}`}
        // eslint-disable-next-line no-return-assign
        onDoubleClick={() => window.location.href = `#/material/${carpeta.objectId}/show`}
        onContextMenu={handleRightClick}
        id={`${carpeta.objectId}`}
        style={{
          alignSelf: 'flex-end',
          width: 150,
          marginLeft: 30,
          marginRight: 30,
          marginBottom: 30,
          justifyContent: 'center',
          textAlign: 'center',
          cursor: 'pointer',
        }}
      >
        <Menu
          keepMounted
          open={
                       state.mouseY !== null
                    }
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
                    state.mouseY !== null && state.mouseX !== null
                      ? { top: state.mouseY, left: state.mouseX }
                      : undefined
                    }
        >
          <MenuItem
            onClick={() => {
              window.location.href = `#/material/${carpeta.objectId}/show`;
            }}
          >
            <ListItemIcon>
              <OpenWithIcon fontSize="small" />
            </ListItemIcon>
            {translate('ra.label.abrir')}
            {' '}
          </MenuItem>
          {/* <MenuItem
                        onClick={()=>{
                            var a = document.createElement("a");
                            a.href = doc.src;
                            a.setAttribute("download", doc.title);
                            a.click();
                            handleClose()
                        }}
                    >
                        <ListItemIcon>
                            <CloudDownloadIcon fontSize="small" />
                        </ListItemIcon>
                        Descargar</MenuItem> */}
          {/* <MenuItem onClick={handleClose}>
                        <ListItemIcon><VisibilityIcon fontSize="small"/></ListItemIcon>
                        Ver Permisos</MenuItem> */}
          {perfil && perfil !== 'alumno' && perfil !== 'profesor' && (
            <MenuItem
              onClick={() => {
                deleteItem(index);
                handleClose();
              }}
            >
              <ListItemIcon><DeleteOutlineIcon fontSize="small" /></ListItemIcon>
              {translate('ra.label.excluir')}
            </MenuItem>
          )}
        </Menu>
        <img
          alt="Directorio"
          id={`img-${carpeta.nombre}-index-${index}`}
          style={{ width: 60 }}
          src={CarpetaIcon}
        />
        <Typography id={`carpeta-${carpeta.nombre}-index-${index}`} noWrap className="docTitle">{carpeta.nombre}</Typography>
      </div>

    </div>
  );
}

function MyFile({
  doc, index, deleteItem, renameItem, moduloId, louza, enAula, cursoId,
}) {
  const dataProvider = useDataProvider();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(null);
  const [docHtml, setDocHtml] = useState(null);
  const [open, setOpen] = useState(null);
  const [values, setValues] = useState({});
  const [state, setState] = React.useState(initialState);
  const [showDialog, setShowDialog] = React.useState(false);
  const perfil = localStorage.getItem('perfil') || null;
  const userId = localStorage.getItem('userId') || null;
  const editorRef = useRef(null);

  function handleRightClick(event) {
    // console.log(event.target)
    event.preventDefault();

    if (!event.target.id) {
      handleClose();
    } else if (event.target.id) {
      setState({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
    }
  }

  function handleClose() {
    setState(initialState);
  }

  function abrodocumento(doc) {
    localStorage.setItem('docId', doc.src);
    setDocHtml(doc);
    setValues(doc);
    setOpen(true);
  }

  // eslint-disable-next-line no-unused-vars
  const handleSubmit = async () => {
    setLoading(true);
    console.log(values);
    if (values.body) {
      dataProvider.update('alumno_documentos', { id: docHtml.objectId, data: { body: values.body } })
        .then(({ data }) => {
          console.log(data);
          setLoading(false);
          alert('Documento guardado');
          //   setOpen(false)
          //   window.location.reload()
        });
    } else {
      setLoading(false);
      // notify('O documento e obrigatorio', 'error');
      // return
    }
  };
  const translate = useTranslate();
  return (
    <div style={{ position: 'relative' }}>
      <div
        id={`doc-${doc.title}-index-${index}`}
        onContextMenu={handleRightClick}
        key={`${doc.src}-${index}`}
        style={{
          alignSelf: 'flex-end',
          width: 150,
          marginLeft: 30,
          marginRight: 30,
          marginBottom: 30,
          justifyContent: 'center',
          textAlign: 'center',
          cursor: 'pointer',
        }}
      >
        <Menu
          keepMounted
          open={
                       state.mouseY !== null
                    }
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
                    state.mouseY !== null && state.mouseX !== null
                      ? { top: state.mouseY, left: state.mouseX }
                      : undefined
                    }
        >
          <MenuItem>{JSON.stringify(perfil)}</MenuItem>
          <MenuItem
            onClick={async () => {
              console.log(doc);
              if (doc.src && doc.src.includes('.doc')) {
                console.log(doc.src);
                // Reviso si ya tengo el html alumno-documento
                const { data: usuarioDoc } = await dataProvider.getList(
                  'alumno_documentos',
                  { filter: { alumnoId: userId, src: doc.src } },
                );
                console.log(usuarioDoc);
                if (usuarioDoc.length === 0) {
                  const request = new XMLHttpRequest();
                  request.open('GET', doc.src, true);
                  request.responseType = 'blob';
                  request.onload = function () {
                    const reader = new FileReader();
                    // reader.readAsDataURL(request.response);
                    reader.readAsArrayBuffer(request.response);
                    reader.onload = async (e) => {
                      const text = (e);
                      console.log(text);
                      const mammoth = require('mammoth');

                      mammoth.convertToHtml({ arrayBuffer: e.target.result })
                        .then((result) => {
                          const html = result.value; // The generated HTML
                          const { messages } = result;
                          console.log(html);
                          console.log(messages);
                          /**
                                                 * Aquí tengo el html del documento
                                                 */
                          dataProvider.create(
                            'alumno_documentos',
                            {
                              data: {
                                alumnoId: userId,
                                src: doc.src,
                                body: html,
                                moduloId,
                                cursoId: cursoId || '',
                                enAula: !!enAula,
                                title: doc.title,
                              },
                            },
                          )
                            .then(({ data }) => {
                              console.log(data);
                              if (!enAula) {
                                abrodocumento({
                                  ...data, alumnoId: userId, src: doc.src, body: html,
                                });
                              }
                            });
                        })
                        .done(
                          // setLoading(false)
                        );
                    };
                  };
                  request.send();
                } else if (!enAula) abrodocumento(usuarioDoc[0]);
              } else if (doc.src && doc.src.includes('.htmlll')) {
                // src="M3%20-%20AULA%2005_files/
                console.log(encodeURI(doc.title));
              } else if (doc.src && doc.src.includes('.html')) {
                const { data: usuarioDoc } = await dataProvider.getList(
                  'alumno_documentos',
                  { filter: { alumnoId: userId, src: doc.src } },
                );

                if (usuarioDoc.length === 0) {
                  /**
                                                     * Aquí tengo el html del documento
                                                     */

                  try {
                    const response = await fetch(doc.src);
                    const html = await response.text();
                    dataProvider.create(
                      'alumno_documentos',
                      {
                        data: {
                          alumnoId: userId,
                          src: doc.src,
                          title: doc.title,
                          moduloId,
                          cursoId: cursoId || '',
                          enAula: enAula,
                          body: html.split(`src="${encodeURI(doc.title)}_files`).join(`src="https://www.portuguesplus.co/wp-content/uploads/2022/${encodeURI(doc.title)}_files`),
                        },
                      },
                    )
                      .then(({ data }) => {
                        console.log(data);
                        if (!enAula) {
                          abrodocumento({
                            ...data, alumnoId: userId, src: doc.src, body: html.split(`src="${encodeURI(doc.title)}_files`).join(`src="https://www.portuguesplus.co/wp-content/uploads/2022/${encodeURI(doc.title)}_files`),
                          });
                        }
                      });
                  } catch (error) {
                    console.error(error);
                  }
                } else {
                  console.log(usuarioDoc[0]);
                  if (!enAula) abrodocumento(usuarioDoc[0]);
                }
              } else { window.open(doc.src, '_blank'); }

              handleClose();
            }}
          >
            <ListItemIcon>
              <OpenWithIcon fontSize="small" />
            </ListItemIcon>
            { !enAula ? 'Abrir' : 'Compartir al grupo'}
          </MenuItem>
          {/* {(!perfil || (perfil && perfil !== 'alumno' && perfil !== 'profesor')) && (
            <MenuItem
              onClick={() => {
                const a = document.createElement('a');
                a.href = doc.src;
                a.setAttribute('download', doc.title);
                a.click();
                handleClose();
              }}
            >
              <ListItemIcon>
                <CloudDownloadIcon fontSize="small" />
              </ListItemIcon>
              {translate('ra.label.baixar')}
            </MenuItem>
          )} */}
          {/* <MenuItem onClick={handleClose}>
                        <ListItemIcon><VisibilityIcon fontSize="small"/></ListItemIcon>
                        Ver Permisos</MenuItem> */}
          <MenuItem
            onClick={() => {
              setShowDialog(true);
              handleClose();
            }}
          >
            <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon>
            {translate('ra.label.renomear')}
          </MenuItem>
          {(!perfil || JSON.stringify(perfil) === 'undefined' || (perfil && (perfil === 'coordinador' || perfil === 'admin'))) && (
            <MenuItem
              onClick={() => {
                setShowDialog(true);
                handleClose();
              }}
            >
              <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon>
              {translate('ra.label.renomear')}
            </MenuItem>
          )}
          {(!perfil || (perfil && (perfil === 'coordinador' || perfil === 'admin'))) && (
            <MenuItem
              onClick={() => {
                deleteItem(doc);
                handleClose();
              }}
            >
              <ListItemIcon><DeleteOutlineIcon fontSize="small" /></ListItemIcon>
              {translate('ra.label.excluir')}
            </MenuItem>
          )}

        </Menu>
        <img
          alt="Icono"
          id={`img-${doc.title}-index-${index}`}
          style={{ width: 60 }}
          src={doc.src && doc.src.split('.').pop() === 'pdf'
            ? PdfIcon
            : doc.src && doc.src.includes('youtube') ? YoutubeIcon
              : (doc.src && doc.src.split('.').pop() === 'doc') || (doc.src && doc.src.split('.').pop() === 'docx' || doc.src && doc.src.split('.').pop() === 'html')
                ? WordIcon
                : (doc.src && doc.src.split('.').pop() === 'png') || (doc.src && doc.src.split('.').pop() === 'jpg' || doc.src && doc.src.split('.').pop() === 'jpeg')
                  ? ImageIcon
                  : doc.src && doc.src.split('.').pop() === 'ppt' || (doc.src && doc.src.split('.').pop() === 'pptx')
                    ? PPtIcon
                    : doc.src && doc.src.split('.').pop() === 'zip'
                      ? ZipIcon
                      : (doc.src && doc.src.split('.').pop() === 'xls') || (doc.src.split('.').pop() === 'xlsx')
                        ? ExcelIcon
                        : WordIcon}
        />
        <Typography id={`title-${doc.title}-index-${index}`} noWrap className="docTitle">{doc.title}</Typography>
      </div>
      <Dialog
        fullWidth
        fullScreen
        open={open}
        onClose={() => setShowDialog(false)}
      >
        <DialogTitle />
        {/* <FormWithRedirect
          resource="alumno_documentos"
          save={handleSubmit}
          render={({
            handleSubmitWithRedirect,
          }) => ( */}
        <>
          <DialogContent style={{
            paddingTop: 0,
            paddingLeft: 10,
            paddingRight: 10,
          }}
          >
            {/* <RichTextInput label='' source="body" defaultValue={docHtml.body} /> */}
            {/* <CKEditor
                        editor={ ClassicEditor }
                        data={values.body}
                        onReady={ editor => {
                            console.log( 'Editor is ready to use!', editor );
                        } }
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            setValues({body:data})
                            console.log( { event, editor, data } );
                        } }
                    /> */}
            {louza
              ? (
                <div style={{ display: 'flex', height: '100%' }}>
                  <div style={{
                    marginRight: 20, flex: 1, paddingBottom: 50, height: '100%',
                  }}
                  >
                    <Typography
                      style={{ marginBottom: 10 }}
                      variant="h4"
                    >
                      {translate('ra.label.lousa')}
                    </Typography>
                    {louza}
                  </div>
                  <div style={{ marginRight: 20, flex: 2 }}>
                    <Editor
                      apiKey="uy3q5l7cf7dleypasrmsoj07udywg77xp86c6l6zenisb1vm"
                      onInit={(evt, editor) => editorRef.current = editor}
                      value={values.body}
                      onEditorChange={(content) => ({ body: content })}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace textcolor visualblocks code fullscreen',
                          'insertdatetime image media table paste code help wordcount',
                        ],
                        toolbar: 'undo redo | formatselect | '
                            + 'bold italic forecolor backcolor | alignleft aligncenter '
                            + 'alignright alignjustify | bullist numlist outdent indent | '
                            + 'media image table | bullist numlist outdent indent | '
                            + 'removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial, sans-serif; font-size:14px }',
                      }}
                    />
                  </div>
                </div>
              )
              : (
                <Editor
                  apiKey="uy3q5l7cf7dleypasrmsoj07udywg77xp86c6l6zenisb1vm"
                  onInit={(evt, editor) => editorRef.current = editor}
                  value={values.body}
                  onEditorChange={(content) => {
                    setValues({ body: content });
                  }}
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace textcolor visualblocks code fullscreen',
                      'insertdatetime image media table paste code help wordcount',
                    ],
                    toolbar: 'undo redo | formatselect | '
                        + 'bold italic forecolor backcolor | alignleft aligncenter '
                        + 'alignright alignjustify | bullist numlist outdent indent | '
                        + 'media image table | bullist numlist outdent indent | '
                        + 'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial, sans-serif; font-size:14px }',
                  }}
                />
                // <MyEditor
                //   handleCloseClick={() => setOpen(false)}
                //   user={
                //   localStorage.getItem('profe')
                //     ? JSON.parse(localStorage.getItem('profe'))
                //     : localStorage.getItem('alumno')
                //       ? JSON.parse(localStorage.getItem('alumno'))
                //       : JSON.parse(localStorage.getItem('user'))
                //   }
                //   dataProvider={dataProvider}
                //   docHtml={docHtml}
                //   iddocumento={`2title-${doc.title}-index-${index}`}
                // />
              )}
          </DialogContent>
          {/* <DialogActions>
            <Button
              className="cancelar-btn"
              label="Cerrar sin guardar"
              onClick={() => setOpen(false)}
            >
              <IconCancel />
            </Button>
            {!loading ? (
              <SaveButton
                handleSubmitWithRedirect={
                              handleSubmitWithRedirect
                          }
              />
            ) : <Button label="Guardando" disabled />}
          </DialogActions> */}
        </>
        {/* )}
        /> */}
      </Dialog>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={() => setShowDialog(false)}
      >
        <DialogTitle>
          {translate('ra.label.trocar_nome')}
        </DialogTitle>
        <DialogContent>
          <MUITextField
            onChange={(e) => setName(e.target.value)}
            variant="outlined"
            fullWidth
            label="ra.label.nome"
            defaultValue={doc.title}
          />
        </DialogContent>
        <DialogActions>
          <Button
            label="ra.label.fechar"
            onClick={() => setShowDialog(false)}
          >
            <IconCancel />
          </Button>
          <Button
            label="ra.label.salvar"
            onClick={() => {
              renameItem(name, index);
              setShowDialog(false);
            }}
          >
            <SaveIcon />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function MisIconos({
  id, deleteItem, renameItem, louza, enAula, cursoId,
}) {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [carpetas, setCarpetas] = useState([]);
  const [recordBK, setRecordBk] = useState(null);
  const perfil = localStorage.getItem('perfil') || null;

  function getChilds() {
    setLoading(true);

    // console.log(record.objectId)
    // if(!record)
    dataProvider.getOne('material', { id })
      .then(({ data }) => {
        setRecordBk(data);
        setLoading(false);
      });

    dataProvider.getList('material', { filter: { padreId: id } })
      .then(({ data }) => {
        setCarpetas(data);
      });
  }
  useEffect(() => getChilds(), [id]);
  // useEffect(()=>console.log(id), [id])

  return (
    <div style={{
      display: 'flex', flexDirection: 'row', alignItems: 'stretch', flexWrap: 'wrap', minHeight: 100,
    }}
    >
      {(loading) && <CircularProgress />}
      {!loading && record && carpetas.map((carpeta, index) => (
        <MyDir carpeta={carpeta} index={index} />
      ))}
      {!loading && recordBK && recordBK.pictures
        ? recordBK.pictures.sort((a, b) => (a.title > b.title ? 1 : -1)).map((doc, index) => (
          <MyFile
            cursoId={cursoId}
            enAula={enAula}
            louza={louza}
            doc={doc}
            index={index}
            deleteItem={deleteItem}
            renameItem={renameItem}
            moduloId={recordBK.moduloId}
          />
        ))
        : !loading && !recordBK ? (
          <Typography>
            Parece que hubo un error cargando el material!
            {JSON.stringify(recordBK)}
          </Typography>
        )
          : null}
      {recordBK && perfil && perfil !== 'alumno' && perfil !== 'profesor' && !enAula && <CreateCarpetaMaterialButton getChilds={getChilds} record={recordBK} />}
    </div>
  );
}

export function MaterialEdit(props) {
  return (
    <Edit title="Material" undoable={false} {...props}>
      <TabbedForm redirect="edit">
        <FormTab label="Permisos">
          <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
            <img src={VoltarIcon} alt="Voltar" />
          </Button>
          <ArrayInput source="permisos">
            <SimpleFormIterator
              className="RaSimpleFormIterator"
              addButton={<Button label="Agregar"><AddCircleIcon /></Button>}
              removeButton={<Button><RemoveCircleIcon /></Button>}
            >
              <ReferenceInput
                perPage={100000}
                variant="outlined"
                sort={{ field: 'nombre', order: 'ASC' }}
                label="Alumno"
                source="alumno"
                reference="alumnos"
              >
                <AutocompleteInput
                  fullWidth
                  variant="outlined"
                  optionText="nombre"
                />
              </ReferenceInput>
              <CheckboxGroupInput
                label="Accesos"
                source="acceso"
                choices={[
                  { id: 'Leer', name: 'Leer' },
                  { id: 'Editar', name: 'Editar' },
                  { id: 'Descargar', name: 'Descargar' },
                ]}
              />
              <SelectInput
                fullWidth
                variant="outlined"
                source="status"
                label="Status"
                choices={[
                  { id: 'Activo', name: 'Activo' },
                  { id: 'Inactivo', name: 'Inactivo' },
                  { id: 'Bloqueado', name: 'Bloqueado' },
                ]}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

// function PostTitle({ record }) {
//   return (
//     <span style={{ fontSize: 28 }}>
//       Carpeta:
//       {record.nombre}
//     </span>
//   );
// }

function CustomToolbar(props) {
  return (
    <Toolbar {...props}>
      {localStorage.getItem('perfil')
        && localStorage.getItem('perfil') !== 'alumno'
        && localStorage.getItem('perfil') !== 'profesor' && <SaveButton />}
      {localStorage.getItem('perfil')
        && localStorage.getItem('perfil') !== 'alumno'
        && localStorage.getItem('perfil') !== 'profesor' && <DeleteButton undoable={false} />}
    </Toolbar>
  );
}

export function MaterialShow(props) {
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState([]);
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const renameItem = (name, index) => {
    const GameScore = Parse.Object.extend('material');
    const query = new Parse.Query(GameScore);
    query.get(props.id)
      .then((gameScore) => {
        const myPictures = gameScore.get('pictures') ? gameScore.get('pictures') : [];
        myPictures[index].title = name;
        gameScore.set('pictures', myPictures);
        gameScore.save().then(() => refresh());
      }, (error) => {
        console.log(error);
      });
  };

  const deleteItem = (doc) => {
    const GameScore = Parse.Object.extend('material');
    const query = new Parse.Query(GameScore);
    query.get(props.id)
      .then((gameScore) => {
        const myPictures = gameScore.get('pictures') ? gameScore.get('pictures') : [];
        const myIndex = myPictures.findIndex(
          (item) => item.title === doc.title && item.src === doc.src,
        );
        myPictures.splice(myIndex, 1);
        gameScore.set('pictures', myPictures);
        gameScore.save().then(() => refresh());
      }, (error) => {
        console.log(error);
      });
  };

  function removeUploading(filename) {
    const myIndex = uploading.findIndex(
      (item) => item === filename,
    );
    const myUploadingTemp = uploading;
    myUploadingTemp.splice(myIndex, 1);
    setUploading(myUploadingTemp);
    if (!myUploadingTemp.length) {
      refresh();
    }
  }

  function subirArchivo(file, dirId) {
    const filename = file.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').substring(0, 28);
    setLoading(true);
    const uploadingT = [...uploading];
    setUploading([...uploadingT, filename]);
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    // eslint-disable-next-line no-unused-vars
    reader.onload = (event) => {
      const parseFile = new Parse.File(filename, file);
      parseFile.save().then(async () => {
        // setLoading(true);
        const url = parseFile.url().replace('http://localhost:1337', parseConfig.DOMAIN);
        const newpic = {
          src: url,
          title: file.name.replace(/\.[^/.]+$/, ''),
        };
        const GameScore = Parse.Object.extend('material');
        const query = new Parse.Query(GameScore);
        query.get(dirId)
          .then((gameScore) => {
            gameScore.set(
              'pictures',
              gameScore.get('pictures')
                ? [...gameScore.get('pictures'), newpic]
                : [newpic],
            );
            gameScore.save().then(() => {
              console.log(gameScore.get('pictures'));
              // setLoading(false);
              removeUploading(filename);
            });
          }, (error) => {
            console.log(error);
            removeUploading(filename);
            setLoading(false);
          });
      }, (error) => {
        alert(error);
        console.log(error, file.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
        setLoading(false);
        removeUploading(filename);
      });
    };
  }

  // const { getRootProps, getInputProps, acceptedFiles } = useDropzone({ onDrop });
  // const files = acceptedFiles.map((file) => {
  //   console.log(file);

  //   return (
  //     <li key={file.path}>
  //       {file.path}
  //       <CircularProgress size={20} />
  //     </li>
  //   );
  // });

  function MyDropZone(props) {
    return (
      <Dropzone onDrop={async (acceptedFiles) => {
        setLoading(true);
        console.log('..............', props);
        await Parse.User.logIn('crobinson', 'crobinson');
        // Parse.User.logIn('crobinson', 'crobinson')
        // .then(user=>{
        /**
                     * Armo un arreglo con los dirctorios que voy creando
                     */
        const miscarpetas = [];
        let padreActual = props.id;
        // acceptedFiles.forEach(async(file) => {
        for (const file of acceptedFiles) {
          const pathArr = file.path.split('/');
          console.log(file.path);
          if (pathArr.length > 1) {
            /*
                            *Está dentro de un directorio.
                            *1. Mapeo los directorios y si no están en mi carpeta temporal reviso
                            *vs el api si existen en la base de datos con su estructura y si no;
                            *los creo
                            *Es importante que el orden en el que llegan contiene la gerarquía
                            */
            for (const directorio of pathArr) {
              //! miscarpetas.includes(directorio) &&
              if (directorio.length && directorio !== file.name) {
                miscarpetas.push(directorio);
                console.log(directorio);
                // Busco si existe y si no lo creo
                // eslint-disable-next-line max-len
                // const {data:carpetas} = await dataProvider.getList('material', {filter:{padreId: padreActual, nombre:directorio}})
                const response = await fetch(
                  `${parseConfig.URL}/classes/material?where={"padreId":"${props.id}", "nombre":"${directorio}"}`,
                  {
                    headers: {
                      'Content-Type': 'application/json',
                      'X-Parse-Application-Id': parseConfig.APP_ID,
                      'X-Parse-REST-API-Key': 'PPlusr3stk3y',
                      'X-Parse-Master-Key': parseConfig.MASTER_KEY,
                    },
                  },
                );
                const json = await response.json();
                const carpetas = json.results;
                console.log(carpetas.length);
                if (!carpetas.length) {
                  console.log(`creo al directorio:${directorio}`);
                  const { data: carpetacreate } = await dataProvider.create('material', { data: { padreId: props.id, nombre: directorio } });
                  console.log(carpetacreate);
                  padreActual = carpetacreate.id;
                  /**
                                         * Creo sus archivos
                                         */
                } else {
                  console.log(carpetas);
                  console.log(`ya existía directorio:${directorio}`);
                  // eslint-disable-next-line no-unused-vars
                  padreActual = carpetas[0].objectId;
                }
              }
            }
          }

          subirArchivo(file, padreActual);
          //   })
        }
        // setLoading(false);
      }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'transparent',
              borderColor: '#518F18',
              borderStyle: 'dashed',
              borderWidth: 1,
              borderRadius: 16,
              padding: 20,

            }}
            >
              <p>
                <img src={FileIcon} alt="Archivo" />
              </p>
              <p>
                {translate('ra.label.arraste_solte')}
              </p>
            </div>
          </div>
        )}
      </Dropzone>
    );
  }
  const translate = useTranslate();

  if (loading) {
    return (
      <>
        <Loading loadingPrimary="Espere" loadingSecondary="Cargando archivos..." />
        {uploading && uploading.length > 0 ? (
          <div style={{ position: 'fixed', bottom: 0, padding: 4 }}>
            <Paper variant="outlined">
              <Typography variant="h5">{translate('ra.label.arquivos_uploading')}</Typography>
              <MUIList dense>
                {uploading.map((item) => (
                  <ListItem>
                    <ListItemIcon>
                      <FolderIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={item}
                      secondary={<LinearProgress />}
                    />
                  </ListItem>
                ))}
              </MUIList>
            </Paper>
          </div>
        ) : null}
      </>
    );
  }

  return (
    <Edit title="Material" undoable={false} {...props} actions={<EmptyActions />}>
      <TabbedForm toolbar={<CustomToolbar />} redirect="edit">
        <FormTab label="ra.label.material">
          <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
            <img src={VoltarIcon} alt="Voltar" />
          </Button>
          {localStorage.getItem('perfil') !== 'alumno' && (
          <>
            <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
              <Box
                flex={1}
                mr={{ xs: 0, sm: '0.5em' }}
              >
                <TextInput variant="outlined" fullWidth label="ra.label.nome_pasta" source="nombre" />
              </Box>
              <Box
                flex={1}
                ml={{ xs: 0, sm: '0.5em' }}
              >
                <ReferenceInput
                  label="ra.label.modulo"
                  perPage={100000}
                  sort={{ field: 'name', order: 'ASC' }}
                  variant="outlined"
                  style={{ minWidth: '50%' }}
                  source="moduloId"
                  reference="modulos"
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
                <CreateModuloButton />
              </Box>
            </Box>
            <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
              <Box
                flex={1}
                mr={{ xs: 0, sm: '0.5em' }}
              >
                <TextInput minRows={6} multiline variant="outlined" fullWidth label="ra.label.descricao" source="descripcion" />
              </Box>
              <Box flex={1} />
            </Box>
            <Typography style={{ marginBottom: 10 }} variant="h4">{translate('ra.label.adicionar_arquivo')}</Typography>
          </>
          )}

          <Box style={{ display: 'block' }} fullWidth display={{ xs: 'block', sm: 'block' }}>
            <MisIconos id={props.id} source="pictures" deleteItem={deleteItem} renameItem={renameItem} />
            {localStorage.getItem('perfil') !== 'alumno' && <MyDropZone id={props.id} />}
            {localStorage.getItem('perfil') !== 'alumno' && <CreateLinkButton {...props} />}
          </Box>
          {uploading && uploading.length > 0 ? (
            <div style={{ position: 'fixed', bottom: 0, padding: 4 }}>
              <Paper variant="outlined">
                <Typography variant="h5">{translate('ra.label.arquivos_uploading')}</Typography>
                <MUIList dense>
                  {uploading.map((item) => (
                    <ListItem>
                      <ListItemIcon>
                        <FolderIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={item}
                        secondary={<LinearProgress />}
                      />
                    </ListItem>
                  ))}
                </MUIList>
              </Paper>
            </div>
          ) : null}
        </FormTab>
        {localStorage.getItem('perfil') !== 'alumno' && (
        <FormTab label="ra.label.permissoes">
          <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
            <img src={VoltarIcon} alt="Voltar" />
          </Button>
          <ArrayInput source="permisos">
            <SimpleFormIterator
              className="RaSimpleFormIterator"
              addButton={<Button label="Agregar"><AddCircleIcon /></Button>}
              removeButton={<Button><RemoveCircleIcon /></Button>}
            >
              <ReferenceInput
                perPage={100000}
                variant="outlined"
                label="Alumno"
                source="alumno"
                reference="alumnos"
              >
                <AutocompleteInput
                  fullWidth
                  variant="outlined"
                  optionText="nombre"
                />
              </ReferenceInput>
              <CheckboxGroupInput
                label="Accesos"
                source="acceso"
                choices={[
                  { id: 'Leer', name: 'Leer' },
                  { id: 'Editar', name: 'Editar' },
                  { id: 'Descargar', name: 'Descargar' },
                ]}
              />
              <SelectInput
                fullWidth
                variant="outlined"
                source="status"
                label="Status"
                choices={[
                  { id: 'Activo', name: 'Activo' },
                  { id: 'Inactivo', name: 'Inactivo' },
                  { id: 'Bloqueado', name: 'Bloqueado' },
                ]}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        )}
      </TabbedForm>
    </Edit>
  );
}

function EmptyActions() {
  return <TopToolbar />;
}
