import {
  AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR,
} from 'react-admin';
import Parse from 'parse';

export default (parseConfig) => {
  const manageAuth = (type, params) => {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-Parse-Application-Id': parseConfig['APP-ID'],
      'X-Parse-REST-API-Key': parseConfig['REST-API-KEY'],
    });

    if (type === AUTH_LOGIN) {
      const { username, password } = params;
      if (Parse.applicationId === null) {
        Parse.initialize(parseConfig['APP-ID'], null);
        Parse.serverURL = parseConfig.URL;
      }
      Parse.User.logIn(username, password);

      const request = new Request(`${parseConfig.URL}/login?username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`, {
        method: 'GET',
        headers,
      });
      return fetch(request)
        .then((response) => {
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then((response) => {
          fetch(
            `${parseConfig.URL}/classes/profesores?where={"usuarioId":${JSON.stringify(response.objectId)}}`,
            {
              headers: {
                'Content-Type': 'application/json',
                'X-Parse-Application-Id': parseConfig.APP_ID,
                'X-Parse-REST-API-Key': 'PPlusr3stk3y',
              },
            },
          )
            .then((response) => response.json())
            .then(({ results }) => {
              if (results && results.length) { localStorage.setItem('profe', JSON.stringify(results[0])); }
            });
          fetch(
            `${parseConfig.URL}/classes/alumnos?where={"usuarioId":${JSON.stringify(response.objectId)}}`,
            {
              headers: {
                'Content-Type': 'application/json',
                'X-Parse-Application-Id': parseConfig.APP_ID,
                'X-Parse-REST-API-Key': 'PPlusr3stk3y',
              },
            },
          )
            .then((response) => response.json())
            .then(({ results }) => {
              if (results && results.length) {
                localStorage.setItem('alumno', JSON.stringify(results[0]));
                // console.log('alumno:', results);
                /** Mis cursos */
                fetch(
                  `${parseConfig.URL}/classes/cursos?where={"alumnos":${JSON.stringify(response.objectId)}}`,
                  {
                    headers: {
                      'Content-Type': 'application/json',
                      'X-Parse-Application-Id': parseConfig.APP_ID,
                      'X-Parse-REST-API-Key': 'PPlusr3stk3y',
                    },
                  },
                )
                  .then((response) => response.json())
                  .then(({ results }) => {
                    localStorage.setItem('miscursos', JSON.stringify(results[0]));
                    console.log('miscursos:', results);
                  });
              }
            });
          localStorage.setItem('userId', response.objectId);
          localStorage.setItem('user', JSON.stringify(response));
          localStorage.setItem('perfil', response.perfil);
          localStorage.setItem('parseToken', response.sessionToken);
        });
    }
    if (type === AUTH_LOGOUT) {
      if (localStorage.getItem('parseToken')) {
        headers.set('X-Parse-Session-Token', localStorage.getItem('parseToken'));
        const request = new Request(`${parseConfig.URL}/logout`, {
          method: 'POST',
          headers,
        });
        return fetch(request)
          .then((response) => {
            localStorage.removeItem('parseToken');
            localStorage.removeItem('userId');
            localStorage.removeItem('user');
            localStorage.removeItem('perfil');
            localStorage.removeItem('profe');
            localStorage.removeItem('alumno');
            localStorage.removeItem('miscursos');
            // window.location.href = 'https://www.portuguesplus.co';
            if (response.status < 200 || response.status >= 300) {
              response.json().then((object) => {
                if (object.code !== 209) {
                  throw new Error(object.error);
                }
              });
            }
          });
      }

      return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
      const { status } = params;
      if (status === 209) {
        localStorage.removeItem('parseToken');
        return Promise.reject();
      }
      return Promise.resolve();
    }
    if (type === AUTH_CHECK) {
      return localStorage.getItem('parseToken') ? Promise.resolve() : Promise.reject();
    }
    return Promise.reject('Unkown method');
  };

  return (type, params) => manageAuth(type, params);
};
