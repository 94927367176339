import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  DateInput,
  SelectInput,
  RadioButtonGroupInput,
  useTranslate,
  FormDataConsumer,
} from 'react-admin';
import {
  Box, Tabs, Tab,
} from '@material-ui/core';
import IconCancel from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Dialog from '@material-ui/core/Dialog';
import { Country, State, City } from 'country-state-city';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { ButtonDefault } from './Buttons';

function CreateProfButton() {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('profesores');
  const notify = useNotify();
  const form = useForm();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          form.change('profId', data.id);
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        },
      },
    );
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const translate = useTranslate();
  return (
    <>
      <Button
        onClick={handleClick} // onClick={()=>redirect('/profesores/create')}
        label=""
      >
        <AddCircleIcon />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        maxWidth="xl"
      >
        <DialogTitle>{translate('ra.label.adicionar_prof')}</DialogTitle>
        <FormWithRedirect
          resource="moviles"
          save={handleSubmit}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving,
          }) => (
            <>
              <DialogContent>
                <Tabs
                  value={value}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChange}
                  aria-label="disabled tabs example"
                >
                  <Tab label={translate('ra.label.perfil')} />
                  <Tab label={translate('ra.label.dados_pgto')} />
                </Tabs>
                {value === 0 && (
                <>
                  <TextInput label="ra.label.nome" variant="outlined" fullWidth validate={required()} source="nombre" />
                  <TextInput label="ra.label.email" variant="outlined" fullWidth source="correo" />
                  <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
                    <Box
                      flex={1}
                      mr={{ xs: 0, sm: '0.5em' }}
                    >
                      <TextInput label="ra.label.celular" variant="outlined" source="celular" />
                    </Box>
                    <Box
                      flex={1}
                      ml={{ xs: 0, sm: '0.5em' }}
                    >
                      <TextInput label="ra.label.telefone" variant="outlined" source="telefono" />
                    </Box>
                  </Box>
                  <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
                    <Box
                      flex={1}
                      mr={{ xs: 0, sm: '0.5em' }}
                    >
                      <TextInput label="ra.label.cargos" fullWidth variant="outlined" source="cargo" />
                    </Box>
                  </Box>
                  <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
                    <Box
                      flex={0.5}
                      mr={{ xs: 0, sm: '0.5em' }}
                    >
                      <SelectInput
                        fullWidth
                        variant="outlined"
                        source="doc_type"
                        label="ra.label.doc_ident"
                        choices={[
                          { id: 'CPF', name: 'ra.label.cpf' },
                          { id: 'Passaporte', name: 'ra.label.passaporte' },
                          { id: 'Ced_RG', name: 'ra.label.rg' },
                          { id: 'Ced_RGE', name: 'ra.label.rge' },
                          { id: 'SSN', name: 'ra.label.ssn' },
                        ]}
                      />
                    </Box>
                    <Box
                      flex={1}
                      ml={{ xs: 0, sm: '0.5em' }}
                    >
                      <TextInput fullWidth variant="outlined" source="no_identificacion" label="ra.label.n_identificacao" />
                    </Box>
                  </Box>
                  <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
                    <Box
                      flex={1}
                      mr={{ xs: 0, sm: '0.5em' }}
                    >
                      <DateInput fullWidth variant="outlined" source="fecha_nacimiento" label="ra.label.data_nascimento" />
                    </Box>
                    <Box
                      flex={1}
                      ml={{ xs: 0, sm: '0.5em' }}
                    >
                      <SelectInput
                        fullWidth
                        variant="outlined"
                        source="genero"
                        label="ra.label.genero"
                        choices={[
                          { id: 'Femenino', name: 'ra.label.feminino' },
                          { id: 'Masculino', name: 'ra.label.masculino' },
                          { id: 'Transgénero', name: 'ra.label.transgenero' },
                          { id: 'Transgénero', name: 'ra.label.outro' },
                        ]}
                      />
                    </Box>
                  </Box>
                  <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
                    <Box
                      flex={1}
                      mr={{ xs: 0, sm: '0.5em' }}
                    >
                      {/* <TextInput fullWidth label='País' variant='outlined' source="pais" /> */}
                      <SelectInput
                        fullWidth
                        variant="outlined"
                        label="ra.label.pais"
                        optionText={(choice) => `${choice.flag} ${choice.name}`}
                        optionValue="isoCode"
                        source="pais"
                        choices={Country.getAllCountries()}
                      />
                    </Box>
                    <Box
                      flex={1}
                      ml={{ xs: 0, sm: '0.5em' }}
                    >
                      <FormDataConsumer>
                        {({ formData, ...rest }) => (
                          <SelectInput
                            fullWidth
                            label="ra.label.estado"
                            source="distrito"
                            variant="outlined"
                            optionValue="isoCode"
                            choices={
                                  formData.pais
                                    ? State.getStatesOfCountry(formData.pais)
                                    : []
                              }
                            {...rest}
                          />
                        )}
                      </FormDataConsumer>
                    </Box>
                    <Box
                      flex={1}
                      ml={{ xs: 0, sm: '0.5em' }}
                    >
                      <FormDataConsumer>
                        {({ formData, ...rest }) => (
                          <SelectInput
                            fullWidth
                            variant="outlined"
                            label="ra.label.cidade"
                            source="ciudad"
                            optionValue="name"
                            choices={
                                  formData.distrito
                                    ? City.getCitiesOfState(formData.pais, formData.distrito)
                                    : []
                              }
                            {...rest}
                          />
                        )}
                      </FormDataConsumer>
                    </Box>
                  </Box>
                  <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
                    <Box
                      flex={1}
                      mr={{ xs: 0, sm: '0.5em' }}
                    >
                      <TextInput validate={required()} fullWidth variant="outlined" label="ra.label.endereco" source="direccion" />
                    </Box>
                    <Box
                      flex={1}
                      ml={{ xs: 0, sm: '0.5em' }}
                    />
                  </Box>
                </>
                )}
                {value === 1 && (
                <>
                  <RadioButtonGroupInput
                    fullWidth
                    source="tipo_cuenta"
                    choices={[
                      { id: 'Ahorro', name: 'Ahorro' },
                      { id: 'Corriente', name: 'Corriente' },
                    ]}
                  />
                  <TextInput fullWidth variant="outlined" source="banco" />
                  <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
                    <Box
                      flex={1}
                      mr={{ xs: 0, sm: '0.5em' }}
                    >
                      <TextInput fullWidth variant="outlined" source="numero_de_cuenta" />
                    </Box>
                    <Box
                      flex={1}
                      ml={{ xs: 0, sm: '0.5em' }}
                    >
                      <DateInput fullWidth variant="outlined" source="fecha_de_pago" />
                    </Box>
                  </Box>
                </>
                )}
              </DialogContent>
              <DialogActions>
                <ButtonDefault
                  buttonClass="branco-azul"
                  label="ra.label.cancelar"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </ButtonDefault>
                <SaveButton
                  label="ra.label.salvar"
                  className="destaque-verde"
                  handleSubmitWithRedirect={
                                  handleSubmitWithRedirect
                              }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default CreateProfButton;
