import React, { useEffect } from 'react';
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  DateInput,
  Loading,
  useRedirect,
  SelectInput,
  TabbedForm,
  FormTab,
  RadioButtonGroupInput,
  FileField,
  FileInput,
} from 'react-admin';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {
  Box,
} from '@material-ui/core';
import AddImg from './static/img/addImg.png';

export function UserList() {
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const redirect = useRedirect();
  useEffect(() => (currentUser ? redirect(`/users/${currentUser.id}`) : console.log('Error')));

  return (<Loading />);
}

function PostTitle() {
  return <span style={{ fontSize: 28 }}>Informaciones Personal</span>;
}

// export const UserList = props => {
//   /**
//     Redirecciono a config
//    */
//    window.location.href = `#/configuracion`
// }

export function UserCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm
        redirect="list"
      >
        <TextInput fullWidth source="username" />
        <TextInput fullWidth source="password" />
      </SimpleForm>
    </Create>
  );
}
export function UserEdit(props) {
  return (
    <Edit title={<PostTitle />} undoable={false} {...props}>
      <TabbedForm redirect="edit">
        <FormTab label="Perfil">
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={0.3}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <img src={AddImg} alt="Agregar" />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <TextInput variant="outlined" fullWidth source="nombre" />
              <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
                <Box
                  flex={1}
                  mr={{ xs: 0, sm: '0.5em' }}
                >
                  <TextInput variant="outlined" source="celular" />
                </Box>
                <Box
                  flex={1}
                  ml={{ xs: 0, sm: '0.5em' }}
                >
                  <TextInput variant="outlined" source="telefono" />
                </Box>
              </Box>
            </Box>
          </Box>

          <TextInput variant="outlined" fullWidth source="correo" />
          <TextInput variant="outlined" source="cargo" label="Profesión" />
          <TextInput variant="outlined" source="no_identificacion" label="No. Identificación" />
          <DateInput variant="outlined" source="fecha_nacimiento" label="Fecha de nacimiento" />
          <SelectInput
            variant="outlined"
            source="genero"
            label="Género"
            choices={[
              { id: 'Femenino', name: 'Femenino' },
              { id: 'Masculino', name: 'Masculino' },
              { id: 'Transgénero', name: 'Transgénero' },
            ]}
          />
        </FormTab>
        <FormTab label="Dirección">
          <TextInput variant="outlined" fullWidth source="calle" />
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <TextInput variant="outlined" source="numero" />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <TextInput variant="outlined" source="complemento" />
            </Box>
          </Box>
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <TextInput fullWidth variant="outlined" source="distrito" />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            />
          </Box>
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <TextInput fullWidth variant="outlined" source="ciudad" />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            />
          </Box>
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <TextInput fullWidth label="País" variant="outlined" source="pais" />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            />
          </Box>
        </FormTab>
        <FormTab label="Datos de pago">
          <RadioButtonGroupInput
            source="tipo_cuenta"
            choices={[
              { id: 'Ahorro', name: 'Ahorro' },
              { id: 'Corriente', name: 'Corriente' },
            ]}
          />
          <TextInput variant="outlined" source="banco" />
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <FileInput
                multiple
                label="Root"
                source="documentos"
                placeholder={(
                  <div style={{
                    borderColor: 'black', borderStyle: 'dashed', borderWidth: 1, borderRadius: 16, padding: 20,
                  }}
                  >
                    <p><FileCopyIcon /></p>
                    <p>Arraste y suelte el archivo aqui</p>
                  </div>
                )}
              >
                <FileField source="src" title="title" />
              </FileInput>
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            />
          </Box>
          <TextInput variant="outlined" source="numero_de_cuenta" />
          <NumberInput variant="outlined" label="Día de pago" source="fecha_de_pago" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}
