import React, { useEffect, useState } from 'react';
import {
  List,
  Create,
  Edit,
  TextInput,
  TextField,
  Filter,
  Loading,
  FormDataConsumer,
  SelectInput,
  TabbedForm,
  FormTab,
  Pagination,
  useListContext,
  ReferenceManyField,
  ReferenceField,
  useRedirect,
  useDataProvider,
  Button,
  Confirm,
  Toolbar,
  SaveButton,
  required,
  CheckboxGroupInput,
} from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
// import AvatarEditor from 'react-avatar-editor';
import Avatar from 'react-avatar-edit';
import TrashIcon from '@material-ui/icons/Delete';
import {
  Box, CardContent, Card, IconButton,
  Typography, CircularProgress,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import Parse from 'parse';
import { Country, State, City } from 'country-state-city';
import LanguageDialog from './componentes/Lang';
import { DateInput } from './DateInput';
import AddImg from './static/img/addImg.png';
import VoltarIcon from './static/img/Voltar.png';
import SimpleDialog from './componentes/SelectUser';
import Whatsapp from './static/img/whatsapp.png';
import Telefono from './static/img/telefono.png';
import ProfeIcon from './static/img/miniuser@3x.png';

const cardStyle = {
  // width: 288,
  minHeight: 97,
  margin: '0.5em',
  display: 'block',
  verticalAlign: 'top',
};

function ProfFilter(props) {
  return (
    <Filter {...props}>
      <TextInput variant="outlined" label="Buscar por nombre" source="nombre" alwaysOn />
    </Filter>
  );
}

function PostCreateToolbar(props) {
  return (
    <Toolbar {...props} style={{ justifyContent: 'space-between' }}>
      <SaveButton label="ra.label.salvar" />
      <MyDeleteItem />
    </Toolbar>
  );
}

function MyDeleteItem(props) {
  const [openConfirm3, setOpenConfirm3] = useState(false);
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const { record, resource, basePath } = props;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(null);

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };
  useEffect(() => {
    if (selectedValue) setOpenConfirm3(true);
  }, [selectedValue]);

  return (
    <>
      <Button label="ra.label.excluir" style={{ color: 'red', margin: 10 }} onClick={() => setOpen(true)}><TrashIcon /></Button>
      <SimpleDialog resource="profesores" exclude={record.objectId} title={`Seleccione el profe que quedará con los grupos de ${record.nombre}`} selectedValue={selectedValue} open={open} onClose={handleClose} />
      <Confirm
        isOpen={openConfirm3}
        title={loading ? 'Borrar' : 'Espere....'}
        content={loading ? `Estás seguro de borrar ${record.nombre}?` : <CircularProgress />}
        onConfirm={async () => {
          if (selectedValue) {
            setLoading(true);
            /**
             * Busco los grupos y agenda del usuario y se los pongo al otro
             */
            const { data: cursoEventos } = await dataProvider.getList(
              'eventos',
              {
                filter: { profId: record.objectId },
                sort: { field: 'createdAt', order: 'DESC' },
                pagination: { page: 1, perPage: 50000 },
              },
            );

            if (cursoEventos && cursoEventos.length) {
              for (const evnt of cursoEventos) {
                await dataProvider.update('eventos', { id: evnt.objectId, data: { profId: selectedValue } });
              }
            }

            const { data: cursos } = await dataProvider.getList(
              'eventos',
              {
                filter: { profId: record.objectId },
                sort: { field: 'createdAt', order: 'DESC' },
                pagination: { page: 1, perPage: 50000 },
              },
            );
            if (cursos && cursos.length) {
              for (const curso of cursos) {
                await dataProvider.update('cursos', { id: curso.objectId, data: { profId: selectedValue } });
              }
            }

            await dataProvider.delete(resource, { id: record.objectId });

            setOpenConfirm3(false);
            redirect(basePath);
            setLoading(false);
          }
        }}
        onClose={() => setOpenConfirm3(false)}
      />
    </>
  );
}

function GruposGrid() {
  const { ids, data } = useListContext();
  return (
    <div style={{ margin: '1em' }}>
      {ids.map((id) => (
        <span
          style={{ marginRight: 20, display: 'block' }}
          className="list-text"
        >
          Curso:
          <TextField className="list-text" record={data[id]} label="" source="nombre" />
          <span style={{ marginLeft: 10 }}>Módulo: </span>
          <ReferenceField source="moduloId" record={data[id]} reference="modulos">
            <TextField className="list-text" source="name" />
          </ReferenceField>
        </span>
      ))}
    </div>
  );
}

function ProfesGrid() {
  const { ids, data, basePath } = useListContext();
  const redirect = useRedirect();
  return (
    <div style={{ margin: '1em' }}>
      {ids.map((id, index) => {
        /**
         * Reviso el cambio de letra
         */
        const idAnterior = ids[index - 1];
        if (!data[idAnterior]
          || (data[idAnterior]
            && data[id].nombre.charAt(0) !== data[idAnterior].nombre.charAt(0))) {
          return (
            <>
              <div id={`title${id}`} style={{ display: 'block', padding: 10 }}>
                <Typography variant="h4">{data[id].nombre.charAt(0)}</Typography>
              </div>
              <Card variant="outlined" key={id} style={cardStyle}>
                <CardContent>
                  <div
                    className="profe-container"
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => redirect(`${basePath}/${id}`)}
                  >
                    <div className="avatar-container">
                      <img style={{ width: 50 }} src={data[id] && data[id].avatar ? data[id].avatar : ProfeIcon} alt="Profe" />
                    </div>
                    <div className="info-container">
                      <TextField className="list-title" record={data[id]} source="nombre" />
                      <div className="list-actions">
                        <div className="list-action">
                          <ReferenceManyField record={data[id]} reference="cursos" target="profId" label="">
                            <GruposGrid />
                          </ReferenceManyField>
                        </div>
                        <Box fullWidth display={{ xs: 'flex', sm: 'flex' }} className="list-action">
                          <Box style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          >
                            <img alt="Whatsapp" src={Whatsapp} style={{ margin: 10 }} />
                            <a className="list-text" target="_blank" href={`https://wa.me/${data[id].celular ? data[id].celular.split('+').join('') : null}`} rel="noreferrer">
                              <TextField className="list-text" record={data[id]} source="celular" />
                            </a>
                          </Box>
                          <Box style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          >
                            <img alt="Telefono" src={Telefono} style={{ margin: 10 }} />
                            <a className="list-text" href={`tel:${data[id].celular}`}>
                              <TextField className="list-text" record={data[id]} source="telefono" />
                            </a>
                          </Box>
                        </Box>
                      </div>
                      {/* JSON.stringify(data[id]) */}
                    </div>
                  </div>
                </CardContent>
              </Card>
            </>
          );
        }

        return (
          <Card variant="outlined" key={id} style={cardStyle}>
            <CardContent>
              <div
                className="profe-container"
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => redirect(`${basePath}/${id}`)}
              >
                <div className="avatar-container">
                  <img style={{ width: 50 }} src={data[id] && data[id].avatar ? data[id].avatar : ProfeIcon} alt="Profe" />
                </div>
                <div className="info-container">
                  <TextField className="list-title" record={data[id]} source="nombre" />
                  <div className="list-actions">
                    <div className="list-action">
                      <ReferenceManyField record={data[id]} reference="cursos" target="profId" label="">
                        <GruposGrid />
                      </ReferenceManyField>
                    </div>
                    <Box fullWidth display={{ xs: 'flex', sm: 'flex' }} className="list-action">
                      <Box style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      >
                        <img alt="Whatsapp" src={Whatsapp} style={{ margin: 10 }} />
                        <a className="list-text" target="_blank" href={`https://wa.me/${data[id].celular ? data[id].celular.split('+').join('') : null}`} rel="noreferrer">
                          <TextField className="list-text" record={data[id]} source="celular" />
                        </a>
                      </Box>
                      <Box style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      >
                        <img alt="Telefono" src={Telefono} style={{ margin: 10 }} />
                        <a className="list-text" href={`tel:${data[id].celular}`}>
                          <TextField className="list-text" record={data[id]} source="telefono" />
                        </a>
                      </Box>
                    </Box>
                  </div>
                  {/* JSON.stringify(data[id]) */}
                </div>
              </div>
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
}

export function ProfeList(props) {
  return (
    <List
      {...props}
      perPage={100}
      filters={<ProfFilter />}
      sort={{ field: 'nombre', order: 'ASC' }}
      pagination={<Pagination rowsPerPageOptions={[100, 150, 200]} {...props} />}
      exporter={false}
      bulkActionButtons={false}
    >
      <ProfesGrid />
      {/* <Datagrid>
            <TextField source="nombre" />
            <EditButton label="Editar" />
        </Datagrid> */}
    </List>
  );
}

export function ProfeCreate(props) {
  return (
    <Create {...props}>
      <TabbedForm redirect="edit">
        <FormTab label="ra.label.perfil">
          <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
            <img src={VoltarIcon} alt="Voltar" />
          </Button>
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={0.3}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <img alt="Agregar" src={AddImg} style={{ cursor: 'pointer' }} />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <TextInput variant="outlined" fullWidth label="ra.label.nome" source="nombre" />
              <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
                <Box
                  flex={1}
                  mr={{ xs: 0, sm: '0.5em' }}
                >
                  <TextInput variant="outlined" label="ra.label.celular" source="celular" />
                </Box>
                <Box
                  flex={1}
                  ml={{ xs: 0, sm: '0.5em' }}
                >
                  <TextInput variant="outlined" label="ra.label.telefone" source="telefono" />
                </Box>
              </Box>
            </Box>
          </Box>
          <TextInput validate={required()} label="ra.label.email" variant="outlined" fullWidth source="correo" />
          <CheckboxGroupInput
            label="ra.label.cargos"
            source="cargos"
            choices={[
              { id: 'Coordenador', name: 'ra.label.coordenador' },
              { id: 'Financeiro', name: 'ra.label.financeiro' },
              { id: 'Professor', name: 'ra.label.professor' },
            ]}
          />
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={0.5}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <SelectInput
                fullWidth
                variant="outlined"
                source="doc_type"
                label="ra.label.doc_ident"
                choices={[
                  { id: 'CPF', name: 'ra.label.cpf' },
                  { id: 'Passaporte', name: 'ra.label.passaporte' },
                  { id: 'Ced_RG', name: 'ra.label.rg' },
                  { id: 'Ced_RGE', name: 'ra.label.rge' },
                  { id: 'SSN', name: 'ra.label.ssn' },
                ]}
              />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <TextInput fullWidth variant="outlined" source="no_identificacion" label="ra.label.n_identificacao" />
            </Box>
          </Box>
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <DateInput validate={required()} source="fecha_nacimiento" label="ra.label.data_nascimento" />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <SelectInput
                validate={required()}
                variant="outlined"
                source="genero"
                label="ra.label.genero"
                choices={[
                  { id: 'Femenino', name: 'ra.label.feminino' },
                  { id: 'Masculino', name: 'ra.label.masculino' },
                  { id: 'Transgénero', name: 'ra.label.transgenero' },
                  { id: 'Otro', name: 'ra.label.outro' },
                ]}
              />
            </Box>
          </Box>
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              {/* <TextInput fullWidth label='País' variant='outlined' source="pais" /> */}
              <SelectInput
                fullWidth
                variant="outlined"
                label="ra.label.pais"
                optionText={(choice) => `${choice.flag} ${choice.name}`}
                optionValue="isoCode"
                source="pais"
                choices={Country.getAllCountries()}
              />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <SelectInput
                    fullWidth
                    label="ra.label.estado"
                    source="distrito"
                    variant="outlined"
                    optionValue="isoCode"
                    choices={
                          formData.pais
                            ? State.getStatesOfCountry(formData.pais)
                            : []
                      }
                    {...rest}
                  />
                )}
              </FormDataConsumer>
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <SelectInput
                    fullWidth
                    variant="outlined"
                    label="ra.label.cidade"
                    source="ciudad"
                    optionValue="name"
                    choices={
                          formData.distrito
                            ? City.getCitiesOfState(formData.pais, formData.distrito)
                            : []
                      }
                    {...rest}
                  />
                )}
              </FormDataConsumer>
            </Box>
          </Box>
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <TextInput validate={required()} fullWidth variant="outlined" label="ra.label.endereco" source="direccion" />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            />
          </Box>
        </FormTab>
        {/* ver como incluir dados diferentes para linguagens diferente, depois paises */}
      </TabbedForm>
    </Create>
  );
}

export function ProfeEdit(props) {
  const [open, setOpen] = React.useState(false);
  const [imagen, setImagen] = React.useState(null);
  const [preview, setPreview] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const inputFile = React.useRef(null);
  const dataProvider = useDataProvider();
  const perfil = localStorage.getItem('perfil') || null;

  function onChangeFile(event) {
    setLoading(true);
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    /**
     * Subo la imagen y después si la edito
     */
    Parse.User.logIn('crobinson', 'crobinson')
      .then(() => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const base64 = event.target.result;
          const parseFile = new Parse.File(file.name, { base64 });
          parseFile.save().then(() => {
            setImagen(parseFile.url());
            setLoading(false);
            //  resolve(parseFile.url().replace("http://localhost:1337", "https://interactiva.portuguesplus.com"));
          }, (error) => {
            setLoading(false);
            return error;
          });
        };

        reader.onerror = (error) => alert(JSON.stringify(error));
      });
  }

  useEffect(() => {
    dataProvider.getOne(props.resource, { id: props.id })
      .then(({ data: record }) => {
        if (record && record.avatar) {
          setPreview(record.avatar);
        }
      });
  }, []);

  useEffect(() => {
    if (imagen) {
      setOpen(true);
      setLoading(false);
    }
  }, [imagen]);

  function onClose() {
    setPreview(null);
  }

  function onCrop(preview) {
    setPreview(preview);
  }

  function onSavePreview() {
    dataProvider.update(props.resource, { id: props.id, data: { avatar: preview } })
      .then(() => setOpen(false));
  }

  function onBeforeFileLoad(elem) {
    if (elem.target.files[0].size > 71680) {
      alert('Archivo demasiado grande!');
      // eslint-disable-next-line no-param-reassign
      elem.target.value = '';
    }
  }

  if (loading) return <Loading loadingPrimary="Espere" loadingSecondary="Preparando el editor de fotos..." />;

  return (
    <Edit title="Perfil" undoable={false} {...props}>
      <TabbedForm redirect="edit" toolbar={<PostCreateToolbar />}>
        <FormTab label="ra.label.perfil">
          <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
            <img src={VoltarIcon} alt="Voltar" />
          </Button>
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={0.2}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              {!preview
                ? (
                  <img alt="Agregar" src={AddImg} onClick={() => inputFile.current.click()} style={{ cursor: 'pointer', width: 100 }} />
                ) : (
                  <img src={preview} onClick={() => inputFile.current.click()} style={{ cursor: 'pointer', width: 100 }} alt="Preview" />
                )}
              <input
                onChange={(e) => onChangeFile(e)}
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: 'none' }}
              />
              <Dialog onClose={() => setOpen(false)} aria-labelledby="simple-dialog-title" open={open}>
                <Avatar
                  width={390}
                  height={295}
                  onCrop={onCrop}
                  onClose={onClose}
                  onBeforeFileLoad={onBeforeFileLoad}
                  src={imagen}
                />
                <DialogActions>
                  <IconButton onClick={onSavePreview} color="primary">
                    <SaveIcon style={{ fontSize: 20 }} />
                  </IconButton>
                </DialogActions>
              </Dialog>
            </Box>
            <Box
              flex={0.2}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <LanguageDialog />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <TextInput validate={required()} variant="outlined" fullWidth source="nombre" label="ra.label.nome" />
              <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
                <Box
                  flex={1}
                  mr={{ xs: 0, sm: '0.5em' }}
                >
                  <TextInput validate={required()} variant="outlined" label="ra.label.celular" source="celular" />
                </Box>
                <Box
                  flex={1}
                  ml={{ xs: 0, sm: '0.5em' }}
                >
                  <TextInput variant="outlined" label="ra.label.telefone" source="telefono" />
                </Box>
              </Box>
            </Box>
          </Box>
          <TextInput validate={required()} label="ra.label.email" variant="outlined" fullWidth source="correo" />
          {(!perfil || (perfil && (perfil === 'coordinador' || perfil === 'admin'))) && (
            <CheckboxGroupInput
              label="ra.label.cargos"
              source="cargos"
              choices={[
                { id: 'Coordenador', name: 'ra.label.coordenador' },
                { id: 'Financeiro', name: 'ra.label.financeiro' },
                { id: 'Professor', name: 'ra.label.professor' },
              ]}
            />
          )}
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={0.5}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <SelectInput
                fullWidth
                variant="outlined"
                source="doc_type"
                label="ra.label.doc_ident"
                choices={[
                  { id: 'CPF', name: 'ra.label.cpf' },
                  { id: 'Passaporte', name: 'ra.label.passaporte' },
                  { id: 'Ced_RG', name: 'ra.label.rg' },
                  { id: 'Ced_RGE', name: 'ra.label.rge' },
                  { id: 'SSN', name: 'ra.label.ssn' },
                ]}
              />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <TextInput fullWidth variant="outlined" source="no_identificacion" label="ra.label.n_identificacao" />
            </Box>
          </Box>
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <DateInput validate={required()} source="fecha_nacimiento" label="ra.label.data_nascimento" />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <SelectInput
                validate={required()}
                variant="outlined"
                source="genero"
                label="ra.label.genero"
                choices={[
                  { id: 'Femenino', name: 'ra.label.feminino' },
                  { id: 'Masculino', name: 'ra.label.masculino' },
                  { id: 'Transgénero', name: 'ra.label.transgenero' },
                  { id: 'Otro', name: 'ra.label.outro' },
                ]}
              />
            </Box>
          </Box>
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              {/* <TextInput fullWidth label='País' variant='outlined' source="pais" /> */}
              <SelectInput
                fullWidth
                variant="outlined"
                label="ra.label.pais"
                optionText={(choice) => `${choice.flag} ${choice.name}`}
                optionValue="isoCode"
                source="pais"
                choices={Country.getAllCountries()}
              />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <SelectInput
                    fullWidth
                    label="ra.label.estado"
                    source="distrito"
                    variant="outlined"
                    optionValue="isoCode"
                    choices={
                          formData.pais
                            ? State.getStatesOfCountry(formData.pais)
                            : []
                      }
                    {...rest}
                  />
                )}
              </FormDataConsumer>
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <SelectInput
                    fullWidth
                    variant="outlined"
                    label="ra.label.cidade"
                    source="ciudad"
                    optionValue="name"
                    choices={
                          formData.distrito
                            ? City.getCitiesOfState(formData.pais, formData.distrito)
                            : []
                      }
                    {...rest}
                  />
                )}
              </FormDataConsumer>
            </Box>
          </Box>
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <TextInput validate={required()} fullWidth variant="outlined" label="ra.label.endereco" source="direccion" />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            />
          </Box>
        </FormTab>
        {/* <FormTab label="ra.label.dado_pgto">
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <RadioButtonGroupInput
                fullWidth
                label="ra.label.tipo_conta"
                source="tipo_cuenta"
                choices={[
                  { id: 'Ahorro', name: 'ra.label.poupanca' },
                  { id: 'Corriente', name: 'ra.label.corrente' },
                  { id: 'salario', name: 'ra.label.salario' },
                ]}
              />
              <TextInput fullWidth variant="outlined" label="ra.label.banco" source="banco" />
              <TextInput fullWidth variant="outlined" label="ra.label.agencia" source="numero_de_agencia" />
              <TextInput fullWidth variant="outlined" label="ra.label.conta" source="numero_de_cuenta" />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <Typography>Pagos realizados a mi:</Typography>
              <ReferenceManyField reference="pagos" target="profeId" label="">
                <Datagrid>
                  <DateField label="ra.label.data_pgto" source="fecha" />
                  <ReferenceField source="financieroId" reference="financiero" label="ra.">
                    <TextField label="ra.label.observacoes" source="observaciones" />
                  </ReferenceField>
                  <PriceField label="ra.label.valr_pago" source="valor" />
                </Datagrid>
              </ReferenceManyField>
            </Box>
          </Box>
        </FormTab> */}
      </TabbedForm>
    </Edit>
  );
}

// function PriceField({ record = {}, source }) {
//   return (
//     <span style={{
//       fontWeight: 'bolder', textAlign: 'right', display: 'block', fontSize: 12,
//     }}
//     >
//       $
//       {record[source] ? record[source].toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '{body},').replace('.0', '') : 0}
//     </span>
//   );
// }
