import React, { useEffect } from 'react';
import {
  List,
  Button,
  Create,
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  TextField,
  Filter,
  useListContext,
  useDataProvider,
  SaveButton,
  TopToolbar,
  Loading,
  useRecordContext,
  ReferenceField,
  Toolbar,
  DeleteButton,
  useTranslate,
  // ListActions
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import FullCalendar from '@fullcalendar/react';
// import timeGridPlugin from '@fullcalendar/timegrid';
import allLocales from '@fullcalendar/core/locales-all';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { Box } from '@material-ui/core';
import { DateTimeInput } from './DateInput';
import VoltarIcon from './static/img/Voltar.png';
import CreateProfButton from './componentes/CreateProfButton';
import CreateEventoButton from './componentes/CreateEventoButton';

function EventFilter(props) {
  return (
    <Filter {...props}>
      <ReferenceInput
        perPage={100000}
        alwaysOn
        fullWidth
        sort={{ field: 'nombre', order: 'ASC' }}
        variant="outlined"
        style={{ minWidth: '50%' }}
        source="profId"
        label="ra.label.professor"
        reference="profesores"
      >
        <SelectInput optionText="nombre" />
      </ReferenceInput>
    </Filter>
  );
}

function MyCalendar() {
  const { ids, data } = useListContext();
  // const [showDialog, setShowDialog] = useState(false);
  const eventos = ids.map((id) => ({
    title: data[id].nombre,
    start: data[id].fecha_inicio,
    end: data[id].fecha_final,
    allDay: false,
    backgroundColor: 'red',
    extendedProps: { evento: data[id] },
  }));

  const handleEventClick = (arg) => { // bind with an arrow function
    // const redirect = useRedirect
    // alert(JSON.stringify(arg.event.extendedProps))
    window.location.href = `#/eventos/${arg.event.extendedProps.evento.objectId}`;
  };

  const handleDateClick = () => {
    // alert('Clicked on: ' + info.dateStr);
    // alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
    // alert('Current view: ' + info.view.type);

    // info.dayEl.style.backgroundColor = 'red';
    // setShowDialog(true);
    // showDialog = true
    document.getElementById('CrearEventoBtn').click();
  };

  return (
    <div style={{ margin: '1em' }}>
      <FullCalendar
        weekends
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        plugins={[
          dayGridPlugin,
          interactionPlugin,
          // timeGridPlugin,
          listPlugin]}
        initialView="dayGridMonth"
        events={eventos}
        locales={allLocales}
        // options={{ locale: 'es' }}
        locale={localStorage.getItem('lang')}
        headerToolbar={
                    {
                      start: 'prev,next title',
                      center: '',
                      end: 'dayGridMonth,listWeek,dayGridWeek',
                    }
                }
      />

    </div>
  );
}

function ListActions() {
  return (
    <TopToolbar>
      {/* Add your custom actions */}
      <CreateEventoButton />
    </TopToolbar>
  );
}

export function EventList(props) {
  const dataProvider = useDataProvider();
  const [filtro, setFiltro] = React.useState(null);
  const perfil = localStorage.getItem('perfil') || null;
  const profe = localStorage.getItem('profe') ? JSON.parse(localStorage.getItem('profe')) : null;

  console.log(profe);
  console.log(perfil);

  // Filtro alumno
  // Traigo mis clases
  async function armarFiltro() {
    const alumno = JSON.parse(localStorage.getItem('alumno'));
    /**
         * Busco los cursos del usuario
         */

    const { data: cursosQry } = await dataProvider.getList(
      'cursos',
      { filter: { alumnos: alumno.objectId } },
    );
    console.log(cursosQry);
    const miscursosT = cursosQry.map((item) => item.objectId);
    setFiltro({ cursoId: { $in: miscursosT } });
  }
  useEffect(() => (perfil && perfil === 'alumno' ? armarFiltro() : null), []);

  if (perfil === 'alumno' && !filtro) { return <Loading />; }

  return (
    <List
      perPage={1000000}
      pagination={null}
      {...props}
      exporter={false}
      actions={<ListActions />}
      filters={perfil && (perfil === 'profesor' || perfil === 'alumno') ? null : <EventFilter />}
      filter={perfil && perfil === 'profesor' ? { profId: profe.objectId }
        : perfil && perfil === 'alumno' ? filtro : null}
    >
      <MyCalendar />
    </List>
  );
}

export function EventCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm
        redirect="edit"
      >
        <TextInput variant="outlined" fullWidth source="nombre" />
        <TextInput variant="outlined" fullWidth multiline source="descripcion" />
        <DateTimeInput
          variant="outlined"
          source="fecha_inicio"
        />
        <DateTimeInput
          variant="outlined"
          source="fecha_final"
        />
        <div style={{ display: 'flex' }}>
          <ReferenceInput
            perPage={100000}
            sort={{ field: 'nombre', order: 'ASC' }}
            variant="outlined"
            style={{ minWidth: '50%' }}
            source="profId"
            label="ra.label.professor"
            reference="profesores"
          >
            <SelectInput optionText="nombre" />
          </ReferenceInput>
          <CreateProfButton />
        </div>
        <ReferenceInput
          perPage={100000}
          sort={{ field: 'nombre', order: 'ASC' }}
          variant="outlined"
          style={{ minWidth: '50%' }}
          source="cursoId"
          label="ra.label.curso"
          reference="cursos"
        >
          <SelectInput optionText="nombre" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}

function CustomToolbar(props) {
  const cancelar = () => {
    console.log(props);
    alert('Esta funcionalidad se encuentra en reparación.');
  };

  const modificar = () => {
    console.log(props);
    alert('Esta funcionalidad se encuentra en reparación.');
  };

  return (
    <Toolbar {...props}>
      {localStorage.getItem('perfil')
        && localStorage.getItem('perfil') !== 'alumno'
        && localStorage.getItem('perfil') !== 'profesor' && <SaveButton />}
      {localStorage.getItem('perfil')
        && localStorage.getItem('perfil') !== 'alumno'
        && localStorage.getItem('perfil') !== 'profesor' && <DeleteButton undoable={false} />}
      {localStorage.getItem('perfil')
        && localStorage.getItem('perfil') === 'alumno'
        && localStorage.getItem('perfil') !== 'profesor' && <Button onClick={cancelar} style={{ marginLeft: 5 }} variant="contained" color="secondary" label="Cancelar aula" />}
      {localStorage.getItem('perfil')
        && localStorage.getItem('perfil') === 'alumno'
        && localStorage.getItem('perfil') !== 'profesor' && <Button onClick={modificar} style={{ marginLeft: 5 }} variant="contained" color="default" label="Modificar fecha" />}
    </Toolbar>
  );
}

export function EventEdit(props) {
  const translate = useTranslate();
  return (
    <Edit title="Eventos" undoable={false} {...props}>
      {localStorage.getItem('perfil') && localStorage.getItem('perfil') !== 'alumno' ? (
        <SimpleForm toolbar={<CustomToolbar />} redirect="edit">
          <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
            <img src={VoltarIcon} alt="Voltar" />
          </Button>
          <TextInput variant="outlined" label="ra.label.titulo_evento" fullWidth source="nombre" />
          <TextInput variant="outlined" minRows={4} fullWidth multiline source="descripcion" />
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <DateTimeInput variant="outlined" source="fecha_inicio" />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <DateTimeInput variant="outlined" source="fecha_final" />
            </Box>
          </Box>
          <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
            <Box
              flex={1}
              mr={{ xs: 0, sm: '0.5em' }}
            >
              <div style={{ display: 'flex' }}>
                <ReferenceInput
                  perPage={100000}
                  sort={{ field: 'nombre', order: 'ASC' }}
                  variant="outlined"
                  style={{ minWidth: '50%' }}
                  source="profId"
                  label="ra.label.professor"
                  reference="profesores"
                >
                  <SelectInput optionText="nombre" />
                </ReferenceInput>
                <CreateProfButton />
              </div>
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: '0.5em' }}
            >
              <ReferenceInput
                perPage={100000}
                sort={{ field: 'nombre', order: 'ASC' }}
                variant="outlined"
                style={{ minWidth: '50%' }}
                source="cursoId"
                label="ra.label.curso"
                reference="cursos"
              >
                <SelectInput optionText="nombre" />
              </ReferenceInput>
            </Box>
          </Box>
          <SelectInput
            label="ra.label.tipo_evento"
            variant="outlined"
            source="tipo_evento"
            choices={[
              { id: 'Reunión', name: 'Reunión' },
              { id: 'Clase', name: 'Clase' },
              { id: 'Otro', name: 'Otro' },
            ]}
          />
        </SimpleForm>
      )
        : (
          <SimpleForm toolbar={<CustomToolbar />} redirect="edit">
            <Button style={{ width: 89, marginTop: 10, marginBottom: 30 }} onClick={() => window.history.back()} label="ra.label.voltar">
              <img src={VoltarIcon} alt="Voltar" />
            </Button>
            <Typography>
              {translate('ra.label.titulo_evento')}
              {': '}
              <TextField source="nombre" />
            </Typography>
            <Typography>
              {translate('ra.label.data_inicio')}
              {': '}
              <DateTimeField source="fecha_inicio" />
            </Typography>
            <Typography>
              {translate('ra.label.data_termino')}
              {': '}
              <DateTimeField source="fecha_final" />
            </Typography>
            <Typography>
              {translate('ra.label.professor')}
              {': '}
              <ReferenceField
                source="profId"
                reference="profesores"
              >
                <TextField source="nombre" />
              </ReferenceField>
            </Typography>
            <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
              <Box
                flex={1}
                mr={{ xs: 0, sm: '0.5em' }}
              >
                <DateTimeInput variant="outlined" source="fecha_inicio" />
              </Box>
              <Box
                flex={1}
                ml={{ xs: 0, sm: '0.5em' }}
              >
                <DateTimeInput variant="outlined" source="fecha_final" />
              </Box>
            </Box>
          </SimpleForm>
        )}
    </Edit>
  );
}
const { format } = require('date-fns');

function DateTimeField(props) {
  const record = useRecordContext(props);
  return <span>{format(new Date(record[props.source]), 'dd-MM-yyyy HH:mm')}</span>;
}
