import React, { useState } from 'react';
import {
  AppBar,
} from 'react-admin';
import {
  withStyles, IconButton, Button, Typography,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
// import User from '../dashboard/User'
import Drawer from '@material-ui/core/Drawer';

const styles = {
  title: {
    
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: '#518f18',
  },
  spacer: {
    flex: 1,
  },
};

function CustomAppBar({ classes, ...props }) {
  const [open, setOpen] = useState(false);
  return (
    <AppBar
      elevation={0}
      style={{
        background: '#fff', borderBottomStyle: 'solid', borderBottomColor: '#518f18', borderBottomWidth: 2,
      }}
      {...props}
      userMenu={false}
    >
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <span className={classes.spacer} />
      <IconButton>
        <AccountCircleIcon onClick={() => setOpen(true)} style={{ fontSize: 30, color: 'white' }} />
      </IconButton>
      <Drawer anchor="right" open={open}>
        <div>
          <Button label="Close" onClick={() => setOpen(false)}>
            <IconKeyboardArrowRight style={{ fontSize: 40 }} />
          </Button>
        </div>
        {/* <User onClose={()=>setOpen(false)}/> */}
      </Drawer>
    </AppBar>
  );
}

export default withStyles(styles)(CustomAppBar);
