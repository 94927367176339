import React from 'react';
import {
  ImageInput,
  Create,
  Edit,
  TextInput,
  SimpleForm,
  ImageField,
  BooleanInput,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Empleados from './static/img/empleados.png';
import Permisos from './static/img/miniuser@3x.png';
import LanguageDialog from './componentes/Lang';

const myStyles = makeStyles({
  total: { fontWeight: 'bold' },
  root: {
    maxWidth: 160,
    minWidth: 100,
    margin: 10,
    cursor: 'pointer',
  },
});

export function ConfigList() {
  const redirect = useRedirect();
  const classes = myStyles();
  const translate = useTranslate();
  return (
    <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
      <Box
        mr={{ xs: 0, sm: '0.5em' }}
      >
        <Card onClick={() => redirect('/profesores')} className={classes.root}>
          <CardContent
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              width: 150,
              height: 150,
            }}
          >
            <img
              alt="Empleados"
              style={{
                margin: 'auto',
                width: 80,
              }}
              src={Empleados}
            />
          </CardContent>
          <CardActions
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Button size="small">{translate('ra.label.empregados')}</Button>
          </CardActions>
        </Card>
      </Box>
      <Box
        ml={{ xs: 0, sm: '0.5em' }}
      >
        <Card onClick={() => redirect('/permisos')} className={classes.root}>
          <CardContent
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              width: 150,
              height: 150,
            }}
          >
            <img
              alt="Permisos"
              style={{
                margin: 'auto',
                width: 80,
              }}
              src={Permisos}
            />
          </CardContent>
          <CardActions>
            <Button size="small">{translate('ra.label.permissoes')}</Button>
          </CardActions>
        </Card>
      </Box>
      <Box
        ml={{ xs: 0, sm: '0.5em' }}
      >
        <Card onClick={() => redirect('/users/create')} className={classes.root}>
          <CardContent
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              width: 150,
              height: 150,
            }}
          >
            <img
              alt="Permisos"
              style={{
                margin: 'auto',
                width: 80,
              }}
              src={Permisos}
            />
          </CardContent>
          <CardActions>
            <Button size="small">{translate('ra.label.usuario')}</Button>
          </CardActions>
        </Card>
      </Box>
      <Box
        ml={{ xs: 0, sm: '0.5em' }}
      >
        <LanguageDialog />
      </Box>
    </Box>
  );
}

export function ConfigCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm
        redirect="edit"
      >
        <TextInput fullWidth source="nombre" />
        <ImageInput multiple label="Foto del Plato" source="pictures" placeholder={<p>Haz clic o arrastra</p>}>
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
}

export function ConfigEdit(props) {
  return (
    <Edit undoable={false} {...props}>
      <SimpleForm redirect="edit">
        <TextInput fullWidth source="nombre" />
        <ImageInput multiple label="Foto del Plato" source="pictures" placeholder={<p>Haz clic o arrastra</p>}>
          <ImageField source="src" title="title" />
        </ImageInput>
        <BooleanInput source="publicado" />
      </SimpleForm>
    </Edit>
  );
}
