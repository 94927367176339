import React from 'react';
import Postit from './components/Postit.jsx';
import './css/main.css';

function Marlon(props) {
  let show = true;
  let opacityNotHover = 1;

  if (typeof props.show === 'boolean') { show = props.show; }
  if (typeof props.opacityNotHover === 'number') { opacityNotHover = props.opacityNotHover; }

  const sendEvent = ((e) => {
    let id;
    if (props.id !== undefined) { id = props.id; }
    if (props.onEvent !== undefined) { if (typeof props.onEvent === 'function') { props.onEvent(e); } }
  });

  return (
    <>
      {

            (opacityNotHover !== 1)
            && (
            <>
              {' '}
              <style>{` .resize-postit{ opacity:${opacityNotHover}; } .resize-postit:hover{ opacity:1; } `}</style>
              {' '}
            </>
            )

        }
      {
            (show === true)
            && props.data.map((value, index) => (
              <Postit sendEvent={(e) => sendEvent(e)} value={value} key={value.id} index={index} />
            ))

        }
    </>
  );
}

export default Marlon;
