import React from 'react';
// import { useForm } from 'react-final-form';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  FormWithRedirect,
  useDataProvider,
  useRefresh,
} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import TrashIcon from '@material-ui/icons/Delete';
// import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Checkbox from '@material-ui/core/Checkbox';

export default function EventCheck(props) {
  const { evento, alumno, curso } = props;
  const dataProvider = useDataProvider();
  const [showDialog, setShowDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const refresh = useRefresh();
  const [checked, setChecked] = React.useState(false);
  const [selected, setSelected] = React.useState(null);

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const showAsistencia = () => {
    dataProvider.getList('asistencias', {
      filter: {
        alumnoId: alumno.objectId,
        cursoId: curso.objectId,
        fecha: evento.fecha_inicio,
      },
    }).then(({ data }) => {
      if (data && data.length) setSelected(data[0]);
      else setSelected(null);

      setShowDialog(true);
    });
  };

  const handleChange = (event) => {
    if (event.target.checked) setChecked(event.target.checked);
    dataProvider.getList('asistencias', {
      filter: {
        alumnoId: alumno.objectId,
        cursoId: curso.objectId,
        fecha: evento.fecha_inicio,
      },
    }).then(({ data }) => {
      if (data && data.length) setSelected(data[0]);
      else setSelected(null);

      setShowDialog(true);
    });
  };

  React.useEffect(() => {
    dataProvider.getList('asistencias', {
      filter: {
        alumnoId: alumno.objectId,
        cursoId: curso.objectId,
        fecha: evento.fecha_inicio,
      },
    }).then(({ data }) => {
      if (data && data.length) {
        setChecked(true);
        setSelected(data[0]);
      } else setChecked(false);
    });
  }, []);

  const handleDelete = async () => {
    dataProvider.delete('asistencias', { id: selected.objectId })
      .then((resultado) => console.log(resultado));
    setChecked(false);
    setLoading(false);
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    console.log(values, props);
    setLoading(false);
    /** Busco si ya existe la asistencia */
    setChecked(true);
    if (selected) {
      /** Si ya existe la actualizo */
      dataProvider.update('asistencias', {
        id: selected.objectId,
        data: {
          alumnoId: alumno.objectId,
          cursoId: curso.objectId,
          fecha: evento.fecha_inicio,
          observaciones: values.observaciones ? values.observaciones : '',
        },
      }).then(() => {
        alert('Actualizada con éxito');
        setLoading(false);
        setShowDialog(false);
      });
    } else {
      /** Sino la creo */
      dataProvider.create('asistencias', {
        data: {
          alumnoId: alumno.objectId,
          cursoId: curso.objectId,
          fecha: evento.fecha_inicio,
          observaciones: values.observaciones ? values.observaciones : '',
        },
      }).then(() => {
        alert('Creada con éxito');
        setLoading(false);
        setShowDialog(false);
      });
    }

    // const results = await dataProvider.update(props.resource, {
    //     id: props.id,
    //     data: {
    //         pictures: myPics
    //     }
    // })

    // console.log(results)
    refresh();
  };

  return (
    <div>
      <Checkbox
        disabled={props.disabled}
        checked={checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
      />
      {checked ? <Button label="VER" onClick={showAsistencia} /> : null}
      {/*{!checked ? <Button label="OBS" onClick={showAsistencia} /> : null}*/}
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>
          {selected ? '' : 'Confirmar'}
          {' '}
          Asistencia
        </DialogTitle>
        {/* <Typography>Puedes agregar observaciones relacionadas a la asistencia</Typography> */}
        <FormWithRedirect
          resource="cursos"
          save={handleSubmit}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving,
          }) => (
            <>
              <DialogContent>
                <TextInput
                  defaultValue={selected && selected.observaciones ? selected.observaciones : null}
                  label="Adicionar observações"
                  multiline
                  minRows={3}
                  variant="outlined"
                  fullWidth
                  validate={required()}
                  source="observaciones"
                />
              </DialogContent>
              <DialogActions>
                <Button
                  className="cancelar-btn"
                  label="Cerrar"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  label={selected ? 'Editar observación' : 'Confirmar'}
                  handleSubmitWithRedirect={
                                handleSubmitWithRedirect
                            }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
                {selected && (
                <Button
                  label={selected ? 'Eliminar la asistencia' : 'Confirmar'}
                  onClick={handleDelete}
                >
                  <TrashIcon />
                </Button>
                )}
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </div>
  );
}
