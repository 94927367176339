import React from 'react';
import {
  useRedirect,
} from 'react-admin';
import DollarIcon from '@material-ui/icons/AttachMoney';
import {
  Card,
  Box,
  Typography,
} from '@material-ui/core';
import parseConfig from '../parseConfig';

export function Receitas() {
  const navigate = useRedirect();
  const [data, setData] = React.useState({
    pendientes: [],
    pagadas: [],
  });
  async function fetchDatas() {
    let hoy = new Date();
    hoy.setHours(0, 0, 0, 0);
    hoy = new Date(hoy.getFullYear(), hoy.getMonth(), 0).toISOString();

    let tomorrow = new Date();
    tomorrow.setHours(0, 0, 0, 0);
    tomorrow = new Date(tomorrow.getFullYear(), tomorrow.getMonth() + 1, 0).toISOString();

    // const filtro1 = {
    //   fecha_vencimiento: { $gte: hoy, $lte: tomorrow },
    // };
    // console.log(`${parseConfig.URL}/classes/receitas?limit=1000&where=${JSON.stringify(filtro1)}`);
    const responseReceitas = await fetch(
      `${parseConfig.URL}/classes/receitas?limit=1000`, //&where={"estado":"pendiente"},
      {
        headers: parseConfig.headers,
      },
    );

    const json = await responseReceitas.json();
    const parcelasPendientes = [];
    const parcelasPagadas = [];
    if (json && json.results) {
      /**
       * Aquí debo buscar las parcelas si tiene parcelas para incluirlas entre las pendientes.
       */
      const jsonPendientes = json.results.filter((p) => p.estado === 'pendiente');
      const jsonPagadas = json.results.filter((p) => p.estado !== 'pendiente');
      for (let indexJson = 0; indexJson < jsonPendientes.length; indexJson++) {
        const venta = json.results[indexJson];
        /**
          * Busco las parcelas vencidas y reviso si estan pendientes o pagadas
          */
        if (venta.parcelas && venta.parcelas.length) {
          const jsonParcelas = venta.parcelas;
          for (let index = 0; index < jsonParcelas.length; index++) {
            const parcela = jsonParcelas[index];
            if (parcela.data_vencimento <= tomorrow && (!parcela.estado || parcela.estado === 'pendiente')) {
              /**
                * Aquí estoy en las vencidas
                */
              parcelasPendientes.push({
                ...parcela,
                alumnoId: venta.alumnoId,
              });
            } else if (parcela.saldo) {
              /**
                * Aquí estoy en las pagadas que dejaron saldo
                */
              parcelasPendientes.push({
                ...parcela,
                valor: parcela.saldo,
                alumnoId: venta.alumnoId,
              });
            } else if (parcela.estado === 'pagada' || parcela.valorPagado) {
              /**
                * Aquí estoy en las pagadas
                */
              parcelasPagadas.push({
                ...parcela,
                valor: parcela.valorPagado ? parcela.valorPagado : parcela.valor,
                alumnoId: venta.alumnoId,
              });
            }

            // if (parcela.valorPagado && parcela.estado !== 'pagada') {
            //   /**
            //     * Aquí estoy en las pagadas
            //     */
            //   parcelasPagadas.push({
            //     ...parcela,
            //     valor: parcela.valorPagado,
            //     alumnoId: venta.alumnoId,
            //   });
            // }
          }
        } else {
          /**
           * Si no tiene parcelas pero esta pendiente de pagar la agrego
           */
          if (venta.fecha_vencimiento <= tomorrow) {
            parcelasPendientes.push({
              ...venta,
            });
          }
        }
      }

      //Si una venta est completamente pagada la agrego
      for (let indexJson = 0; indexJson < jsonPagadas.length; indexJson++) {
        const venta = json.results[indexJson];
        parcelasPagadas.push({
              ...venta,
            });
      }
      // const output = jsonParcelas.results.reduce((acc, curr) => acc + parseInt(curr.valor), 0);
      // console.log(output);
      // console.log(parcelasPendientes, parcelasPagadas);
      setData({ pagadas: parcelasPagadas, pendientes: parcelasPendientes });
    }
  }

  React.useEffect(() => {
    fetchDatas();
  }, []);

  // React.useEffect(() => {
  //   console.log(data);
  // }, [data]);

  return (
    <>
      <Card
        style={{ cursor: 'pointer' }}
        onClick={() => navigate('/receitas')}
        sx={{
          minHeight: 52,
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
          '& a': {
            textDecoration: 'none',
            color: 'inherit',
          },
        }}
      >
        <Box
          sx={{
            overflow: 'inherit',
            padding: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& .icon': {
              color: '#518f18',
              backgroundColor: '#ffdf00',
            },
          }}
        >
          <Box
            width="3em"
            height="3em"
            className="icon"
            style={{
              justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: 5,
            }}
          >
            <DollarIcon style={{ fontSize: 40 }} />
          </Box>
          <Box textAlign="right">
            <Typography color="textSecondary">Pendientes</Typography>
            <Typography variant="h5" component="h2">
              {data.pendientes && data.pendientes.length ? `$${data.pendientes.reduce((acc, curr) => acc + parseInt(curr.valor), 0)}` : '$0'}
            </Typography>
          </Box>
        </Box>
      </Card>
      <br />
      <Card
        style={{ cursor: 'pointer' }}
        onClick={() => navigate('/receitas')}
        sx={{
          minHeight: 52,
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
          '& a': {
            textDecoration: 'none',
            color: 'inherit',
          },
        }}
      >
        <Box
          sx={{
            overflow: 'inherit',
            padding: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& .icon': {
              color: '#004AAD',
              backgroundColor: '#518f18',
            },
          }}
        >
          <Box
            width="3em"
            height="3em"
            className="icon"
            style={{
              justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: 5,
            }}
          >
            <DollarIcon style={{ fontSize: 40 }} />
          </Box>
          <Box textAlign="right">
            <Typography color="textSecondary">Pagadas</Typography>
            <Typography variant="h5" component="h2">
              {data.pagadas && data.pagadas.length ? `$${data.pagadas.reduce((acc, curr) => acc + parseInt(curr.valor), 0)}` : '$0'}
            </Typography>
          </Box>
        </Box>
      </Card>
    </>
  );
}

export function Despesas() {
  const navigate = useRedirect();
  const [data, setData] = React.useState({
    pendientes: [],
    pagadas: [],
  });
  async function fetchDatas() {
    let hoy = new Date();
    hoy.setHours(0, 0, 0, 0);
    hoy = new Date(hoy.getFullYear(), hoy.getMonth(), 0).toISOString();

    let tomorrow = new Date();
    tomorrow.setHours(0, 0, 0, 0);
    tomorrow = new Date(tomorrow.getFullYear(), tomorrow.getMonth() + 1, 0).toISOString();

    // const filtro1 = {
    //   fecha_vencimiento: { $gte: hoy, $lte: tomorrow },
    // };
    // console.log(`${parseConfig.URL}/classes/receitas?limit=1000&where=${JSON.stringify(filtro1)}`);
    const responseReceitas = await fetch(
      `${parseConfig.URL}/classes/despesas?limit=1000`, //&where={"estado":"pendiente"}
      {
        headers: parseConfig.headers,
      },
    );

    const json = await responseReceitas.json();
    console.log(json);
    const parcelasPendientes = [];
    const parcelasPagadas = [];
    if (json && json.results) {
      /**
       * Aquí debo buscar las parcelas si tiene parcelas para incluirlas.
       */
      for (let indexJson = 0; indexJson < json.results.length; indexJson++) {
        const venta = json.results[indexJson];
        // console.log(venta);
        /**
          * Busco las parcelas vencidas
          */
        if (venta.parcelas && venta.parcelas.length) {
          const jsonParcelas = venta.parcelas;
          // console.log(jsonParcelas);
          for (let index = 0; index < jsonParcelas.length; index++) {
            const parcela = jsonParcelas[index];
            if (parcela.data_vencimento <= tomorrow && (!parcela.estado || parcela.estado === 'pendiente')) {
              /**
                * Aquí estoy en las vencidas
                */
              parcelasPendientes.push({
                ...parcela,
                alumnoId: venta.alumnoId,
              });
            } else if (parcela.estado === 'pendiente' && parcela.saldo) {
              /**
                * Aquí estoy en las pagadas
                */
              parcelasPendientes.push({
                ...parcela,
                valor: parcela.saldo,
                alumnoId: venta.alumnoId,
              });
            } else if (parcela.estado === 'pagada') {
              /**
                * Aquí estoy en las pagadas
                */
              parcelasPagadas.push({
                ...parcela,
                alumnoId: venta.alumnoId,
              });
            }

            if (parcela.valorPagado && parcela.estado !== 'pagada') {
              /**
                * Aquí estoy en las pagadas
                */
              parcelasPagadas.push({
                ...parcela,
                valor: parcela.valorPagado,
                alumnoId: venta.alumnoId,
              });
            }
          }
        } else {
          /**
           * Que hago si no tiene parcelas?
           */
          // console.log('venta sin parcelas', venta);
          // parcelasPendientes.push(json.results);
          if(!venta.estado || venta.estado === 'pendiente') {
            parcelasPendientes.push({
              ...venta,
            });
          }else {
            parcelasPagadas.push({
              ...venta,
            });
          }
        }
      }
      // const output = jsonParcelas.results.reduce((acc, curr) => acc + parseInt(curr.valor), 0);
      // console.log(output);
      console.log(parcelasPendientes, parcelasPagadas);
      setData({ pagadas: parcelasPagadas, pendientes: parcelasPendientes });
    }
  }

  React.useEffect(() => {
    fetchDatas();
  }, []);

  React.useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <>
      <Card
        style={{ cursor: 'pointer' }}
        onClick={() => navigate('/despesas')}
        sx={{
          minHeight: 52,
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
          '& a': {
            textDecoration: 'none',
            color: 'inherit',
          },
        }}
      >
        <Box
          sx={{
            overflow: 'inherit',
            padding: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& .icon': {
              color: '#518f18',
              backgroundColor: '#ffdf00',
            },
          }}
        >
          <Box
            width="3em"
            height="3em"
            className="icon"
            style={{
              justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: 5,
            }}
          >
            <DollarIcon style={{ fontSize: 40 }} />
          </Box>
          <Box textAlign="right">
            <Typography color="textSecondary">Pendientes</Typography>
            <Typography variant="h5" component="h2">
              {data.pendientes && data.pendientes.length ? `$${data.pendientes.reduce((acc, curr) => acc + parseInt(curr.valor), 0)}` : '$0'}
            </Typography>
          </Box>
        </Box>
      </Card>
      <br />
      <Card
        style={{ cursor: 'pointer' }}
        onClick={() => navigate('/despesas')}
        sx={{
          minHeight: 52,
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
          '& a': {
            textDecoration: 'none',
            color: 'inherit',
          },
        }}
      >
        <Box
          sx={{
            overflow: 'inherit',
            padding: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& .icon': {
              color: '#004AAD',
              backgroundColor: '#518f18',
            },
          }}
        >
          <Box
            width="3em"
            height="3em"
            className="icon"
            style={{
              justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: 5,
            }}
          >
            <DollarIcon style={{ fontSize: 40 }} />
          </Box>
          <Box textAlign="right">
            <Typography color="textSecondary">Pagadas</Typography>
            <Typography variant="h5" component="h2">
              {data.pagadas && data.pagadas.length ? `$${data.pagadas.reduce((acc, curr) => acc + parseInt(curr.valor), 0)}` : '$0'}
            </Typography>
          </Box>
        </Box>
      </Card>
    </>
  );
}
