import React, { Component, Fragment } from 'react';
import { useForm } from 'react-final-form';
import {
  showNotification,
  SimpleForm,
  TextInput,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Parse from 'parse';
import { ButtonSubmit } from '../componentes/Buttons';

let submitQC;
const QCSubmitExtractor = ({ children }) => {
  submitQC = useForm().submit;
  return children;
};

class CambiarClave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
    };
  }

  handleClick = () => {
    const self = this;
    self.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleSaveClick = () => {
    // Trigger a submit of our custom quick create form
    // This is needed because our modal action buttons are oustide the form
    submitQC();
  };

  handleSubmit = async (values) => {
    Parse.User.requestPasswordReset(values.email)
      .then(() => {
        showNotification(`Se ha enviado un correo a ${values} con un enlace para cambiar tu contraseña`, 'success');
      })
      .catch((error) => {
        showNotification(`Hubo un error intentando actualizar tu contraseña: ${error.message}`, 'error');
      });
  };

  render() {
    const { showDialog } = this.state;
    const { isSubmitting } = this.props;

    return (
      <>
        <Button fullWidth color="primary" style={{ fontSize: 14, cursor: 'pointer' }} onClick={this.handleClick}>
          Esqueceu a senha?
        </Button>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Crear Pago"
        >
          <DialogTitle>Cambiar contraseña</DialogTitle>
          <DialogContent>
            <SimpleForm
              // We override the redux-form name to avoid collision with the react-admin main form
              // form="post-quick-create"
              resource="posts"
              // We override the redux-form onSubmit prop to handle the submission ourselves
              save={this.handleSubmit}
              // We want no toolbar at all as we have our modal actions
              toolbar={null}
            >
              <QCSubmitExtractor>
                <TextInput variant="outlined" size="small" fullWidth source="email" label="Correo Electrónico" />
              </QCSubmitExtractor>
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <ButtonSubmit
              label="Confirmar cambio al correo"
              saving={isSubmitting}
              handleSubmitWithRedirect={() => {}}
              onClick={this.handleSaveClick}
              color="primary"
              fullWidth
              buttonClass="azul-verde"
            >
              Confirmar cambio al correo
            </ButtonSubmit>
            <Button
              color="primary"
              label="Cancelar"
              onClick={this.handleCloseClick}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default (
  CambiarClave
);
