import React, { useState } from 'react';
import {
  Button,
  SaveButton,
  useCreate,
  useNotify,
  FormWithRedirect,
  NumberInput,
  useTranslate,
  useDataProvider,
  ReferenceInput,
  SelectInput,
  TextInput,
  // BooleanInput,
  // FormDataConsumer,
} from 'react-admin';
import {
  InputAdornment, Card, Box, Typography,
} from '@material-ui/core';
import IconCancel from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// import { useFormState } from 'react-final-form';
import { DateInput } from '../DateInput';

function CreatePagoButton(props) {
  // const { values: temporalValues } = useFormState();
  const [showDialog, setShowDialog] = useState(false);
  const [showDialog2, setShowDialog2] = useState(false);
  const [create, { loading }] = useCreate('pagos');
  const notify = useNotify();
  // eslint-disable-next-line no-unused-vars
  const dataProvider = useDataProvider();
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
    setShowDialog2(false);
  };

  const handleSubmit = async (values) => {
    values.parcela = props.parcela;
    console.log(values);

    if (props.parcela.valor !== values.valor) {
      // Muestro el alert
      setShowDialog2(true);
    } else {
      create(
        { payload: { data: values } },
        {
          onSuccess: ({ data }) => {
            const parcelasT = props.data.parcelas;
            const fecha = values.fecha ? new Date(values.fecha) : new Date();
            values.data_vencimento = values.data_vencimento ? new Date(values.data_vencimento) : null;
            let saldo = props.parcela.saldo ? parseInt(props.parcela.saldo) - parseInt(values.valor) : parseInt(props.parcela.valor) - parseInt(values.valor);
            if (values.baja && values.baja === true) { saldo = 0; }
            const newAction = {
              pagoId: data.objectId,
              valorPagado: values.valor,
              fecha: fecha.toISOString(),
              accion: 'Pago efectuado',
            };

            const parcelaUpdated = {
              ...parcelasT[props.parcela.index],
              // pagoId: data.objectId,
              data_vencimento: values.data_vencimento ? values.data_vencimento.toISOString() : props.parcela.data_vencimento,
              valorPagado: props.parcela.valorPagado ? parseInt(props.parcela.valorPagado) + parseInt(values.valor) : parseInt(values.valor),
              fecha_pago: fecha.toISOString(),
              saldo,
              observaciones: values.observaciones,
              estado: props.parcela.valor === values.valor || saldo === 0 ? 'pagada' : 'pendiente',
              log: props.parcela.log ? [...props.parcela.log, newAction] : [newAction],
            };
            parcelasT[props.parcela.index] = parcelaUpdated;
            console.log(parcelasT);
            dataProvider.update(props.table, {
              id: props.parcela.id,
              data: {
                parcelas: parcelasT,
              },
            });
            setShowDialog(false);
            // Update the comment form to target the newly created post
            // Updating the ReferenceInput value will force it to reload the available posts
            //   form.change('moduloId', data.id);
            // onChange();
          },
          onFailure: ({ error }) => {
            notify(error.message, 'error');
          },
        },
      );
    }
    // if (temporalValues.alumnoId) values.alumnoId = temporalValues.alumnoId;
    // if (temporalValues.profeId) values.profeId = temporalValues.profeId;
  };
  const translate = useTranslate();
  return (
    <>
      <Button onClick={handleClick} label="">
        <AddCircleIcon />
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>{translate('ra.label.lancar_pgto')}</DialogTitle>
        <FormWithRedirect
          resource="pagos"
          save={handleSubmit}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving,
          }) => (
            <>
              <DialogContent>
                <h4>
                  Parcela No.
                  {JSON.stringify(props.parcela.index)}
                </h4>
                {props.table === 'receitas' ? <AlumnoField data={props.data} /> : null}
                <Box fullWidth display={{ xs: 'block', sm: 'flex' }}>
                  <Box
                    flex={1}
                    mr={{ xs: 0, sm: '0.5em' }}
                  >
                    <Box style={{
                      alignItems: 'center', backgroundColor: '#004AAD20', padding: 40, borderRadius: 40, marginBottom: 20,
                    }}
                    >
                      <NumberInput
                        defaultValue={props.parcela.valor ? props.parcela.valor : 0}
                        variant="outlined"
                        label="ra.label.valor"
                        source="valor"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              $
                            </InputAdornment>
                          ),
                        }}
                      />
                      <DateInput defaultValue={new Date()} source="fecha" label="ra.label.data_pgto" />
                    </Box>
                    {/* <BooleanInput label="Dar baja" defaultValue source="baja" />
                    <FormDataConsumer>
                      {({ formData }) => (formData.baja
                        ? <DateInput source="data_vencimento" defaultValue={props.parcela.data_vencimento} disabled label="ra.label.data_vencimento" />
                        : <DateInput source="data_vencimento" defaultValue={props.parcela.data_vencimento} label="ra.label.data_vencimento" />)}
                    </FormDataConsumer> */}
                    {/* <DateInput source="data_vencimento" defaultValue={props.parcela.data_vencimento} label="ra.label.data_vencimento" /> */}
                    <ReferenceInput
                      disabled
                      perPage={100000}
                      record={props.data}
                      fullWidth
                      filter={{ tipo_natureza: 'saida' }}
                      sort={{ field: 'nombre', order: 'ASC' }}
                      variant="outlined"
                      style={{ minWidth: '50%' }}
                      label="ra.label.natureza"
                      source="naturezaId"
                      reference="natureza_entrada"
                      defaultValue={props.parcela.naturezaId}
                    >
                      <SelectInput optionText="nombre" />
                    </ReferenceInput>
                  </Box>
                  <Box
                    flex={1}
                    ml={{ xs: 0, sm: '0.5em' }}
                  >
                    <Typography variant="h5">Historial:</Typography>
                    <br />
                    {props.parcela.log && props.parcela.log.map((item) => {
                      item.fecha = new Date(item.fecha);
                      return (
                        <div style={{
                          display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20,
                        }}
                        >
                          <div style={{ flexWrap: 'wrap' }}>
                            {item.accion}
                            {' '}
                            {item.valorPagado ? `por valor de $${item.valorPagado.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.0', '')}` : ''}
                          </div>
                          <span style={{ flexWrap: 'wrap', fontStyle: 'italic', fontSize: 12 }}>{`${item.fecha.toLocaleDateString()} ${item.fecha.toLocaleTimeString()}`}</span>
                        </div>
                      );
                    })}
                    <TextInput label="Observaciones" multiline variant="outlined" source="observaciones" fullWidth minRows={4} defaultValue={props.parcela.observaciones} />
                    {/* {JSON.stringify(props.parcela.log)} */}
                  </Box>
                </Box>
                {/* {JSON.stringify(props.parcela)} */}
              </DialogContent>
              <DialogActions>
                <Button
                  className="cancelar-btn"
                  label="ra.label.cancelar"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                                  handleSubmitWithRedirect
                              }
                  label="ra.label.salvar"
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
        <Dialog
          maxWidth="sm"
          fullWidth
          open={showDialog2}
          onClose={handleCloseClick}
        >
          <DialogTitle>
            {translate('ra.label.reparcelar')}
            ?
          </DialogTitle>
        </Dialog>
      </Dialog>
    </>
  );
}

function AlumnoField(props) {
  const { data } = props;
  const dataProvider = useDataProvider();
  const [alumno, setAlumno] = React.useState(null);
  // const record = useRecordContext(props);
  React.useEffect(() => {
    console.log(data);
    dataProvider.getOne('alumnos', { id: data.alumnoId })
      .then((result) => {
        setAlumno(result.data);
      });
  }, []);
  return (
    <Card style={{ padding: 20, marginBottom: 20, marginTop: 10 }}>
      <h5 style={{ marginBottom: 15 }}>Cliente:</h5>
      {alumno ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img style={{ width: 72, marginRight: 10 }} alt="" src={alumno && alumno.avatar ? alumno.avatar : null} />
          {alumno.nombre}
        </div>
      ) : null}
    </Card>
  );
}

export default CreatePagoButton;
