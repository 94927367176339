import React from 'react';
import parseConfig from '../parseConfig';

export default function UsuarioParcelas(props) {
  const [data, setData] = React.useState({});
  async function fetchDatas() {
    let hoy = new Date();
    hoy.setHours(0, 0, 0, 0);
    hoy = new Date(hoy.getFullYear(), hoy.getMonth(), 0).toISOString();

    let tomorrow = new Date();
    tomorrow.setHours(0, 0, 0, 0);
    tomorrow = new Date(tomorrow.getFullYear(), tomorrow.getMonth() + 1, 0).toISOString();

    const filtro1 = {
      fecha_vencimiento: { $gte: hoy, $lte: tomorrow },
    };
    const responseReceitas = await fetch(
      `${parseConfig.URL}/classes/receitas?limit=1000&where=${JSON.stringify(filtro1)}`,
      {
        headers: parseConfig.headers,
      },
    );
    const jsonParcelas = await responseReceitas.json();
    if (jsonParcelas && jsonParcelas.results) {
      const output = jsonParcelas.results.reduce((acc, curr) => acc + parseInt(curr.valor), 0);
      setData({ ...jsonParcelas, total: output });
    }
  }

  React.useEffect(() => {
    fetchDatas();
  }, []);

  return (
    <div>
      <p>
        ...
      </p>
    </div>
  );
}
