export const darkTheme = {
  palette: {
    type: 'dark', // Switching the dark mode on is a single property value change.
  },
};

export const lightTheme = {
  palette: {
    primary: { main: '#004aad' },
    secondary: {
      light: '#ffff',
      main: '#11263A',
      dark: '#567ea6',
      contrastText: '#518f18',
    },
    danger: { main: '#fd0202' },
  },
};
