import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import { useDataProvider, Button, Confirm } from 'react-admin';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import SaveIcon from '@material-ui/icons/Save';

function MyItem(props) {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(props.email.name);
  const [openConfirm, setOpenConfirm] = useState(false);
  const Delete = () => {
    props.dataProvider.delete('modulos', { id: props.email.objectId })
      .then(() => {
        props.fetchData();
      });
  };

  return (
    <>
      <Confirm
        isOpen={openConfirm}
        title="Borrar"
        content={`Estás seguro de borrar ${props.email.name}?`}
        onConfirm={async () => {
          setOpenConfirm(false);
          Delete();
        }}
        onClose={() => setOpenConfirm(false)}
      />
      <ListItem
        button
        onClick={() => {
          if (!edit) { props.handleListItemClick(props.email.objectId, props.email); }
        }}
      >
        <ListItemText primary={!edit ? props.email.name : <TextField size="small" value={value} variant="outlined" onChange={(e) => setValue(e.target.value)} />} />
        <ListItemSecondaryAction>
          <Button
            label=""
            onClick={() => {
              setEdit(!edit);
              // Guardo si estoy en modo editar
              if (edit) {
                props.dataProvider.update('modulos', { id: props.email.objectId, data: { name: value } })
                  .then((results) => {
                    console.log(results);
                    props.fetchData();
                  });
              }
            }}
          >
            {!edit ? <EditIcon /> : <SaveIcon />}
          </Button>
          <Button label="" onClick={() => setOpenConfirm(true)}>
            <DeleteForeverIcon />
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
}

export default function SelectCrud(props) {
  const dataProvider = useDataProvider();
  const { onClose, selectedValue, open } = props;
  const [emails, setEmails] = useState([]);
  const [value, setValue] = useState(null);
  const form = useForm();
  const handleClose = () => {
    setValue(null);
    onClose(selectedValue);
  };

  const handleListItemClick = (value, obj) => {
    onClose(value, obj);
    form.change('moduloId', value);
  };

  function fetchData() {
    if (props.resource !== 'users') {
      dataProvider.getList(props.resource, {
        filter: { },
        sort: { field: 'nombre', order: 'ASC' },
        pagination: { page: 1, perPage: 50000 },
      })
        .then(({ data }) => {
          if (props.resource2 && props.resource2 !== '') {
            const temporalData = data;
            dataProvider.getList(props.resource2, {
              filter: { },
              sort: { field: 'nombre', order: 'ASC' },
              pagination: { page: 1, perPage: 50000 },
            })
              .then(({ data }) => setEmails(
                [...temporalData, ...data].sort((a, b) => a.nombre > b.nombre),
              ));
          } else {
            setEmails(data);
          }
        });
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Dialog
      maxWidth="xs"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
    >
      <DialogTitle id="simple-dialog-title">{props.title}</DialogTitle>
      <TextField
        size="small"
        onChange={(e) => setValue(e.target.value)}
        variant="outlined"
        label="Buscar por nome"
        fullWidth
        style={{
          width: '80%',
          margin: 20,
        }}
      />
      <List>
        {emails && emails.filter(
          (item) => (value && item.name
            ? item.name.toLowerCase().includes(value.toLowerCase()) : item),
        ).map((email) => (props.exclude !== email.objectId ? (
          <MyItem fetchData={fetchData} handleListItemClick={handleListItemClick} email={email} key={email.objectId} dataProvider={dataProvider} />
        ) : null))}

        {/* <ListItem autoFocus button onClick={() => handleListItemClick('addAccount')}>
          <ListItemAvatar>
            <Avatar>
              <AddIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Add account" />
        </ListItem> */}
      </List>
    </Dialog>
  );
}

SelectCrud.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
