import React, { useState, useEffect } from 'react';
import {
  useDataProvider, useRedirect, useLogout,
  MenuItemLink, useTranslate,
} from 'react-admin';
import {
  Box,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import Miniuser from '../static/img/miniuser.png';
import Logo from '../static/img/logo.png';
// if(Parse.applicationId !== null)
//  user = Parse.User.current()
// if(!userid && !user){
//     console.log("sin usuario")
//     window.location.href = "/cliente/#/login";
// }
// class Menu extends Component {
function Menu({ onMenuClick }) {
  const translate = useTranslate();
  const [userFetch, setUser] = useState(null);
  const dataProvider = useDataProvider();
  const perfil = localStorage.getItem('perfil') || null;
  const redirect = useRedirect();
  const logout = useLogout();
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  // const [open] = useToggleSidebar();
  // const [state, setState] = useState({
  //   menuCatalog: false,
  //   menuComercial: false,
  //   menuCustomers: false,
  // });
  // const handleToggle = (menu) => {
  //   setState((state) => ({ ...state, [menu]: !state[menu] }));
  // };

  useEffect(() => {
    // const user = Parse.User.current();
    // dataProvider.setUser('users', {id:user.id})
    // .then(({data})=>console.log(data))
    if (perfil === 'profesor') {
      dataProvider.getList('profesores', {
        filter: {
          usuarioId: localStorage.getItem('userId'),
        },
      })
        .then(({ data }) => {
          if (data && data[0]) setUser(data[0]);
        })
        .catch((error) => console.log(error));
    }

    if (perfil === 'alumno') {
      dataProvider.getList('alumnos', {
        filter: {
          usuarioId: localStorage.getItem('userId'),
        },
      })
        .then(({ data }) => {
          if (data && data.length && data[0]) setUser(data[0]);
        })
        .catch((error) => console.log(error));
    }
  }, []);

  // render() {
  //     const { onMenuClick } = this.props;
  //     const { user } = this.state;

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) => theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      }}
    >
      {' '}
      {Logo
                && (
                <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
                  <a style={{ cursor: 'pointer' }} onClick={() => redirect('/')}>
                    <img
                      alt="LOGO"
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        WebkitTextOverflow: 'ellipsis',
                        textOverflow: 'ellipsis',
                        width: '90%',
                        margin: '20px 0 10px 10px',
                      }}
                      src={Logo}
                    />
                  </a>
                </div>
                )}
      {userFetch && (
      <MenuItemLink
        to={perfil === 'profesor' ? `/profesores/${userFetch.objectId}` : `/alumnos/${userFetch.objectId}`}
        primaryText={`${userFetch.nombre ? userFetch.nombre : ''}`}
        leftIcon={<img alt="agenda" style={{ width: 30 }} src={userFetch.avatar ? userFetch.avatar : Miniuser} />}
        onClick={onMenuClick}
      />
      )}
      <MenuItemLink
        to="/eventos"
        className="menu-agenda"
        primaryText={translate('ra.label.agenda')}
        onClick={onMenuClick}
      />
      {perfil !== 'alumno' && (
      <MenuItemLink
        to="/alumnos"
        className="menu-alunos"
        primaryText={translate('ra.label.alunos')}
        onClick={onMenuClick}
      />
      )}
      <MenuItemLink
        to="/cursos"
        className="menu-cursos"
        primaryText={translate('ra.label.cursos')}
        onClick={onMenuClick}
      />
      {perfil !== 'profesor' && perfil !== 'alumno' && (
      <MenuItemLink
        to="/financiero"
        className="menu-financeiro"
        primaryText={translate('ra.label.financeiro')}
        onClick={onMenuClick}
      />
      )}
      {perfil !== 'alumno' && (
      <MenuItemLink
        to="/material"
        className="menu-material"
        primaryText={translate('ra.label.material')}
        onClick={onMenuClick}
      />
      )}
      {perfil !== 'profesor' && perfil !== 'alumno' && (
      <MenuItemLink
        to="/configuracion"
        className="menu-configuracoes"
        primaryText={translate('ra.label.configuracoes')}
        onClick={onMenuClick}
      />
      )}
      <MenuItemLink
        to="/login"
        className="menu-sair"
        primaryText={translate('ra.label.sair')}
        onClick={() => logout()}
      />
    </Box>
  );
  // }
}

// const mapStateToProps = state => ({
//     open: state.admin.ui.sidebarOpen,
//     theme: state.theme,
//     locale: state.i18n.locale,
// });

// const enhance = compose(
//     withRouter,
//     connect(
//         mapStateToProps,
//         {}
//     ),
//     translate
// );

export default Menu;
