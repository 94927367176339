import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { ThemeProvider } from '@material-ui/styles';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import PasswordStrengthBar from 'react-password-strength-bar';
import { Notification, useDataProvider } from 'react-admin';
import { useLogin, useNotify } from 'ra-core';
import CambiarClave from './CambiarClave';
import { ButtonSubmit } from '../componentes/Buttons';
import ImagemFundo from '../static/img/fondo.png';

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    size="small"
    fullWidth
  />
);

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    minWidth: '100vw',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundImage: `url(${ImagemFundo})`,
    backgroundColor: '#004aad',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    top: '-16px',
  },
  imagen: {
    width: 300,
    height: 'auto',
    marginTop: 60,
  },
  card: {
    minWidth: 300,
    marginTop: '2em',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
  },
  hint: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.palette.primary.main,
  },
  politicas: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.primary.main,
  },
  form: {
    padding: '1em',
  },
  input: {
    marginTop: '1em',
  },
  actions: {
    padding: '0 1em 1em 1em',
    flexDirection: 'column',
  },
  link: {
    alignSelf: 'center',
  },
  title: {
    marginTop: '2em',
    color: theme.palette.primary.main,
  },
  botonLogin: {
    borderRadius: 10,
    backgroundColor: '#004AAD',
  },
}));

function MyLoginPage({ theme }) {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState('login');
  const login = useLogin();
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    //
  });

  const submit = async (auth) => {
    setLoading(true);
    console.log(auth);
    if (auth.username && auth.email && auth.password) {
      /**
             * Estoy en un registo
             */
      console.log('registro');
      const { data: alumno } = await dataProvider.getList(
        'alumnos',
        {
          filter: {
            correo: auth.email,
          },
        },
      );
      const { data: profesor } = await dataProvider.getList(
        'profesores',
        {
          filter: {
            correo: auth.email,
          },
        },
      );

      console.log(alumno);
      console.log(profesor);
      if (alumno.length || profesor.length) {
        auth.perfil = alumno.length ? 'alumno' : 'profesor';
        dataProvider.create('users', { data: auth })
          .then(() => {
            setLoading(false);
            dataProvider.getList(
              'users',
              {
                filter: {
                  email: auth.email,
                },
              },
            ).then(({ data }) => {
              console.log(data);
              if (alumno.length) {
                console.log(alumno[0].objectId);
                dataProvider.update('alumnos', { id: alumno[0].objectId, data: { usuarioId: data[0].objectId } })
                  .then(() => {
                    login({
                      username: auth.username,
                      password: auth.password,
                    })
                      .then((user) => {
                        console.log(user);
                        // localStorage.setItem('userId', user.id);
                        // Actualizo la tabla
                      })
                      .catch((error) => {
                        setLoading(false);
                        notify(
                          typeof error === 'string'
                            ? error
                            : typeof error === 'undefined' || !error.message
                              ? 'ra.auth.sign_in_error'
                              : error.message,
                          'warning',
                        );
                      });
                  });
              }
              if (profesor.length) {
                console.log(profesor[0].objectId);
                dataProvider.update('profesores', { id: profesor[0].objectId, data: { usuarioId: data[0].objectId } })
                  .then(() => {
                    login({
                      username: auth.username,
                      password: auth.password,
                    })
                      .then((user) => {
                        console.log(user);
                        // localStorage.setItem('userId', user.id);
                        // Actualizo la tabla
                      })
                      .catch((error) => {
                        setLoading(false);
                        notify(
                          typeof error === 'string'
                            ? error
                            : typeof error === 'undefined' || !error.message
                              ? 'ra.auth.sign_in_error'
                              : error.message,
                          'warning',
                        );
                      });
                  });
              }
            });
          })
          .catch((error) => {
            setLoading(false);
            alert(error);
            notify(
              typeof error === 'string'
                ? error
                : typeof error === 'undefined' || !error
                  ? 'ra.auth.sign_in_error'
                  : error,
              'warning',
            );
          });
      } else if (!alumno.length && !profesor.length) {
        setLoading(false);
        alert('Su correo electrónico no fué encontrado en nuestra base de datos de alumnos o profesores');
        notify(
          'Su correo electrónico no fué encontrado en nuestra base de datos de alumnos o profesores',
          'warning',
        );
      }
    } else if (auth.username && auth.password) {
      /**
             * Estoy en un login
             */
      console.log('login');
      setLoading(true);
      login(auth)
        .then((user) => {
          console.log(user);
          // localStorage.setItem('userId', user.id);
        })
        .catch( // () => notify('Invalid email or password')
          (error) => {
            console.log(error);
            setLoading(false);
            notify(
              typeof error === 'string'
                ? error
                : typeof error === 'undefined' || !error.message
                  ? 'ra.auth.sign_in_error'
                  : error.message,
              'warning',
            );
          },
        );
    }
  };

  const validate = () => {
    // const errors = {}
    // if (!values.username) {
    //   errors.username = 'Campo obrigatorio'
    // }
    // if (!values.documento) {
    //   errors.documento = 'Campo obrigatorio'
    // }
    // if (!values.password) {
    //   errors.confirm = 'Campo obrigatorio'
    // } else if (values.password !== values.password2) {
    //   errors.password2 = 'As senhas não coincidem'
    // }
    // return errors
  };
  const pswrdValidate = () => {
    // console.log(value);
    // setPassword(value);
    // return (value ? undefined : 'Required')
  };

  return (
    <ThemeProvider theme={theme}>
      {/* <SimpleForm
            save={submit}
            validate={validate}
            > */}
      <Form
        onSubmit={submit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className={classes.main}>
              {/* <Typography variant="h2" className={classes.title} style={{color: theme.palette.primary.main}}>Mobilia</Typography> */}
              {/* <img className={classes.imagen} src={logo} /> */}
              {currentPage && currentPage === 'login' ? (
                <Card className={classes.card}>
                  <div className={classes.avatar}>
                    {/* <Avatar className={classes.icon}>
                                        <LockIcon />
                                    </Avatar> */}
                    {/* <Link style={{fontSize: 14, cursor: 'pointer'}} onClick={()=>setCurrentPage('cadastro')}>Cadastrar-se</Link> */}
                  </div>
                  <div className={classes.form}>
                    <div className={classes.input}>
                      <Field
                        autoFocus
                        name="username"
                                            // @ts-ignore
                        component={renderInput}
                        label="Usuario"
                        variant="outlined"
                        disabled={loading}
                      />
                    </div>
                    <div className={classes.input}>
                      <Field
                        name="password"
                        variant="outlined"
                                            // @ts-ignore
                        component={renderInput}
                        label="Senha"
                        type="password"
                        disabled={loading}
                      />
                    </div>
                  </div>
                  <CardActions className={classes.actions}>
                    <ButtonSubmit
                      label="Ingresar"
                      buttonClass="azul-verde"
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={loading}
                      fullWidth
                    >
                      {loading && (
                        <CircularProgress
                          size={25}
                          thickness={2}
                        />
                      )}
                      Ingresar
                    </ButtonSubmit>
                  </CardActions>
                  <div className={classes.hint}>
                    <Button
                      onClick={() => setCurrentPage('cadastro')}
                      color="primary"
                      fullWidth
                      style={{ fontWeight: 'bold' }}
                    >
                      Registrarme
                    </Button>
                  </div>
                  <CambiarClave />
                  {/* <CardActions>
                                    <FacebookLogin
                                        fields="name,email,picture"
                                        appId="427553282021761"
                                        // autoLoad
                                        callback={responseFacebook}
                                        render={renderProps => (
                                            <Button
                                            fullWidth
                                            variant="contained"
                                            color="blue"
                                            className={classes.submit}
                                            onClick={renderProps.onClick}
                                            style={{backgroundColor: '#3b5998', color: '#ffffff'}}
                                            startIcon={<FacebookIcon />}
                                            >
                                            Entrar com facebook
                                            </Button>
                                        )}
                                    />
                                </CardActions> */}
                  {/* <CardActions style={{justifyContent: 'center'}}>
                                    <GoogleLogin
                                        // render={renderProps => (
                                        //     <Button
                                        //     fullWidth
                                        //     variant="contained"
                                        //     color="blue"
                                        //     className={classes.submit}
                                        //     onClick={renderProps.onClick}
                                        //     style={{backgroundColor: 'white', color: 'rgba(0, 0, 0, 0.54)'}}
                                        //     startIcon={<FacebookIcon />}
                                        //     >
                                        //     Entrar com Google
                                        //     </Button>
                                        // )}
                                        buttonText="Entrar com Google"
                                        clientId="443908196844-garml0vbko8tfgon7ou63gtvcj0db8if.apps.googleusercontent.com"
                                        onSuccess={responseGoogle}
                                        onFailure={responseGoogle}
                                        // isSignedIn={true}
                                        cookiePolicy={'single_host_origin'}
                                    />
                                </CardActions> */}
                </Card>
              ) : (
                <Card className={classes.card}>
                  <div className={classes.avatar}>
                    <Link style={{ fontSize: 18, cursor: 'pointer' }} onClick={() => setCurrentPage('login')}>Volver</Link>
                  </div>
                  <div className={classes.form}>
                    <Typography variant="h5" style={{ fontWeight: 'bold', color: '#3f51b5' }}>Ingrese los datos de su cuenta</Typography>
                    <div className={classes.input}>
                      <Field
                        autoFocus
                        name="username"
                                            // @ts-ignore
                        component={renderInput}
                        label="Usuario"
                        disabled={loading}
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.input}>
                      <Field
                        autoFocus
                        name="email"
                                            // @ts-ignore
                        component={renderInput}
                        label="correo electrónico"
                        disabled={loading}
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.input}>
                      <Field
                        name="password"
                                            // @ts-ignore
                        component={renderInput}
                        label="Contraseña"
                        type="password"
                        disabled={loading}
                        validate={pswrdValidate}
                        variant="outlined"
                      />
                      {/* <PasswordStrengthBar password={password}/> */}
                      {/* <Typography style={{ maxWidth: 250, fontSize: 12, color: '#707070' }}>Use seis ou mais caracteres com uma combinação de letras e números</Typography> */}
                    </div>
                  </div>
                  <CardActions className={classes.actions}>
                    <ButtonSubmit
                      label="Registrarse"
                      buttonClass="azul-verde"
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={loading}
                      fullWidth
                    >
                      {loading && (
                        <CircularProgress
                          size={25}
                          thickness={2}
                        />
                      )}
                      Registrarse
                    </ButtonSubmit>
                  </CardActions>
                </Card>
              )}
              <Notification />
            </div>
          </form>
        )}
      />
    </ThemeProvider>
  );
}

export default MyLoginPage;
