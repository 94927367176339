import React, { useState } from 'react';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  FormWithRedirect,
  useDataProvider,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Linkattach from '../static/img/linkattach.png';

function CreateLinkButton(props) {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [showDialog, setShowDialog] = useState(false);
  const refresh = useRefresh();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    const myPics = record.pictures ? [...record.pictures, {
      src: values.enlace,
      title: values.enlace,
    }] : [{
      src: values.enlace,
      title: values.enlace,
    }];

    await dataProvider.update(props.resource, {
      id: props.id,
      data: {
        pictures: myPics,
      },
    });

    refresh();
  };

  return (
    <>
      <Button onClick={handleClick} label="">
        <img alt="Attach" src={Linkattach} />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Crear Movil"
      >
        <DialogTitle>Agregar Link</DialogTitle>
        <FormWithRedirect
          resource="moviles"
          save={handleSubmit}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving,
          }) => (
            <>
              <DialogContent>
                <TextInput variant="outlined" fullWidth validate={required()} source="enlace" />
              </DialogContent>
              <DialogActions>
                <Button
                  className="cancelar-btn"
                  label="ra.label.cancelar"
                  onClick={handleCloseClick}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                                  handleSubmitWithRedirect
                              }
                  pristine={pristine}
                  saving={saving}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default CreateLinkButton;
